export const PILL_COLORS_LIST = [
  { name: 'Purple', pill: '#9F8FEF', text: '#171719' },
  { name: 'Blue', pill: '#579DFF', text: '#171719' },
  { name: 'Green', pill: '#4CCE97', text: '#171719' },
  { name: 'Teal', pill: '#6CC3E0', text: '#171719' },
  { name: 'Yellow', pill: '#F6CC47', text: '#171719' },
  { name: 'Orange', pill: '#FEA363', text: '#171719' },
  { name: 'Red', pill: '#F87168', text: '#171719' },
  { name: 'Grey', pill: '#8C9BAA', text: '#171719' },
];

export const DARK_PILL_COLORS_LIST = [
  { name: 'Dark Purple', pill: '#362C63', text: '#B8ACF6' },
  { name: 'Dark Blue', pill: '#0A326C', text: '#85B8FF' },
  { name: 'Dark Green', pill: '#174B35', text: '#7DE2B8' },
  { name: 'Dark Teal', pill: '#154555', text: '#9DD9EE' },
  { name: 'Dark Yellow', pill: '#533F03', text: '#F6CC47' },
  { name: 'Dark Orange', pill: '#5C2804', text: '#FEA363' },
  { name: 'Dark Red', pill: '#5D1F1A', text: '#FD9891' },
  { name: 'Dark Grey', pill: '#454F59', text: '#9FADBC' },
];
