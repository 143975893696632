import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import * as FacilityService from 'app/business-logic/services/facility-service';
import { mapConfigurationChanged } from 'app/redux/profile/facility/actions';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { LogManager } from 'core/logging/LogManager';
import { useEffect } from 'react';

const logger = LogManager.getLogger('useMapConfigurationQuery');

export const useMapConfigurationQuery = () => {
  const { facility } = useAppSelector(state => state.profile);
  const { companyAlias, facilityAlias } = facility;
  const dispatch = useAppDispatch();
  const mapConfigurationQuery = useQuery({
    queryKey: [QUERY_KEY.authMapConfiguration, companyAlias, facilityAlias],
    queryFn: () => {
      if (!companyAlias || !facilityAlias) throw new Error('No preferred facility specified');
      return FacilityService.fetchFacilityMapConfiguration({
        companyAlias,
        facilityAlias,
      });
    },
    enabled: !!companyAlias && !!facilityAlias,
  });
  const { data, isSuccess, isError } = mapConfigurationQuery;
  useEffect(() => {
    if (isError) {
      return logger.warn('Failed to fetch map configuration');
    }
    if (isSuccess) {
      if (!data) return logger.warn('No map configuration data');
      logger.debug('Setting map configuration');
      dispatch(mapConfigurationChanged(data));
    }
  }, [isSuccess, isError, data, dispatch]);

  return mapConfigurationQuery;
};
