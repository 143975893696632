import { NAV_BAR_WIDTH } from 'app/views/components/navigation-bar/NavigationBar.styles';
import { styled, css } from 'styled-components';

const mobileStyles = css`
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        flex-direction: column;
      `
    )}
`;

export const AppWrapper = styled.div`
  display: flex;
  max-height: 100%;
  height: 100%;
  ${mobileStyles}
`;

export const NavWrapper = styled.div`
  width: ${NAV_BAR_WIDTH}px;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: none;
      `
    )}
`;

const desktopStyles = css`
  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        margin-left: 1px;
        width: calc(100% - ${NAV_BAR_WIDTH}px);
      `
    )}
`;

export const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  z-index: 0;
  overflow: hidden;
  ${desktopStyles}
`;
