import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { NoiseEvent, classifyNoiseEvent } from 'app/business-logic/services/noise-service';
import { useAppSelector } from 'app/redux/store';
import { useI18n } from 'core/hooks/useI18n';
import { useCallback } from 'react';
import { EventClassificationState } from './EventClassification';
import { DateTime } from 'luxon';
import { ClassifyNoiseEventMutation } from 'app/__generated__/global';

type MutationParams = Parameters<typeof classifyNoiseEvent>[0];

export const useClassifyNoiseEvent = ({
  event,
  onSuccess,
  onError,
}: {
  event: NoiseEvent;
  onSuccess?: (data: ClassifyNoiseEventMutation, params: MutationParams) => void;
  onError?: (error: unknown, params: MutationParams) => void;
}) => {
  const { l10n } = useI18n('app/components', 'errors.unexpected');
  const { id: eventId } = event;
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (params: MutationParams) => {
      return classifyNoiseEvent(params);
    },
    onSuccess: (data, params) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.noiseEventsByAlertId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.noiseEventHistory],
      });
      onSuccess?.(data, params);
    },
    onError: (error, params) => {
      window.alert(l10n('title'));
      onError?.(error, params);
    },
  });
  const { details } = useAppSelector(state => state.profile.user);
  const { id: userId } = details ?? {};
  return {
    classifyNoiseEvent: useCallback(
      (values: EventClassificationState) => {
        if (!userId) throw new Error('No user ID found');
        const utcModifiedTime = DateTime.now().toUTC().toISO();
        if (!utcModifiedTime) throw new Error('Invalid date');
        return mutate({
          eventClassificationState: values,
          eventId,
          modifiedByUserId: userId,
          utcModifiedTime,
        });
      },
      [eventId, userId, mutate]
    ),
    isPending,
  };
};
