import { useQuery } from '@tanstack/react-query';
import { getAlertResponseCategories } from 'app/business-logic/services/alerts-services/getAlertResponseCategories';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { useAlertConfigurations } from 'app/views/alerts/pages/alert-history/alert-history-filters/useAlertConfigurations';
import { useMemo } from 'react';

export const useAlertResponseCategories = ({
  allCategories,
}: {
  allCategories?: boolean;
} = {}) => {
  const {
    data: alertResponseCategories,
    isLoading: isLoadingCategories,
    isFetching: isFetchingCategories,
  } = useQuery({
    queryKey: [QUERY_KEY.alertResponseCategories],
    queryFn: () => getAlertResponseCategories(),
  });
  const {
    data: alertConfigurations,
    isLoading: isLoadingConfigurations,
    isFetching: isFetchingConfigurations,
  } = useAlertConfigurations();
  const configuredCategories = useMemo(
    () =>
      alertResponseCategories?.filter(
        category => alertConfigurations?.some(config => config.alertTypeId === category.id)
      ),
    [alertConfigurations, alertResponseCategories]
  );
  const isLoading = isLoadingCategories || isLoadingConfigurations;
  const isFetching = isFetchingCategories || isFetchingConfigurations;
  return {
    data: allCategories ? alertResponseCategories : configuredCategories,
    isLoading,
    isFetching,
  };
};
