import { useAppSelector } from 'app/redux/store';
import { useAlertById } from 'app/views/alerts/hooks';

import { Classes } from '@blueprintjs/core';

import { EventsDrawerHeading } from './EventsDrawerTitle.styles';

export const EventsDrawerTitle = () => {
  const { selectedAlert } = useAppSelector(state => state.ui);
  const { data, isLoading } = useAlertById(selectedAlert);
  const { alertScriptConfigurationName } = data ?? {};
  const className = isLoading ? Classes.SKELETON : '';
  return <EventsDrawerHeading className={className}>{alertScriptConfigurationName ?? ''}</EventsDrawerHeading>;
};
