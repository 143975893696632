import { useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';

export const useSeekTo = (waveSurferRef: React.MutableRefObject<WaveSurfer | undefined>) => {
  return useCallback(
    (progressSeconds: number) => {
      waveSurferRef.current?.seekTo(progressSeconds);
    },
    [waveSurferRef]
  );
};
