import { Classes } from '@blueprintjs/core';
import { styled } from 'styled-components';

export const SimulationToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.spacing()}px 0;

  .${Classes.SWITCH} {
    margin-left: auto;
    margin-bottom: 0;
  }
`;

export const SimulationToggleButton = styled.button`
  appearance: none;
  border: 0;
  background-color: transparent;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
`;

export const SimulationToggleLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacing()}px;
`;

export const BlastModelLegendWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  padding: ${({ theme }) => theme.spacing()}px;
`;

export const BlastModelLegendTitle = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
`;

export const BlastModelLegendDescription = styled.div`
  font-size: 1.2rem;
  text-align: center;
  margin: ${({ theme }) => theme.spacing(1)}px 0;
  color: ${({ theme }) => theme.colors.mono.text03};
`;
