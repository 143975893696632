import { Label } from 'app/components/form/label';
import { srOnly } from 'app/visuals/mixins/srOnly';
import { styled } from 'styled-components';

export const FilterWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export const FilterLabel = styled(Label)`
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: ${({ theme: { spacing } }) => spacing()}px;
  color: ${({ theme }) => theme.colors.mono.text02};
  white-space: nowrap;
`;

export const FilterHiddenInput = styled.input.attrs({ readOnly: true, tabIndex: -1 })`
  ${() => srOnly};
`;
