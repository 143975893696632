import { css } from 'styled-components';
import { Classes } from '@blueprintjs/core';
import { Classes as DatepickerClasses } from '@blueprintjs/datetime';

export const blueprint = css`
  // Blueprint style overrides - we nest these in the 'body' tag to make them more specific than the defaults.
  body {
    .${Classes.DARK}, .${Classes.PORTAL} {
      &.${Classes.POPOVER}, .${Classes.POPOVER} {
        .${DatepickerClasses.DATEPICKER_YEAR_SELECT} {
          min-width: 80px;
        }

        .${DatepickerClasses.DATEPICKER_MONTH_SELECT} {
          min-width: 100px;
          .${({ theme }) => theme.blueprint.namespace}-icon-double-caret-vertical {
            right: 0 !important;
          }
        }

        .${DatepickerClasses.TIMEPICKER} {
          .${DatepickerClasses.TIMEPICKER_INPUT_ROW} {
            background-color: ${({ theme }) => theme.colors.mono.text01};
          }

          .${DatepickerClasses.TIMEPICKER_INPUT} {
            color: ${({ theme }) => theme.colors.mono.ui01};
          }

          .${Classes.HTML_SELECT} > select {
            background-color: ${({ theme }) => theme.colors.mono.text01};
            color: ${({ theme }) => theme.colors.mono.ui01};
          }
        }

        .DayPicker {
          .DayPicker-Body {
            min-height: 180px;
          }
        }
      }

      .${Classes.INPUT} {
        &:focus,
        &.${Classes.ACTIVE} {
          box-shadow: none;
        }
      }

      a {
        color: ${({ theme }) => theme.colors.brand.brand02};
        &:hover {
          color: ${({ theme }) => theme.colors.brand.brand02};
        }
      }
    }
  }
`;
