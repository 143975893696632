import { ReturnHome } from 'app/components/error/ReturnHome';
import { useI18n } from 'core/hooks/useI18n';
import { ErrorLogo, Inner, MainHeading, Wrapper, Text } from './Error.styles';

export const NotFound = () => {
  const { l10n } = useI18n('app/components', 'errors');
  return (
    <Wrapper>
      <Inner>
        <ErrorLogo />
        <MainHeading>404</MainHeading>
        <Text>{l10n('404.title')}</Text>
        <ReturnHome />
      </Inner>
    </Wrapper>
  );
};
