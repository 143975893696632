import { NoiseEventClassificationCategory } from 'app/business-logic/services/noise-service/noiseService.types';
import { NestedCategory } from './createNestedCategories';

export type LeveledCategory = {
  level: number;
} & NoiseEventClassificationCategory;

export const createLeveledCategories = (categories: NestedCategory[]) => {
  return categories.flatMap(getLeveledCategory);
};

function getLeveledCategory({ children, ...rest }: NestedCategory): LeveledCategory[] {
  return [
    {
      ...rest,
    },
    ...children.flatMap(getLeveledCategory),
  ];
}
