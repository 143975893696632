const REACT_QUERY_KEY_VALUE_PAIRS = {
  alertById: 'alertById',
  alertConfiguration: 'alertConfiguration',
  alertConfigurations: 'alertConfigurations',
  alertDrawerData: 'alertDrawerData',
  alertHistory: 'alertHistory',
  alertHistoryExport: 'alertHistoryExport',
  alertInputVariables: 'alertInputVariables',
  alertMonitors: 'alertMonitors',
  alertOutputs: 'alertOutputs',
  alertPointReports: 'alertPointReports',
  alertPoints: 'alertPoints',
  alertResponders: 'alertResponders',
  alertResponse: 'alertResponse',
  alertResponseCategories: 'alertResponseCategories',
  alertResponseClassifications: 'alertResponseClassifications',
  allMeasureGroups: 'allMeasureGroups',
  allMonitorsDetails: 'allMonitorsDetails',
  annotations: 'annotations',
  authAccessControls: 'authAccessControls',
  authAccessibleFacilities: 'authAccessibleFacilities',
  authSingleFacility: 'authSingleFacility',
  authApiKey: 'authApiKey',
  authMapConfiguration: 'authMapConfiguration',
  authUserDetails: 'authUserDetails',
  azureMapAttribution: 'azureMapAttribution',
  bingMetadata: 'bingMetadata',
  blastingKmlOverlays: 'blastingKmlOverlays',
  blastingScenariosSummary: 'blastingScenariosSummary',
  blastingSimulation: 'blastingSimulation',
  chartProcessVariableData: 'chartProcessVariableData',
  chartProcessVariableIds: 'chartProcessVariableIds',
  customGroupSensors: 'customGroupSensors',
  customImageOverlays: 'customImageOverlays',
  domainBoundaries: 'domainBoundaries',
  elevationByLatLng: 'elevationByLatLng',
  emissionsDataFiles: 'emissionsDataFiles',
  emissionSources: 'emissionSources',
  facilityUsers: 'facilityUsers',
  fileSystemObjects: 'fileSystemObjects',
  fileSystemObjectsRootDirectory: 'fileSystemObjectsRootDirectory',
  fileSystemObjectsSentinelReportsDirectory: 'fileSystemObjectsSentinelReportsDirectory',
  fileSystemObjectsSentinelReportsDirectoryTrackingJson: 'fileSystemObjectsSentinelReportsDirectoryTrackingJson',
  gisModelOptions: 'gisModelOptions',
  incidentIntelligencePathModel: 'incidentIntelligencePathModel',
  incidentIntelligenceSummary: 'incidentIntelligenceSummary',
  incidentIntelligenceTickets: 'incidentIntelligenceTickets',
  individualBlastingScenario: 'individualBlastingScenario',
  individualTrajectory: 'individualTrajectory',
  influenceArcs: 'influenceArcs',
  latestProcessDataTime: 'latestProcessDataTime',
  linkedAlertOutputs: 'linkedAlertOutputs',
  lowLatencyNoiseSeries: 'lowLatencyNoiseSeries',
  mapCredit: 'mapCredit',
  mapMonitors: 'mapMonitors',
  measurementTypeDetails: 'measurementTypeDetails',
  measurementTypeOptions: 'measurementTypeOptions',
  measurementTypes: 'measurementTypes',
  metStations: 'metStations',
  modelData: 'modelData',
  modelDataAvailability: 'modelDataAvailability',
  modelDetails: 'modelDetails',
  modellingIntervals: 'modellingIntervals',
  modelOptions: 'modelOptions',
  models: 'models',
  modelTypeConfig: 'modelTypeConfig',
  monitorCredentialDetails: 'monitorCredentialDetails',
  monitorCredentials: 'monitorCredentials',
  monitorDetails: 'monitorDetails',
  monitorDisplayConfig: 'monitorDisplayConfig',
  monitorLocation: 'monitorLocation',
  monitors: 'monitors',
  monitorWeatherData: 'monitorWeatherData',
  noiseEventAudio: 'noiseEventAudio',
  noiseEventClassificationCategories: 'noiseEventClassificationCategories',
  noiseEventClassifications: 'noiseEventClassifications',
  noiseEventsByAlertId: 'noiseEventsByAlertId',
  noiseEventHistory: 'noiseEventHistory',
  pollutants: 'pollutants',
  processVariableTrendData: 'processVariableTrendData',
  realTimeNoiseDevices: 'realTimeNoiseDevices',
  regularKmlOverlays: 'regularKmlOverlays',
  reportDefinitions: 'reportDefinitions',
  reports: 'reports',
  sensorGroups: 'sensorGroups',
  sensors: 'sensors',
  sourceAreaData: 'sourceAreaData',
  sourceAreaProcessGroups: 'sourceAreaProcessGroups',
  spotData: 'spotData',
  tempBlastingKmlOverlays: 'tempBlastingKmlOverlays',
  tempKmlOverlayFiles: 'tempKmlOverlayFiles',
  timeZones: 'timeZones',
  traceVariablesSeries: 'traceVariablesSeries',
  trajectoriesList: 'trajectoriesList',
  trajectoryRunTrackData: 'trajectoryRunTrackData',
  trajectorySummaryTrack: 'trajectorySummaryTrack',
  trajectoryTypes: 'trajectoryTypes',
  trendLineData: 'trendLineData',
  units: 'units',
  userDetails: 'userDetails',
  vibrationEvents: 'vibrationEvents',
  weather: 'weather',
  weatherMonitors: 'weatherMonitors',
  weatherMonitorSensors: 'weatherMonitorSensors',
  weatherStations: 'weatherStations',
  windowsTimeZones: 'windowsTimeZones',
} as const;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const REACT_QUERY_KEY_VALUES = Object.values(REACT_QUERY_KEY_VALUE_PAIRS);

type ReactQueryKeys = (typeof REACT_QUERY_KEY_VALUES)[number];

export const QUERY_KEY: { [Key in ReactQueryKeys]: Key } = {
  ...REACT_QUERY_KEY_VALUE_PAIRS,
};
