import { gql } from '@apollo/client';

export const GET_NOISE_EVENT_CLASSIFICATION_CATEGORIES = gql(`
  query GetNoiseEventClassificationCategories {
    lookup {
      categories {
        id
        name
        slug
        parentId
      }
    }
  }
`);
