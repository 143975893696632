import { useCallback, useEffect, useState } from 'react';

import WaveSurfer from 'wavesurfer.js';
import { WaveSurferRef } from './types';

type LoadArgs = Parameters<WaveSurfer['load']>;

export const useLoad = (waveSurferRef: WaveSurferRef): WaveSurfer['load'] => {
  const [loadArgs, setLoadArgs] = useState<LoadArgs>();

  useEffect(() => {
    // Only attempt to load audio when the WaveSurfer instance is ready
    if (!waveSurferRef.current) return;
    if (!loadArgs) return;
    waveSurferRef.current.load(...loadArgs);
  }, [loadArgs, waveSurferRef]);

  return useCallback(async (...args) => {
    setLoadArgs(args);
  }, []);
};
