const realTime = (root: string) => () => {
  return `${root}/real-time`;
};

const analyse = (root: string) => () => {
  return `${root}/analyse`;
};

export const gridRoutes = (root: string) => ({
  root,
  realTime: realTime(root),
  analyse: analyse(root),
});
