import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { fetchUserDetails } from 'app/business-logic/services/security-service/fetchUserDetails';
import { userDetailsChanged } from 'app/redux/profile/user/actions';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { LogManager } from 'core/logging/LogManager';
import { useEffect } from 'react';

const logger = LogManager.getLogger('useUserDetailsQuery');

export const useUserDetailsQuery = () => {
  const {
    facility: { companyAlias, facilityAlias },
  } = useAppSelector(state => state.profile);
  const dispatch = useAppDispatch();
  const userDetailsQuery = useQuery({
    queryKey: [QUERY_KEY.authUserDetails, companyAlias, facilityAlias],
    queryFn: () => {
      if (!companyAlias || !facilityAlias) throw new Error('No facility specified');
      return fetchUserDetails(companyAlias, facilityAlias);
    },
    enabled: !!companyAlias && !!facilityAlias,
  });
  const { data, isSuccess, isError } = userDetailsQuery;

  useEffect(() => {
    if (isError) {
      return logger.warn('Failed to fetch user details');
    }
    if (isSuccess) {
      if (!data) return logger.warn('No user details');
      logger.debug('Setting user details');
      dispatch(userDetailsChanged(data));
    }
  }, [data, dispatch, isError, isSuccess]);

  return userDetailsQuery;
};
