import { useCallback, useState } from 'react';

export const useSetIsPlaying = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const onPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);
  const onPause = useCallback(() => {
    setIsPlaying(false);
  }, []);
  return {
    isPlaying,
    onPlay,
    onPause,
  };
};
