import { useMemo } from 'react';
import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom';

export function useAllSearchParams() {
  const [searchParams] = useReactRouterSearchParams();
  return useMemo(() => {
    const allSearchParams: Record<string, string[]> = {};
    for (const [key, value] of searchParams.entries()) {
      if (!(key in allSearchParams)) {
        allSearchParams[key] = [value];
      } else {
        const existingValue = allSearchParams[key];
        if (!existingValue) continue;
        if (typeof existingValue === 'string') {
          allSearchParams[key] = [existingValue, value];
        } else {
          allSearchParams[key] = [...existingValue, value];
        }
      }
    }
    return allSearchParams;
  }, [searchParams]);
}
