import { useRef } from 'react';

import type { WaveSurferOptions } from './types';
import { useDurationMilliseconds } from './use-duration-milliseconds';
import { useIsLoading } from './use-is-loading';
import { useIsPlaying } from './use-is-playing';
import { useLoadingProgressPercentage } from './use-loading-progress';
import { useMillisecondsTimestamp } from './use-milliseconds-timestamp';
import { useCreateWaveSurfer } from './useCreateWaveSurfer';
import { useLoad } from './useLoad';
import { useLoadBlob } from './useLoadBlob';
import { usePause } from './usePause';
import { usePlay } from './usePlay';
import { usePlayPause } from './usePlayPause';
import { useSeekTo } from './useSeekTo';

/**
 * Returns values used to create a waveform and control audio playback
 * @param options WaveSurfer options - https://wavesurfer-js.org/docs/options.html
 * @returns object.containerRef - ref to use with the element to contain the waveform
 * @returns object.waveSurferRef - ref containing the WaveSurfer instance. You have full control over the waveform through this instance
 * @returns object.load - https://wavesurfer-js.org/docs/methods.html
 * @returns object.loadBlob - https://wavesurfer-js.org/docs/methods.html
 * @returns object.pause - https://wavesurfer-js.org/docs/methods.html
 * @returns object.play - https://wavesurfer-js.org/docs/methods.html
 * @returns object.playPause - https://wavesurfer-js.org/docs/methods.html
 * @returns object.isPlaying - boolean
 * @returns object.millisecondsTimestamp - timestamp in milliseconds
 * @returns object.loadingProgressPercentage - loading progress as percentage
 * @returns object.isLoading - if audio is loading
 * @returns object.durationMilliseconds - duration of the audio in milliseconds
 */
export const useWaveSurfer = (options: WaveSurferOptions = {}) => {
  const memoisedOptions = useRef(options);
  const { containerRef, waveSurferRef } = useCreateWaveSurfer(memoisedOptions.current);
  const load = useLoad(waveSurferRef);
  const loadBlob = useLoadBlob(waveSurferRef);
  const pause = usePause(waveSurferRef);
  const play = usePlay(waveSurferRef);
  const playPause = usePlayPause(waveSurferRef);
  const isPlaying = useIsPlaying(waveSurferRef);
  const millisecondsTimestamp = useMillisecondsTimestamp(waveSurferRef);
  const loadingProgressPercentage = useLoadingProgressPercentage(waveSurferRef);
  const isLoading = useIsLoading(waveSurferRef);
  const durationMilliseconds = useDurationMilliseconds(waveSurferRef);
  const seekTo = useSeekTo(waveSurferRef);
  return {
    containerRef,
    durationMilliseconds,
    isLoading,
    isPlaying,
    load,
    loadBlob,
    loadingProgressPercentage,
    millisecondsTimestamp,
    pause,
    play,
    playPause,
    waveSurferRef,
    seekTo,
  };
};
