import { styled } from 'styled-components';

export const StyledTabList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  font-size: 1.4rem;
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  overflow-x: auto;
`;

export const TabListItem = styled.li<{ $isSelected: boolean }>`
  margin: 0 ${({ theme }) => theme.spacing(2)}px;
  border-bottom: ${({ $isSelected, theme }) => $isSelected && `2px solid ${theme.colors.brand.brand02}`};
  min-height: 48px;
  white-space: nowrap;
  svg {
    margin-left: ${({ theme }) => theme.spacing()}px;
  }
`;

export const TabListLink = styled.button<{ $isSelected: boolean }>`
  && {
    color: ${({ theme }) => theme.colors.mono.text02};
    color: ${({ $isSelected, theme }) => $isSelected && theme.colors.brand.brand02};
    &:hover {
      color: ${({ theme }) => theme.colors.brand.brand02};
      text-decoration: none;
    }
  }
  display: flex;
  height: 100%;
  align-items: center;
  padding: ${({ theme }) => theme.spacing()}px 0;
  line-height: 1.6;
`;
