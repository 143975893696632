import { useAccessibleFacilities } from './useAccessibleFacilities';
import { useUserDetailsQuery } from './useUserDetailsQuery';
import { useAccessControlsQuery } from './useAccessControlsQuery';
import { useMapConfigurationQuery } from './useMapConfigurationQuery';

export const useAuthenticator = () => {
  const accessibleFacilitiesQuery = useAccessibleFacilities();
  const userDetailsQuery = useUserDetailsQuery();
  const accessControlsQuery = useAccessControlsQuery();
  const mapConfigurationQuery = useMapConfigurationQuery();
  return {
    accessibleFacilities: accessibleFacilitiesQuery,
    userDetails: userDetailsQuery,
    accessControls: accessControlsQuery,
    mapConfiguration: mapConfigurationQuery,
  };
};
