import { PopoverProps } from '@blueprintjs/core';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { DeepRequired } from 'app/types';
import { useCallback } from 'react';

type Offset = DeepRequired<PopoverProps>['modifiers']['offset']['options']['offset'];
type OffsetFunction = Extract<Offset, (...args: any[]) => any>;

export function useDateRangePopoverOffset(): OffsetFunction {
  const isMobile = useIsMobile();
  const offsetFunction: OffsetFunction = useCallback(
    ({ reference: { width, height, x, y } }) => {
      if (!isMobile) return [0, 8];
      // Move popover to top-left of window when on mobile to maximise available space
      return [-Math.abs(width + x), -Math.abs(height + y)];
    },
    [isMobile]
  );
  return offsetFunction;
}
