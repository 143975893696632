import { styled } from 'styled-components';
import { inputStyles } from '../input';

export const TextArea = styled.textarea`
  &&& {
    ${inputStyles}
    width: 100%;
    resize: vertical;
    font-family: inherit;
    min-height: 120px;
    padding: ${({ theme: { spacing } }) => `${spacing(1.2)}px ${spacing(1.5)}px`};
  }
`;
