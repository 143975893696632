import {
  StyledTable,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderText,
  TableRow,
} from 'app/components/table/StyledTable';
import { SortIcon } from 'app/components/table/table-header';
import { Throbber } from 'app/components/throbber';
import ChevonRightIcon from 'app/visuals/images/icons/chevron-right.svg?react';
import { styled, css } from 'styled-components';

const isFetchingStyles = css`
  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const EventsTableWrapper = styled.div`
  overflow: auto;
  position: relative;
  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        padding-top: 0;
      `
    )}
`;

export const EventsTable = styled(StyledTable)<{ $isFetching: boolean }>`
  position: relative;
  ${({ $isFetching }) => $isFetching && isFetchingStyles}
`;

const cellPadding = css`
  padding: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const EventsTableHeading = styled(TableHeader)``;

export const EventsTableHeadingInner = styled(TableHeaderText)`
  appearance: none;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  ${cellPadding}
  ${SortIcon} {
    position: static;
    margin-left: ${({ theme }) => theme.spacing()}px;
  }
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
    outline-offset: ${({ theme }) => theme.spacing()}px;
  }
`;

export const EventsTableHeadingText = styled.span``;

export const EventsTableCell = styled(TableCell)`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.text01};
  cursor: pointer;
  ${cellPadding}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden !important;
  width: fit-content !important;
  max-width: 200px !important;
  &:last-child {
    width: 100% !important;
    max-width: 100% !important;
  }
`;

export const ActionCell = styled(EventsTableCell)`
  cursor: default;
  padding: 0;
`;

export const EventsTableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.mono.ui01};
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.mapControlBox};
`;

const expandedRowStyles = css<{ $isSelectable?: boolean; $isExpanded?: boolean }>`
  background-color: ${({ $isExpanded, theme }) => $isExpanded && theme.colors.mono.ui04};
  &:hover {
    background-color: ${({ $isSelectable, theme }) => $isSelectable && theme.colors.mono.ui04};
  }
`;

export const EventsTableRow = styled(TableRow)<{ $isSelectable?: boolean; $isExpanded?: boolean }>`
  ${expandedRowStyles}
`;

export const EventsTableBody = styled(TableBody)`
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  ${EventsTableRow} {
    border-top: 1px solid ${({ theme }) => theme.colors.mono.ui05};
  }
`;

export const ExpandRowButton = styled.button`
  width: 40px;
  height: 40px;
  ${cellPadding}
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
  }
`;

export const ExpandRowButtonIcon = styled(ChevonRightIcon)<{ $isExpanded?: boolean }>`
  transition: transform 150ms ease;
  transform: ${({ $isExpanded }) => $isExpanded && `rotate(90deg)`};
`;

export const ExpandedCell = styled(EventsTableCell)`
  cursor: default;
`;

export const Loading = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin: 0;
  min-height: 120px;
  position: relative;
`;

export const NoEvents = styled.p`
  margin: 0;
  font-size: 1.6rem;
  text-align: center;
`;

export const NoMoreEvents = styled.p`
  margin: 0;
  font-size: 1.8rem;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const LoadingEventsThrobber = styled(Throbber)`
  position: sticky;
  bottom: 50%;
  top: unset;
  left: 50%;
  width: fit-content;
`;
