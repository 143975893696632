import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { AlertResponseClassification } from 'app/business-logic/services/alerts-services/alertResponse.types';
import { SelectableButton } from 'app/components/selectable-button';
import { getRandomIntegerInclusive } from 'app/helpers/getRandomIntegerInclusive';
import { getRandomString } from 'app/helpers/getRandomString';
import { useHasAccess } from 'app/hooks/use-has-access';
import {
  DARK_PILL_COLORS_LIST,
  PILL_COLORS_LIST,
} from 'app/views/configuration/pages/alert/alert-responses/alert-response-form/alert-response-pill-colors/AlertResponsePillColors.constants';
import { useI18n } from 'core/hooks/useI18n';
import { useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { AlertResponseFormType } from '../AlertResponse';
import { NoAlertResponseClassifications } from '../AlertResponse.styles';
import { ClassificationButtonsWrapper } from './AlertResponse.styles';

type AlertResponseClassificationButtonsProps = {
  isLoading: boolean;
  displayedClassifications: AlertResponseClassification[];
};

export const AlertResponseClassificationButtons = ({
  displayedClassifications = [],
  isLoading,
}: AlertResponseClassificationButtonsProps) => {
  const { l10n } = useI18n('app/views/alerts', 'alertResponse');
  const {
    setFieldValue,
    values: { classifications },
  } = useFormikContext<AlertResponseFormType>();
  const hasAlertResponseWriteAccess = useHasAccess()(KnownAccessControls.app.alerts.response.data.write._);
  return (
    <ClassificationButtonsWrapper>
      {isLoading && <LoadingSkeleton />}
      {!isLoading && !displayedClassifications?.length && (
        <NoAlertResponseClassifications>{l10n('noAlertResponseClassifications')}</NoAlertResponseClassifications>
      )}
      {!isLoading &&
        displayedClassifications?.map(classification => {
          const classificationColorSet = [...PILL_COLORS_LIST, ...DARK_PILL_COLORS_LIST].find(
            color => color.pill === classification.hexColor
          );
          const textColor = classificationColorSet?.text;
          const backgroundColor = classificationColorSet?.pill;
          return (
            <SelectableButton
              name={classification.name}
              isSelected={classifications?.find(c => c.classification.id === classification.id)?.isSelected ?? false}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const field: keyof AlertResponseFormType = 'classifications';
                const values: AlertResponseFormType[typeof field] = classifications.map(c => {
                  if (c.classification.id !== classification.id) return c;
                  return {
                    ...c,
                    isSelected: e.target.checked,
                  };
                });
                setFieldValue(field, values);
              }}
              key={classification.id}
              isLoading={isLoading}
              disabled={!hasAlertResponseWriteAccess}
              textColor={textColor}
              backgroundColor={backgroundColor}
            />
          );
        })}
    </ClassificationButtonsWrapper>
  );
};

const LoadingSkeleton = () => {
  const itemCount = getRandomIntegerInclusive(4, 6);
  return (
    <>
      {Array(itemCount)
        .fill(null)
        .map((_, i) => (
          <SelectableButton name={getRandomString(4, 10)} key={i} isLoading />
        ))}
    </>
  );
};
