import { useThemeContext } from 'app/theme/useThemeContext';
import { useCallback, useLayoutEffect, useRef } from 'react';

import { getSeekToProgressOnClick } from './getSeekToProgressOnClick';
import { WAVESURFER_PLAYBACK_HEIGHT } from './WaveSurferConstants';
import { PlaybackCanvas } from './WaveSurferPlayback.styles';

const SEEKER_WIDTH = 2;

interface WaveSurferScrubberProps {
  isPlaying: boolean;
  duration: number | undefined;
  seekTo: (percentage: number) => void;
  timestamp: number;
  width: number;
}

export const WaveSurferScrubber = ({ isPlaying, duration = 0, seekTo, timestamp, width }: WaveSurferScrubberProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const playbackPercentage = timestamp / duration;
  const theme = useThemeContext();
  const currentFrame = useRef(0);

  const update = useCallback(() => {
    const context = canvasRef.current?.getContext('2d');
    if (context) {
      context.fillStyle = theme.colors.mono.ui01;
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
      context.fillRect(context.canvas.width * playbackPercentage, 0, SEEKER_WIDTH, context.canvas.height);
    }
    if (isPlaying) {
      currentFrame.current = requestAnimationFrame(update);
    }
  }, [playbackPercentage, theme, isPlaying]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement>) => {
      const progress = getSeekToProgressOnClick(event);
      seekTo(progress);
    },
    [seekTo]
  );

  useLayoutEffect(() => {
    currentFrame.current = requestAnimationFrame(update);
    return () => cancelAnimationFrame(currentFrame.current);
  }, [update]);

  useLayoutEffect(() => {
    const context = canvasRef.current?.getContext('2d');
    if (!context) return;
    context.canvas.height = WAVESURFER_PLAYBACK_HEIGHT;
    context.canvas.width = width;
  }, [canvasRef, width]);

  return <PlaybackCanvas $width={width} ref={canvasRef} onClick={handleClick} />;
};
