import { useCallback, useState } from 'react';

export const useSetMillisecondsTimestamp = () => {
  const [millisecondsTimestamp, setMillisecondsTimestamp] = useState(0);
  const setAudioProcess = useCallback(
    (seconds: number) => {
      setMillisecondsTimestamp(Math.round(seconds * 1000));
    },
    [setMillisecondsTimestamp]
  );
  const setSeeking = useCallback((currentTimeSecond: number) => {
    setMillisecondsTimestamp(Math.round(currentTimeSecond * 1000));
  }, []);
  return {
    millisecondsTimestamp,
    setAudioProcess,
    setSeeking,
  };
};
