import { useEffect, useRef, useState } from 'react';

export const OPENING_DELAY_MS = 500;

export const useDesktopNavigationVisibility = () => {
  const [isOpen, setIsOpen] = useState(false);
  const hoverTimeout = useRef(0);
  const onMouseEnter = () => {
    window.clearTimeout(hoverTimeout.current);
    hoverTimeout.current = window.setTimeout(() => {
      setIsOpen(true);
    }, OPENING_DELAY_MS);
  };
  const onMouseLeave = () => {
    window.clearTimeout(hoverTimeout.current);
    setIsOpen(false);
  };

  useEffect(() => {
    return () => {
      window.clearTimeout(hoverTimeout.current);
    };
  }, []);

  return {
    isOpen,
    onMouseEnter,
    onMouseLeave,
  };
};
