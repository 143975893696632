import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { getNoiseEventsByAlertId } from 'app/business-logic/services/noise-service';

export const useEventsByAlertId = (alertId: string | undefined) => {
  const { data, isSuccess, isFetching, isLoading } = useQuery({
    queryKey: [QUERY_KEY.noiseEventsByAlertId, alertId],
    queryFn: () => {
      if (!alertId) return;
      return getNoiseEventsByAlertId(alertId);
    },
    enabled: !!alertId,
    retry: false,
  });
  return {
    data,
    isSuccess,
    isFetching,
    isLoading,
  };
};
