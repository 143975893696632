import { styled, css } from 'styled-components';
import { Visibility } from '../DrawerWithHandle';

const sharedButtonStyles = css`
  width: 40px;
  height: 40px;
  color: ${({ theme }) => theme.colors.mono.ui06};
  border-radius: 3px;
  svg {
    width: 14px;
  }
  path {
    fill: currentColor;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.mono.text01};
  }
  &:focus-visible {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.mono.text01};
  }
`;

export const ExpandCollapse = styled.button<{ $drawerVisibility: Visibility }>`
  ${sharedButtonStyles}
  transform: rotate(90deg);
  ${({ $drawerVisibility }) => $drawerVisibility === 'partial' && `transform: rotate(${270}deg)`}
`;

export const Close = styled.button`
  ${sharedButtonStyles}
`;
