import { GetNoiseEventsByAlertIdQuery } from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';
import { getDetailedNoiseEvents } from './getDetailedNoiseEvents';
import { NOISE_EVENTS_BY_ALERT_ID } from './graphql/facility.noiseEventsByAlertId';

export type CumulusNoiseEvent = NonNullable<
  NonNullable<NonNullable<GetNoiseEventsByAlertIdQuery['facility']>['alerts']>['noiseEvents']
>[number];

export type ArenaNoiseEvent = Awaited<ReturnType<typeof getDetailedNoiseEvents>>[number];

export const getNoiseEventsByAlertId = async (alertId: Guid) => {
  const cumulusNoiseEvents = await getCumulusNoiseEvents(alertId);
  if (!cumulusNoiseEvents.length) return [];
  const noiseEventIds = cumulusNoiseEvents.flatMap(event => event?.id ?? []);
  const arenaNoiseEvents = await getDetailedNoiseEvents({ noiseEventIds });
  return cumulusNoiseEvents.flatMap(event => {
    if (!event) return [];
    const arenaNoiseEvent = arenaNoiseEvents.find(({ noiseEventId }) => noiseEventId === event.id);
    if (!arenaNoiseEvent) return [];
    return {
      ...event,
      ...arenaNoiseEvent,
    };
  });
};

async function getCumulusNoiseEvents(alertId: Guid) {
  const variables = { filter: { id_in: [alertId] } };
  const {
    data: { facility },
    errors,
  } = await ApolloProvider.global().query<GetNoiseEventsByAlertIdQuery>({
    query: NOISE_EVENTS_BY_ALERT_ID,
    variables,
  });
  if (errors?.length)
    throw new Error(`Something went wrong when trying to get noise event IDs by alert ID: ${alertId}`);
  const cumulusNoiseEvents = facility?.alerts?.noiseEvents;
  return cumulusNoiseEvents ?? [];
}
