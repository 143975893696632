import { useAppSelector } from 'app/redux/store';
import { useEffect } from 'react';

export const useHeapIdentifyUser = () => {
  const { details } = useAppSelector(state => state.profile.user);
  const { id } = details ?? {};
  useEffect(() => {
    if (!window.heap) return;
    if (!id) return;
    window.heap.identify(id);
  }, [id]);
};
