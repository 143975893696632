import { useEffect } from 'react';
import { WaveSurferRef } from '../types';
import { useSetMillisecondsTimestamp } from './useSetMillisecondsTimestamp';

export const useMillisecondsTimestamp = (waveSurferRef: WaveSurferRef) => {
  const waveSurfer = waveSurferRef.current;
  const { millisecondsTimestamp, setAudioProcess, setSeeking } = useSetMillisecondsTimestamp();

  useEffect(() => {
    if (!waveSurfer) return;
    waveSurfer.on('audioprocess', setAudioProcess);
    waveSurfer.on('seeking', setSeeking);
    return () => {
      waveSurfer.un('audioprocess', setAudioProcess);
      waveSurfer.un('seeking', setSeeking);
    };
  }, [setSeeking, setAudioProcess, waveSurfer]);

  return millisecondsTimestamp;
};
