import AirHorn from 'app/visuals/images/icons/noise-classification/air-horn.svg?react';
import AirTraffic from 'app/visuals/images/icons/noise-classification/air-traffic.svg?react';
import Airport from 'app/visuals/images/icons/noise-classification/airport.svg?react';
import Alarm from 'app/visuals/images/icons/noise-classification/alarm.svg?react';
import Alert from 'app/visuals/images/icons/noise-classification/alert.svg?react';
import AngleGrinder from 'app/visuals/images/icons/noise-classification/angle-grinder.svg?react';
import Atv from 'app/visuals/images/icons/noise-classification/atv.svg?react';
import BackgroundNoise from 'app/visuals/images/icons/noise-classification/background-noise.svg?react';
import BeltNoise from 'app/visuals/images/icons/noise-classification/belt-noise.svg?react';
import Bike from 'app/visuals/images/icons/noise-classification/bike.svg?react';
import Bird from 'app/visuals/images/icons/noise-classification/bird.svg?react';
import Blasting from 'app/visuals/images/icons/noise-classification/blasting.svg?react';
import Bus from 'app/visuals/images/icons/noise-classification/bus.svg?react';
import CafeteriaNoise from 'app/visuals/images/icons/noise-classification/cafeteria-noise.svg?react';
import Calibrations from 'app/visuals/images/icons/noise-classification/calibrations.svg?react';
import CallToPrayer from 'app/visuals/images/icons/noise-classification/call-to-prayer.svg?react';
import Car from 'app/visuals/images/icons/noise-classification/car.svg?react';
import Cleaning from 'app/visuals/images/icons/noise-classification/cleaning.svg?react';
import CoffeeMachine from 'app/visuals/images/icons/noise-classification/coffee-machine.svg?react';
import Community from 'app/visuals/images/icons/noise-classification/community.svg?react';
import Compactor from 'app/visuals/images/icons/noise-classification/compactor.svg?react';
import ConstructionVehicle from 'app/visuals/images/icons/noise-classification/construction-vehicle.svg?react';
import Construction from 'app/visuals/images/icons/noise-classification/construction.svg?react';
import Crane from 'app/visuals/images/icons/noise-classification/crane.svg?react';
import CrowdNoise from 'app/visuals/images/icons/noise-classification/crowd-noise.svg?react';
import Dog from 'app/visuals/images/icons/noise-classification/dog.svg?react';
import DoorOpeningClosing from 'app/visuals/images/icons/noise-classification/door-opening-closing.svg?react';
import Drilling from 'app/visuals/images/icons/noise-classification/drilling.svg?react';
import Drone from 'app/visuals/images/icons/noise-classification/drone.svg?react';
import Entertainment from 'app/visuals/images/icons/noise-classification/entertainment.svg?react';
import Evacuation from 'app/visuals/images/icons/noise-classification/evacuation.svg?react';
import Excavator from 'app/visuals/images/icons/noise-classification/excavator.svg?react';
import FanNoise from 'app/visuals/images/icons/noise-classification/fan-noise.svg?react';
import FarmAnimals from 'app/visuals/images/icons/noise-classification/farm-animals.svg?react';
import Farm from 'app/visuals/images/icons/noise-classification/farm.svg?react';
import FireArms from 'app/visuals/images/icons/noise-classification/fire-arms.svg?react';
import Fires from 'app/visuals/images/icons/noise-classification/fires.svg?react';
import Fireworks from 'app/visuals/images/icons/noise-classification/fireworks.svg?react';
import Footsteps from 'app/visuals/images/icons/noise-classification/footsteps.svg?react';
import Forklift from 'app/visuals/images/icons/noise-classification/forklift.svg?react';
import Frogs from 'app/visuals/images/icons/noise-classification/frogs.svg?react';
import FrontEndLoader from 'app/visuals/images/icons/noise-classification/front-end-loader.svg?react';
import Hail from 'app/visuals/images/icons/noise-classification/hail.svg?react';
import Hammering from 'app/visuals/images/icons/noise-classification/hammering.svg?react';
import HarbourNoise from 'app/visuals/images/icons/noise-classification/harbour-noise.svg?react';
import Harbour from 'app/visuals/images/icons/noise-classification/harbour.svg?react';
import HaulTruck from 'app/visuals/images/icons/noise-classification/haul-truck.svg?react';
import Helicopter from 'app/visuals/images/icons/noise-classification/helicopter.svg?react';
import HumanActivity from 'app/visuals/images/icons/noise-classification/human-activity.svg?react';
import Insects from 'app/visuals/images/icons/noise-classification/insects.svg?react';
import Jackhammer from 'app/visuals/images/icons/noise-classification/jackhammer.svg?react';
import LandscapingEquipment from 'app/visuals/images/icons/noise-classification/landscaping-equipment.svg?react';
import LargeCar from 'app/visuals/images/icons/noise-classification/large-car.svg?react';
import LawnMower from 'app/visuals/images/icons/noise-classification/lawn-mower.svg?react';
import LoudExhaust from 'app/visuals/images/icons/noise-classification/loud-exhaust.svg?react';
import MaterialHandling from 'app/visuals/images/icons/noise-classification/material-handling.svg?react';
import Microphone from 'app/visuals/images/icons/noise-classification/microphone.svg?react';
import Milling from 'app/visuals/images/icons/noise-classification/milling.svg?react';
import Mining from 'app/visuals/images/icons/noise-classification/mining.svg?react';
import Motorbike from 'app/visuals/images/icons/noise-classification/motorbike.svg?react';
import MultipleUsersWifi from 'app/visuals/images/icons/noise-classification/multiple-users-wifi.svg?react';
import Music from 'app/visuals/images/icons/noise-classification/music.svg?react';
import NeighbouringSiteNoise from 'app/visuals/images/icons/noise-classification/neighbouring-site-noise.svg?react';
import Neighbours from 'app/visuals/images/icons/noise-classification/neighbours.svg?react';
import NoAudio from 'app/visuals/images/icons/noise-classification/no-audio.svg?react';
import OffsiteActivities from 'app/visuals/images/icons/noise-classification/offsite-activities.svg?react';
import Other from 'app/visuals/images/icons/noise-classification/other.svg?react';
import Pedestrians from 'app/visuals/images/icons/noise-classification/pedestrians.svg?react';
import PileDriver from 'app/visuals/images/icons/noise-classification/pile-driver.svg?react';
import Plane from 'app/visuals/images/icons/noise-classification/plane.svg?react';
import Playground from 'app/visuals/images/icons/noise-classification/playground.svg?react';
import PowerSaw from 'app/visuals/images/icons/noise-classification/power-saw.svg?react';
import RaceCar from 'app/visuals/images/icons/noise-classification/race-car.svg?react';
import Rain from 'app/visuals/images/icons/noise-classification/rain.svg?react';
import ReversingSignal from 'app/visuals/images/icons/noise-classification/reversing-signal.svg?react';
import Ring from 'app/visuals/images/icons/noise-classification/ring.svg?react';
import RoadNoise from 'app/visuals/images/icons/noise-classification/road-noise.svg?react';
import ShipContainer from 'app/visuals/images/icons/noise-classification/ship-container.svg?react';
import ShipEngine from 'app/visuals/images/icons/noise-classification/ship-engine.svg?react';
import ShipHorn from 'app/visuals/images/icons/noise-classification/ship-horn.svg?react';
import Ship from 'app/visuals/images/icons/noise-classification/ship.svg?react';
import ShippingContainer from 'app/visuals/images/icons/noise-classification/shipping-container.svg?react';
import Siren1 from 'app/visuals/images/icons/noise-classification/siren-1.svg?react';
import SirenAlarms from 'app/visuals/images/icons/noise-classification/siren-alarms.svg?react';
import Siren from 'app/visuals/images/icons/noise-classification/siren.svg?react';
import SiteActivities from 'app/visuals/images/icons/noise-classification/site-activities.svg?react';
import Snowmobile from 'app/visuals/images/icons/noise-classification/snowmobile.svg?react';
import Stapler from 'app/visuals/images/icons/noise-classification/stapler.svg?react';
import Storm from 'app/visuals/images/icons/noise-classification/storm.svg?react';
import Talking from 'app/visuals/images/icons/noise-classification/talking.svg?react';
import Technician from 'app/visuals/images/icons/noise-classification/technician.svg?react';
import Tractor from 'app/visuals/images/icons/noise-classification/tractor.svg?react';
import Traffic from 'app/visuals/images/icons/noise-classification/traffic.svg?react';
import Train from 'app/visuals/images/icons/noise-classification/train.svg?react';
import Tram from 'app/visuals/images/icons/noise-classification/tram.svg?react';
import TruckIdling from 'app/visuals/images/icons/noise-classification/truck-idling.svg?react';
import Truck from 'app/visuals/images/icons/noise-classification/truck.svg?react';
import VehicleBrakes from 'app/visuals/images/icons/noise-classification/vehicle-brakes.svg?react';
import VehicleHorn from 'app/visuals/images/icons/noise-classification/vehicle-horn.svg?react';
import Vehicles from 'app/visuals/images/icons/noise-classification/vehicles.svg?react';
import Vent from 'app/visuals/images/icons/noise-classification/vent.svg?react';
import WaterAlert from 'app/visuals/images/icons/noise-classification/water-alert.svg?react';
import Weather from 'app/visuals/images/icons/noise-classification/weather.svg?react';
import Whistle from 'app/visuals/images/icons/noise-classification/whistle.svg?react';
import Wildlife from 'app/visuals/images/icons/noise-classification/wildlife.svg?react';
import Wind from 'app/visuals/images/icons/noise-classification/wind.svg?react';
import Omnis from 'app/visuals/images/logo-symbol.svg?react';

export const NoiseClassificationIconMap = {
  'air-horn': AirHorn,
  'air-traffic': AirTraffic,
  aircraft: Plane,
  airport: Airport,
  alarm: Alarm,
  alarms: Alarm,
  alert: Alert,
  'angle-grinder-mini-cutter': AngleGrinder,
  animals: Wildlife,
  atv: Atv,
  'background-noise': BackgroundNoise,
  'belt-noise': BeltNoise,
  bike: Bike,
  bird: Bird,
  blasting: Blasting,
  bus: Bus,
  'cafeteria-noise': CafeteriaNoise,
  calibrations: Calibrations,
  'call-to-prayer': CallToPrayer,
  car: Car,
  cleaning: Cleaning,
  'coffee-machine': CoffeeMachine,
  community: Community,
  compactor: Compactor,
  construction: Construction,
  'construction-vehicle': ConstructionVehicle,
  crane: Crane,
  'crowd-noise': CrowdNoise,
  dog: Dog,
  'door-opening-closing': DoorOpeningClosing,
  drill: Drilling,
  drilling: Drilling,
  drone: Drone,
  'electric-hum': BackgroundNoise,
  entertainment: Entertainment,
  equipment: Drilling,
  evacuation: Evacuation,
  excavator: Excavator,
  'fan-noise': FanNoise,
  farm: Farm,
  'farm-animals': FarmAnimals,
  'fire-arms': FireArms,
  fires: Fires,
  fireworks: Fireworks,
  footsteps: Footsteps,
  forklift: Forklift,
  frogs: Frogs,
  'front-end-loader': FrontEndLoader,
  gardening: LawnMower,
  hail: Hail,
  hammering: Hammering,
  harbour: Harbour,
  'harbour-noise': HarbourNoise,
  'haul-truck': HaulTruck,
  helicopter: Helicopter,
  'human-activity': HumanActivity,
  'human-activity-header': HumanActivity,
  insects: Insects,
  jackhammer: Jackhammer,
  'landscaping-equipment': LandscapingEquipment,
  'large-car': LargeCar,
  'lawn-mower': LawnMower,
  'loud-exhaust': LoudExhaust,
  maintenance: Technician,
  'material-handling': MaterialHandling,
  microphone: Microphone,
  milling: Milling,
  mining: Mining,
  'mining-header': Mining,
  misc: Other,
  motorbike: Motorbike,
  'multiple-users-wifi': MultipleUsersWifi,
  music: Music,
  'neighbouring-site-noise': NeighbouringSiteNoise,
  neighbours: Neighbours,
  'no-audio': NoAudio,
  'non-construction': OffsiteActivities,
  'offsite-activities': OffsiteActivities,
  omnis: Omnis,
  other: Other,
  'other-vehicle': Vehicles,
  pedestrians: Pedestrians,
  'pile-driver': PileDriver,
  plane: Plane,
  playground: Playground,
  'power-saw': PowerSaw,
  'race-car': RaceCar,
  rain: Rain,
  'reversing-signal': ReversingSignal,
  ring: Ring,
  'road-noise': RoadNoise,
  ship: Ship,
  'ship-container': ShipContainer,
  'ship-engine': ShipEngine,
  'ship-horn': ShipHorn,
  'shipping-container': ShippingContainer,
  siren: Siren,
  'siren-1': Siren1,
  'siren-alarms': SirenAlarms,
  'siren-vehicles': Siren,
  'site-activities': SiteActivities,
  snowmobile: Snowmobile,
  'standard-alert': Alert,
  stapler: Stapler,
  storm: Storm,
  talking: Talking,
  technician: Technician,
  tractor: Tractor,
  traffic: Traffic,
  train: Train,
  'train-bogey-squeal-screech': Train,
  'train-idle': Train,
  'train-operations-whistle': Train,
  'train-shunt': Train,
  tram: Tram,
  truck: Truck,
  'truck-idling': TruckIdling,
  'vehicle-brakes': VehicleBrakes,
  'vehicle-horn': VehicleHorn,
  vehicles: Vehicles,
  vent: Vent,
  'water-alert': WaterAlert,
  weather: Weather,
  'weather-header': Weather,
  whistle: Whistle,
  wildlife: Wildlife,
  wind: Wind,
} as const;
