import { GetAlertConfigurationsQuery, GetAlertConfigurationsQueryVariables } from 'app/__generated__/global';
import { GET_ALERT_CONFIGURATIONS } from 'app/business-logic/services/alerts-services/graphql/facility.getAlertConfigurations';
import { ApolloProvider } from 'core/net/ApolloProvider';

export const getAlertConfigurations = async () => {
  const { data, error } = await ApolloProvider.global().query<
    GetAlertConfigurationsQuery,
    GetAlertConfigurationsQueryVariables
  >({
    query: GET_ALERT_CONFIGURATIONS,
  });
  if (!data || error) throw new Error('Something went wrong when trying to get alert configurations');
  return (
    data.facility?.alerts?.alertScriptConfigurations.map(({ id, name, alertTypeId, linkedOutputs }) => {
      const { alertOutputs } = linkedOutputs ?? {};
      const isWebAlert = !!alertOutputs?.some(alert => alert.__typename === 'WebAlertOutput');
      return { id, name, alertTypeId, isWebAlert };
    }) ?? []
  );
};
