import { LocaleUtils } from 'react-day-picker';
import { DateTime, Info } from 'luxon';
import { LANGUAGE_OPTIONS } from 'app/views/profile/pages/language-settings/LanguageSettings.constants';
import { Locale } from 'app/business-logic/domain-models/User';

type MonthNames = [string, string, string, string, string, string, string, string, string, string, string, string];
type LanguageOptionValue = (typeof LANGUAGE_OPTIONS)[number]['value'];

const FALLBACK_MONTH_NAMES: MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getMonthNames = (locale: Locale) => {
  return Info.months('long', { locale });
};

const getWeekdayNames = (locale: Locale) => {
  const weekdays = Info.weekdays('long', { locale });
  return [...weekdays.slice(6), ...weekdays.slice(0, 6)];
};

const getShortWeekdayNames = (locale: Locale) => {
  const weekdays = Info.weekdays('short', { locale });
  return [...weekdays.slice(6), ...weekdays.slice(0, 6)];
};

const WEEKDAYS_LONG = LANGUAGE_OPTIONS.reduce((acc, language) => {
  acc[language.value] = getWeekdayNames(language.value);
  return acc;
}, {} as Record<LanguageOptionValue, string[]>);

const WEEKDAYS_SHORT = LANGUAGE_OPTIONS.reduce((acc, language) => {
  acc[language.value] = getShortWeekdayNames(language.value);
  return acc;
}, {} as Record<LanguageOptionValue, string[]>);

const MONTHS = LANGUAGE_OPTIONS.reduce((acc, language) => {
  acc[language.value] = getMonthNames(language.value) as MonthNames;
  return acc;
}, {} as Record<LanguageOptionValue, MonthNames>);

const FIRST_DAY = LANGUAGE_OPTIONS.reduce((acc, language) => {
  acc[language.value] = 0;
  return acc;
}, {} as Record<LanguageOptionValue, number>);

const formatDay = (date: Date, locale: Locale) => {
  return `${WEEKDAYS_LONG[locale]?.[date.getDay()]}, ${date.getDate()} ${
    MONTHS[locale]?.[date.getMonth()]
  } ${date.getFullYear()}`;
};

const formatMonthTitle = (date: Date, locale: Locale) => {
  return `${MONTHS[locale]?.[date.getMonth()]}`;
};

const formatWeekdayShort = (weekday: number, locale: Locale) => {
  return WEEKDAYS_SHORT[locale]?.[weekday] ?? '';
};

const formatWeekdayLong = (weekday: number, locale: Locale) => {
  return WEEKDAYS_LONG[locale]?.[weekday] ?? '';
};

const getFirstDayOfWeek = (locale: Locale) => {
  return FIRST_DAY[locale] ?? 0;
};

const getMonths = (locale: Locale) => {
  return MONTHS[locale] ?? FALLBACK_MONTH_NAMES;
};

const formatDate = (date: Date, locale: Locale) => {
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED, { locale });
};

const parseDate = (str: string) => {
  return new Date(str);
};

export const localeUtils: LocaleUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  formatDate,
  parseDate,
};

export const DEFAULT_LOCALE = 'en';
