import { IFacility, IMapConfiguration } from 'app/business-logic/domain-models/Facility';
import IDomainBoundary from 'app/business-logic/domain-models/map/IDomainBoundary';
import { IWeatherStation } from 'app/business-logic/domain-models/Weather';
import Guid from 'core/types/Guid';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'app/redux/actions';
import { MapProvider } from 'app/__generated__/global';

export type FacilityState = typeof INITIAL_STATE;

const INITIAL_FACILITY: Partial<IFacility> & { map: IMapConfiguration } = {
  companyAlias: undefined,
  companyId: undefined,
  defaultZoomLevel: undefined,
  domainBoundaries: [],
  facilityAlias: undefined,
  facilityCode: undefined,
  ianaTimezone: undefined,
  id: undefined,
  latitude: undefined,
  longitude: undefined,
  map: {
    center: [0, 0],
    detailZoomLevels: [],
    mapLogoImage: null,
  },
  name: undefined,
  region: undefined,
  ticketingSystemTenantId: undefined,
  windowsTimezoneId: undefined,
  roadMapProvider: MapProvider.Mapbox,
  satelliteMapProvider: MapProvider.Azure,
  es1RouteName: 'demo_brisbane',
};

export const INITIAL_STATE = {
  ...INITIAL_FACILITY,
  preferredWeatherStationId: '' as Guid,
  switchingFacilities: false as boolean,
  weatherStations: [] as IWeatherStation[],
};

export const slice = createSlice({
  name: 'profile/facility',
  initialState: INITIAL_STATE,
  reducers: {
    weatherStationsLoaded: (state, { payload }: PayloadAction<IWeatherStation[]>) => {
      if (!(payload && payload.length)) {
        return state;
      }

      const newState = {
        ...state,
        weatherStations: payload,
      };

      const hasPreferredWeatherStation = !!(
        newState.preferredWeatherStationId && payload.find(ws => ws.monitorId === newState.preferredWeatherStationId)
      );

      if (!hasPreferredWeatherStation) {
        const value = payload.find(ws => ws.isDefault) || payload[0];
        newState.preferredWeatherStationId = value?.monitorId ?? '';
      }

      return newState;
    },

    mapConfigurationChanged: (state, { payload }: PayloadAction<IMapConfiguration>) => {
      return {
        ...state,
        map: payload,
      };
    },

    facilityChanged: (state, { payload }: PayloadAction<IFacility>) => {
      return {
        ...state,
        ...payload,
      };
    },

    preferredWeatherStationChanged: (state, { payload }: PayloadAction<Guid>) => {
      return {
        ...state,
        preferredWeatherStationId: payload,
      };
    },

    switchingFacilities: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        switchingFacilities: payload,
      };
    },

    domainBoundariesChanged: (state, { payload }: PayloadAction<IDomainBoundary[]>) => {
      return {
        ...state,
        domainBoundaries: payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
