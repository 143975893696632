import { ApolloQueryResult } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { FetchAlertConfigQuery, FetchAlertConfigQueryVariables } from 'app/__generated__/global';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { NoiseMeasurementType } from 'app/views/components/real-time-noise-connection/RealTimeNoiseConnection.types';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';
import { useMemo } from 'react';
import { FETCH_ALERT_CONFIG } from './graphql/facility.fetchAlertConfig';

export type AlertConfig = ReturnType<typeof extractConfig>;

export const useAlertConfiguration = (alertConfigId: Guid | undefined) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEY.alertConfiguration, alertConfigId],
    queryFn: () => {
      if (!alertConfigId) return;
      return ApolloProvider.global().query<FetchAlertConfigQuery, FetchAlertConfigQueryVariables>({
        query: FETCH_ALERT_CONFIG,
        variables: {
          fetchAlertConfigFilter: {
            id_in: [alertConfigId],
          },
        },
      });
    },
    enabled: !!alertConfigId,
  });
  return useMemo(
    () => ({
      data: extractConfig(data),
      ...rest,
    }),
    [data, rest]
  );
};

const extractConfig = (response?: ApolloQueryResult<FetchAlertConfigQuery>) => {
  if (!response) return;
  const [config] = response.data.facility?.alerts?.alertScriptConfigurations ?? [];
  if (!config) return;
  const { __typename, omnisDataType, variables, ...rest } = config;
  return {
    ...rest,
    omnisDataType: (omnisDataType as NoiseMeasurementType) ?? null,
    variables,
  };
};
