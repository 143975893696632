import { ComponentProps } from 'react';
import { NoiseClassificationIconMap } from './NoiseClassificationIconMap';

type NoiseClassificationIconProps = {
  icon: keyof typeof NoiseClassificationIconMap;
} & ComponentProps<(typeof NoiseClassificationIconMap)[keyof typeof NoiseClassificationIconMap]>;

export const NoiseClassificationIcon = ({ icon, ...rest }: NoiseClassificationIconProps) => {
  return NoiseClassificationIconMap[icon]({ ...rest });
};
