import { GlobalHotKeys } from 'react-hotkeys';

import { useChangeFacilityDialogState } from './useChangeFacilityDialogState';
import { useCanChangeFacility } from './useCanChangeFacility';

export const ChangeFacilityHotkey = () => {
  const { handlers, keyMap } = useChangeFacilityDialogState();
  const canChangeFacility = useCanChangeFacility();
  if (!canChangeFacility) return null;
  return <GlobalHotKeys keyMap={keyMap} handlers={handlers} />;
};
