import { memo } from 'react';
import { connect } from 'react-redux';
import { IGlobalState } from 'app/redux/reducer';
import LocalTime from 'core/types/LocalTime';

interface IProps {
  ianaTimezone: string | undefined;
}

const TimeLocalizer = memo(({ ianaTimezone }: IProps) => {
  if (!ianaTimezone) return null;
  LocalTime.IanaTimeZone = ianaTimezone;
  return null;
});
TimeLocalizer.displayName = 'TimeLocalizer';

const mapStateToProps = (state: IGlobalState) => {
  const { ianaTimezone } = state.profile.facility;
  return { ianaTimezone };
};

export default connect(mapStateToProps)(TimeLocalizer);
