import { styled } from 'styled-components';
import { Button } from '@blueprintjs/core';
import { srOnly } from 'app/visuals/mixins/srOnly';
import { DateTimeInputField } from 'app/components/date-selectors/date-time-input-field';
export const ActualBlastTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.spacing()}px 0;
`;
export const ActualBlastTimeActionsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledChangeButton = styled.button`
  color: ${({ theme }) => theme.colors.brand.brand01};
  &:hover {
    color: ${({ theme }) => theme.colors.brand.brand02};
  }
`;

export const SaveButton = styled(Button)`
  &&& {
    color: ${({ theme: { colors } }) => colors.mono.ui01};
    background-color: ${({ theme: { colors } }) => colors.brand.brand02};
    &:hover {
      color: ${({ theme: { colors } }) => colors.mono.ui01};
      background-color: ${({ theme: { colors } }) => colors.brand.brand01};
    }
  }
`;

export const CancelButton = styled(Button)`
  &&& {
    color: ${({ theme: { colors } }) => colors.mono.text02};
    margin-left: ${({ theme: { spacing } }) => spacing()}px;
    &:hover {
      color: ${({ theme: { colors } }) => colors.mono.text01};
      background-color: transparent;
    }
  }
`;

export const AddButton = styled(Button)`
  &&& {
    color: ${({ theme: { colors } }) => colors.mono.text02};
    background-color: transparent;

    &:hover {
      color: ${({ theme: { colors } }) => colors.mono.text01};
      background-color: transparent;
    }
  }
`;

export const StyledDateTimeInputField = styled(DateTimeInputField)`
  label {
    ${srOnly}
  }
`;
