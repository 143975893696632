import { Button } from 'app/components/buttons';
import { useI18n } from 'core/hooks/useI18n';
import { useFormikContext } from 'formik';
import { AlertResponseFormType } from '../AlertResponse';
import { StyledAlertResponseFooter } from './AlertResponseFooter.styles';
import { useAppDispatch } from 'app/redux/store';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { deselectAlert, openDrawer } from 'app/redux/ui/actions';

type AlertResponseFooterProps = {
  isLoading: boolean;
};

export const AlertResponseFooter = ({ isLoading }: AlertResponseFooterProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { l10n } = useI18n('app/views/alerts', 'alertResponse');
  const { resetForm } = useFormikContext<AlertResponseFormType>();
  return (
    <StyledAlertResponseFooter>
      <Button type="submit" $variant="outlined" disabled={isLoading}>
        {l10n('actions.confirm')}
      </Button>
      <Button
        $variant="text"
        type="button"
        onClick={() => {
          resetForm();
          dispatch(deselectAlert());
          if (isMobile) {
            dispatch(openDrawer('ALERTS'));
          }
        }}
      >
        {l10n('actions.discard')}
      </Button>
    </StyledAlertResponseFooter>
  );
};
