import { getRandomIntegerInclusive as randomNumber } from 'app/helpers/getRandomIntegerInclusive';

// Returns an array of distinct colors - each hue is 360/palletSize degrees away from each other

export const getDistinctColours = (paletteSize: number): string[] => {
  const s = randomNumber(85, 95); // Not too dull, not too bright
  const l = randomNumber(50, 70); // Not too dark, not too light

  return Array(paletteSize)
    .fill(null)
    .map((_, index) => `hsl(${(index * (360 / paletteSize)) % 360}deg,${s}%,${l}%)`);
};
