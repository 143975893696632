import { GetAlertHistoryQuery, GetAlertHistoryQueryVariables } from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { GET_ALERT_HISTORY } from './graphql/facility.getAlertHistory';

export type GetAlertHistoryParameters = GetAlertHistoryQueryVariables;

export const getAlertHistory = async (params: GetAlertHistoryQueryVariables) => {
  const { data, error } = await ApolloProvider.global().query<GetAlertHistoryQuery, GetAlertHistoryQueryVariables>({
    query: GET_ALERT_HISTORY,
    variables: params,
  });
  const { alertListing } = data.facility?.alerts ?? {};
  if (!alertListing || error) throw new Error('Something went wrong when trying to get alert history');
  return {
    ...alertListing,
    results: alertListing.results.flatMap(result => {
      if (!result) return [];
      return {
        ...result,
        inputSnapshot: result.inputSnapshot.flatMap(input => {
          if (!input) return [];
          return input;
        }),
        triggerSnaphot: result.triggerSnaphot.flatMap(value => {
          if (!value) return [];
          return value;
        }),
      };
    }),
  };
};
