const monitoringRoot = '/monitoring';

const settings = (root: string) => () => {
  return `${root}${monitoringRoot}/settings`;
};
const monitorsRoot = (root: string) => () => {
  return `${root}${monitoringRoot}/monitors`;
};
const measurementGroupsRoot = (root: string) => () => {
  return `${root}${monitoringRoot}/measurement-groups`;
};

const base = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/:page`;
};

const createNew = (root: string) => () => {
  return `${monitorsRoot(root)()}/add`;
};

const details = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/details`;
};

const measurementTypes = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/measurement-types`;
};

const createMeasurementType = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/measurement-types/add`;
};

const editMeasurementType = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/measurement-types/:measurementTypeId/edit`;
};

const mapConfiguration = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/map-configuration`;
};

const dataImport = (root: string) => () => {
  return `${monitorsRoot(root)()}/:id/data-import`;
};

const addCredential = (root: string) => () => {
  return `${dataImport(root)()}/add`;
};

const editCredential = (root: string) => () => {
  return `${dataImport(root)()}/:credentialId/edit`;
};

const measurementGroupsBase = (root: string) => () => {
  return `${measurementGroupsRoot(root)()}/:id/:page`;
};
const measurementGroupsCreate = (root: string) => () => {
  return `${measurementGroupsRoot(root)()}/add`;
};
const measurementGroupsEdit = (root: string) => () => {
  return `${measurementGroupsRoot(root)()}/:id/edit`;
};

export const configurationMonitoringRoutes = (root: string) => ({
  root: `${root}${monitoringRoot}`,
  settings: settings(root),
  monitors: {
    root: monitorsRoot(root),
    base: base(root),
    createNew: createNew(root),
    details: details(root),
    measurementTypes: measurementTypes(root),
    createMeasurementType: createMeasurementType(root),
    editMeasurementType: editMeasurementType(root),
    mapConfiguration: mapConfiguration(root),
    dataImport: dataImport(root),
    addCredential: addCredential(root),
    editCredential: editCredential(root),
  },
  measurementGroups: {
    root: measurementGroupsRoot(root),
    base: measurementGroupsBase(root),
    create: measurementGroupsCreate(root),
    edit: measurementGroupsEdit(root),
  },
});
