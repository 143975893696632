const noiseRoot = '/noise';

const classificationsRoot = (root: string) => `${root}${noiseRoot}/classifications`;

const classificationsCreate = (root: string) => () => {
  return `${classificationsRoot(root)}/add`;
};

const classificationsEdit = (root: string) => () => {
  return `${classificationsRoot(root)}/edit/:id`;
};

export const configurationNoiseRoutes = (root: string) => ({
  root: `${root}${noiseRoot}`,
  classifications: {
    root: classificationsRoot(root),
    create: classificationsCreate(root),
    edit: classificationsEdit(root),
  },
});
