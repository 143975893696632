import { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

const isNamespacedKey = (key: string) => /:/.test(key);

export const useI18n = (filePath: string | string[], prefix = '', useSuspense = true) => {
  const safeFilePath = typeof filePath === 'string' ? removeTrailingSlash(filePath) : filePath;
  const { t, i18n } = useTranslation(safeFilePath, { useSuspense });

  return useMemo(() => {
    const l10n = (key: string, options?: TOptions) => {
      let qualifiedKey = key;

      if (prefix && !isNamespacedKey(qualifiedKey)) {
        qualifiedKey = `${prefix}.${qualifiedKey}`;
      }

      const translation = t(qualifiedKey, options);

      return options?.prefix || options?.suffix
        ? (options.prefix || '') + translation + (options.suffix || '')
        : translation;
    };

    return { l10n, i18n };
  }, [t, i18n, prefix]);
};

function removeTrailingSlash(value: string) {
  const lastIndexOfSlash = value.lastIndexOf('/');
  const lastIndex = value.length - 1;
  if (lastIndexOfSlash !== lastIndex) return value;
  return value.substring(0, lastIndex);
}
