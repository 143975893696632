import { gql } from '@apollo/client';

export const SENSOR = gql`
  fragment Sensor on ProcessVariable {
    id
    name
    staleDataTimeoutSeconds
    samplingRateSeconds
    displayUnit {
      id
      name
    }

    thresholds {
      valueInDisplayUnit
      hexColor
    }

    process {
      id
      name
    }

    influenceArcDisplayThreshold
    numericScale
  }
`;
