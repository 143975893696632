import { styled, css } from 'styled-components';
import Color from 'color';
import { NAV_BAR_TRANSITION_SPEED } from './NavigationBar.styles';

const BORDER_WIDTH = 3;

const activeStyles = css`
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  &::before {
    transform: translateX(0);
  }
`;

const hoverStyles = css`
  background-color: ${({ theme }) => theme.colors.mono.ui03};
`;

const disabledStyles = css`
  cursor: not-allowed;

  &&,
  &&:hover {
    color: ${({ theme }) => Color(theme.colors.mono.text02).darken(0.25).toString()};
  }

  path {
    fill: ${({ theme }) => Color(theme.colors.mono.text02).darken(0.25).toString()};
  }
`;

const borderStyles = css`
  &::before {
    content: '';
    width: ${BORDER_WIDTH}px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.mono.text01};
    transform: translateX(-${BORDER_WIDTH}px);
    transition: transform ${NAV_BAR_TRANSITION_SPEED}s ease;
  }
`;

export const NavItemLink = styled.a<{ $isActive?: boolean; $isDisabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  && {
    color: ${({ theme }) => theme.colors.mono.text01};
  }

  &&:hover,
  &&:focus-visible {
    color: ${({ theme }) => theme.colors.mono.text01};
    ${({ $isActive, $isDisabled }) => !$isActive && !$isDisabled && hoverStyles}
  }

  ${borderStyles}

  ${({ $isActive }) => $isActive && activeStyles}

  ${({ $isDisabled }) => $isDisabled && disabledStyles}

  & + & {
    margin-top: 1px;
  }
`;
