import { gql } from '@apollo/client';

export const CREATE_CLASSIFICATION = gql(`
  mutation CreateClassification($input: CreateClassification!) {
    facility {
      classification {
        createClassification(input: $input) {
          id
        }
      }
    }
  }
`);
