import { Button } from 'app/components/buttons';
import { CheckboxLabel } from 'app/components/checkbox/Checkbox.styles';
import { Label } from 'app/components/form/label';
import { TextArea } from 'app/components/form/textarea';
import { srOnly } from 'app/visuals/mixins/srOnly';
import { css, styled } from 'styled-components';

export const EventClassificationHeading = styled.h3`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mono.text03};
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  margin: ${({ theme }) => theme.spacing(3)}px 0;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.ui05};
  letter-spacing: 0.05em;
`;

export const ClassificationsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)}px;
  flex-wrap: wrap;
`;

export const OptionsWrapper = styled.div``;

export const DisregardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 250px;

  ${CheckboxLabel} {
    display: inline-block;
  }
`;

export const ClassificationCommentsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const ClassificationCommentsField = styled(TextArea)<{ $invalid: boolean }>`
  &&& {
    resize: both;
    width: auto;
    min-width: 420px;
    max-width: 100%;
    border-radius: ${({ theme }) => theme.spacing()}px;
    border-color: ${({ $invalid, theme }) => ($invalid ? theme.colors.threshold.high : '')};
    &:hover,
    &:focus {
      border-color: ${props => (props.$invalid ? props.theme.colors.threshold.high : '')};
    }
    ${({ theme }) =>
      theme.breakpoints.down(
        'sm',
        css`
          min-width: 100%;
        `
      )}
  }
`;

export const HiddenLabel = styled(Label)`
  ${srOnly}
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const ConfirmButton = styled(Button)`
  margin-right: ${({ theme }) => theme.spacing()}px;
`;

export const DiscardButton = styled(Button)``;
