import { Classes } from '@blueprintjs/core';
import { css } from 'styled-components';

export const blueprintButtonReset = css`
  &&& {
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    &:hover,
    &:focus,
    &.${Classes.ACTIVE} {
      background-color: transparent;
      background-image: none;
      box-shadow: none;
    }
  }
`;
