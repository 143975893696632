import { css } from 'styled-components';

import { Classes } from '@blueprintjs/core';
import { Classes as OmnibarClasses } from '@blueprintjs/select';

export const searchBarStyles = css`
  .${Classes.PORTAL} {
    z-index: ${({ theme }) => theme.zIndex.mapControlBox + 100};

    .${OmnibarClasses.OMNIBAR} {
      top: 10vh;
      left: 50%;
      max-width: 450px;
      width: 100%;
      transform: translateX(-50%);

      .${Classes.INPUT} {
        background-color: ${({ theme }) => theme.colors.mono.ui02};
        color: ${({ theme }) => theme.colors.mono.text02};
        border: 1px solid ${({ theme }) => theme.colors.mono.ui04};
        &::placeholder {
          color: ${({ theme }) => theme.colors.mono.text02};
        }
      }

      .${Classes.MENU} {
        border-radius: 0;
        box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
        max-height: calc(80vh - 60px);
        overflow: auto;
        background-color: ${({ theme }) => theme.colors.mono.ui04};
      }

      .${Classes.MENU_ITEM} {
        font-size: 1.4rem;
        color: ${({ theme }) => theme.colors.mono.text01};
        &:hover {
          color: ${({ theme }) => theme.colors.mono.text01};
          text-decoration: none;
        }
        &[aria-selected='true'] {
          background-color: ${({ theme }) => theme.colors.mono.ui05};
          color: ${({ theme }) => theme.colors.mono.text01};
          &:hover {
            color: ${({ theme }) => theme.colors.mono.text01};
            text-decoration: none;
          }
        }
      }

      .${Classes.MENU_ITEM_LABEL} {
        color: ${({ theme }) => theme.colors.mono.text02};
      }

      .${Classes.ACTIVE} {
        background-color: ${({ theme }) => theme.colors.brand.brand01};
        color: ${({ theme }) => theme.colors.mono.text01};
      }
    }
  }
`;
