import { forwardRef, useRef, useImperativeHandle } from 'react';
import { Left, Right, Wrapper } from './MobileSubnavigationBar.styles';
import { MobileNavRef } from 'app/views/useMobileNavPortal';

export const MobileSubnavigationBar = forwardRef<MobileNavRef>((_, ref) => {
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    leftContent: leftRef.current,
    rightContent: rightRef.current,
  }));

  return (
    <Wrapper>
      <Left ref={leftRef} />
      <Right ref={rightRef} />
    </Wrapper>
  );
});
MobileSubnavigationBar.displayName = 'MobileSubnavigationBar';
