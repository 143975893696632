import { useScript } from 'app/hooks/useScript';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { setNewPostCount } from 'app/redux/ui/actions';
import { BeamerParameters } from 'beamer';
import { AppSettings } from 'core/AppSettings';
import { LogManager } from 'core/logging/LogManager';
import { useEffect, useMemo } from 'react';

const logger = LogManager.getLogger('Beamer');
const BEAMER_CONFIG_KEY = 'beamer_config';

export const Beamer = () => {
  const scriptLoadStatus = useLoadBeamerScript();
  useConfigureBeamer({ scriptLoadStatus });
  return null;
};

function useLoadBeamerScript() {
  return useScript('https://app.getbeamer.com/js/beamer-embed.js', {
    shouldPreventLoad: !AppSettings.beamerProductId,
  });
}

function useConfigureBeamer({ scriptLoadStatus }: { scriptLoadStatus: ReturnType<typeof useLoadBeamerScript> }) {
  const { details } = useAppSelector(state => state.profile.user);
  const { id } = details ?? {};
  const dispatch = useAppDispatch();
  const beamerConfig: BeamerParameters = useMemo(
    () => ({
      product_id: AppSettings.beamerProductId,
      button: false,
      notification_prompt: 'none',
      alert: false,
      user_id: id,
      nps_delay: Infinity,
      callback: newPostCount => {
        try {
          if (typeof newPostCount !== 'number') {
            throw new Error(`New post count was not a number:\n${JSON.stringify(newPostCount, null, 2)}`);
          }
          logger.info(`New post count for Beamer received: ${newPostCount}`);
          dispatch(setNewPostCount(newPostCount));
        } catch (e) {
          logger.error(e);
        }
      },
    }),
    [dispatch, id]
  );

  Object.assign(window, { [BEAMER_CONFIG_KEY]: beamerConfig });

  useEffect(() => {
    if (scriptLoadStatus !== 'ready') return;
    try {
      if (!window.Beamer) {
        return logger.warn(`"Beamer" is not on the window and cannot be configured`);
      }
      window.Beamer.update(beamerConfig);
    } catch (e) {
      logger.error(e);
    }
  }, [beamerConfig, scriptLoadStatus]);
}
