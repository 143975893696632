import { useEffect } from 'react';

/**
 * Creates a CSS variable, set to the height of the document, which can
 * be used instead of 100vh, which doesn't work well on mobile devices
 */
export function useDocumentHeight() {
  useEffect(() => {
    setDocumentHeight();
    window.addEventListener('resize', setDocumentHeight);
    return () => {
      window.removeEventListener('resize', setDocumentHeight);
    };
  }, []);
}

function setDocumentHeight() {
  const doc = document.documentElement;
  doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
}
