import { AppController } from 'app/AppController';
import HighchartsGlobalOptions from 'app/components/charts/HighchartsGlobalOptions';
import ErrorBoundary from 'app/components/ErrorBoundary';
import LanguageLocalizer from 'app/components/LanguageLocalizer';
import TimeLocalizer from 'app/components/TimeLocalizer';
import { persistor, store } from 'app/redux/store';
import { Protected } from './routing/protected/Protected';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';

import { FocusStyleManager } from '@blueprintjs/core';

import { THEME } from './theme/theme';
import { GlobalStyle } from './theme/GlobalStyle';
import { Suspense } from 'react';
import { Throbber } from 'app/components/throbber';
import { createl10n } from 'i18n';
import { useDocumentHeight } from './hooks/useDocumentHeight';

FocusStyleManager.onlyShowFocusOnTabs();

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const l10n = createl10n('app');

const App = () => {
  useDocumentHeight();
  return (
    <Suspense fallback={<Throbber />}>
      <ThemeProvider theme={THEME}>
        <GlobalStyle />
        <ErrorBoundary>
          <Provider store={store}>
            <PersistGate loading={<h1>{l10n('activity.loading')}</h1>} persistor={persistor}>
              <HighchartsGlobalOptions />
              <TimeLocalizer />
              <LanguageLocalizer />
              <QueryClientProvider client={queryClient}>
                <Protected>
                  <AppController />
                </Protected>
              </QueryClientProvider>
            </PersistGate>
          </Provider>
        </ErrorBoundary>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
