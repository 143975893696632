import { useHeapFacilityData } from './useHeapFacilityData';
import { useHeapDetermineInternalUsers } from './useHeapDetermineInternalUsers';
import { useHeapIdentifyUser } from './useHeapIdentifyUser';

export const HeapAnalytics = () => {
  useHeapIdentifyUser();
  useHeapDetermineInternalUsers();
  useHeapFacilityData();
  return null;
};
