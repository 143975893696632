import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateAcknowledgeAlertEvent } from 'app/business-logic/services/alerts-services/updateAcknowledgeAlertEvent';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { AlertHistory } from '../alert-history/use-alert-history/fetchAlertHistory';

export const useUpdateAcknowledgeAlertEvent = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: updateAcknowledgeAlertEvent,
    onSuccess: (_, acknowledgedId) => {
      queryClient.setQueryData([QUERY_KEY.alertDrawerData], (data: AlertHistory) => {
        return {
          ...data,
          results: data.results.filter(result => result.id !== acknowledgedId),
        };
      });
    },
  });
  return {
    updateAcknowledgeAlertEvent: mutate,
    isPending,
  };
};
