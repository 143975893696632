import { useAppSelector } from 'app/redux/store';
import { useRef } from 'react';

export const useUpdatedJob = () => {
  const jobs = useAppSelector(state => state.jobs);
  const oldJobs = useRef(jobs);
  const job = jobs.find(job => {
    const currentJob = oldJobs.current.find(current => current.jobId === job.jobId);
    return currentJob?.completionStatus !== job.completionStatus;
  });
  oldJobs.current = jobs;
  return { job };
};
