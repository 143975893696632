import { AccessControls, AccessibleFacility } from 'app/redux/profile/user/slice';
import { Routing } from 'core/routing/Routing';
import { useRouting } from 'core/routing/useRouting';
import { useCallback } from 'react';
import { REMEMBERED_ROUTES } from './useRememberRoute';

export const useGetFallbackRoute = () => {
  const { generatePath } = useRouting();
  const rememberedRoute = localStorage.getItem('rememberedRoute');

  return useCallback(
    ({
      facility: { companyAlias, facilityAlias },
      accessControls,
    }: {
      facility: Partial<AccessibleFacility>;
      accessControls: AccessControls;
    }) => {
      if (rememberedRoute) {
        const route = REMEMBERED_ROUTES[rememberedRoute];
        if (route) {
          const { path, access } = route;
          if (accessControls[access]) return generatePath(path, { companyAlias, facilityAlias });
        }
      }

      if (accessControls['app.monitoring']) {
        return generatePath(Routing.routes.monitoring.realTime(), { companyAlias, facilityAlias });
      }

      if (accessControls['app.modelling']) {
        return generatePath(Routing.routes.modelling.root, { companyAlias, facilityAlias });
      }

      if (accessControls['app.modelling.trajectory']) {
        return generatePath(Routing.routes.trajectories.root, { companyAlias, facilityAlias });
      }

      if (accessControls['app.blasting']) {
        return generatePath(Routing.routes.blasting.root, { companyAlias, facilityAlias });
      }

      if (accessControls['app.incident-intelligence']) {
        return generatePath(Routing.routes.incidentIntelligence.root, { companyAlias, facilityAlias });
      }

      if (accessControls['app.alerts']) {
        return generatePath(Routing.routes.alerts.root, { companyAlias, facilityAlias });
      }

      if (accessControls['app.reports']) {
        return generatePath(Routing.routes.reports.root, { companyAlias, facilityAlias });
      }

      return generatePath(Routing.routes.profile.root, { companyAlias, facilityAlias });
    },
    [generatePath, rememberedRoute]
  );
};
