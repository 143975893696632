import App from 'app/App';
import { createRoot } from 'react-dom/client';
import './i18n';
import { StrictMode } from 'react';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
