import CommentsIcon from 'app/visuals/images/icons/comments.svg?react';
import NoCommentsIcon from 'app/visuals/images/icons/no-comments.svg?react';
import { styled } from 'styled-components';

export const Comments = styled(CommentsIcon)`
  width: 20px;
  height: 20px;
`;

export const NoComments = styled(NoCommentsIcon)`
  width: 20px;
  height: 20px;
`;
