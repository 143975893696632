import { useAppSelector } from 'app/redux/store';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { i18nInstance } from 'i18n';
import { getLocalTime } from './getLocalTime';

/**
 * Returns a function that when called applies the facility's timezone to the supplied time value
 * @returns (time?: number | string | DateTime) => DateTime
 */
export function useLocalTimeCallback(): (time?: number | string | DateTime) => DateTime;

export function useLocalTimeCallback() {
  const { facility } = useAppSelector(state => state.profile);
  const { ianaTimezone } = facility;
  return useCallback(
    (time?: number | string | DateTime) => {
      return getLocalTime(time, ianaTimezone, i18nInstance.language);
    },
    [ianaTimezone]
  );
}
