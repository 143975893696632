import { DatePickerProps, TimePrecision } from '@blueprintjs/datetime';
import { useI18n } from 'core/hooks/useI18n';
import LocalTime from 'core/types/LocalTime';
import React, { useCallback, useState } from 'react';
import isNil from 'lodash/fp/isNil';
import { i18nInstance } from 'i18n';
import { localeUtils, DEFAULT_LOCALE } from './localeUtils';
import { ButtonBar, Cancel, Confirm, DatePickerWrapper, StyledDatePicker } from './StyledComponents';

type IProps = {
  initialValue: number | null;
  onValueChanging?: (millis: number) => void;
  onValueChange: (millis: number) => void;
  onCancel: () => void;
  dateOnly?: boolean;
} & DatePickerProps;

export const DateTimeSelector = (props: IProps) => {
  const { initialValue, onValueChange, onValueChanging, onCancel, dateOnly, ...datePickerProps } = props;
  const { l10n } = useI18n(['app/components', 'app']);
  const timePickerProps = dateOnly ? undefined : { useAmPm: true };

  const [date, setDate] = useState<Date>(() => {
    const referenceDate = isNil(initialValue) ? LocalTime.now() : LocalTime.fromMillis(initialValue);
    return referenceDate.toBrowser();
  });

  const handleDateChange = useCallback(
    (selectedDate: Date) => {
      setDate(selectedDate);

      if (onValueChanging) {
        const timeMillis = LocalTime.fromBrowser(selectedDate).valueOf();
        onValueChanging(timeMillis);
      }
    },
    [onValueChanging, setDate]
  );

  const handleOkClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    onValueChange(LocalTime.fromBrowser(date).valueOf());
  };

  const handleCancelClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    onCancel();
  };

  return (
    <DatePickerWrapper>
      <StyledDatePicker
        timePrecision={TimePrecision.MINUTE}
        timePickerProps={timePickerProps}
        defaultValue={date}
        onChange={handleDateChange}
        dayPickerProps={{ firstDayOfWeek: 1 }}
        locale={i18nInstance.language ?? DEFAULT_LOCALE}
        localeUtils={localeUtils}
        {...datePickerProps}
      />

      <ButtonBar>
        <Confirm text={l10n('app:action.confirm')} onClick={handleOkClick} />
        <Cancel text={l10n('app:action.cancel')} onClick={handleCancelClick} />
      </ButtonBar>
    </DatePickerWrapper>
  );
};
