import { useI18n } from 'core/hooks/useI18n';
import { ModellingNotifications } from 'app/views/modelling/notifications';
import { Intent } from '@blueprintjs/core';
import { useUpdatedJob } from './useUpdatedJob';
import { useEffect } from 'react';

export const useJobStatusNotifications = () => {
  const { l10n } = useI18n('app/views/modelling', 'notifications');
  const { job } = useUpdatedJob();
  const { completionStatus, modelName } = job ?? {};

  useEffect(() => {
    if (completionStatus === 'incomplete') {
      const message = l10n('jobCreated', { modelName });
      ModellingNotifications.show({ message, intent: Intent.PRIMARY });
    } else if (completionStatus === 'success' || completionStatus === 'partialSuccess') {
      const message = l10n('jobSucceeded', { modelName });
      ModellingNotifications.show({ message, intent: Intent.SUCCESS });
    } else if (completionStatus === 'failure') {
      const message = l10n('jobFailed', { modelName });
      ModellingNotifications.show({ message, intent: Intent.DANGER });
    }
  }, [completionStatus, l10n, modelName]);
};
