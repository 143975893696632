import { useAlertById } from 'app/views/alerts/hooks';

export const LOADING_ALERT: NonNullable<ReturnType<typeof useAlertById>['data']> = {
  triggerSnaphot: [],
  alertScriptConfigurationId: 'MMMM-MMMM-MMMM-MMMM',
  alertScriptConfigurationName: 'MMMMMMMMMM',
  id: 'MMMM-MMMM-MMMM-MMMM',
  inputSnapshot: [],
  utcTriggeredTime: new Date().toISOString(),
  appliedClassifications: [],
  alertTypeId: 'MMMMMMM',
  longMessage: 'MMMMMMMMMM',
};
