import Color from 'color';
import { PropsWithChildren } from 'react';
import { css, styled } from 'styled-components';
import { ButtonIntent, ButtonVariant } from './Button';

type ButtonProps = Partial<{ $variant: ButtonVariant; $intent: ButtonIntent }>;

const sharedStyles = css`
  appearance: none;
  font-size: 1.4rem;
  border-radius: 3px;
  padding: ${({ theme: { spacing } }) => `${spacing(1.25)}px ${spacing(2.5)}px`};
  text-decoration: none;
  display: inline-block;
`;

const variantContained = css<ButtonProps>`
  border: 0;
  background-color: ${({ theme }) => theme.colors.brand.brand01};
  color: ${({ theme }) => theme.colors.mono.text01};
  transition: background-color 0.2s ease;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.mono.text01};
    background-color: ${({ theme }) => Color(theme.colors.brand.brand01).darken(0.2).hex()};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => Color(theme.colors.mono.text01).darken(0.3).hex()};
    background-color: ${({ theme }) => Color(theme.colors.brand.brand01).desaturate(0.6).hex()};
  }

  ${({ $intent }) =>
    $intent === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.colors.feedback.error};
      &:hover,
      &:active {
        background-color: ${({ theme }) => Color(theme.colors.feedback.error).darken(0.2).hex()};
      }
      &:disabled {
        background-color: ${({ theme }) => Color(theme.colors.feedback.error).desaturate(0.6).hex()};
      }
    `}
`;

const variantText = css<ButtonProps>`
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.mono.text02};
  transition: color 0.2s ease;
  font-weight: ${({ theme }) => theme.font.default.weight.bold};

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    color: ${({ theme }) => theme.colors.mono.text01};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  ${({ $intent }) =>
    $intent === 'warning' &&
    css`
      color: ${({ theme }) => theme.colors.feedback.error};
      &:hover:not(:disabled),
      &:active:not(:disabled) {
        color: ${({ theme }) => Color(theme.colors.feedback.error).darken(0.2).hex()};
      }
    `}
`;

const variantOutlined = css<ButtonProps>`
  border: 2px solid ${({ theme }) => theme.colors.brand.brand02};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.brand.brand02};
  font-weight: ${({ theme }) => theme.font.default.weight.bold};
  transition:
    background-color 0.2s ease,
    color 0.2s ease,
    border-color 0.2s ease;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border-color: ${({ theme }) => theme.colors.brand.brand01};
    background-color: ${({ theme }) => theme.colors.brand.brand01};
    color: ${({ theme }) => theme.colors.mono.text01};
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.75;
  }

  ${({ $intent }) =>
    $intent === 'warning' &&
    css`
      border-color: ${({ theme }) => theme.colors.feedback.error};
      color: ${({ theme }) => theme.colors.feedback.error};
      &:hover:not(:disabled),
      &:active:not(:disabled) {
        border-color: ${({ theme }) => Color(theme.colors.feedback.error).darken(0.2).hex()};
        background-color: ${({ theme }) => Color(theme.colors.feedback.error).darken(0.2).hex()};
      }
      &:disabled {
        background-color: ${({ theme }) => Color(theme.colors.feedback.error).desaturate(0.6).hex()};
      }
    `}
`;

export const StyledButtonText = styled.span``;

const StyledButtonInner = ({ children }: PropsWithChildren<Record<never, never>>) => {
  return <StyledButtonText>{children}</StyledButtonText>;
};

export const StyledButton = styled.button.attrs<ButtonProps>(({ children, $variant = 'contained', $intent }) => ({
  children: <StyledButtonInner>{children}</StyledButtonInner>,
  $variant,
  $intent,
}))<ButtonProps>`
  ${sharedStyles}
  ${({ $variant }) => $variant === 'contained' && variantContained}
  ${({ $variant }) => $variant === 'text' && variantText}
  ${({ $variant }) => $variant === 'outlined' && variantOutlined}
`;
