import { Button } from 'app/components/buttons';
import { Icon } from 'app/components/icon';
import { styled, css } from 'styled-components';

const hasValueStyles = css`
  color: ${({ theme }) => theme.colors.mono.text01};
  svg {
    color: ${({ theme }) => theme.colors.mono.text01};
  }
`;

const isActiveStyles = css`
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand.brand02};
  color: ${({ theme }) => theme.colors.mono.text01};
  svg {
    color: ${({ theme }) => theme.colors.mono.text01};
  }
`;

export const DropdownButtonArrow = styled(Icon.Triangle)`
  color: ${({ theme }) => theme.colors.mono.text02};
  width: 8px !important;
  height: auto !important;
`;

export const StyledDropdownButton = styled(Button).attrs({ $variant: 'text' })<{
  $isActive?: boolean;
  $hasValue?: boolean;
}>`
  text-align: left;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.mono.text03};
  border-radius: 3px;
  color: ${({ theme }) => theme.colors.mono.text02};
  min-width: 120px;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing()}px;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    ${isActiveStyles}
  }

  ${({ $isActive }) => $isActive && isActiveStyles}
  ${({ $hasValue }) => $hasValue && hasValueStyles};

  & > * {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing()}px;
  }
`;
