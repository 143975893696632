import { gql } from '@apollo/client';

export const GET_ALERT_INPUT_VARIABLES = gql(`
  query GetAlertInputVariables ($alertScriptConfigurationsFilter: GuidIdInFilter) {
    facility {
      alerts {
        alertScriptConfigurations(filter: $alertScriptConfigurationsFilter) {
          variables {
            isTraceVariable
            variable {
              id
            }
          }
        }
      }
    }
  }
`);
