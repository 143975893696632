import { DrawerWithHandle } from 'app/components/drawer-with-handle';
import { Drawer } from 'app/components/drawer-with-handle/DrawerWithHandle.styles';
import { styled } from 'styled-components';

import { ViewAlertHeader } from '../view-alert-header';

export const StyledEventsDrawer = styled(DrawerWithHandle)`
  ${Drawer} {
    padding: 0;
  }
`;

export const EventsDrawerHeader = styled(ViewAlertHeader)`
  background-color: ${({ theme }) => theme.colors.mono.ui01};
  padding: ${({ theme: { spacing } }) => `${spacing(2)}px ${spacing(3)}px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.ui05};
`;

export const EventsChartWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const EventsListingWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const AlertResponseWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)}px;
  padding-top: 0;
`;
