import { configurationAlertRoutes } from './configurationAlertRoutes';
import { configurationModellingRoutes } from './configurationModellingRoutes';
import { configurationMonitoringRoutes } from './configurationMonitoringRoutes';
import { configurationNoiseRoutes } from './configurationNoiseRoutes';

const mailers = (root: string) => () => `${root}/mailers`;

export const configurationRoutes = (root: string) => ({
  root,
  mailers: mailers(root),
  modelling: configurationModellingRoutes(root),
  monitoring: configurationMonitoringRoutes(root),
  noise: configurationNoiseRoutes(root),
  alert: configurationAlertRoutes(root),
});
