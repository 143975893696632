import startsWith from 'lodash/fp/startsWith';
import urlJoin from 'url-join';

export type RequestHeaders = typeof requestHeaders;

type InjectedSettings = typeof injectedAppSettings;

interface IAppSettings {
  /**
   * Base URL for the ES2 web application.
   */
  readonly es2Url: string;

  /**
   * Base URL for the WS Web Socket service.
   */
  readonly wsUrl: string;

  /**
   * Base URL for the ES2 internal API.
   */
  readonly apiUrl: string;

  /**
   * Base URL for the Omnis internal API.
   */
  readonly arenaApiUrl: string;

  /**
   * Base URL for the Omnis internal RealTime service.
   */
  readonly arenaRealTimeUrl: string;

  /**
   * Base URL for the ES2 public API.
   */
  readonly publicApiUrl: string;

  /**
   * Version code to append for ES2 public API requests.
   */
  readonly publicApiVersion: string;

  /**
   * URL to the reporting (node) server.
   */
  readonly reportsServerUrl: string;

  /**
   * API key used for Google Maps.
   */
  readonly googleMapsApiKey: string;

  /**
   * Product ID for [Beamer](https://www.getbeamer.com/docs/#parameters)
   */
  readonly beamerProductId: string;

  /**
   * The build/release number for this version.
   */
  readonly releaseNumber: string;

  /**
   * The measurement id sent to google analytics for tracking
   */
  readonly googleAnalyticsMeasurementId: string;

  /**
   * The baseUrl tells the react router how much of the URL to "ignore" when
   * figuring out the location.
   *
   * When developing using the webpack dev server, our base path is '/' - but
   * when running through IIS (both on a local machine or when deployed) the
   * base path shifts to '/auth/'.
   */
  readonly baseUrl: string;
  readonly isDevelopment: boolean;
  readonly environment: string;

  /**
   * Request headers required for authorization
   */
  readonly requestHeaders: RequestHeaders;

  buildApiUrl(...segments: string[]): string;
  buildPublicApiUrl(...segments: string[]): string;

  readonly isProduction: boolean;

  readonly frontendVersion: string;
}

let injectedAppSettings = {
  get omnisUrl(): string {
    return '#{Protocol}://#{OmnisAppHost}';
  },
  get es1Url(): string {
    return '#{MvcHostUri}';
  },
  get es2Url(): string {
    return '#{WebAppHostUri}';
  },
  get wsUrl(): string {
    return '#{WSAppHostUri}';
  },
  get apiUrl(): string {
    return '#{WebApiAppHostUri}';
  },
  get arenaApiUrl(): string {
    return '#{ArenaWebApiAppHostUri}';
  },
  get arenaRealTimeUrl(): string {
    return '#{ArenaWebApiAppHostUri}/real-time-data';
  },
  get reportsServerUrl(): string {
    return '#{appSettings:ReportsAppHostUri}';
  },
  get publicApiUrl(): string {
    return '#{ES2VersionedApiUri}';
  },
  get publicApiVersion(): string {
    return '#{ES2VersionedApiVersion}';
  },
  get environment() {
    return import.meta.env.MODE;
  },
  get googleMapsApiKey(): string {
    return '#{GoogleMapsApiKey.DomainRestricted}';
  },
  get mapboxApiKey(): string {
    return '#{MapboxApiKey.DomainRestricted}';
  },
  get bingMapsApiKey(): string {
    return '#{BingMapsApiKey.DomainRestricted}';
  },
  get beamerProductId(): string {
    return '#{BeamerProductId.DomainRestricted}';
  },
  get releaseNumber(): string {
    return '#{Octopus.Release.Number}';
  },
  get googleAnalyticsMeasurementId(): string {
    return '#{GoogleAnalyticsMeasurementId}';
  },
  get icpCertificate(): string {
    return '#{China-IcpRegistrations}';
  },
  get internetSecurityNumberText(): string {
    return '#{China-InternetSecurityNumberText}';
  },
  get internetSecurityNumber(): string {
    return '#{China-InternetSecurityNumber}';
  },
  get apiKey(): string {
    return '';
  },
  get showAlphaFeatures(): boolean {
    return '#{ShowAlphaFeatures}'.toLowerCase() === 'true';
  },
  get isProduction() {
    return !!'#{OmnisAppHost}'.match(/^omnis.envirosuite.[a-z]+/);
  },
  get guestApiKey(): string {
    return '#{GuestApiKey}';
  },
  get useGuestApiKey(): boolean {
    return '#{UseGuestApiKey}'.toLowerCase() === 'true';
  },

  get frontendVersion(): string {
    // There are a number of rules set up in Cloudfront to handle file caching depending
    // on the type of request. This value is currently used for browser cache busting
    // and I want to make sure any requests that include it aren't misinterpreted as
    // a particular file type, so making the value alphanumeric just in case.
    return alphanumeric('#{OmnisFrontendVersion}');
  },
};

function alphanumeric(value: string) {
  return value.replace(/\W/g, '');
}

// Cater for development where Octopus doesn't run, hence no substitution
if (!startsWith('http', injectedAppSettings.es2Url)) {
  const ES1_HOST = import.meta.env.VITE_ES1_HOST || 'http://localhost:60000';
  const ES2_HOST = import.meta.env.VITE_ES2_HOST || 'http://localhost:61000';
  const WS_HOST = import.meta.env.VITE_WS_HOST || 'wss://localhost:5263';
  const ARENA_HOST = import.meta.env.VITE_ARENA_HOST || 'http://localhost:5196';

  // Check if we have stuff in environment variables
  injectedAppSettings = {
    get omnisUrl(): string {
      return import.meta.env.VITE_OMNIS_URL || 'http://localhost:3000';
    },
    get es2Url(): string {
      return import.meta.env.VITE_WebAppHostUri || `${ES2_HOST}`;
    },
    get es1Url(): string {
      return import.meta.env.VITE_WebAppHostUri || `${ES1_HOST}`;
    },
    get wsUrl(): string {
      return import.meta.env.VITE_WsUri || `${WS_HOST}`;
    },
    get apiUrl(): string {
      return import.meta.env.VITE_WebApiAppHostUri || `${ES2_HOST}/api`;
    },
    get arenaApiUrl(): string {
      return import.meta.env.VITE_ArenaApiAppHostUri || `${ARENA_HOST}/`;
    },
    get arenaRealTimeUrl(): string {
      return import.meta.env.VITE_ArenaApiAppHostUri || `${ARENA_HOST}/real-time-data`;
    },
    get reportsServerUrl(): string {
      return import.meta.env.VITE_ReportsAppHostUri || `${ES2_HOST}/reports`;
    },
    get publicApiUrl(): string {
      return import.meta.env.VITE_ES2VersionedApiUri || `${ES1_HOST}/api`;
    },
    get publicApiVersion(): string {
      return 'latest';
    },
    get environment() {
      return import.meta.env.MODE;
    },
    get googleMapsApiKey(): string {
      return 'AIzaSyAZsoqQGsDFID-waQbSinVR3NQkq9j-msQ';
    },
    get mapboxApiKey(): string {
      return (
        import.meta.env.VITE_MAPBOX_ACCESS_TOKEN ||
        'pk.eyJ1IjoiZW52aXJvc3VpdGUtYnJpc2JhbmUiLCJhIjoiY2sybzY1OWNpMDd6ZDNkczd4Z2Z2cWxxZyJ9.-wuqJp_YE_zFr4JjPsZHAg'
      );
    },
    get bingMapsApiKey(): string {
      return import.meta.env.VITE_BING_MAPS_API_KEY;
    },
    get beamerProductId(): string {
      return import.meta.env.VITE_BEAMER_PRODUCT_ID;
    },
    get releaseNumber(): string {
      return 'Development';
    },
    get googleAnalyticsMeasurementId(): string {
      return 'UA-8575676-11';
    },
    get icpCertificate(): string {
      return '';
    },
    get internetSecurityNumber(): string {
      return '';
    },
    get internetSecurityNumberText(): string {
      return '';
    },
    get apiKey(): string {
      return import.meta.env.VITE_API_KEY ?? '';
    },
    get showAlphaFeatures(): boolean {
      return import.meta.env.VITE_SHOW_ALPHA_FEATURES === 'true';
    },
    get isProduction() {
      return import.meta.env.PROD;
    },
    get guestApiKey(): string {
      return import.meta.env.VITE_GUEST_API_KEY ?? '';
    },
    get useGuestApiKey(): boolean {
      return import.meta.env.VITE_USE_GUEST_API_KEY === 'true';
    },

    get frontendVersion() {
      return crypto.getRandomValues(new Uint32Array(1))[0]?.toString() ?? '';
    },
  };
}

const requestHeaders = {
  companyAlias: 'PEL-Company-Alias' as const,
  facilityAlias: 'PEL-Facility-Alias' as const,
};

const baseUrl = location.href.toLowerCase().indexOf('/auth') > 0 ? '/auth/' : '/';

const createUrlBuilder =
  (urlBase: string) =>
  (...segments: string[]) =>
    urlJoin(urlBase, ...segments);

export const AppSettings: IAppSettings & InjectedSettings = {
  ...injectedAppSettings,

  get baseUrl(): string {
    return baseUrl;
  },
  get isDevelopment(): boolean {
    return import.meta.env.MODE === 'development';
  },
  get requestHeaders() {
    return requestHeaders;
  },

  buildApiUrl: createUrlBuilder(injectedAppSettings.apiUrl),
  buildPublicApiUrl: createUrlBuilder(injectedAppSettings.publicApiUrl),
};
