import { styled, css } from 'styled-components';

export const AudioVisualisationList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
`;

export const AudioVisualisationListItem = styled.li``;

const selectedStyles = css`
  color: ${({ theme }) => theme.colors.brand.brand02};
  box-shadow: 0 -2px 0 0 ${({ theme }) => theme.colors.brand.brand02} inset;
  &:hover {
    color: ${({ theme }) => theme.colors.brand.brand02};
  }
`;

export const AudioVisualisationButton = styled.button<{ $isSelected?: boolean }>`
  color: ${({ theme }) => theme.colors.mono.text03};
  padding: ${({ theme: { spacing } }) => `${spacing(1.5)}px`};
  &:hover {
    color: ${({ theme }) => theme.colors.mono.text02};
  }
  ${({ $isSelected }) => $isSelected && selectedStyles}
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
  }
`;
