import { useFetchBlastingScenario } from 'app/views/blasting/pages/view-scenario/useFetchBlastingScenario';
import { useParams } from 'react-router-dom';
import { useI18n } from 'core/hooks/useI18n';
import { Classes } from '@blueprintjs/core';
import { BlastModelType } from 'app/business-logic/domain-models/Blasting';
import { Label, Value, Parameter, ScenarioCardContainer, ScenarioCardTitle } from './ScenarioDataCards.styles';

export const GroundVibrationCard = () => {
  const { alias } = useParams();
  const { l10n } = useI18n('app/views/blasting');

  const { data: scenario, isLoading } = useFetchBlastingScenario(alias);
  const loadingClass = isLoading ? Classes.SKELETON : undefined;
  if (!scenario.blastModels?.some(model => model.modelType === BlastModelType.GroundVibration) && !isLoading) {
    return null;
  }
  return (
    <ScenarioCardContainer>
      <ScenarioCardTitle className={loadingClass}>
        {l10n('page.createScenario.form.section.groundVibration')}
      </ScenarioCardTitle>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.chargeMassPerHole.labelWithoutUnit')}</Label>
        <Value>
          {scenario.chargeMassPerHole} {l10n('fields.chargeMassPerHole.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.groundVibrationConstant.label')}</Label>
        <Value>{scenario.groundVibrationSiteConstantKv}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.groundVibrationExponent.label')}</Label>
        <Value>{scenario.groundVibrationSiteExponentKb}</Value>
      </Parameter>
    </ScenarioCardContainer>
  );
};
