import { Classes as DateTimeClasses } from '@blueprintjs/datetime';
import { Classes } from '@blueprintjs/core';
import { styled, css } from 'styled-components';
import Color from 'color';

const {
  DATEPICKER,
  DATERANGEPICKER_SHORTCUTS,
  TIMEPICKER_INPUT_ROW,
  DATEPICKER_DAY,
  DATEPICKER_DAY_SELECTED,
  DATERANGEPICKER_DAY_SELECTED_RANGE,
  DATEPICKER_CAPTION,
} = DateTimeClasses;

const { DIVIDER, HTML_SELECT, MENU_ITEM, ACTIVE } = Classes;

export const StyledDateRangeSelector = styled.div`
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  border-radius: 3px;
  box-shadow: 0px 14px 41px rgba(0, 0, 0, 0.45);
  border: 1px solid ${({ theme }) => theme.colors.mono.ui04};
  padding: ${({ theme: { spacing } }) => spacing(2)}px;
  max-width: 100vw;
  max-height: 100vh; // Fallback for safety, in case --doc-height goes missing
  max-height: var(--doc-height);
  overflow: auto;

  .${DATEPICKER} {
    padding: 0;
    background: transparent;
    ${({ theme }) =>
      theme.breakpoints.down(
        'sm',
        css`
          flex-direction: column;
          overflow: hidden;
        `
      )}
  }

  .${DIVIDER} {
    border-color: ${({ theme }) => theme.colors.mono.ui05};
  }

  .DayPicker-Weekday {
    font-weight: ${({ theme }) => theme.font.default.weight.normal};
  }

  .${HTML_SELECT} option {
    background-color: ${({ theme }) => theme.colors.mono.ui04};
  }

  &&& {
    .${MENU_ITEM} {
      padding: ${({ theme: { spacing } }) => spacing(0.75)}px;
      margin-left: -${({ theme: { spacing } }) => spacing(0.75)}px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.mono.ui04};
      }
      &.${ACTIVE}, &:active {
        background-color: ${({ theme }) => theme.colors.brand.brand01};
        color: ${({ theme }) => theme.colors.mono.text01};
        &:hover {
          background-color: ${({ theme }) => Color(theme.colors.brand.brand01).lighten(0.15).toString()};
        }
      }
      ${({ theme }) =>
        theme.breakpoints.down(
          'sm',
          css`
            margin-left: 0;
          `
        )}
    }

    .${DATERANGEPICKER_SHORTCUTS} {
      background: transparent;
      font-size: 1.6rem;
      ${({ theme }) =>
        theme.breakpoints.down(
          'sm',
          css`
            display: flex;
            align-items: center;
            overflow-x: auto;
            gap: ${({ theme }) => theme.spacing()}px;
          `
        )}
    }

    .${TIMEPICKER_INPUT_ROW} {
      background-color: ${({ theme }) => theme.colors.mono.ui04};
    }

    .${DATEPICKER_DAY} {
      &:hover {
        background-color: ${({ theme }) => theme.colors.mono.ui04};
      }
    }

    .${DATEPICKER_DAY_SELECTED} {
      background-color: ${({ theme }) => theme.colors.brand.brand01};
      &:hover {
        background-color: ${({ theme }) => Color(theme.colors.brand.brand01).lighten(0.15).toString()};
      }
    }

    .${DATERANGEPICKER_DAY_SELECTED_RANGE} {
      background-color: ${({ theme }) => theme.colors.mono.ui04};
      &:hover {
        background-color: ${({ theme }) => Color(theme.colors.mono.ui04).lighten(0.15).toString()};
      }
    }

    .${DATEPICKER_CAPTION} select {
      font-weight: ${({ theme }) => theme.font.default.weight.normal};
    }
  }
`;

export const Title = styled.p`
  font-size: 1.8rem;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  margin-bottom: ${({ theme: { spacing } }) => spacing(3)}px;
  color: ${({ theme }) => theme.colors.mono.text01};
`;
