import { useCallback, useState } from 'react';

export const useSetIsLoading = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleSetIsLoading = useCallback(() => {
    setIsLoading(false);
  }, []);
  return {
    isLoading,
    setIsLoading: handleSetIsLoading,
  };
};
