import { DateTime } from 'luxon';

/**
 * Compare the time the data was received and the stale data timeout with the facility time
 * to determine whether the data should be considered stale.
 */
export const isStale = ({
  facilityTimeMillis,
  dataTimeMillis,
  staleDataTimeoutSeconds,
}: {
  facilityTimeMillis: number;
  dataTimeMillis: number;
  staleDataTimeoutSeconds: number;
}) => {
  if (staleDataTimeoutSeconds === 0) return false; // If stale data timeout seconds is set to zero, consider the value to never be stale
  const staleTime = DateTime.fromMillis(dataTimeMillis).plus({ seconds: staleDataTimeoutSeconds }).valueOf();
  return facilityTimeMillis > staleTime;
};
