import { DateRange } from 'app/redux/analyse/slice';
import { useAppSelector } from 'app/redux/store';
import { DateTime } from 'luxon';

export const useUtcDateTimeStart = (utcDateTimeEnd: string | null) => {
  const { dateRange } = useAppSelector(state => state.analyse);
  if (!utcDateTimeEnd) return null;
  const dateTimeEnd = DateTime.fromISO(utcDateTimeEnd);
  const dateTimeStart = getDateTimeStart(dateRange, dateTimeEnd);
  if (!dateTimeStart) return null;
  return dateTimeStart;
};

function getDateTimeStart(dateRange: DateRange, dateTimeEnd: DateTime) {
  switch (dateRange) {
    case 'LAST_24_HOURS':
      return dateTimeEnd.minus({ hours: 24 }).toISO();
    case 'LAST_6_HOURS':
      return dateTimeEnd.minus({ hours: 6 }).toISO();
    case 'LAST_7_DAYS':
      return dateTimeEnd.minus({ days: 7 }).toISO();
    case 'LAST_HOUR':
      return dateTimeEnd.minus({ hour: 1 }).toISO();
    default: {
      throw new Error(`Could not determine date time start for ${dateRange}`);
    }
  }
}
