import { useEffect } from 'react';
import { WaveSurferRef } from '../types';
import { useSetLoadingProgressPercentage } from './useSetLoadingProgressPercentage';

export const useLoadingProgressPercentage = (waveSurferRef: WaveSurferRef) => {
  const { loadingProgress, setLoadingProgress } = useSetLoadingProgressPercentage();
  useEffect(() => {
    if (!waveSurferRef.current) return;
    const waveSurfer = waveSurferRef.current;
    waveSurfer.on('loading', setLoadingProgress);
    return () => {
      waveSurfer.un('loading', setLoadingProgress);
    };
  }, [setLoadingProgress, waveSurferRef]);

  return loadingProgress;
};
