import { useI18n } from 'core/hooks/useI18n';
import { TableState } from '@tanstack/react-table';
import { LoadingPages } from './LoadingPages';
import {
  Dots,
  NextButton,
  NextPageIcon,
  PageButton,
  PrevButton,
  PrevPageIcon,
  Wrapper,
  Items,
  ItemsCount,
} from './TablePagination.styles';
import { Localiser } from 'i18n';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';

type TablePaginationProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  gotoPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (size: number) => void;
  state: TableState;
  pageCount: number;
  isLoading: boolean;
  showItemCount?: {
    totalItems: number;
  };
  pageLimit?: number;
};

const PAGINATION_NAV_BUTTON_COUNT = 2; // "prev" & "next"

export const TablePagination = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  isLoading,
  gotoPage,
  nextPage,
  previousPage,
  showItemCount,
  state: {
    pagination: { pageIndex, pageSize },
  },
  pageLimit = 5,
}: TablePaginationProps) => {
  const { l10n } = useI18n('app/components');
  const pages = usePages(pageOptions, pageIndex, pageLimit);
  const pageCount = pages.length;
  if (pageCount === 1) return null;
  const testId = `table-pagination${isLoading ? '--is-loading' : ''}`;
  return (
    <Wrapper data-testid={testId}>
      {!isLoading && (
        <>
          <Items style={{ gridTemplateColumns: `repeat(${pages.length + PAGINATION_NAV_BUTTON_COUNT}, 1fr)` }}>
            <PrevButton
              onClick={previousPage}
              disabled={!canPreviousPage}
              title={l10n('pagination.previousPage')}
              data-tracking={DATA_TRACKING_KEY['pagination-previous']}
            >
              <PrevPageIcon />
            </PrevButton>
            {pages.map((value, index) =>
              typeof value === 'number' ? (
                <PageButton
                  key={value}
                  onClick={() => gotoPage(value - 1)}
                  $isCurrent={value - 1 === pageIndex}
                  data-testid="pagination-page-button"
                  data-tracking={DATA_TRACKING_KEY['pagination-number']}
                >
                  {value}
                </PageButton>
              ) : (
                <Dots key={`dots-${index}`}>...</Dots>
              )
            )}
            <NextButton
              onClick={nextPage}
              disabled={!canNextPage}
              title={l10n('pagination.nextPage')}
              data-tracking={DATA_TRACKING_KEY['pagination-next']}
            >
              <NextPageIcon />
            </NextButton>
          </Items>
          {showItemCount && (
            <ItemsCount data-testid="items-count">
              {itemCountAgainstTotalCount(pageIndex, pageSize, l10n, showItemCount.totalItems)}
            </ItemsCount>
          )}
        </>
      )}
      {isLoading && <LoadingPages />}
    </Wrapper>
  );
};

function usePages(allPages: number[], currentIndex: number, pageLimit: number) {
  const limit = pageLimit;
  const pageNumbers = allPages.map(option => option + 1);
  const pageCount = pageNumbers.length;
  const currentPage = currentIndex + 1;
  const lastPage = pageNumbers[pageCount - 1];

  const tooFewPagesToTruncate = pageCount <= limit + 2; // [ 1 2 3 4 5 ]
  const truncateEnd = currentPage < limit; // [ 1 2 3 4 5 ... 20 ]
  const truncateStart = currentPage > pageCount - limit + 1; // [ 1 ... 16 17 18 19 20 ]

  if (tooFewPagesToTruncate) return pageNumbers;
  if (truncateEnd) return [...pageNumbers.filter(number => number <= limit), null, lastPage];
  if (truncateStart) return [1, null, ...pageNumbers.filter(number => number > pageCount - limit)];

  // [ 1 ... 7 8 9 ... 20 ]
  return [1, null, currentPage - 1, currentPage, currentPage + 1, null, lastPage];
}

const itemCountAgainstTotalCount = (pageIndex: number, pageSize: number, l10n: Localiser, itemCount?: number) => {
  if (!itemCount) return '';
  const start = pageIndex * pageSize + 1;
  const end = Math.min(start + pageSize - 1, itemCount);
  return l10n('table.itemCount', { start, end, itemCount });
};
