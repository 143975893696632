import { useAppSelector } from 'app/redux/store';
import { useEffect } from 'react';

export const useHeapFacilityData = () => {
  const {
    facility: { companyAlias, facilityAlias, region, smaxCustomerCode, type },
    user: { details },
  } = useAppSelector(state => state.profile);
  const { firstName, lastName, email } = details ?? {};
  useEffect(() => {
    if (!window.heap) return;
    // To account for initial values before they're set
    if (!companyAlias || !facilityAlias) return;
    window.heap.addUserProperties({
      Company: companyAlias,
      Facility: facilityAlias,
      Region: region ?? 'UNKNOWN_REGION',
      SmaxCustomerCode: smaxCustomerCode ?? 'UNKNOWN_SMAX_CUSTOMER_CODE',
      FacilityType: type ?? 'UNKNOWN_FACILITY_TYPE',
      FirstName: firstName ?? 'UNKNOWN_FIRST_NAME',
      LastName: lastName ?? 'UNKNOWN_LAST_NAME',
      Email: email ?? 'UNKNOWN_EMAIL',
    });
  }, [companyAlias, email, facilityAlias, firstName, lastName, region, smaxCustomerCode, type]);
};
