import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Job, JobCompletionStatus } from './types';
import { resetStore } from '../actions';

export const INITIAL_STATE: Job[] = [];

export const slice = createSlice({
  name: 'jobs',
  initialState: INITIAL_STATE,
  reducers: {
    jobCreated: (state, { payload }: PayloadAction<Job>) => {
      // Sometimes SignalR receives multiple "created" events for the same job
      const alreadyHasJob = state.some(job => job.jobId === payload.jobId);
      if (!alreadyHasJob) {
        state.push(payload);
      }
    },

    jobUpdated: (
      state,
      { payload }: PayloadAction<{ jobId: string; progress: number; progressMessage?: string; errorMessage?: string }>
    ) => {
      const newProgressMessages = payload.progressMessage || [];
      const newErrorMessages = payload.errorMessage || [];
      return state.map(job => {
        if (job.jobId !== payload.jobId) return job;
        return {
          ...job,
          progress: payload.progress,
          progressMessages: job.progressMessages.concat(newProgressMessages),
          errorMessages: job.errorMessages.concat(newErrorMessages),
        };
      });
    },

    jobCompleted: (state, { payload }: PayloadAction<{ jobId: string; completionStatus: JobCompletionStatus }>) => {
      return state.map(job => {
        if (job.jobId !== payload.jobId) return job;
        return {
          ...job,
          completionStatus: payload.completionStatus,
        };
      });
    },

    jobDismissed: (state, { payload }: PayloadAction<Job['jobId']>) => {
      return state.filter(job => job.jobId !== payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
