import {
  UpdateAcknowledgeAlertEventMutation,
  UpdateAcknowledgeAlertEventMutationVariables,
} from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';
import { UPDATE_ACKNOWLEDGE_ALERT_EVENT } from './graphql/facility.updateAcknowledgeAlertEvent';

export const updateAcknowledgeAlertEvent = async (alertEventId: Guid) => {
  const { data, errors } = await ApolloProvider.global().mutate<
    UpdateAcknowledgeAlertEventMutation,
    UpdateAcknowledgeAlertEventMutationVariables
  >({
    mutation: UPDATE_ACKNOWLEDGE_ALERT_EVENT,
    variables: {
      input: { alertEventId },
    },
  });
  if (!data || errors) throw new Error(`Something went wrong when trying update alert response: ${alertEventId}`);
  const acknowledgedAlertEvent = data.facility.alertEvent.acknowledgeAlertEvent;
  return {
    acknowledgedAlertEvent,
  };
};
