import { useLocation, Link } from 'react-router-dom';
import { StyledTabList, TabListItem, TabListLink } from './TabList.styles';

export interface ITabItem {
  label: string;
  link?: string;
}

interface IProps {
  tabs: ITabItem[];
  onClick?: (index: number) => void;
  selectedTabIndex?: number;
}

export const TabList = ({ tabs, onClick, selectedTabIndex }: IProps) => {
  const location = useLocation().pathname;

  const tabItems = tabs.map(({ link, label }, index) => {
    if (!link) {
      return (
        <TabListItem key={label} $isSelected={selectedTabIndex === index}>
          <TabListLink onClick={() => onClick && onClick(index)} $isSelected={selectedTabIndex === index}>
            {label}
          </TabListLink>
        </TabListItem>
      );
    }

    return (
      <TabListItem key={label} $isSelected={location.startsWith(link)}>
        <TabListLink as={Link} to={link} $isSelected={location.startsWith(link)}>
          {label}
        </TabListLink>
      </TabListItem>
    );
  });

  return <StyledTabList>{tabItems}</StyledTabList>;
};
