import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { AlertResponseClassification } from 'app/business-logic/services/alerts-services/alertResponse.types';
import { useHasAccess } from 'app/hooks/use-has-access';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useAppDispatch } from 'app/redux/store';
import { deselectAlert, openDrawer } from 'app/redux/ui/actions';
import { useAlertResponseClassifications } from 'app/views/configuration/pages/alert/alert-responses/alert-responses-list/useAlertResponseClassifications';
import { useI18n } from 'core/hooks/useI18n';
import Guid from 'core/types/Guid';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { AlertResponseHeading, AlertResponseWrapper } from './AlertResponse.styles';
import { AlertAcknowledgeFooter } from './alert-acknowledge-footer';
import { AlertResponseClassificationButtons } from './alert-response-classification-buttons';
import { AlertResponseCommentsField } from './alert-response-comments-field';
import { AlertResponseFooter } from './alert-response-footer/AlertResponseFooter';
import { useApplyAlertResponse } from './useApplyAlertResponse';
import { useDisplayedClassifications } from './useDisplayedClassifications';
import { useUpdateAcknowledgeAlertEvent } from './useUpdateAcknowledgeAlertEvent';

export type AlertResponseFormType = {
  classifications: {
    isSelected: boolean;
    classification: AlertResponseClassification;
  }[];
  comments: string;
};

type AlertResponseProps = {
  alertId: Guid;
};

export const AlertResponse = ({ alertId }: AlertResponseProps) => {
  const { l10n } = useI18n('app/views/alerts', 'alertResponse');
  const { updateAlertResponse, isLoading: isLoadingAlertResponse } = useApplyAlertResponse(alertId);
  const { updateAcknowledgeAlertEvent, isPending: isUpdatingAcknowledgeAlertEvent } = useUpdateAcknowledgeAlertEvent();
  const { data: displayedClassifications = [], isLoading: isLoadingAlertResponseClassifications } =
    useDisplayedClassifications(alertId);
  const initialValues = useInitialAlertResponseValues(alertId);
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const hasAlertResponseReadAccess = useHasAccess()(KnownAccessControls.app.alerts.response.data.read._);
  const hasAlertResponseWriteAccess = useHasAccess()(KnownAccessControls.app.alerts.response.data.write._);
  const hasAcknowledgeAccess = useHasAccess()(KnownAccessControls.app.alerts.acknowledge._);
  const handleOnSubmit = (values: AlertResponseFormType) => {
    if (!alertId) return;
    if (hasAlertResponseWriteAccess) {
      updateAlertResponse({ ...values, alertEventId: alertId });
    }
    if (hasAcknowledgeAccess) {
      updateAcknowledgeAlertEvent(alertId);
    }
    dispatch(deselectAlert());
    if (isMobile) {
      dispatch(openDrawer('ALERTS'));
    }
  };
  const isLoading = isLoadingAlertResponse || isUpdatingAcknowledgeAlertEvent || isLoadingAlertResponseClassifications;

  if (!isLoadingAlertResponseClassifications && !displayedClassifications.length) {
    if (hasAcknowledgeAccess) return <AlertAcknowledgeFooter isLoading={isLoading} alertId={alertId} />;
    return null;
  }

  if (!hasAlertResponseReadAccess) return null;

  return (
    <Formik<AlertResponseFormType>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={handleOnSubmit}
      key={alertId}
    >
      <Form>
        <AlertResponseWrapper>
          <AlertResponseHeading>{l10n('heading.alertResponse')}</AlertResponseHeading>
          <AlertResponseClassificationButtons
            displayedClassifications={displayedClassifications}
            isLoading={isLoadingAlertResponseClassifications}
          />
          <AlertResponseHeading>{l10n('heading.comments')}</AlertResponseHeading>
          <AlertResponseCommentsField isLoading={isLoading} />
        </AlertResponseWrapper>
        {(hasAlertResponseWriteAccess || hasAcknowledgeAccess) && <AlertResponseFooter isLoading={isLoading} />}
      </Form>
    </Formik>
  );
};

const useInitialAlertResponseValues = (alertId: Guid) => {
  const { data: alertResponse } = useApplyAlertResponse(alertId);
  const { data: allClassifications = [] } = useAlertResponseClassifications();

  const initialClassifications = useMemo(
    () =>
      allClassifications.map(classification => {
        const isSelected =
          alertResponse?.alertEventResponseClassifications.some(
            c => c.alertResponseClassificationId === classification.id
          ) ?? false;
        return {
          isSelected,
          classification,
        };
      }),
    [allClassifications, alertResponse]
  );
  return { classifications: initialClassifications, comments: alertResponse?.comments ?? '' };
};
