import { NoiseMeasurementType } from 'app/views/components/real-time-noise-connection/RealTimeNoiseConnection.types';
import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';

type UtcDateTime = string;
type Value = number;

export type LowLatencyNoiseSeriesByDateTime = Record<UtcDateTime, ByNoiseMeasurementType>;
type ByNoiseMeasurementType = Partial<Record<NoiseMeasurementType, Value>>;

export const getLowLatencyNoiseSeriesData = async (params: {
  locationId: number;
  utcStartDate: string;
  utcEndDate: string;
  noiseTypes: NoiseMeasurementType[];
}) => {
  const { data, error }: HttpResponse<LowLatencyNoiseSeriesByDateTime> = await httpClient.useArenaInternalApi
    .asAuthorised()
    .get('/device-data/noisemeasurementsecondly-devices', params);
  if (error || !data) throw new Error(`Something went wrong when trying to fetch low latency noise data`);
  return data;
};
