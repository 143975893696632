import { styled } from 'styled-components';

export const BeamerIconWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
`;

export const NewPostCount = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.feedback.error};
  border-radius: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
