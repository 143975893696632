import { AppSettings } from 'core/AppSettings';
import { DateTime } from 'luxon';

// Basic replacement for Winston. Not sure how it ever worked... it's not supposed to:
// https://github.com/winstonjs/winston/issues/287
const getLogger = (source: string) => {
  return {
    error: (...data: any[]) => {
      const time = DateTime.now().toUTC().toString();
      console.error(time, `[${source}]`, ...data);
    },
    debug: (...data: any[]) => {
      if (AppSettings.isProduction) return;
      const time = DateTime.now().toUTC().toString();
      console.log(time, `[${source}]`, ...data);
    },
    warn: (...data: any[]) => {
      const time = DateTime.now().toUTC().toString();
      console.warn(time, `[${source}]`, ...data);
    },
    info: (...data: any[]) => {
      const time = DateTime.now().toUTC().toString();
      console.log(time, `[${source}]`, ...data);
    },
  };
};

const defaultExport = { getLogger };

export default defaultExport;
export const LogManager = { ...defaultExport };
