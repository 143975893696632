import { AppSettings } from 'core/AppSettings';
import { LogManager } from 'core/logging/LogManager';
import { Routing } from 'core/routing/Routing';
import { generatePath, matchPath } from 'react-router-dom';

const logger = LogManager.getLogger('getReturnUrl');
let ROUTES: string[] = [];

export function getReturnUrl() {
  // Seeing "Cannot access 'ROUTES' before initialization" on alerts screen, due to web worker usage,
  // so setting routes from within this function as a workaround.
  if (ROUTES.length === 0) {
    ROUTES = Object.values(Routing.routes).flatMap(extractRoute);
  }
  const { pathname, search } = window.location;
  const pathnameParts = pathname.split('/');
  const [match] = ROUTES.filter(route => matchPath(route, pathname));

  if (!match) return AppSettings.omnisUrl;

  const matchParts = match.split('/');

  if (!pathnameParts.length || !matchParts.length || pathnameParts.length !== matchParts.length) {
    return AppSettings.omnisUrl;
  }

  const replacements = Object.fromEntries(
    matchParts.flatMap((part, index) => {
      if (!part.startsWith(':')) return [];
      const partName = part.substring(1);
      const replacement = pathnameParts.at(index);
      if (!replacement) {
        logger.error(`Failed to find value for "${partName}" parameter`);
        return [];
      }
      return [[partName, replacement]];
    })
  );

  // generatePath can throw an error if params aren't replaced
  try {
    return [AppSettings.omnisUrl, generatePath(match, replacements), search].join('');
  } catch (e) {
    logger.error(e);
    return AppSettings.omnisUrl;
  }
}

type StringOrFunction = string | (() => string);

type RecursiveObject = {
  [key: string]: StringOrFunction | RecursiveObject;
};

function extractRoute(v: StringOrFunction | RecursiveObject): string | Array<string> {
  if (typeof v === 'string') return v;
  if (typeof v === 'function') return v();
  return Object.values(v).flatMap(extractRoute);
}
