import { generatePath } from 'react-router-dom';

import * as routes from './routes';
import * as roots from './Routing.constants';

const {
  ABOUT_ROUTES_ROOT,
  ALERTS_ROUTES_ROOT,
  ANNOTATIONS_ROUTES_ROOT,
  BLASTING_ROUTES_ROOT,
  CONFIGURATION_ROUTES_ROOT,
  GRID_ROUTES_ROOT,
  INCIDENT_INTELLIGENCE_ROUTES_ROOT,
  MODELLING_ROUTES_ROOT,
  MONITORING_ROUTES_ROOT,
  PROFILE_ROUTES_ROOT,
  REPORTS_ROUTES_ROOT,
  TRAJECTORIES_ROUTES_ROOT,
} = roots;

const {
  aboutRoutes,
  alertsRoutes,
  annotationsRoutes,
  blastingRoutes,
  configurationRoutes,
  gridRoutes,
  incidentIntelligenceRoutes,
  modellingRoutes,
  monitoringRoutes,
  profileRoutes,
  reportsRoutes,
  trajectoriesRoutes,
} = routes;

const ROUTES = {
  about: aboutRoutes(ABOUT_ROUTES_ROOT),
  alerts: alertsRoutes(ALERTS_ROUTES_ROOT),
  annotations: annotationsRoutes(ANNOTATIONS_ROUTES_ROOT),
  blasting: blastingRoutes(BLASTING_ROUTES_ROOT),
  configuration: configurationRoutes(CONFIGURATION_ROUTES_ROOT),
  grid: gridRoutes(GRID_ROUTES_ROOT),
  incidentIntelligence: incidentIntelligenceRoutes(INCIDENT_INTELLIGENCE_ROUTES_ROOT),
  modelling: modellingRoutes(MODELLING_ROUTES_ROOT),
  monitoring: monitoringRoutes(MONITORING_ROUTES_ROOT),
  profile: profileRoutes(PROFILE_ROUTES_ROOT),
  reports: reportsRoutes(REPORTS_ROUTES_ROOT),
  trajectories: trajectoriesRoutes(TRAJECTORIES_ROUTES_ROOT),
};

/**
 * Used to get paths used to match routes,
 * e.g. `/:companyAlias/:facilityAlias/trajectories/view/:trajectoryId/:trajectoryVisualisation
 */
export const Routing = {
  generatePath,
  routes: ROUTES,
};
