import { gql } from '@apollo/client';

export const GET_ALERT_RESPONSE = gql(`
  query GetAlertResponse ($alertEventId: Guid!) {
    facility {
      alerts {
        alertEventResponse (alertEventId: $alertEventId) {
          alertEventId
          comments
          alertEventResponseClassifications {
            alertEventId
            alertResponseClassificationId
            utcCreatedAt
            utcDeletedAt
            createdBy
          }
          utcDeletedAt
          createdBy
          utcCreatedAt
        }
      }
    }
  }
`);
