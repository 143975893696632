import { blueprintButtonReset } from 'app/visuals/mixins/blueprintButtonReset';
import { styled, css } from 'styled-components';

import { Button } from '@blueprintjs/core';
import { MOBILE_NAVIGATION_BAR_HEIGHT } from 'app/views/components/mobile-navigation-bar/MobileNavigationBar.styles';

export const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        flex-direction: column;
      `
    )}
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        width: 100%;
        flex-shrink: 1;
        max-height: calc(50vh - ${MOBILE_NAVIGATION_BAR_HEIGHT}px);
      `
    )}
`;

export const SidebarHeader = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
`;

export const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.mono.text01};
  font-size: 1.8rem;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: none;
      `
    )}
`;

export const SidebarContent = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
  padding-right: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        display: flex;
        overflow: auto;
        gap: ${({ theme }) => theme.spacing()}px;
        padding-bottom: ${({ theme }) => theme.spacing(2)}px;
      `
    )}
  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        overflow-y: auto;
      `
    )}
`;

export const SidebarFooter = styled.div`
  flex-grow: 0;
  padding: ${({ theme }) => theme.spacing(3)}px;
  display: flex;
  justify-content: flex-end;
`;

export const ConfirmButton = styled(Button)`
  ${() => blueprintButtonReset};
  &&& {
    padding: ${({ theme: { spacing } }) => `${spacing()}px ${spacing(1.2)}px ${spacing(1.2)}px`};
    color: ${({ theme }) => theme.colors.mono.text01};
    border: 2px solid ${({ theme }) => theme.colors.mono.text01};
    &:disabled {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.mono.text01};
      border: 2px solid ${({ theme }) => theme.colors.mono.text01};
      opacity: 0.5;
    }
  }
`;

export const CancelButton = styled(Button)`
  ${() => blueprintButtonReset};
  &&& {
    padding: ${({ theme: { spacing } }) => `${spacing(0.95)}px ${spacing()}px ${spacing(1.1)}px`};
    color: ${({ theme }) => theme.colors.mono.text02};
    border: 2px solid transparent;
    margin-right: ${({ theme }) => theme.spacing(2)}px;
    &:disabled {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.mono.text02};
      border: 2px solid transparent;
      opacity: 0.5;
    }
  }
`;

export const MapWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
`;
