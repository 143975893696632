import { GetAlertResponseQuery, GetAlertResponseQueryVariables } from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';
import { GET_ALERT_RESPONSE } from './graphql/facility.getAlertResponse';

export const getAlertResponse = async (alertEventId: Guid) => {
  const { data, error } = await ApolloProvider.global().query<GetAlertResponseQuery, GetAlertResponseQueryVariables>({
    query: GET_ALERT_RESPONSE,
    variables: {
      alertEventId,
    },
  });
  if (!data || error) throw new Error('Something went wrong when trying to get alert response classifications');
  return data.facility?.alerts?.alertEventResponse;
};
