import { styled } from 'styled-components';

export const ProtectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const UnauthorisedMessage = styled.p`
  font-size: 1.6rem;
  text-transform: capitalize;
`;

export const LoginButton = styled.a`
  && {
    text-transform: uppercase;
    font-size: 1.4rem;
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    border: 1px solid ${({ theme }) => theme.colors.mono.ui05};
    display: inline-block;
    border-radius: 3px;
    color: ${({ theme }) => theme.colors.mono.text01};
    line-height: 1;
    transition: none;
    &:hover {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.mono.text01};
    }
  }
`;
