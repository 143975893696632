import { useThemeContext } from 'app/theme/useThemeContext';
import { NoiseMeasurementType } from 'app/views/components/real-time-noise-connection/RealTimeNoiseConnection.types';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { EventsChartSeries } from './useEventsChartOptions.types';
import { getLowLatencyNoiseSeriesData, LowLatencyNoiseSeriesByDateTime } from './getLowLatencyNoiseSeriesData';

export const useLowLatencyNoiseSeries = ({
  locationId,
  utcStartDate,
  utcEndDate,
  noiseTypes,
  traceVariableIds,
}: Partial<{
  locationId: number | null;
  utcStartDate: string;
  utcEndDate: string;
  noiseTypes: NoiseMeasurementType[];
  traceVariableIds: Guid[];
}>) => {
  const hasAllParameters = typeof locationId === 'number' && !!utcStartDate && !!utcEndDate && !!noiseTypes?.length;
  const doesNotHaveTraceVariables = Array.isArray(traceVariableIds) && traceVariableIds.length === 0;
  const convertResponseToSeries = useConvertResponseToSeries();
  const { data, error, ...rest } = useQuery({
    queryKey: [QUERY_KEY.lowLatencyNoiseSeries, locationId, utcStartDate, utcEndDate, noiseTypes?.join(',')],
    queryFn: () => {
      if (!hasAllParameters) return;
      return getLowLatencyNoiseSeriesData({
        locationId,
        noiseTypes,
        utcEndDate,
        utcStartDate,
      });
    },
    enabled: hasAllParameters && doesNotHaveTraceVariables,
  });

  return useMemo(
    () => ({
      data: convertResponseToSeries(data),
      ...rest,
    }),
    [convertResponseToSeries, data, rest]
  );
};

function useConvertResponseToSeries() {
  const { colors } = useThemeContext();
  return useCallback(
    (response: LowLatencyNoiseSeriesByDateTime | undefined) => {
      if (!response) return;
      const series = Object.entries(response).reduce((accumulator, [dateTime, valuesByNoiseMeasurementType]) => {
        const byNoiseMeasurementType = Object.entries(valuesByNoiseMeasurementType) as [NoiseMeasurementType, number][];
        byNoiseMeasurementType.forEach(([noiseType, value]) => {
          if (accumulator.has(noiseType)) {
            accumulator.get(noiseType)?.data.push([DateTime.fromISO(dateTime).toMillis(), value]);
          } else {
            accumulator.set(noiseType, {
              type: 'line',
              data: [[DateTime.fromISO(dateTime).toMillis(), value]],
              color: colors.brand.brand02,
              name: noiseType,
            });
          }
        });
        return accumulator;
      }, new Map<NoiseMeasurementType, EventsChartSeries>());
      return Array.from(series.values());
    },
    [colors]
  );
}
