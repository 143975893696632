const editProfile = (root: string) => () => {
  return `${root}/details`;
};

const changePassword = (root: string) => () => {
  return `${root}/password`;
};

const languageSettings = (root: string) => () => {
  return `${root}/settings`;
};

const about = (root: string) => () => {
  return `${root}/about`;
};

export const profileRoutes = (root: string) => ({
  root,
  editProfile: editProfile(root),
  changePassword: changePassword(root),
  languageSettings: languageSettings(root),
  about: about(root),
});
