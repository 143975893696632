import Guid from 'core/types/Guid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from '../actions';

export const DATE_RANGES = ['LAST_HOUR', 'LAST_6_HOURS', 'LAST_24_HOURS', 'LAST_7_DAYS'] as const;
export type DateRange = (typeof DATE_RANGES)[number];

export const INITIAL_STATE = {
  selectedSensorIds: [] as Guid[],
  selectedSourceAreaIds: [] as Guid[],
  dateRange: 'LAST_24_HOURS' as DateRange,
  selectedProcessVariableIds: [] as Guid[],
  selectedLowLatencyVariableIds: [] as string[],
};

export const slice = createSlice({
  name: 'analyse',
  initialState: INITIAL_STATE,
  reducers: {
    toggleSourceAreaSelection: (state, { payload }: PayloadAction<Guid>) => {
      if (state.selectedSourceAreaIds.includes(payload)) {
        state.selectedSourceAreaIds = state.selectedSourceAreaIds.filter(id => id !== payload);
      } else {
        state.selectedSourceAreaIds.push(payload);
      }
      state.selectedProcessVariableIds = [];
      state.selectedLowLatencyVariableIds = [];
      state.selectedSensorIds = [];
    },

    toggleMonitorSelection: (state, { payload }: PayloadAction<Guid>) => {
      if (state.selectedSensorIds.includes(payload)) {
        state.selectedSensorIds = state.selectedSensorIds.filter(id => id !== payload);
      } else {
        state.selectedSensorIds.push(payload);
      }
      state.selectedSourceAreaIds = [];
      state.selectedProcessVariableIds = [];
      state.selectedLowLatencyVariableIds = [];
    },

    deselectAllMonitors: state => {
      state.selectedSensorIds = [];
      state.selectedProcessVariableIds = [];
      state.selectedSourceAreaIds = [];
      state.selectedLowLatencyVariableIds = [];
    },

    setDateRange: (state, { payload }: PayloadAction<DateRange>) => {
      state.dateRange = payload;
    },

    toggleProcessVariableSelection: (state, { payload }: PayloadAction<Guid>) => {
      if (state.selectedProcessVariableIds.includes(payload)) {
        state.selectedProcessVariableIds = state.selectedProcessVariableIds.filter(id => id !== payload);
      } else {
        state.selectedProcessVariableIds.push(payload);
      }
      state.selectedLowLatencyVariableIds = [];
    },

    toggleLowLatencyVariableSelection: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedLowLatencyVariableIds.includes(payload)) {
        state.selectedLowLatencyVariableIds = state.selectedLowLatencyVariableIds.filter(type => type !== payload);
      } else {
        state.selectedLowLatencyVariableIds.push(payload);
      }
      state.selectedProcessVariableIds = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
