import { useRef } from 'react';
import { DownloadAudioProps } from './DownloadAudio';
import { useAppSelector } from 'app/redux/store';
import { useLocalTime } from 'app/hooks/use-local-time';

export const useHandleAudioDownload = ({ data, noiseEventId, startTime }: DownloadAudioProps) => {
  const linkRef = useRef<HTMLAnchorElement>();
  const { companyAlias, facilityAlias } = useAppSelector(state => state.profile.facility);
  const localTime = useLocalTime(startTime);
  const formattedTime = localTime.toFormat("yyyy-MM-dd'T'HHmm");
  const fileName = `${formattedTime}_${companyAlias}_${facilityAlias}_${noiseEventId}.opus`;
  return (element?: HTMLAnchorElement) => () => {
    linkRef.current = element ?? document.createElement('a');
    linkRef.current.href = URL.createObjectURL(data);
    linkRef.current.download = fileName;
    linkRef.current.click();
    URL.revokeObjectURL(linkRef.current.href);
  };
};
