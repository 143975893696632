import { DateRange } from '@blueprintjs/datetime';
import { getLocalDate } from 'app/helpers/getLocalDate';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useI18n } from 'core/hooks/useI18n';
import { Duration } from 'luxon';
import { useCallback } from 'react';

type GetDateRange = () => DateRange;

const useToday = (): GetDateRange => {
  const localTime = useLocalTimeCallback();
  return useCallback(() => {
    const now = localTime();
    return [getLocalDate(now.startOf('day')), getLocalDate(now)];
  }, [localTime]);
};

const useYesterday = (): GetDateRange => {
  const localTime = useLocalTimeCallback();
  return useCallback(() => {
    const now = localTime();
    const yesterday = now.minus(Duration.fromObject({ day: 1 }));
    const yesterdayStart = yesterday.startOf('day');
    const yesterdayEnd = yesterday.endOf('day');
    return [getLocalDate(yesterdayStart), getLocalDate(yesterdayEnd)];
  }, [localTime]);
};

const useLast24Hours = (): GetDateRange => {
  const localTime = useLocalTimeCallback();
  return useCallback(() => {
    const now = localTime();
    const twentyFourHoursAgo = now.minus(Duration.fromObject({ hours: 24 }));
    return [getLocalDate(twentyFourHoursAgo), getLocalDate(now)];
  }, [localTime]);
};

const useLast7Days = (): GetDateRange => {
  const localTime = useLocalTimeCallback();
  return useCallback(() => {
    const now = localTime();
    const sevenDaysAgo = now.minus(Duration.fromObject({ days: 7 }));
    return [getLocalDate(sevenDaysAgo), getLocalDate(now)];
  }, [localTime]);
};

const useLast30Days = (): GetDateRange => {
  const localTime = useLocalTimeCallback();
  return useCallback(() => {
    const now = localTime();
    const thirtyDaysAgo = now.minus(Duration.fromObject({ days: 30 }));
    return [getLocalDate(thirtyDaysAgo), getLocalDate(now)];
  }, [localTime]);
};

export const useShortcuts = () => {
  const { l10n } = useI18n('app/components', 'dates.ranges');
  const today = useToday();
  const yesterday = useYesterday();
  const last24Hours = useLast24Hours();
  const last7Days = useLast7Days();
  const last30Days = useLast30Days();
  return [
    {
      id: 'TODAY' as const,
      label: l10n('today'),
      dateRange: today(),
      includeTime: true,
    },
    {
      id: 'YESTERDAY' as const,
      label: l10n('yesterday'),
      dateRange: yesterday(),
      includeTime: true,
    },
    {
      id: 'LAST_24_HOURS' as const,
      label: l10n('last24Hours'),
      dateRange: last24Hours(),
      includeTime: true,
    },
    {
      id: 'LAST_7_DAYS' as const,
      label: l10n('last7Days'),
      dateRange: last7Days(),
      includeTime: true,
    },
    {
      id: 'LAST_30_DAYS' as const,
      label: l10n('last30Days'),
      dateRange: last30Days(),
      includeTime: true,
    },
  ];
};
