import { Layout } from 'app/views/Layout';
import { HeapAnalytics } from './views/components/heap-analytics';
import { useAppSelector } from './redux/store';
import { Beamer } from 'app/views/components/beamer';

export const AppController = () => {
  const {
    facility: { companyAlias, facilityAlias },
    user: { accessControls },
  } = useAppSelector(state => state.profile);
  const hasFacilityDetails = !!companyAlias && !!facilityAlias;
  const hasAccessControls = !!Object.keys(accessControls).length;
  return (
    <>
      {hasFacilityDetails && hasAccessControls && (
        <>
          <HeapAnalytics />
          <Beamer />
          <Layout />
        </>
      )}
    </>
  );
};
