import { ILatLng } from 'app/business-logic/domain-models/ILocation';
import { INamedEntity } from 'app/business-logic/services/types';
import { ActiveJob } from 'app/redux/modelling/types/models';
import Guid from 'core/types/Guid';
import { DateTime } from 'luxon';
import { GisPoint, ITemporalDataWind } from 'app/business-logic/services/modelling-service/types';

export enum BlastModelType {
  Dispersion = 'dispersion',
  Flyrock = 'flyrock',
  FumeManagementZone = 'fumeManagementZone',
  GroundVibration = 'groundVibration',
  OverpressureContour = 'overpressureContour',
  OverpressureGain = 'overpressureGain',
  Path = 'path',
  Vibration = 'vibration',
}

export interface IBlastingScenarioRouteParams {
  alias: string;
}

export interface ILegendItem {
  color: string;
  description: string;
  frontFaceDistanceInMetres?: number;
  backFaceDistanceInMetres?: number;
}

export interface ILegend {
  description: string | null;
  items: ILegendItem[];
  legendType: string;
  title: string;
}

export enum BlastSetup {
  Burden = 'burden',
  Stemming = 'stemming',
}

export interface IBlastModel {
  category: BlastModelCategory;
  id: string;
  legends: ILegend[];
  modelType: BlastModelType;
}

export interface IBlastScenarioSummary
  extends Pick<IBlastScenario, 'name' | 'alias' | 'lastUpdatedTime' | 'actualBlastTime'> {
  firstScenarioTime: DateTime | null;
}

export interface IBlastScenarioSummaryTable {
  name: string;
  alias: string;
  lastUpdatedTime: number;
  actualBlastTime: number | null;
  firstScenarioTime: number | null;
}

export type BlastScenarioSummaryResponse = {
  blastScenarioSummaries: BlastScenarioSummary[];
};

export type BlastScenarioSummary = Omit<
  IBlastScenarioSummary,
  'lastUpdatedTime' | 'actualBlastTime' | 'firstScenarioTime'
> & {
  utcLastUpdatedTime: string;
  utcActualBlastTime: string | null;
  utcFirstScenarioTime: string | null;
};

export type BlastScenarioResponse = Omit<
  IBlastScenario,
  'utcActualBlastTime' | 'utcBlastTimes' | 'utcLastUpdatedDateTime'
> & {
  utcActualBlastTime: string | null;
  utcBlastTimes: string[];
  utcLastUpdatedDateTime: string;
};

export interface IBlastScenario extends INamedEntity {
  alias: string;
  azimuth: number | null;
  blastSetup: string;
  backFaceKFactor: number | null;
  blastModels: IBlastModel[];
  burden: number | null;
  chargeMass: number | null;
  chargeMassPerHole: number | null;
  displayUnitDescription: string | null;
  displayUnitId: string | null;
  dispersalAngle: number | null;
  elevation: number;
  forwardTrajectoryScientificModelDescription: string;
  forwardTrajectoryScientificModelId: string;
  frontFaceKFactor: number | null;
  flyrockSiteConstantKv: number | null;
  groundVibrationSiteConstantKv: number | null;
  groundVibrationSiteExponentKb: number | null;
  height: number;
  holeAngle: number | null;
  holeDiameter: number | null;
  initialConcentrationGramsPerCubicMetre: number | null;
  latitude: number;
  longitude: number;
  length: number | null;
  modelPollutantId: string | null;
  pointsOfInterest: number[];
  pollutantDescription: string | null;
  pollutantScientificModelDescription: string | null;
  pollutantScientificModelId: string | null;
  stemmingHeight: number | null;
  actualBlastTime: DateTime | null;
  blastTimes: DateTime[];
  lastUpdatedTime: DateTime;
  windScientificModelDescription: string;
  windScientificModelId: string;
  width: number | null;
}

export enum BlastModelCategory {
  Animated = 'animated',
  Snapshot = 'snapshot',
}

export enum BlastSimulationGroupingAttribute {
  Model = 'model',
  Time = 'time',
}

export enum ModellingStatus {
  /** The data has not been loaded so it's state is unknown */
  Unknown = 'unknown',
  Loading = 'loading',

  /** A modelling job is running to generate the model data */
  Modelling = 'modelling',

  /** No data was generated for the model */
  NoData = 'noData',

  /** The modelling data was loaded successfully */
  Success = 'success',

  /** The modelling data failed to load */
  Failed = 'failed',
}

export type IBlastModelSimulationData =
  | IFlyrockSimulationData
  | IGroundVibrationSimulationData
  | IOverpressureSimulationData
  | IPathSimulationData
  | IFumeManagementZoneSimulationData
  | IDispersionSimulationData;

// This 'unknown' is just a placeholder for the fact that we haven't added all the interfaces required for all the
// different blast models. Once we've got them all mapped, this 'unknown' should be removed.
// | unknown;

export type SimulationData<T> = T extends BlastModelType.Dispersion
  ? IDispersionSimulationData
  : T extends BlastModelType.Flyrock
  ? IFlyrockSimulationData
  : T extends BlastModelType.FumeManagementZone
  ? IFumeManagementZoneSimulationData
  : T extends BlastModelType.GroundVibration
  ? IGroundVibrationSimulationData
  : T extends BlastModelType.OverpressureContour
  ? IOverpressureSimulationData
  : T extends BlastModelType.OverpressureGain
  ? IOverpressureSimulationData
  : T extends BlastModelType.Path
  ? IPathSimulationData
  : never;

export interface IFlyrockSimulationData {
  activeJobBatch: ActiveJob | null;
  modelData: IFlyrockSimulationModel | null;
}

export interface IFlyrockSimulationModel {
  azimuth: number;
  flyrockBoundaries: IFlyrockBoundary[];
  location: ILatLng;
}
export interface IFumeManagementZoneSimulationData {
  activeJobBatch: ActiveJob | null;
  modelData: {
    modelType: BlastModelType;
    plumes: {
      type: string;
      data: IPlumeData[];
    };
  } | null;
}

export interface IDispersionSimulationData {
  activeJobBatch: ActiveJob | null;
  modelData: {
    modelType: BlastModelType;
    plumes: {
      type: string;
      data: IPlumeData[];
    };
    windData: ITemporalDataWind;
  } | null;
}

export interface IPlumeData {
  concentrations: IConcentrationData[];
  utcEndTime: string;
}

export interface IConcentrationData {
  value: number;
  displayColor: string;
  regions: IConcentrationRegion[];
}
export interface IConcentrationRegion {
  gisPoints: { latitude: number; longitude: number; elevation: number }[];
}

export interface IFlyrockBoundary {
  frontFaceDistanceInMetres: number;
  backFaceDistanceInMetres: number;
  color: string;
  description: string;
}

export interface IGroundVibrationSimulationData {
  activeJobBatch: ActiveJob | null;
  modelData: IGroundVibrationSimulationModel | null;
}

export interface IGroundVibrationSimulationModel {
  modelType: BlastModelType;
  ppvReadings: IPpvReadings[];
  ppvThresholds: IPpvThreshold[];
  location: ILatLng;
}

export interface IPathSimulationData {
  activeJobBatch: ActiveJob | null;
  modelData: {
    modelType: BlastModelType;
    primaryTrack: {
      gisTrackDataTimeSteps: IGisTrackDataTimeStep[];
    };
  } | null;
}

export interface IGisTrackDataTimeStep {
  latitudeStdDev: number;
  longitudeStdDev: number;
  elevationStdDev: number;
  gisPoint: GisPoint;
  utcEndTime: string;
}

export interface IOverpressureSimulationData {
  activeJobBatch: ActiveJob | null;
  modelData: IOverpressureSimulationModelData | null;
}

export interface IOverpressureSimulationModelData {
  image: string;
  latitude: number;
  longitude: number;
  modelType: BlastModelCategory;
}

export interface IPpvThreshold {
  ppvThreshold: number;
  distanceInMetres: number;
  color: string;
}

export interface IPpvReadings {
  location: ILatLng;
  value: number;
}

export interface IBlastModelSimulation {
  model: IBlastModel;
  time: DateTime;
  isActive: boolean;
  data: IBlastModelSimulationData;
  modellingStatus: ModellingStatus;
}

export interface ILabelledBlastModelSimulation extends IBlastModelSimulation {
  label: string;
}

export interface IScenarioSimulationData extends Pick<IBlastModelSimulation, 'time' | 'data'> {
  modelType: BlastModelType;
}

export type ModelTypeConfigResponse = {
  dispersion: null | string;
  flyrock: null | string;
  fumeManagementZone: null | string;
  groundVibration: null | string;
  overpressureContour: null | string;
  overpressureGain: null | string;
  path: null | string;
};

export type FlyrockModelTypeConfig = {
  Kv: number | null;
  DispersalAngle: number | null;
};

export type GroundVibrationModelTypeConfig = {
  Kv: number | null;
  Kb: number | null;
};

export type ModelTypeConfig = {
  flyrock: FlyrockModelTypeConfig;
  groundVibration: GroundVibrationModelTypeConfig;
};

export type ModelTypeConfigKey = keyof ModelTypeConfigResponse;

export type OverlayFileInfo = {
  length: number;
  name: string;
  type: string;
  utcLastModifiedTime: string;
};

export type CreateScenarioResponse = {
  activeJobBatchForScenario: ActiveJob;
  scenarioId: Guid;
  scenarioAlias: string;
};

export type ISimulation = {
  simulationKey: string;
  modelLabel: string;
  blastModel: IBlastModel;
  simulationTime: DateTime;
};
