import { useAppSelector } from 'app/redux/store';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { i18nInstance } from 'i18n';
import { getLocalTime } from './getLocalTime';

export function useLocalTime(time?: number | string | DateTime) {
  const { facility } = useAppSelector(state => state.profile);
  const { ianaTimezone } = facility;
  return useMemo(() => {
    return getLocalTime(time, ianaTimezone, i18nInstance.language);
  }, [ianaTimezone, time]);
}
