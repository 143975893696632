import { styled, keyframes } from 'styled-components';

const bounce = keyframes`
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-100%);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(0.9, 1.1) translateY(0);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
`;

export const StyledThrobber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #202123; // Not using theme so component can be used outside of theme context, e.g. Suspense
  box-shadow:
    0 0 15px rgba(0, 0, 0, 0.2),
    0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: progress;
`;

export const ThrobberInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ThrobberLeft = styled.div`
  padding: 10px;
  svg {
    width: 20px;
    height: 20px;
    animation-name: ${bounce};
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
  }
`;

export const ThrobberRight = styled.div`
  white-space: nowrap;
  padding: 10px;
  padding-left: 0;
  font-size: 1.4rem;
  color: white; // Not using theme so component can be used outside of theme context, e.g. Suspense
`;
