export const HTTP_ERROR =
  'An error has occurred on the server. Please refresh your browser and try again. Contact cms@envirosuite.com for assistance if the problem persists.';

type FetchResponse<T> = { data: T; messages: { code: string; content: string }[] } | null;

export default class HttpResponse<T = any> {
  public data: T | null;
  public messages?: string[];

  constructor(public status: number, private fetchResponse: FetchResponse<T>, public error: unknown = null) {
    this.status = status;
    this.error = error;

    if (!fetchResponse || typeof fetchResponse !== 'object' || !('data' in fetchResponse)) {
      this.data = fetchResponse;
    } else {
      this.data = fetchResponse.data;
    }

    this.messages = this.isError
      ? [HTTP_ERROR]
      : fetchResponse?.messages?.map(message => message.content ?? message) || [];
  }

  public get isSuccess() {
    return this.status >= 200 && this.status < 300;
  }

  public get isError() {
    return this.status >= 500 || !!this.error;
  }

  public get isUnauthorized() {
    return this.status === 401;
  }

  public get isForbidden() {
    return this.status === 403;
  }
}
