import { useAppSelector } from 'app/redux/store';
import { AlertsDrawerContent } from 'app/views/alerts/components/alerts-drawer-content';
import { useAlertsDrawerData } from 'app/views/alerts/components/alerts-drawer-content/useAlertsDrawerData';
import { ScenarioDataDrawerContent } from 'app/views/blasting/components/blasting-map/scenario-data-drawer-content/ScenarioDataDrawerContent';
import { Sidebar } from './RightDrawer.styles';

export const RightDrawer = () => {
  const { openingDrawers } = useAppSelector(state => state.ui);
  const { data, lastUpdated } = useAlertsDrawerData();
  return (
    <Sidebar $isOpen={openingDrawers.length > 0}>
      {openingDrawers.includes('ALERTS') && <AlertsDrawerContent alerts={data} lastUpdated={lastUpdated} />}
      {openingDrawers.includes('SCENARIO_DATA') && <ScenarioDataDrawerContent />}
    </Sidebar>
  );
};
