import { useThemeContext } from 'app/theme/useThemeContext';
import { useMemo } from 'react';

export const useDefaultYAxis = () => {
  const theme = useThemeContext();
  return useMemo(() => {
    return {
      labels: {
        style: {
          color: theme.colors.mono.ui06,
          fontSize: '1.4rem',
        },
        enabled: true,
      },
      tickColor: theme.colors.mono.ui06,
      title: {
        text: '',
        style: {
          color: theme.colors.mono.ui06,
          fontSize: '1.4rem',
        },
      },
      endOnTick: true,
      gridLineColor: theme.colors.mono.ui04,
      opposite: undefined,
    };
  }, [theme]);
};
