import { DataAttributeProps } from 'app/types';
import { HTMLAttributes, MouseEvent, PropsWithChildren, ReactNode } from 'react';
import { useMeasure } from 'react-use';
import { DrawerActions } from './drawer-actions';
import {
  Drawer,
  FloatingContainer,
  Handle,
  HandleActionsWrapper,
  HandleContentWrapper,
  InnerContainer,
} from './DrawerWithHandle.styles';
import { useOffset } from './useOffset';

export type Visibility = 'hidden' | 'partial' | 'visible';

type DrawerWithHandleProps = PropsWithChildren<{
  drawerVisibility: Visibility;
  handleContent: ReactNode;
  componentProps?: {
    floatingContainer?: HTMLAttributes<HTMLDivElement>;
    innerContainer?: HTMLAttributes<HTMLDivElement>;
    handleContentWrapper?: HTMLAttributes<HTMLDivElement> & DataAttributeProps;
    handleActionsWrapper?: HTMLAttributes<HTMLDivElement> & DataAttributeProps;
    drawerActionsProps?: {
      expandCollapse?: HTMLAttributes<HTMLButtonElement> & DataAttributeProps;
      close?: HTMLAttributes<HTMLButtonElement> & DataAttributeProps;
    };
    handle?: HTMLAttributes<HTMLDivElement>;
    drawer?: HTMLAttributes<HTMLDivElement>;
  };
  onExpandCollapse?: (event: MouseEvent<HTMLButtonElement>) => void;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
}>;

export const DrawerWithHandle = ({
  children,
  drawerVisibility,
  handleContent,
  componentProps = {},
  onExpandCollapse,
  onClose,
  ...rest
}: DrawerWithHandleProps) => {
  const [handleRef, { height: handleHeight }] = useMeasure();
  const offset = useOffset({ handleHeight, drawerVisibility });
  const {
    floatingContainer = {},
    innerContainer = {},
    handleContentWrapper = {},
    handleActionsWrapper = {},
    handle = {},
    drawer = {},
    drawerActionsProps = {},
  } = componentProps;
  return (
    <FloatingContainer data-testid="drawer-with-handle-floating-container" {...rest} {...floatingContainer}>
      <InnerContainer data-testid="drawer-with-handle-inner-container" $offset={offset} {...innerContainer}>
        <Handle data-testid="drawer-with-handle-handle" ref={handleRef} {...handle}>
          <HandleContentWrapper data-testid="drawer-with-handle-handle-content-wrapper" {...handleContentWrapper}>
            {handleContent}
          </HandleContentWrapper>
          <HandleActionsWrapper data-testid="drawer-with-handle-handle-actions-wrapper" {...handleActionsWrapper}>
            <DrawerActions
              drawerVisibility={drawerVisibility}
              onExpandCollapse={onExpandCollapse}
              onClose={onClose}
              drawerActionsProps={drawerActionsProps}
            />
          </HandleActionsWrapper>
        </Handle>
        <Drawer data-testid="drawer-with-handle-drawer" {...drawer}>
          {children}
        </Drawer>
      </InnerContainer>
    </FloatingContainer>
  );
};
