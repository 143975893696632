import { useEventHistoryData } from 'app/views/alerts/pages/event-history/use-event-history-data';
import HttpResponse from 'core/net/HttpResponse';
import { httpClient } from 'core/net/http';

type EventHistoryNoiseEvent = Pick<
  NonNullable<ReturnType<typeof useEventHistoryData>['data']>[number],
  'noiseEventId' | 'locationId' | 'leq' | 'lfMax' | 'sel' | 'windSpeed' | 'startTime' | 'endTime' | 'weighting'
>;

type DetailedNoiseEvent = {
  noiseEventId: number;
  locationId: number;
  deviceId: number;
  lAeq?: number | null;
  lAeqMax?: number | null;
  lAfMax?: number | null;
  sel?: number | null;
  windSpeed?: number | null;
  startTime: string;
  endTime: string;
  weighting: string;
};

type Options = {
  noiseEventIds?: number[];
  deviceIds?: number[];
  locationIds?: number[];
  from?: string;
  to?: string;
  page?: number;
  pageSize?: number;
};

type RequiredNoiseEventIds = Required<Pick<Options, 'noiseEventIds'>>;
type RequiredFromTo = Required<Pick<Options, 'from' | 'to'>>;

type DetailedNoiseEventsResponse = {
  noiseEvents: DetailedNoiseEvent[];
  page: number;
};

export async function getDetailedNoiseEvents(
  options: RequiredNoiseEventIds & Options
): Promise<EventHistoryNoiseEvent[]>;

export async function getDetailedNoiseEvents(options: RequiredFromTo & Options): Promise<EventHistoryNoiseEvent[]>;

export async function getDetailedNoiseEvents(options: Options): Promise<EventHistoryNoiseEvent[]> {
  const { noiseEventIds, from, to } = options;
  if ((!noiseEventIds || noiseEventIds.length === 0) && (!from || !to)) {
    throw new Error('Either "noiseEventIds" must be provided, or both "from" and "to" must be provided.');
  }
  const { data, isSuccess }: HttpResponse<DetailedNoiseEventsResponse> = await httpClient.useArenaInternalApi
    .asAuthorised()
    .get('noise-events-detailed', {
      ...options,
    });
  if (!isSuccess || !data) {
    throw new Error(
      `Something went wrong when trying to get detailed noise events using the following options:\n${JSON.stringify(
        options,
        null,
        2
      )}`
    );
  }
  return data.noiseEvents.map(({ lAeq, lAfMax, lAeqMax, sel, windSpeed, ...rest }) => ({
    laeq: lAeq,
    lafMax: lAfMax,
    laeqMax: lAeqMax,
    sel: sel,
    windspeed: windSpeed,
    ...rest,
  }));
}
