import { FacilityRegion, FacilityType, MapProvider } from 'app/__generated__/global';
import { IFacility } from 'app/business-logic/domain-models/Facility';
import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';

interface Facility {
  alias: string;
  coordinateOffsetX: number;
  coordinateOffsetY: number;
  databaseId: string;
  defaultZoomLevel: null | number;
  description: string;
  es1RouteName: string;
  facilityCode: string;
  ianaTimeZoneId: string;
  id: string;
  isOnline: boolean;
  isStandardModelArchivingPeriod: boolean;
  latitude: null | number;
  locale: string;
  longitude: null | number;
  name: string;
  passwordResetFrequencyDays: null | number;
  region: string | null;
  smaxCustomerCode: null | string;
  smaxStaleDataTimeoutSeconds: number | null;
  terrockClientId: null | string;
  ticketingSystemTenantId: string;
  ticketingSystemType: null | string;
  useImperialUnits: boolean;
  windowsTimeZoneId: string;
  satelliteMapProvider: MapProvider;
  roadMapProvider: MapProvider;
  type: FacilityType | null;
  isActive: boolean;
}

interface ICompany {
  alias: string;
  description: string | null;
  facilities: Facility[];
  id: string;
  name: string;
}

export async function fetchAccessibleFacilities(): Promise<IFacility[]> {
  const { data }: HttpResponse<ICompany[]> = await httpClient.useInternalApi.asAuthorised().get('companies');
  return (data ?? []).flatMap(company =>
    company.facilities.map(facility => {
      return {
        companyAlias: company.alias,
        companyId: company.id,
        domainBoundaries: [],
        facilityAlias: facility.alias,
        facilityCode: facility.facilityCode,
        ianaTimezone: facility.ianaTimeZoneId,
        id: facility.id,
        name: facility.name,
        ticketingSystemTenantId: facility.ticketingSystemTenantId,
        latitude: facility.latitude,
        longitude: facility.longitude,
        defaultZoomLevel: facility.defaultZoomLevel,
        windowsTimezoneId: facility.windowsTimeZoneId,
        region: facility.region as FacilityRegion | null,
        satelliteMapProvider: facility.satelliteMapProvider,
        roadMapProvider: facility.roadMapProvider,
        es1RouteName: facility.es1RouteName,
        smaxCustomerCode: facility.smaxCustomerCode,
        type: facility.type,
        useImperialUnits: facility.useImperialUnits,
        isActive: facility.isActive,
      };
    })
  );
}
