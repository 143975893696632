import { forwardRef, useImperativeHandle, useMemo, useRef, useState, MouseEvent } from 'react';
import { useBottomItems, useTopItems } from '../navigation-bar/NavigationBar';
import { NavItem } from '../navigation-bar/NavItem';
import {
  MenuIcon,
  MobileNav,
  MobileNavHeader,
  MobileNavMenuButton,
  MobileNavWrapper,
  MobileNavHeaderLeft,
  MobileNavHeaderLeftContent,
  MobileNavHeaderRight,
  MobileNavHeaderRightContent,
} from './MobileNavigationBar.styles';
import { useI18n } from 'core/hooks/useI18n';
import { MobileNavRef } from 'app/views/useMobileNavPortal';
import { ChangeFacilityButton } from '../change-facility-button';

export const MobileNavigationBar = forwardRef<MobileNavRef>((_, ref) => {
  const { l10n } = useI18n('app', 'navigation');
  const [isOpen, setIsOpen] = useState(false);
  const topItems = useTopItems();
  const bottomItems = useBottomItems();
  const allItems = useMemo(
    () =>
      [...topItems, ...bottomItems].map(item => ({
        ...item,
        elementProps: {
          ...item.elementProps,
          onClick: (e: MouseEvent<HTMLAnchorElement>) => {
            if (item.elementProps && 'onClick' in item.elementProps) {
              item.elementProps.onClick?.(e);
            }
            setIsOpen(false);
          },
        },
      })),
    [bottomItems, topItems]
  );
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    leftContent: leftRef.current,
    rightContent: rightRef.current,
  }));

  return (
    <MobileNavWrapper>
      <MobileNavHeader>
        <MobileNavHeaderLeft>
          <MobileNavMenuButton onClick={() => setIsOpen(current => !current)} title={l10n('title')}>
            <MenuIcon />
          </MobileNavMenuButton>
          <MobileNavHeaderLeftContent ref={leftRef} />
        </MobileNavHeaderLeft>
        <MobileNavHeaderRight>
          <MobileNavHeaderRightContent ref={rightRef}>
            <ChangeFacilityButton />
          </MobileNavHeaderRightContent>
        </MobileNavHeaderRight>
      </MobileNavHeader>
      <MobileNav $isOpen={isOpen}>
        {allItems.map(item => (
          <NavItem {...item} key={item.title} />
        ))}
      </MobileNav>
    </MobileNavWrapper>
  );
});
MobileNavigationBar.displayName = 'MobileNavigationBar';
