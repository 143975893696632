import { fetchAlertHistory } from 'app/views/alerts/pages/alert-history/use-alert-history/fetchAlertHistory';
import { useAlertResponseCategories } from 'app/views/configuration/pages/alert/alert-responses/alert-response-form/useAlertResponseCategories';

type AlertHistory = Awaited<ReturnType<typeof fetchAlertHistory>>;
type Alert = AlertHistory['results'][number];
type InputSnapshot = Alert['inputSnapshot'][number];
type TriggerSnapshot = Alert['triggerSnaphot'][number];
type AlertResponseCategories = ReturnType<typeof useAlertResponseCategories>['data'];

export function formatAlertHistory({
  alertHistory,
  alertResponseCategories,
}: {
  alertHistory: AlertHistory;
  alertResponseCategories: AlertResponseCategories;
}) {
  return {
    ...alertHistory,
    results: alertHistory.results.map(result => {
      const {
        alertScriptConfigurationId,
        inputSnapshot,
        triggerSnaphot,
        alertScriptConfigurationName,
        id,
        utcTriggeredTime,
        appliedClassifications,
        alertTypeId,
        utcResponseTime,
        responder,
      } = result;
      const variable = getVariable({ inputSnapshot, triggerSnaphot });
      const highestValue = Math.max(...(variable?.trigger.values ?? []));
      const value = !isNaN(highestValue) && isFinite(highestValue) ? highestValue : undefined;
      const { threshold, displayUnitId, displayUnitName, processId, processName } = variable?.input ?? {};
      const alertResponseCategory = alertResponseCategories?.find(c => c.id === alertTypeId);
      return {
        id,
        alertConfig: {
          id: alertScriptConfigurationId,
        },
        threshold: {
          color: threshold?.color,
          value: threshold?.value,
        },
        name: alertScriptConfigurationName,
        type: {
          id: alertTypeId,
          name: alertResponseCategory?.name,
          slug: alertResponseCategory?.slug,
        },
        monitor: {
          id: processId,
          name: processName,
        },
        value,
        displayUnit: {
          id: displayUnitId,
          name: displayUnitName,
        },
        time: utcTriggeredTime,
        appliedClassifications: appliedClassifications.map(({ id, name }) => ({
          id,
          name,
        })),
        responseTime: utcResponseTime,
        responder,
      };
    }),
  };
}

export function getVariable({
  inputSnapshot,
  triggerSnaphot,
}: {
  inputSnapshot: InputSnapshot[];
  triggerSnaphot: TriggerSnapshot[];
}) {
  const variables = inputSnapshot.flatMap(input => {
    if (!input.isTrace) return [];
    const trigger = triggerSnaphot.find(({ processVariableId }) => input.processVariableId === processVariableId);
    if (!trigger) return [];
    return {
      input,
      trigger,
    };
  });
  const sorted = variables.sort((a, b) => {
    const aLargestValue = Math.max(...a.trigger.values);
    const bLargestValue = Math.max(...b.trigger.values);
    // Sort by process variable value if we don't have a threshold rank to sort by
    if (!a.input.threshold || !b.input.threshold) {
      return bLargestValue - aLargestValue;
    }
    const byThresholdRank = b.input.threshold.rank - a.input.threshold.rank;
    // Sort by threshold rank if both ranks being compared are not equal
    if (byThresholdRank !== 0) {
      return byThresholdRank;
    }
    // Sort by process variable value if both threshold ranks are equal
    return bLargestValue - aLargestValue;
  });
  return sorted.at(0);
}
