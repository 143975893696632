import { styled } from 'styled-components';

export const EventsDrawerHeading = styled.h2`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  color: ${({ theme }) => theme.colors.mono.text01};
  margin: 0;
  line-height: 1;
  padding: ${({ theme }) => theme.spacing()}px;
`;
