import { PersistConfig, persistReducer, PersistState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { slice, INITIAL_STATE, MapState } from './slice';

// ****************************************************************************************

const currentVersion = 5; // increment this number every time the state contract changes

// ****************************************************************************************

const persistenceConfig: PersistConfig<MapState> = {
  key: 'map',
  storage,
  whitelist: ['mapTimeSource', 'baseMapType', 'greyscaleMap', 'layers', 'mapTimeMillis'],
  version: currentVersion,
  migrate: (state, version) => {
    if (!state || (state._persist && state._persist.version < version)) {
      return Promise.resolve(INITIAL_STATE as MapState & { _persist: PersistState });
    }
    return Promise.resolve(state);
  },
};

export const persistedReducer = persistReducer<MapState, any>(persistenceConfig, slice.reducer);
