const createNew = (root: string) => () => {
  return `${root}/create`;
};

const viewScenario = (root: string) => () => {
  return `${root}/view/:alias`;
};

export const blastingRoutes = (root: string) => ({
  root,
  createNew: createNew(root),
  viewScenario: viewScenario(root),
});
