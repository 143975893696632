import { styled, css } from 'styled-components';

import { Classes, InputGroup, InputGroupProps2 } from '@blueprintjs/core';

export type InputProps = {
  isError?: boolean;
} & InputGroupProps2;

export const INPUT_SIZE = 36;

export const inputStyles = css`
  font-size: 1.4rem;
  background: none;
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  border: 1px solid ${({ theme }) => theme.colors.mono.ui04};
  border-radius: 3px;
  padding: ${({ theme: { spacing } }) => `${spacing()}px ${spacing(2)}px`};
  color: ${({ theme }) => theme.colors.mono.text01};
  height: ${INPUT_SIZE}px;
  line-height: normal;
  box-shadow: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.mono.text02};
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.mono.ui05};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.mono.ui02};
    border-color: ${({ theme }) => theme.colors.mono.ui03};
    &::placeholder {
      color: ${({ theme }) => theme.colors.mono.ui05};
    }
  }
`;

const errorStyles = css`
  border: 1px solid ${({ theme }) => theme.colors.feedback.error};
  &:hover,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.feedback.error};
  }
`;

export const StyledInputGroup = styled(InputGroup)<{ $isError?: boolean }>`
  && {
    input {
      ${inputStyles}
      ${({ $isError }) => $isError && errorStyles}
    }
    .${Classes.INPUT_ACTION} {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${INPUT_SIZE}px;
      height: ${INPUT_SIZE}px;
      color: ${({ theme }) => theme.colors.mono.text02};

      svg {
        font-size: ${INPUT_SIZE / 2.5}px;
      }
    }
  }
`;

export const Input = ({ isError, ...rest }: InputProps) => {
  return <StyledInputGroup {...rest} $isError={isError} />;
};
