import { useAppSelector } from 'app/redux/store';
import RightArrow from 'app/visuals/images/icons/right-arrow.svg?react';
import { useI18n } from 'core/hooks/useI18n';
import { DateTime } from 'luxon';
import { styled } from 'styled-components';

interface IProps {
  dateRange: [number, number] | undefined;
}

const RightArrowIcon = styled(RightArrow)`
  width: 12px;
  margin: 0 ${({ theme: { spacing } }) => spacing()}px;
  position: relative;
  top: 1px;
`;

const Text = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const DateRangeText = ({ dateRange }: IProps) => {
  const { l10n } = useI18n('app', 'action');
  const { ianaTimezone } = useAppSelector(state => state.profile.facility);
  if (!dateRange) return <>{l10n('select')}</>;
  const [start, end] = dateRange.map(value => DateTime.fromMillis(value, { zone: ianaTimezone })) as [
    DateTime,
    DateTime,
  ];
  return (
    <Text>
      {start.toLocaleString(DateTime.DATE_MED)} &bull; {start.toFormat('HH:mm')}
      <RightArrowIcon />
      {end.toLocaleString(DateTime.DATE_MED)} &bull; {end.toFormat('HH:mm')}
    </Text>
  );
};
