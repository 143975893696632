import { ReactNode, useCallback, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useOutletContext } from 'react-router-dom';

export type MobileNavRef = {
  leftContent: HTMLDivElement | null;
  rightContent: HTMLDivElement | null;
};

export const useMobileNavRef = () => {
  return {
    mobileNav: useRef<MobileNavRef>({
      leftContent: null,
      rightContent: null,
    }),
    mobileSubnav: useRef<MobileNavRef>({
      leftContent: null,
      rightContent: null,
    }),
  };
};

export const useMobileNavPortal = () => {
  const context = useOutletContext<{ mobileNav?: MobileNavRef; mobileSubnav?: MobileNavRef } | undefined>();
  if (!context) throw new Error(`No context has been provided for the current <Outlet />`);
  const { mobileNav, mobileSubnav } = context;
  if (!mobileNav) throw new Error(`No "mobileNav" property exists in the context for the current <Outlet />`);
  if (!mobileSubnav) throw new Error(`No "mobileSubnav" property exists in the context for the current <Outlet />`);
  return {
    mobileNav: {
      renderOnLeft: useCallback(
        (content: ReactNode) => {
          if (!mobileNav.leftContent) return null;
          return createPortal(content, mobileNav.leftContent);
        },
        [mobileNav.leftContent]
      ),
      renderOnRight: useCallback(
        (content: ReactNode) => {
          if (!mobileNav.rightContent) return null;
          return createPortal(content, mobileNav.rightContent);
        },
        [mobileNav.rightContent]
      ),
    },
    mobileSubnav: {
      renderOnLeft: useCallback(
        (content: ReactNode) => {
          if (!mobileSubnav.leftContent) return null;
          return createPortal(content, mobileSubnav.leftContent);
        },
        [mobileSubnav.leftContent]
      ),
      renderOnRight: useCallback(
        (content: ReactNode) => {
          if (!mobileSubnav.rightContent) return null;
          return createPortal(content, mobileSubnav.rightContent);
        },
        [mobileSubnav.rightContent]
      ),
    },
  };
};
