import Guid from 'core/types/Guid';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';

import { fetchAlertById } from './fetchAlertById';

export const useAlertById = (alertId?: Guid) => {
  return useQuery({
    queryKey: [QUERY_KEY.alertById, alertId],
    queryFn: fetchAlert(alertId),
    enabled: !!alertId,
    staleTime: Infinity,
    retry: false,
  });
};

function fetchAlert(alertId?: Guid) {
  if (!alertId)
    return () => {
      throw new Error('No alert ID');
    };
  return () => fetchAlertById(alertId);
}
