import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { deselectAlert, openDrawer } from 'app/redux/ui/actions';
import { useCallback, useEffect } from 'react';

import { EventsChart } from '../events-chart';
import { useSelectAlertEvent } from '../events-chart/use-select-alert-event';
import { EventsListing } from '../events-listing';
import { EventsDrawerTitle } from './events-drawer-title';
import {
  AlertResponseWrapper,
  EventsChartWrapper,
  EventsDrawerHeader,
  EventsListingWrapper,
  StyledEventsDrawer,
} from './EventsDrawer.styles';
import { useEventsByAlertId } from '../events-listing/useEventsByAlertId';
import { AlertResponse } from 'app/views/alerts/pages/alert-response';
import { useHasAccess } from 'app/hooks/use-has-access';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { useIsMobile } from 'app/hooks/useIsMobile';

export const EventsDrawer = () => {
  return <Drawer />;
};

const Drawer = () => {
  const dispatch = useAppDispatch();
  const { selectedAlert } = useAppSelector(state => state.ui);
  const eventsDrawerIsOpen = !!selectedAlert;
  const isMobile = useIsMobile();
  const handleClose = useCallback(() => {
    dispatch(deselectAlert());
    if (isMobile) {
      dispatch(openDrawer('ALERTS'));
    }
  }, [dispatch, isMobile]);
  const { eventElementsMap, selectAlertEvent } = useSelectAlertEvent();
  const { data, isFetching, isLoading } = useEventsByAlertId(selectedAlert);
  const hasAlertResponseReadAccess = useHasAccess()(KnownAccessControls.app.alerts.response.data.read._);

  useDeselectAlertOnUnmount();

  return (
    <StyledEventsDrawer
      drawerVisibility={eventsDrawerIsOpen ? 'visible' : 'hidden'}
      handleContent={<EventsDrawerTitle />}
      onClose={handleClose}
    >
      <EventsDrawerHeader selectedAlertId={selectedAlert} />
      <EventsChartWrapper>
        <EventsChart selectedAlertId={selectedAlert} selectAlertEvent={selectAlertEvent} />
      </EventsChartWrapper>
      {data && (
        <EventsListingWrapper>
          <EventsListing
            data={data}
            isFetching={isFetching}
            eventElementsMap={eventElementsMap}
            selectedAlertId={selectedAlert}
            isLoading={isLoading}
          />
        </EventsListingWrapper>
      )}
      {hasAlertResponseReadAccess && (
        <AlertResponseWrapper>
          <AlertResponse alertId={selectedAlert} />
        </AlertResponseWrapper>
      )}
    </StyledEventsDrawer>
  );
};

function useDeselectAlertOnUnmount() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(deselectAlert());
    };
  }, [dispatch]);
}
