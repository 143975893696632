import { useI18n } from 'core/hooks/useI18n';
import type { Options } from 'highcharts/highstock';
import { HighchartsReactRefObject as ChartRef, HighchartsReact } from 'highcharts-react-official';
import HighchartsStock from 'highcharts/highstock';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import offline from 'highcharts/modules/offline-exporting';
import { useCallback, useEffect, useMemo, useRef, HTMLAttributes } from 'react';

import { ChartWrapper, StyledChart } from './Chart.styles';
import { Legend, LegendItem } from './legend/Legend';

type ChartProps = {
  options: Options;
  isLoading?: boolean;
  legendItems?: LegendItem[];
  containerProps?: HTMLAttributes<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

addNoDataModule(HighchartsStock);
offline(HighchartsStock);

// override hasData method as it does not handle windbarb series correctly
HighchartsStock.Chart.prototype.hasData = function () {
  return this.series.some(series => {
    return series.hasData() || !!series.points.length;
  });
};

export const Chart = ({ options, isLoading, legendItems = [], containerProps, ...rest }: ChartProps) => {
  const ref = useRef<ChartRef>(null);
  const translations = useTranslations();
  const chartOptions: Options = useMemo(
    () => ({
      ...options,
      lang: {
        ...translations,
        ...options.lang,
      },
    }),
    [options, translations]
  );

  useForceChartReflow({ chartRef: ref.current, legendItems: legendItems });
  useLoadingState({ chartRef: ref.current, isLoading });

  return (
    <ChartWrapper>
      {legendItems.length > 0 && <Legend legendItems={legendItems} />}
      <StyledChart {...rest}>
        <HighchartsReact
          highcharts={HighchartsStock}
          ref={ref}
          options={chartOptions}
          containerProps={containerProps}
        />
      </StyledChart>
    </ChartWrapper>
  );
};

// This hook is needed so the chart takes up the full with of the draw when the legend is not displayed
const useForceChartReflow = ({ chartRef, legendItems }: { chartRef: ChartRef | null; legendItems: LegendItem[] }) => {
  const handleReflow = useCallback(() => {
    if (!chartRef) return;
    chartRef.chart?.reflow();
  }, [chartRef]);

  useEffect(() => {
    setTimeout(() => {
      handleReflow();
    }, 500);
  }, [handleReflow, legendItems]);
};

function useLoadingState({ chartRef, isLoading }: { chartRef: ChartRef | null; isLoading?: boolean }) {
  useEffect(() => {
    if (!chartRef) return;
    if (isLoading) {
      chartRef.chart?.showLoading();
    } else {
      chartRef.chart?.hideLoading();
    }
  }, [isLoading, chartRef]);
}

function useTranslations() {
  const { l10n } = useI18n('app/components', 'highcharts');
  const noData = l10n('noData').split(' ');
  // Add line break to prevent text overflow on smaller charts
  noData.splice(Math.floor(noData.length / 2), 0, '<br />');

  return useMemo(
    () => ({
      loading: l10n('loading'),
      noData: noData.join(' '),
      resetZoom: l10n('resetZoom'),
      resetZoomTitle: l10n('resetZoomTitle'),
    }),
    [l10n, noData]
  );
}
