import { LabelHTMLAttributes } from 'react';
import { styled, css } from 'styled-components';

type IProps = LabelHTMLAttributes<HTMLLabelElement>;

export const labelStyles = css`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.text01};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  display: block;
`;

const StyledLabel = styled.label`
  ${() => labelStyles};
`;

export const Label = ({ children, ...rest }: IProps) => {
  return <StyledLabel {...rest}>{children}</StyledLabel>;
};
