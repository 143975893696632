import { Classes } from '@blueprintjs/core';
import { styled } from 'styled-components';

export const StyledTabsContainer = styled.div`
  .${Classes.TAB_LIST} {
    justify-content: space-evenly;
    background-color: ${({ theme }) => theme.colors.mono.ui03};

    .${Classes.TAB_INDICATOR} {
      background-color: ${({ theme }) => theme.colors.brand.brand02};
      height: 100%;
    }

    .${Classes.TAB} {
      width: 50%;
      margin-right: 0;
      justify-content: center;
      text-align: center;
    }

    .${Classes.TAB}[aria-selected=true] {
      color: ${({ theme }) => theme.colors.mono.ui01};
    }

    .${Classes.TAB}[aria-selected=false] {
      color: ${({ theme }) => theme.colors.mono.text03};
    }
  }
`;
