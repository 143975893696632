import { gql } from '@apollo/client';

const WEATHER_SENSOR = gql`
  fragment WeatherSensor on ProcessVariable {
    id
    name
    displayUnit {
      id
      name
    }
  }
`;

export const FETCH_WEATHER_STATIONS = gql`
  ${WEATHER_SENSOR}
  query FetchWeatherStations($filter: SurfaceWeatherStationFilter) {
    facility {
      id
      monitorConfiguration {
        surfaceWeatherStations(filter: $filter) {
          monitor {
            id
            name
          }
          isDefault
          humiditySensor {
            ...WeatherSensor
          }
          precipitationSensor {
            ...WeatherSensor
          }
          temperatureSensor {
            ...WeatherSensor
          }
          windDirectionSensor {
            ...WeatherSensor
          }
          windSpeedSensor {
            ...WeatherSensor
          }
        }
      }
    }
  }
`;
