import { Button, Classes } from '@blueprintjs/core';
import { Classes as DatePickerClasses, DatePicker } from '@blueprintjs/datetime';
import { styled, css } from 'styled-components';

export const DatePickerWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.mono.ui03};
`;

export const StyledDatePicker = styled(DatePicker)`
  &&&&&& {
    background: ${({ theme: { colors } }) => colors.mono.ui03};
    color: ${({ theme: { colors } }) => colors.mono.text01};
    font-size: 1.4rem;
    font-weight: ${({ theme: { font } }) => font.default.weight.normal};

    input,
    select,
    ${DatePickerClasses.TIMEPICKER_AMPM_SELECT} select {
      font-family: ${({ theme: { font } }) => font.default.family};
      font-weight: ${({ theme: { font } }) => font.default.weight.light};
      border: 2px solid transparent;
      font-size: 1.4rem;
      &:hover {
        background: transparent;
        color: ${({ theme: { colors } }) => colors.mono.text01};
        border-color: ${({ theme: { colors } }) => colors.brand.brand02};
      }
      &:active {
        background-color: ${({ theme: { colors } }) => colors.mono.ui05};
      }
      &:focus {
        border-color: ${({ theme: { colors } }) => colors.brand.brand02};
        box-shadow: none;
      }
    }

    .DayPicker-Day,
    .${Classes.BUTTON}.${Classes.MINIMAL} {
      &:hover {
        background-color: ${({ theme: { colors } }) => colors.mono.ui04};
      }
      &:active {
        background-color: ${({ theme: { colors } }) => colors.mono.ui05};
      }
      &:focus {
        background-color: ${({ theme: { colors } }) => colors.mono.ui03};
        box-shadow: 0 0 0 2px ${({ theme: { colors } }) => colors.brand.brand02} inset;
      }
    }

    .DayPicker-Weekday {
      font-weight: ${({ theme: { font } }) => font.default.weight.normal};
    }

    .DayPicker-Day--selected {
      background-color: ${({ theme: { colors } }) => colors.brand.brand01};
      &:focus {
        background-color: ${({ theme: { colors } }) => colors.brand.brand01};
      }
    }

    .DayPicker-NavButton {
      .${Classes.ICON} {
        color: ${({ theme: { colors } }) => colors.mono.text01};
      }
      &.${Classes.DISABLED} {
        .${Classes.ICON} {
          color: ${({ theme: { colors } }) => colors.mono.text02};
        }
      }
    }

    .${Classes.DIVIDER} {
      border-color: ${({ theme: { colors } }) => colors.mono.ui05};
    }

    .${DatePickerClasses.TIMEPICKER_AMPM_SELECT} {
      select {
        &:hover {
          background-color: ${({ theme: { colors } }) => colors.mono.ui04};
        }
      }
    }
  }
`;

export const ButtonBar = styled.div`
  padding: ${({ theme: { spacing } }) => spacing()}px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
`;

const buttonStyles = css`
  font-weight: ${({ theme: { font } }) => font.default.weight.normal};
  border: 2px solid transparent;
  border-radius: 3px;
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  transition:
    background-color 0.15s ease,
    color 0.15s ease;
  padding: ${({ theme }) => theme.spacing()}px;
  &:hover {
    box-shadow: none;
  }
`;

export const Confirm = styled(Button)`
  &&& {
    ${() => buttonStyles};
    color: ${({ theme: { colors } }) => colors.brand.brand02};
    border-color: ${({ theme: { colors } }) => colors.brand.brand02};
    &:hover {
      color: ${({ theme: { colors } }) => colors.mono.text01};
      background-color: ${({ theme: { colors } }) => colors.brand.brand02};
    }
  }
`;

export const Cancel = styled(Button)`
  &&& {
    ${() => buttonStyles};
    color: ${({ theme: { colors } }) => colors.mono.text02};
    margin-left: ${({ theme: { spacing } }) => spacing()}px;
    &:hover {
      color: ${({ theme: { colors } }) => colors.mono.text01};
      background-color: transparent;
    }
  }
`;
