import { css } from 'styled-components';

export const reactSelect = css`
  // Need a wrapping div to increase specificity
  div {
    .react-select__control {
      background-color: ${({ theme }) => theme.colors.mono.ui03};
      border: 1px solid ${({ theme }) => theme.colors.mono.ui04};
      font-size: 1.4rem;
      padding-right: ${({ theme }) => theme.spacing(0.5)}px;

      &--is-focused {
        border-color: ${({ theme }) => theme.colors.mono.ui04} !important;
        box-shadow: none;
      }

      .react-select--is-disabled & {
        background-color: ${({ theme }) => theme.colors.mono.ui03} !important;
      }
    }

    .react-select__placeholder {
      color: ${({ theme }) => theme.colors.mono.text01};
    }

    .react-select__menu {
      font-size: 1.4rem;
      background-color: ${({ theme }) => theme.colors.mono.ui03};
      color: ${({ theme }) => theme.colors.mono.text01};
      margin-top: 0;
      padding: ${({ theme }) => theme.spacing(0.5)}px;
    }

    .react-select__single-value {
      color: ${({ theme }) => theme.colors.mono.text01};
      .react-select--is-disabled & {
        color: ${({ theme }) => theme.colors.mono.ui05};
      }
    }

    .react-select__indicator-separator {
      background: none;
    }

    .react-select__dropdown-indicator {
      svg {
        color: ${({ theme }) => theme.colors.mono.ui06};
      }
    }

    .react-select__option {
      background-color: ${({ theme }) => theme.colors.mono.ui03};
      font-size: 1.4rem;
      border-radius: 4px;
      cursor: pointer;

      &--is-focused,
      &:hover {
        background-color: ${({ theme }) => theme.colors.mono.ui04};
      }

      &--is-selected {
        background-color: ${({ theme }) => theme.colors.mono.ui03};
        font-weight: ${({ theme }) => theme.font.default.weight.bold};
        color: ${({ theme }) => theme.colors.brand.brand02};
        cursor: default;
        &--is-focused,
        &:hover {
          background-color: ${({ theme }) => theme.colors.mono.ui03};
        }
      }

      &.disabled {
        cursor: default;
        span {
          cursor: default;
        }
      }
    }

    .react-select__input {
      height: auto !important;
      color: ${({ theme }) => theme.colors.mono.text01} !important;
    }
  }
`;
