import { createContext } from 'react';

import { actions } from './slice';
import { State } from './types';

type BlastingActions = typeof actions;
type BlastingAction = ReturnType<BlastingActions[keyof BlastingActions]>;

export const defaultState: State = {
  filters: {},
  selectedBlastSimulations: [],
};

type BlastingContext = State & { dispatch: React.Dispatch<BlastingAction> };

export const Context = createContext<BlastingContext | null>(null);
Context.displayName = 'BlastingContext';
