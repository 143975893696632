import { useI18n } from 'core/hooks/useI18n';

import { useEffect, useMemo } from 'react';
import {
  EventPlaybackHeader,
  EventPlaybackHeaderVisualisationSelection,
  EventPlaybackHeaderVisualisationUtility,
  EventPlaybackWrapper,
  NoNoiseEventAudio,
} from './EventNoisePlayback.styles';
import { AudioVisualisationSelection } from './audio-visualisation-selection';
import { DownloadAudio } from './download-audio';
import { useNoiseEventAudio } from './useNoiseEventAudio';
import { WaveSurferPlayback } from './wave-surfer-playback/WaveSurferPlayback';

interface EventNoisePlaybackProps {
  noiseEventId: number;
  startTime: string;
}

export const EventNoisePlayback = ({ noiseEventId, startTime }: EventNoisePlaybackProps) => {
  const { l10n } = useI18n('app/views/alerts', 'eventsListing');
  const { data, isFetching } = useNoiseEventAudio(noiseEventId);
  const audioUrl = useAudioUrl(data);
  return (
    <EventPlaybackWrapper>
      {data && (
        <EventPlaybackHeader>
          <EventPlaybackHeaderVisualisationSelection>
            <AudioVisualisationSelection />
          </EventPlaybackHeaderVisualisationSelection>
          <EventPlaybackHeaderVisualisationUtility>
            <DownloadAudio data={data} noiseEventId={noiseEventId} startTime={startTime} />
          </EventPlaybackHeaderVisualisationUtility>
        </EventPlaybackHeader>
      )}
      {(isFetching || data) && <WaveSurferPlayback audio={audioUrl} isFetching={isFetching} />}
      {!isFetching && !data && (
        <NoNoiseEventAudio>{l10n('results.noAudioFound', { id: noiseEventId })}</NoNoiseEventAudio>
      )}
    </EventPlaybackWrapper>
  );
};

const useAudioUrl = (data: Blob | undefined) => {
  const audioUrl = useMemo(() => {
    if (data instanceof Blob) {
      return URL.createObjectURL(data);
    }
  }, [data]);
  useEffect(() => {
    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [audioUrl]);
  return audioUrl;
};
