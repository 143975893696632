import { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import Boost from 'highcharts/modules/boost';
import 'highcharts/modules/accessibility';

import { LogManager } from 'core/logging/LogManager';
const logger = LogManager.getLogger('HighchartsGlobalOptions');

declare global {
  interface Window {
    WebGL2RenderingContext: unknown;
  }
}

const HighchartsGlobalOptions = () => {
  useEffect(() => {
    if (canSupportWebGL2()) {
      Boost(Highcharts);
    }
  }, []);

  return null;

  function canSupportWebGL2() {
    const { WebGL2RenderingContext } = window;
    if (!WebGL2RenderingContext) {
      logger.warn('WebGL 2 is not supported: No WebGL2RenderingContext found.');
      return false;
    }

    try {
      let canvas: HTMLCanvasElement | null = document.createElement('canvas');
      canvas.setAttribute('width', '1px');
      canvas.setAttribute('height', '1px');
      document.body.appendChild(canvas);

      const drawingContextNames = ['webgl2', 'experimental-webgl2'];
      const isWebGL2Supported = !!drawingContextNames.find(name => !!canvas?.getContext(name, { stencil: true }));

      canvas.remove();
      canvas = null;

      if (isWebGL2Supported) {
        return true;
      }

      logger.warn(
        'WebGL 2 is not enabled: No WebGL 2 compatible drawing context could be found on the canvas element.'
      );
      return false;
    } catch (err) {
      logger.error('An error occurred when detecting support for WebGL 2 - assuming WebGL 2 is not enabled.', err);
      return false;
    }
  }
};

export default HighchartsGlobalOptions;
