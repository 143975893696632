import { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import type { WaveSurferOptions } from './types';

export const useCreateWaveSurfer = (options: WaveSurferOptions) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const waveSurferRef = useRef<WaveSurfer>();
  useEffect(() => {
    if (!containerRef.current) return;
    waveSurferRef.current = WaveSurfer.create({
      container: containerRef.current,
      ...options,
    });
    return () => {
      waveSurferRef.current?.destroy();
    };
  }, [options, containerRef]);

  return {
    containerRef,
    waveSurferRef,
  };
};
