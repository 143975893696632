import { IFacility } from 'app/business-logic/domain-models/Facility';
import { IItem, SearchBar } from 'app/components/search-bar/';
import { Throbber } from 'app/components/throbber';
import { AccessibleFacility } from 'app/redux/profile/user/slice';
import { useAppSelector } from 'app/redux/store';
import { useI18n } from 'core/hooks/useI18n';
import { useCallback, useMemo, useRef } from 'react';

import { Position, OverlayToaster } from '@blueprintjs/core';

import { ChangeFacilityHotkey } from './ChangeFacilityHotkey';
import { useCanChangeFacility } from './useCanChangeFacility';
import { useChangeFacilityDialogState } from './useChangeFacilityDialogState';
import { useGoToNewFacility } from './useGoToNewFacility';

export const ChangeFacilityDialog = () => {
  const { l10n } = useI18n('app', 'loading');
  const { l10n: onChangeMessage } = useI18n('app/views/components', 'subnav.changeFacility');
  const toaster = useRef<OverlayToaster>(null);
  const {
    facility,
    user: { accessibleFacilities },
  } = useAppSelector(state => state.profile);
  const canChangeFacility = useCanChangeFacility();
  const goToNewFacility = useGoToNewFacility();
  const searchableFacility = useMemo(() => convertToSearchableFacility(facility), [facility]);
  const items = useMemo(
    () =>
      accessibleFacilities?.flatMap(facility => {
        const searchableFacility = convertToSearchableFacility(facility);
        if (!searchableFacility) return [];
        return searchableFacility;
      }),
    [accessibleFacilities]
  );
  const { handlers, isOpen } = useChangeFacilityDialogState();
  const itemSelected = useCallback(
    async (item: IItem) => {
      handlers.CLOSE_SEARCH_BAR();
      if (!accessibleFacilities) return;
      const selectedFacility = accessibleFacilities.find(({ id }) => id === item.key);
      if (!selectedFacility) return;
      goToNewFacility(selectedFacility);
      toaster.current?.show({
        message: onChangeMessage('onChangeMessage', { facilityName: selectedFacility.name }),
      });
    },
    [accessibleFacilities, goToNewFacility, handlers, onChangeMessage]
  );

  if (!(items && facility.map)) return <Throbber>{l10n('facilities')}</Throbber>;
  if (!canChangeFacility) return null;

  return (
    <>
      <ChangeFacilityHotkey />
      <SearchBar
        isOpen={isOpen}
        items={items}
        selectedItem={searchableFacility}
        onItemSelect={itemSelected}
        onClose={handlers.CLOSE_SEARCH_BAR}
      />
      <OverlayToaster position={Position.TOP} ref={toaster} />
    </>
  );
};

function convertToSearchableFacility({
  id,
  name,
  companyAlias,
  facilityAlias,
  isActive,
}: Partial<IFacility> | AccessibleFacility): IItem | undefined {
  if (!name || !facilityAlias || !companyAlias) return;
  if (!isActive) return;
  return {
    key: id,
    description: name,
    label: `${companyAlias}_${facilityAlias}`,
    searchString: `${name} ${companyAlias} ${facilityAlias}`,
  };
}
