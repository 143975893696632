import * as FacilityService from 'app/business-logic/services/facility-service';
import { useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { AppSettings } from 'core/AppSettings';
import { getPreferredFacilityFromUrl } from '../getPreferredFacilityFromUrl';
import { IFacility } from 'app/business-logic/domain-models/Facility';
import { useAppDispatch } from 'app/redux/store';
import { useEffect } from 'react';
import { accessibleFacilitiesChanged } from 'app/redux/profile/user/actions';
import { LogManager } from 'core/logging/LogManager';
import { useClearCache } from 'app/business-logic/services/security-service/logout/useClearCache';
import { setFacility } from 'app/redux/profile/facility/setFacility';

const logger = LogManager.getLogger('authenticator.accessibleFacilities');

export const useAccessibleFacilities = () => {
  const accessibleFacilitiesQuery = useSuspenseQuery({
    queryKey: [QUERY_KEY.authAccessibleFacilities],
    queryFn: () => {
      if (!AppSettings.useGuestApiKey) {
        return FacilityService.fetchAccessibleFacilities();
      }
      const authDomain = getPreferredFacilityFromUrl();
      if (!authDomain) {
        throw new Error('No preferred company/facility specified');
      }
      const { companyAlias, facilityAlias } = authDomain;
      return FacilityService.fetchFacility({ companyAlias, facilityAlias });
    },
  });

  useOnAccessibleFacilitiesFetchSuccess(accessibleFacilitiesQuery.data);
  return accessibleFacilitiesQuery;
};

function useOnAccessibleFacilitiesFetchSuccess(data: IFacility[] | undefined) {
  const dispatch = useAppDispatch();
  const clearCache = useClearCache();
  useEffect(() => {
    if (!data) return;
    logger.debug('Setting accessible facilities');
    dispatch(accessibleFacilitiesChanged(data));
    logger.debug('Setting facility');
    dispatch(
      setFacility({
        accessibleFacilities: data,
        clearCache,
      })
    );
  }, [clearCache, data, dispatch]);
}
