import 'mapbox-gl/dist/mapbox-gl.css';
import 'rc-slider/assets/index.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';

import { NAV_BAR_WIDTH } from 'app/views/components/navigation-bar/NavigationBar.styles';
import { StyledModellingNotifications } from 'app/views/modelling/notifications';
import { createGlobalStyle, css } from 'styled-components';

import { Classes as DatePickerClasses } from '@blueprintjs/datetime';
import { Classes } from '@blueprintjs/core';

import { blueprint, rcSliderStyles, reactSelect, reset, searchBarStyles } from './global-styles';

export const GlobalStyle = createGlobalStyle`
  html {
    font-weight: ${({ theme }) => theme.font.default.weight.normal};
    font-size: 62.5%; // 10px
    color: ${({ theme }) => theme.colors.mono.text02};
  }

  body {
    font-family: ${({ theme: { font } }) => font.default.family};
    font-size: 1.2rem;
    background-color: ${({ theme }) => theme.colors.mono.ui01} !important;
  }

  #root {
    width: 100vw;
    height: 100vh; // Fallback before JS loads
    height: var(--doc-height);
  }

  button {
    font-family: inherit;
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 1em;
  }

  *:focus {
    outline: none;
  }

  // Prevents autofill from changing the background colour of fields.
  // Selectors for both default and webkit need to be separate and not comma-separated.
  input:autofill { transition: background-color 5000s ease-in-out 0s; }
  input:-webkit-autofill { transition: background-color 5000s ease-in-out 0s; }
  
  ${reset}
  ${blueprint}
  ${searchBarStyles}
  ${reactSelect}

  ${() => scrollbarStyles};
  ${() => mapboxStyles};
  ${() => blueprintPopoverStyles};
  ${() => blueprintDatepickerStyles};
`;

const blueprintPopoverStyles = css`
  .${Classes.POPOVER_ARROW} {
    &&&& {
      svg path {
        fill: ${({ theme: { colors } }) => colors.mono.ui03};
      }
    }
  }

  .${Classes.TOOLTIP_INDICATOR} {
    &&&& {
      border-bottom: none;
    }
  }

  .${Classes.POPOVER_CONTENT} {
    &&&& {
      color: ${({ theme: { colors } }) => colors.mono.text02};
      background-color: ${({ theme: { colors } }) => colors.mono.ui03};
    }
  }
`;

const blueprintDatepickerStyles = css`
  .${DatePickerClasses.TIMEPICKER_INPUT_ROW} {
    &&&&& {
      background-color: ${({ theme: { colors } }) => colors.mono.ui04};
      box-shadow: none;
      .${DatePickerClasses.TIMEPICKER_INPUT} {
        color: ${({ theme: { colors } }) => colors.mono.text01};
      }
    }
  }

  .${DatePickerClasses.TIMEPICKER_AMPM_SELECT} {
    &&&&& {
      select {
        background-color: ${({ theme: { colors } }) => colors.mono.ui04};
        background-image: none;
        box-shadow: none;
        color: ${({ theme: { colors } }) => colors.mono.text01};
        &:hover {
          background-color: ${({ theme: { colors } }) => colors.mono.ui04};
        }
      }
    }
  }

  .${DatePickerClasses.DATEPICKER_TIMEPICKER_WRAPPER} {
    margin-top: 0;
    padding-top: 8px;
    margin-left: 5px;
    margin-right: 5px;
    border-top: 1px solid ${({ theme: { colors } }) => colors.mono.ui05};
  }

  ${StyledModellingNotifications};

  ${rcSliderStyles()};
`;

const mapboxStyles = css`
  .mapboxgl-map {
    width: calc(100vw - ${NAV_BAR_WIDTH}px);
    height: 100vh; // Fallback for safety, in case --doc-height goes missing
    height: var(--doc-height);
  }

  .mapboxgl-canvas-container {
    width: 100%;
    height: 100%;
  }

  // Mapbox attribution links
  .mapboxgl-ctrl-attrib-inner {
    &&& {
      color: ${({ theme }) => theme.colors.mono.ui01};
      a,
      a:hover {
        color: ${({ theme }) => theme.colors.mono.ui01};
      }
    }
  }

  // Mapbox popup (clicking KML layers, influence arcs)
  .mapboxgl-popup-tip {
    position: relative;

    // Bridging 1px gap between triangle and bubble
    .mapboxgl-popup-anchor-top & {
      bottom: -1px;
    }
    .mapboxgl-popup-anchor-right & {
      left: -1px;
    }
    .mapboxgl-popup-anchor-bottom & {
      top: -1px;
    }
    .mapboxgl-popup-anchor-left & {
      right: -1px;
    }
  }

  .mapboxgl-popup-content {
    padding: ${({ theme: { spacing } }) => `${spacing(1.5)}px`};
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.mono.ui01};
  }
`;

const scrollbarStyles = css`
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.mono.ui05};
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
