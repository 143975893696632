export const COMPANY_FACILITY = '/:companyAlias/:facilityAlias';

export const ABOUT_ROUTES_ROOT = `${COMPANY_FACILITY}/about`;
export const ALERTS_ROUTES_ROOT = `${COMPANY_FACILITY}/alerts`;
export const ANNOTATIONS_ROUTES_ROOT = `${COMPANY_FACILITY}/annotations`;
export const BLASTING_ROUTES_ROOT = `${COMPANY_FACILITY}/blasting/scenarios`;
export const CONFIGURATION_ROUTES_ROOT = `${COMPANY_FACILITY}/configuration`;
export const GRID_ROUTES_ROOT = `${COMPANY_FACILITY}/grid`;
export const INCIDENT_INTELLIGENCE_ROUTES_ROOT = `${COMPANY_FACILITY}/incident-intelligence`;
export const MODELLING_ROUTES_ROOT = `${COMPANY_FACILITY}/modelling`;
export const MONITORING_ROUTES_ROOT = `${COMPANY_FACILITY}/monitoring`;
export const PROFILE_ROUTES_ROOT = `${COMPANY_FACILITY}/user/profile`;
export const REPORTS_ROUTES_ROOT = `${COMPANY_FACILITY}/reports`;
export const TRAJECTORIES_ROUTES_ROOT = `${COMPANY_FACILITY}/trajectories`;
