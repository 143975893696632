import SideBarItem, { INavItem } from 'app/views/components/navigation-bar/NavItem';
import { useRouting } from 'core/routing/useRouting';

import { DATA_TRACKING_KEY } from '../heap-analytics';
import { Logo, LogoLink, SideBarLinkList, SideBarTitleContainer, StyledSideBar } from './NavigationBar.styles';
import { useDesktopNavigationVisibility } from 'app/views/components/navigation-bar/useDesktopNavigationVisibility';

interface ISideBarProps {
  topItems: INavItem[];
  bottomItems: INavItem[];
}

const SideBar = ({ topItems, bottomItems }: ISideBarProps) => {
  const { routes } = useRouting();
  const { isOpen, onMouseEnter, onMouseLeave } = useDesktopNavigationVisibility();
  return (
    <StyledSideBar $isOpen={isOpen} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <SideBarTitleContainer>
        <LogoLink to={routes.monitoring.realTime()} data-tracking={DATA_TRACKING_KEY['main-nav-bar-logo']}>
          <Logo title="Omnis | Envirosuite" />
        </LogoLink>
      </SideBarTitleContainer>
      <SideBarLinkList>
        <SideBarItemList items={topItems} />
        <SideBarItemList items={bottomItems} />
      </SideBarLinkList>
    </StyledSideBar>
  );
};

const SideBarItemList = ({ items }: { items: INavItem[] }) => {
  return (
    <div>
      {items.flatMap(link => {
        if (link.isHidden) return [];
        return <SideBarItem {...link} key={link.key} elementProps={link.elementProps} />;
      })}
    </div>
  );
};

export default SideBar;
