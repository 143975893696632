import { gql } from '@apollo/client';

export const CLASSIFY_NOISE_EVENT = gql(`
  mutation ClassifyNoiseEvent ($input: UpdateClassifiedNoiseEvent!) {
    facility {
      classification {
        updateClassifiedNoiseEvent (input: $input) {
          id
          disregarded
          comments
          classifications {
            noiseEventId
            classificationId
            modifiedBy
            utcModifiedTime
          }
        }
      }
    }
  }
`);
