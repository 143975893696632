export const alertsRoutes = (root: string) => ({
  root,
  view: () => {
    return `${root}/view/:alertId`;
  },
  events: () => {
    return `${root}/events`;
  },
  vibrationEvents: () => {
    return `${root}/vibration-events`;
  },
});
