import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { getAlertResponse } from 'app/business-logic/services/alerts-services/getAlertResponse';
import { updateAlertResponse } from 'app/business-logic/services/alerts-services/updateAlertResponse';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { useHasAccess } from 'app/hooks/use-has-access';
import Guid from 'core/types/Guid';

export const useApplyAlertResponse = (alertId: Guid) => {
  const queryClient = useQueryClient();
  const hasAlertResponseReadAccess = useHasAccess()(KnownAccessControls.app.alerts.response.data.read._);
  const { mutate, isPending: isUpdating } = useMutation({
    mutationFn: updateAlertResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.alertResponse, alertId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.alertHistory],
      });
    },
  });
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEY.alertResponse, alertId],
    queryFn: () => {
      if (!alertId) return;
      return getAlertResponse(alertId);
    },
    enabled: !!alertId && hasAlertResponseReadAccess,
  });
  const isLoading = isUpdating || isFetching;

  return {
    data,
    updateAlertResponse: mutate,
    isLoading,
  };
};
