import { useAppDispatch } from 'app/redux/store';
import { selectAlertEvent as selectAlertEventAction } from 'app/redux/ui/actions';
import { useCallback, useRef } from 'react';

type NoiseEventId = number;
export type EventElementsMap = Map<NoiseEventId, HTMLElement | null>;

/**
 * Provides a callback and ref used to select alert events
 * @returns "eventElementsMap" - use this to store a refence to each event element, which is used to scroll the window to the element upon its event's selection.
 * @returns "selectAlertEvent" - call with an event ID to select an event.
 */
export const useSelectAlertEvent = () => {
  const eventElementsMap = useRef<EventElementsMap>(new Map());
  const dispatch = useAppDispatch();
  const selectAlertEvent = useCallback(
    (eventId: NoiseEventId) => {
      dispatch(selectAlertEventAction(eventId));
    },
    [dispatch]
  );
  return {
    eventElementsMap,
    selectAlertEvent,
  };
};
