import { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';
import { LogManager } from 'core/logging/LogManager';

const logger = LogManager.getLogger('ProtectedErrorBoundary');

type ErrorBoundaryProps = PropsWithChildren<{ fallback: ReactNode }>;
type ErrorBoundaryState = { hasError: boolean };

export class ProtectedErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    logger.error(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) return this.props.fallback;
    return this.props.children;
  }
}
