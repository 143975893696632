import Guid from 'core/types/Guid';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { GET_PROCESS_VARIABLE_DATA } from './graphql/facility.getProcessVariableData';
import { GetProcessVariableDataQuery, GetProcessVariableDataQueryVariables } from 'app/__generated__/global';

export const getProcessVariableSeriesData = ({
  traceVariableIds,
  utcStartDate,
  utcEndDate,
}: {
  traceVariableIds: Guid[];
  utcStartDate: string;
  utcEndDate: string;
}) => {
  const variables: GetProcessVariableDataQueryVariables = {
    variableFilter: {
      id_in: traceVariableIds,
    },
    variableDataFilter: {
      endTime_between: {
        startTime: utcStartDate,
        endTime: utcEndDate,
      },
    },
  };
  return ApolloProvider.global().query<GetProcessVariableDataQuery, GetProcessVariableDataQueryVariables>({
    query: GET_PROCESS_VARIABLE_DATA,
    variables,
  });
};
