import { Form } from 'formik';
import { styled, css } from 'styled-components';

import { FormGroup } from '@blueprintjs/core';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
`;

export const StyledFormContent = styled.div<{ $fit?: boolean }>`
  flex: 1 1 1px;
  padding: ${({ theme: { spacing } }) => `${spacing(3)}px ${spacing(2)}px`};
  overflow-y: auto;
  @media screen and (min-width: 1400px) {
    width: ${({ $fit }) => $fit && '40%'};
  }
`;

const requiredStyles = css`
  &::after {
    content: ' *';
  }
`;

export const StyledFormGroup = styled(FormGroup)<{ $isRequired?: boolean }>`
  label {
    font-size: 1.4rem;
    ${({ $isRequired }) => $isRequired && requiredStyles}
  }
`;
