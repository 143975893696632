import { useDefaultOptions } from 'app/hooks/use-default-options/useDefaultOptions';
import { useDefaultYAxis } from 'app/hooks/use-default-y-axis/useDefaultYAxis';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useThemeContext } from 'app/theme/useThemeContext';
import { AlertConfig } from 'app/views/alerts/hooks/use-alert-configuration';
import Guid from 'core/types/Guid';
import { YAxisPlotLinesOptions } from 'highcharts';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useConfigData } from './useConfigData';
import { EventChartOptions } from './useEventsChartOptions.types';
import { useLowLatencyNoiseSeries } from './useLowLatencyNoiseSeries';
import { useProcessVariableSeries } from './useProcessVariableSeries';

export const PLOT_BAND_CLASS_NAME = 'omnis-plot-band';

export const useEventsChartOptions = (alertId: Guid, selectAlertEvent: (alertEventId: number) => void) => {
  const { colors, font } = useThemeContext();
  const localTime = useLocalTimeCallback();
  const defaultOptions = useDefaultOptions();
  const { events, series, utcEndDate, utcStartDate, isLoading, displayUnit, thresholds } = useChartData(alertId);
  const defaultYAxis = useDefaultYAxis();
  const plotLines = usePlotLines(thresholds);
  const options: EventChartOptions = useMemo(
    () => ({
      ...defaultOptions,
      series,
      tooltip: {
        backgroundColor: colors.mono.ui02,
        borderRadius: 0,
        borderWidth: 0,
        shadow: false,
        style: {
          color: colors.mono.text02,
          fontSize: '1.4rem',
        },
        useHTML: true,
        formatter: function () {
          const currentTime = localTime(this.x).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
          const title = `<p style="margin:0;font-size:12px">${this.series.userOptions.title}</p>`;
          const header = `<p style="margin:0;font-size:10px">${currentTime}</p>`;
          const body = `<span style="color:${this.color}">●</span> ${this.series.name}: <b>${this.y}</b><br/>`;
          return [title, header, body];
        },
      },
      time: {
        getTimezoneOffset: time => -localTime(time).offset,
      },
      xAxis: {
        ...defaultOptions.xAxis,
        endOnTick: false,
        max: utcEndDate ? DateTime.fromISO(utcEndDate).toMillis() : undefined,
        min: utcStartDate ? DateTime.fromISO(utcStartDate).toMillis() : undefined,
        plotLines: events?.map((event, index) => ({
          className: PLOT_BAND_CLASS_NAME,
          color: colors.mono.ui03,
          events: {
            click: () => {
              selectAlertEvent(event.id);
            },
          },
          from: event.startTime ? DateTime.fromISO(event.startTime).toMillis() : undefined,
          label: {
            text: (index + 1).toString(),
            style: {
              color: colors.mono.text02,
              fontWeight: font.default.weight.bold.toString(),
              fontSize: '1.4rem',
            },
          },
          to: event.endTime ? DateTime.fromISO(event.endTime).toMillis() : undefined,
        })),
        tickPixelInterval: 124,
        type: 'datetime',
      },
      yAxis: {
        ...defaultYAxis,
        maxPadding: 0.15,
        plotLines,
        title: {
          text: displayUnit,
          style: {
            fontSize: '1.4rem',
            color: colors.mono.ui06,
          },
        },
      },
    }),
    [
      colors.mono.text02,
      colors.mono.ui02,
      colors.mono.ui03,
      colors.mono.ui06,
      defaultOptions,
      defaultYAxis,
      displayUnit,
      events,
      font.default.weight.bold,
      localTime,
      plotLines,
      selectAlertEvent,
      series,
      utcEndDate,
      utcStartDate,
    ]
  );
  return { options, isLoading };
};

const usePlotLines = (
  thresholds?: {
    valueInDisplayUnit: number;
    hexColor: string;
  }[]
): YAxisPlotLinesOptions[] => {
  return useMemo(() => {
    if (!thresholds) return [];
    return thresholds.map(({ valueInDisplayUnit, hexColor }) => ({
      color: hexColor,
      value: valueInDisplayUnit,
      width: 2,
      dashStyle: 'Dash',
    }));
  }, [thresholds]);
};

function useChartData(alertId: Guid) {
  const { events, omnisDataType, omnisLocationId, traceVariableIds, utcEndDate, utcStartDate, variables } =
    useConfigData(alertId);
  const { data: processVariableSeries, isFetching: isProcessVariableSeriesLoading } = useProcessVariableSeries({
    traceVariableIds,
    utcStartDate,
    utcEndDate,
  });
  const { data: lowLatencyNoiseSeries, isFetching: isLowLatencyNoiseSeriesLoading } = useLowLatencyNoiseSeries({
    locationId: omnisLocationId,
    utcStartDate,
    utcEndDate,
    noiseTypes: omnisDataType ? [omnisDataType] : undefined,
    traceVariableIds,
  });
  const series = useMemo(() => {
    const hasProcessVariableSeries = !!processVariableSeries?.length;
    const hasLowLatencyNoiseSeries = !!lowLatencyNoiseSeries?.length;
    if (hasProcessVariableSeries) return processVariableSeries;
    if (hasLowLatencyNoiseSeries) return lowLatencyNoiseSeries;
    return [];
  }, [lowLatencyNoiseSeries, processVariableSeries]);
  const { displayUnit, thresholds } = useDisplayUnitAndThresholds(variables);
  return useMemo(
    () => ({
      series: series.map(({ data, ...rest }) => ({
        ...rest,
        data: [...data].sort(([a], [b]) => a - b),
      })),
      events,
      utcEndDate,
      utcStartDate,
      isLoading: isProcessVariableSeriesLoading || isLowLatencyNoiseSeriesLoading,
      displayUnit,
      thresholds,
    }),
    [
      displayUnit,
      events,
      isLowLatencyNoiseSeriesLoading,
      isProcessVariableSeriesLoading,
      series,
      thresholds,
      utcEndDate,
      utcStartDate,
    ]
  );
}

const useDisplayUnitAndThresholds = (variables?: NonNullable<AlertConfig>['variables']) => {
  if (!variables) return {};
  const traceVariables = variables.filter(({ isTraceVariable }) => isTraceVariable);
  // if there are multiple trace variables, unit and thresholds should not be displayed in the chart
  const hasMultipleTraceVariables = traceVariables?.length > 1;
  const displayUnit = hasMultipleTraceVariables ? '' : traceVariables?.[0]?.variable?.displayUnit?.name;
  const thresholds = hasMultipleTraceVariables ? [] : traceVariables?.[0]?.variable.thresholds;
  return { displayUnit, thresholds };
};
