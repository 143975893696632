import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, css } from 'styled-components';
import TabList from 'app/views/configuration/components/tab-list';
import { SidebarContent } from 'app/components/layout-with-map';

export const StyledSimulationStatusIcon = styled(FontAwesomeIcon)<{ $isWarning?: boolean; $isError?: boolean }>`
  color: ${({ theme, $isWarning }) => $isWarning && theme.colors.feedback.warning};
  color: ${({ theme, $isError }) => $isError && theme.colors.feedback.error};
`;

export const StyledTabList = styled(TabList)`
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 1.4rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mono.text03};
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.ui04};
  padding-bottom: ${({ theme }) => theme.spacing()}px;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  letter-spacing: 0.04em;
  width: 100%;
`;

export const ScenarioDetailsSidebarContent = styled(SidebarContent)`
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        flex-direction: column;
      `
    )}
`;
