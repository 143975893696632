import { useFetchBlastingScenario } from 'app/views/blasting/pages/view-scenario/useFetchBlastingScenario';
import { useParams } from 'react-router-dom';
import { useI18n } from 'core/hooks/useI18n';
import { Classes } from '@blueprintjs/core';
import { BlastModelType } from 'app/business-logic/domain-models/Blasting';
import { Label, Value, Parameter, ScenarioCardContainer, ScenarioCardTitle } from './ScenarioDataCards.styles';

export const OverpressureCard = () => {
  const { alias } = useParams();
  const { l10n } = useI18n('app/views/blasting');

  const { data: scenario, isLoading } = useFetchBlastingScenario(alias);
  const loadingClass = isLoading ? Classes.SKELETON : undefined;

  if (
    !scenario.blastModels?.some(
      model =>
        model.modelType === BlastModelType.OverpressureContour || model.modelType === BlastModelType.OverpressureGain
    ) &&
    !isLoading
  ) {
    return null;
  }
  return (
    <ScenarioCardContainer>
      <ScenarioCardTitle className={loadingClass}>
        {l10n('page.createScenario.form.section.overpressure')}
      </ScenarioCardTitle>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.blastSetup.label')}</Label>
        <Value>{scenario.blastSetup}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.elevation.label')}</Label>
        <Value>
          {scenario.elevation} {l10n('fields.elevation.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.chargeMass.labelWithoutUnit')}</Label>
        <Value>
          {scenario.chargeMass} {l10n('fields.chargeMass.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.holeDiameter.labelWithoutUnit')}</Label>
        <Value>
          {scenario.holeDiameter} {l10n('fields.holeDiameter.unit')}
        </Value>
      </Parameter>
      {scenario.blastSetup === 'stemming' && (
        <>
          <Parameter className={loadingClass}>
            <Label>{l10n('fields.stemmingHeight.labelWithoutUnit')}</Label>
            <Value>
              {scenario.stemmingHeight} {l10n('fields.stemmingHeight.unit')}
            </Value>
          </Parameter>
          <Parameter className={loadingClass}>
            <Label>{l10n('fields.kFactor.label')}</Label>
            <Value>{scenario.frontFaceKFactor}</Value>
          </Parameter>
        </>
      )}
      {scenario.blastSetup === 'burden' && (
        <>
          <Parameter className={loadingClass}>
            <Label>{l10n('fields.burden.labelWithoutUnit')}</Label>
            <Value>
              {scenario.burden} {l10n('fields.burden.unit')}
            </Value>
          </Parameter>
          <Parameter className={loadingClass}>
            <Label>{l10n('fields.frontFaceKFactor.label')}</Label>
            <Value>{scenario.frontFaceKFactor}</Value>
          </Parameter>
          <Parameter className={loadingClass}>
            <Label>{l10n('fields.backFaceKFactor.label')}</Label>
            <Value>{scenario.backFaceKFactor}</Value>
          </Parameter>
          <Parameter className={loadingClass}>
            <Label>{l10n('fields.azimuth.label')}</Label>
            <Value>{scenario.azimuth}</Value>
          </Parameter>
        </>
      )}
    </ScenarioCardContainer>
  );
};
