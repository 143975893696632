import { FetchStatus } from 'app/types';
import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';

export type KmlOverlays = Response<KmlOverlaysData>;
export type KmlOverlaysData = KmlOverlay[];
export type KmlOverlay = FeatureCollection<Geometry, KmlOverlayProperties>;
export type KmlFeature = KmlOverlay['features'][number];
export type KmlOverlayProperties = GeoJsonProperties & { id: string };

type Response<D> =
  | {
      status: Extract<FetchStatus, 'NotRequested'>;
    }
  | {
      status: Extract<FetchStatus, 'Loading'>;
    }
  | {
      status: Extract<FetchStatus, 'Success'>;
      data: D;
    }
  | {
      status: Extract<FetchStatus, 'Failure'>;
    };

export enum MapTimeSource {
  realTime = 'Real Time',
  spotTime = 'Spot Time',
  playback = 'Playback',
}

export type BadgeStatus = 'success' | 'neutral' | 'info' | 'warning' | 'error';

export enum BaseMapLayerType {
  road = 'roadmap',
  satellite = 'satellite',
}

export interface ILayers {
  debug: ILayerSettings;
  monitorNames: ILayerSettings;
  sourceAreas: ILayerSettings;
  activityLocations: ILayerSettings;
  inactiveMonitors: ILayerSettings;
  influenceArcs: ILayerSettings;
  kmlOverlays: ILayerSettings;
  customImageOverlays: ILayerSettings;
  monitorsInTrajectories: ILayerSettings;
  domainBoundaries: ILayerSettings;
  emissionSources: ILayerSettings;
}

export interface ILayerSettings {
  groupName?: string;
  visible: boolean;
}
