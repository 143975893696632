import { styled, css } from 'styled-components';

interface ITableRowProps {
  onClick?: () => void;
  $isRowSelected?: boolean;
  $isRowSelectable?: boolean;
}

const selectedRowStyles = css`
  background-color: ${({ theme }) => theme.colors.mono.ui05};
  &:hover {
    background-color: ${({ theme }) => theme.colors.mono.ui05};
  }
`;

const isSelectableStyles = css<{ $isRowSelected?: boolean }>`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.mono.ui04};
  }
  &:active {
    ${selectedRowStyles};
  }
  ${({ $isRowSelected }) => $isRowSelected && selectedRowStyles}
`;

export const StyledTableWrapper = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

export const StyledTable = styled.table<{ $isLoading?: boolean }>`
  text-align: left;
  background-color: ${({ theme }) => theme.colors.mono.ui03};
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  td {
    overflow: ${({ $isLoading }) => ($isLoading ? 'hidden' : 'visible')};
  }
`;

export const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.mono.ui01};
`;

export const TableRow = styled.tr<ITableRowProps>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.ui05};
  ${({ $isRowSelectable }) => $isRowSelectable && isSelectableStyles};
`;

export const TableHeader = styled.th`
  padding: 0;
`;

export const TableHeaderText = styled.div<{ $isSortable?: boolean }>`
  padding: ${({ theme: { spacing } }) => `${spacing(1.5)}px ${spacing(2)}px`};
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.text03};
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  ${({ $isSortable }) =>
    $isSortable &&
    css`
      cursor: pointer;
      display: flex;
      gap: ${({ theme }) => theme.spacing()}px;
    `}
  &:hover {
    background-color: ${({ theme, $isSortable }) => ($isSortable ? theme.colors.mono.ui04 : theme.colors.mono.ui01)};
  }
  svg path {
    fill: ${({ theme }) => theme.colors.mono.text03};
  }
`;

export const TableBody = styled.tbody`
  background-color: ${({ theme }) => theme.colors.mono.ui03};
`;

export const TableCell = styled.td`
  padding: 0;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.text01};
  word-break: break-word;
`;
