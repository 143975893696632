import ColorMap from 'colormap';
import { useMemo } from 'react';
import { SpectrogramPluginOptions } from 'wavesurfer.js/dist/plugins/spectrogram';

// See all available colormap types here https://github.com/bpostlethwaite/colormap#colormap-
type ColorMapParameters = NonNullable<Parameters<typeof ColorMap>[0]>;
type ColorMapType = ColorMapParameters['colormap'];

export const useColorMap = (colorMapType: ColorMapType = 'jet') =>
  useMemo(
    () =>
      ColorMap({
        colormap: colorMapType,
        nshades: 256,
        format: 'float',
      }),
    [colorMapType]
  ) as SpectrogramPluginOptions['colorMap'];
