import { useMemo } from 'react';
import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom';

export function useSearchParamValues<T extends string>(searchParamKeys: Array<T>) {
  const [searchParams] = useReactRouterSearchParams();
  return useMemo(() => {
    return searchParamKeys.reduce<Record<T, Array<string>>>(
      (acc, key) => {
        const values = searchParams.getAll(key);
        acc[key] = values;
        return acc;
      },
      {} as Record<T, Array<string>>
    );
  }, [searchParamKeys, searchParams]);
}
