import { Button as BlueprintButton, Classes } from '@blueprintjs/core';
import { inputStyles } from 'app/components/form/input';
import { labelStyles } from 'app/components/form/label';
import { blueprintButtonReset } from 'app/visuals/mixins/blueprintButtonReset';
import { styled } from 'styled-components';

export const StyledFormGroup = styled.div<{ $isRequired?: boolean }>`
  &&& {
    label {
      ${() => labelStyles}
      &::after {
        content: ${({ $isRequired }) => $isRequired && ' *'};
      }
    }
    button {
      min-height: 36px;
      min-width: 36px;
    }
    input {
      ${inputStyles}
      display: block;
      padding-right: 44px;
    }
    .${Classes.POPOVER_TARGET} {
      // Work around to ensure there is extra gap between form fields
      // below a date picker field
      display: block;
    }
  }
`;

export const CalendarIconButton = styled(BlueprintButton)`
  ${() => blueprintButtonReset};
  &&& {
    padding: ${({ theme }) => theme.spacing(0.5)}px;
    margin: 0;
    &:disabled {
      cursor: not-allowed;
      background-color: transparent;
    }
  }
`;
