import { IFacility } from 'app/business-logic/domain-models/Facility';
import { IUser } from 'app/business-logic/domain-models/User';
import { AccessControl } from 'app/business-logic/security/KnownAccessControls';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from 'app/redux/actions';

export type UserState = typeof INITIAL_STATE;
export type AccessControls = Partial<Record<AccessControl, boolean>>;
export type AccessibleFacility = Omit<IFacility, 'domainBoundaries' | 'map'>;

export const INITIAL_STATE = {
  details: null as IUser | null,
  accessibleFacilities: null as AccessibleFacility[] | null,
  accessControls: {} as AccessControls,
};

export const slice = createSlice({
  name: 'profile/user',
  initialState: INITIAL_STATE,
  reducers: {
    userDetailsChanged: (state, { payload }: PayloadAction<IUser>) => {
      return {
        ...state,
        details: payload,
      };
    },

    accessibleFacilitiesChanged: (state, { payload }: PayloadAction<IFacility[]>) => {
      return {
        ...state,
        accessibleFacilities: payload,
      };
    },

    accessControlsChanged: (state, { payload }: PayloadAction<AccessControls>) => {
      return {
        ...state,
        accessControls: payload,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
