import { styled, css } from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing()}px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-shrink: 0;
  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        display: none;
      `
    )}
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing()}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  &:empty {
    display: none;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing()}px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  &:empty {
    display: none;
  }
`;
