import { NoiseEvent } from 'app/business-logic/services/noise-service';
import {
  useNoiseEventClassificationCategories,
  useNoiseEventClassifications,
} from 'app/views/configuration/pages/noise/noise-classifications/use-noise-event-classifications';
import { EventClassificationIcon } from './EventClassificationIcons.styles';
import { useMemo } from 'react';
import { noiseClassificationSlugHasIcon } from 'app/views/configuration/pages/noise/noise-classifications/noise-classification-icon/noiseClassificationSlugHasIcon';

type EventClassificationIconsProps = {
  classifications: NoiseEvent['classifications'];
};

export const EventClassificationIcons = ({ classifications }: EventClassificationIconsProps) => {
  const { categories } = useNoiseEventClassificationCategories();
  const { data } = useNoiseEventClassifications();
  const icons = useMemo(
    () =>
      classifications?.flatMap(eventClassification => {
        if (!eventClassification) return [];
        const { classificationId } = eventClassification;
        const classification = data?.find(({ id }) => id === classificationId);
        if (!classification) return [];
        const category = categories?.find(({ id }) => id === classification.categoryId);
        if (!category) return [];
        return { classification, category };
      }) ?? [],
    [categories, classifications, data]
  );
  const trimmedIcons = useMemo(() => {
    if (icons.length > 3) return icons.slice(0, 3);
    return icons;
  }, [icons]);
  const isTrimmed = icons.length !== trimmedIcons.length;
  return (
    <>
      {trimmedIcons.map(({ category, classification }) => {
        const icon = noiseClassificationSlugHasIcon(category.slug) ? category.slug : 'omnis';
        return <EventClassificationIcon icon={icon} title={classification.name} key={classification.id} />;
      })}
      {isTrimmed && '...'}
    </>
  );
};
