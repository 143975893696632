import { useEffect } from 'react';
import { WaveSurferRef } from '../types';
import { useSetDurationMilliseconds } from './useSetDurationMilliseconds';

export const useDurationMilliseconds = (waveSurferRef: WaveSurferRef) => {
  const { durationMilliseconds, setDurationMilliseconds } = useSetDurationMilliseconds(waveSurferRef);
  const waveSurfer = waveSurferRef.current;

  useEffect(() => {
    if (!waveSurfer) return;
    waveSurfer.on('ready', setDurationMilliseconds);
    return () => {
      waveSurfer.un('ready', setDurationMilliseconds);
    };
  }, [setDurationMilliseconds, waveSurfer]);
  return durationMilliseconds;
};
