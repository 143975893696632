import { GetAlertResponseCategoriesQuery } from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { GET_ALERT_RESPONSE_CATEGORIES } from './graphql/lookup.getAlertResponseCategories';

export const getAlertResponseCategories = async () => {
  const { data, error } = await ApolloProvider.global().query<GetAlertResponseCategoriesQuery>({
    query: GET_ALERT_RESPONSE_CATEGORIES,
  });
  if (!data || error) throw new Error('Something went wrong when trying to get alert response categories');
  return data.lookup.alertResponseCategories;
};
