import { parse } from 'query-string';
import { useLocation, useMatch } from 'react-router-dom';
import { Routing } from 'core/routing/Routing';

export const useIsTrajectoryExport = () => {
  const isTrajectories = useMatch({ path: Routing.routes.trajectories.root, end: false }) !== null;
  const { search } = useLocation();
  const { trajectoryExport } = parse(search);

  return !!trajectoryExport && isTrajectories;
};
