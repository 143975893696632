import { styled, css } from 'styled-components';

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  width: fit-content;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        flex-direction: row;
        gap: ${({ theme }) => theme.spacing()}px;
      `
    )}
`;

export const LegendValue = styled.div`
  display: flex;
`;
export const Label = styled.span`
  margin: ${({ theme }) => theme.spacing(0.5)}px;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.mono.text02};
`;

export const Key = styled.div<{ $color: string; $dashWidth: number }>`
  height: 0px;
  width: 2rem;
  margin: ${({ theme }) => `${theme.spacing(1.5)}px ${theme.spacing(0.5)}px`};
  border-bottom: ${({ $color, $dashWidth }) => `${$dashWidth}px dashed ${$color}`};
`;
