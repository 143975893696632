import { NoiseClassificationIcon } from 'app/views/configuration/pages/noise/noise-classifications/noise-classification-icon';
import { srOnly } from 'app/visuals/mixins/srOnly';
import { styled } from 'styled-components';

export const SelectableButtonWrapper = styled.label<{ $isLoading?: boolean }>`
  display: inline-block;
  cursor: pointer;
  cursor: ${({ $isLoading }) => $isLoading && 'not-allowed'};
  user-select: none;
`;

export const SelectableButtonInner = styled.span<{ $textColor?: string; $backgroundColor?: string }>`
  background-color: ${({ theme, $backgroundColor }) => $backgroundColor ?? theme.colors.mono.ui04};
  border-radius: 100px;
  color: ${({ theme, $textColor }) => $textColor ?? theme.colors.mono.text02};
  padding: ${({ theme: { spacing } }) => `${spacing(0.75)}px ${spacing(1.25)}px`};
  transition: box-shadow 0.15s ease;
  display: flex;
  align-items: center;
`;

export const SelectableButtonInput = styled.input.attrs<{ $textColor?: string; $backgroundColor?: string }>({
  type: 'checkbox',
})`
  ${srOnly}
  &:not(:disabled) ~ ${SelectableButtonInner} {
    &:hover {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.mono.ui06};
    }
  }
  &:checked ~ ${SelectableButtonInner} {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand.brand02};
    &:hover {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand.brand02};
    }
  }
  &:not(:disabled):focus-visible ~ ${SelectableButtonInner} {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.mono.ui06};
  }
`;

export const SelectableButtonIcon = styled(NoiseClassificationIcon)`
  max-width: 24px;
  max-height: 24px;
  margin-right: ${({ theme }) => theme.spacing()}px;
  path {
    fill: currentColor;
  }
`;

export const SelectableButtonText = styled.span`
  font-size: 1.4rem;
`;
