import { useCallback, useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';

type LoadBlobArgs = Parameters<WaveSurfer['loadBlob']>;

export const useLoadBlob = (waveSurferRef: React.MutableRefObject<WaveSurfer | undefined>): WaveSurfer['loadBlob'] => {
  const [loadBlobArgs, setLoadBlobArgs] = useState<LoadBlobArgs>();

  useEffect(() => {
    // Only attempt to load audio when the WaveSurfer instance is ready
    if (!waveSurferRef.current) return;
    if (!loadBlobArgs) return;
    waveSurferRef.current.loadBlob(...loadBlobArgs);
  }, [loadBlobArgs, waveSurferRef]);

  return useCallback(async (...args) => {
    setLoadBlobArgs(args);
  }, []);
};
