import { LogManager } from 'core/logging/LogManager';
import { getPreferredFacilityFromUrl } from '../getPreferredFacilityFromUrl';
import { AccessibleFacility } from 'app/redux/profile/user/slice';
import { IGlobalState } from 'app/redux/reducer';

const logger = LogManager.getLogger('usePreferredFacility');

export function getPreferredFacility({
  accessibleFacilities,
  clearCache,
  persistedFacility,
}: {
  clearCache: () => void;
  persistedFacility: IGlobalState['profile']['facility'];
  accessibleFacilities: AccessibleFacility[] | null;
}) {
  if (!accessibleFacilities?.length) return;
  const urlFacility = getPreferredFacilityFromUrl();

  const hasPersistedFacility = !!persistedFacility.companyAlias && !!persistedFacility.facilityAlias;

  if (
    !!urlFacility &&
    hasPersistedFacility &&
    (persistedFacility.companyAlias !== urlFacility.companyAlias ||
      persistedFacility.facilityAlias !== urlFacility.facilityAlias)
  ) {
    clearCache();
  }

  const { companyAlias, facilityAlias } = urlFacility ?? persistedFacility;

  if (!companyAlias || !facilityAlias) {
    const [firstAvailableFacility] = accessibleFacilities;
    if (!firstAvailableFacility) return;
    logger.debug(
      `Setting facility from first available facility: ${firstAvailableFacility.companyAlias}/${firstAvailableFacility.facilityAlias}`
    );
    return {
      ...firstAvailableFacility,
      domainBoundaries: [],
    };
  }

  const authDomainFacility = accessibleFacilities.find(
    ac => ac.companyAlias === companyAlias && ac.facilityAlias === facilityAlias
  );

  if (!authDomainFacility) return;

  logger.debug(`Setting facility: ${authDomainFacility.companyAlias}/${authDomainFacility.facilityAlias}`);

  return {
    ...authDomainFacility,
    domainBoundaries: [],
  };
}
