import { Classes } from '@blueprintjs/core';
import { Chart } from 'app/components/charts/Chart';
import { styled } from 'styled-components';
import { PLOT_BAND_CLASS_NAME } from './use-events-chart-options/useEventsChartOptions';

export const StyledEventsChart = styled(Chart)`
  .${PLOT_BAND_CLASS_NAME} {
    cursor: pointer;
    &:hover {
      fill: ${({ theme }) => theme.colors.mono.ui04};
    }
  }
`;

export const EventsChartLoading = styled.div.attrs({ className: Classes.SKELETON })`
  height: 300px;
`;
