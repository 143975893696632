import { gql } from '@apollo/client';

export const UPDATE_CLASSIFICATION = gql(`
  mutation UpdateClassification($input: UpdateClassification!) {
    facility {
      classification {
        updateClassification(input: $input) {
          id
          name
          categoryId
          utcDeactivatedAt
          utcDeletedAt
          disregardEvents
        }
      }
    }
  }
`);
