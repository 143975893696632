import { useEffect } from 'react';
import { Routing } from 'core/routing/Routing';
import { matchPath, useLocation } from 'react-router-dom';
import { AccessControl } from 'app/business-logic/security/KnownAccessControls';

export const REMEMBERED_ROUTES: Record<
  string,
  {
    path: string;
    access: AccessControl;
  }
> = {
  monitoringMap: { path: Routing.routes.monitoring.realTime(), access: 'app.monitoring' },
  monitoringGrid: { path: Routing.routes.grid.realTime(), access: 'app.monitoring' },
  modelling: { path: Routing.routes.modelling.root, access: 'app.modelling' },
  alerts: { path: Routing.routes.alerts.root, access: 'app.alerts' },
  trajectories: { path: Routing.routes.trajectories.root, access: 'app.modelling.trajectory' },
  blasting: { path: Routing.routes.blasting.root, access: 'app.blasting' },
  annotations: { path: Routing.routes.annotations.root, access: 'app.config.annotations.monitor-pollutants' },
  incidentIntelligence: { path: Routing.routes.incidentIntelligence.root, access: 'app.incident-intelligence' },
  reports: { path: Routing.routes.reports.root, access: 'app.reports' },
} as const;

export const useRememberRoute = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    Object.entries(REMEMBERED_ROUTES).forEach(([route, { path }]) => {
      if (matchPath({ path, end: false }, pathname)) {
        localStorage.setItem('rememberedRoute', route);
      }
    });
  }, [pathname]);
};
