import { gql } from '@apollo/client';

export const FETCH_FACILITY_MAP_CONFIGURATION = gql`
  query FetchFacilityMapConfiguration {
    facility {
      id
      mapLogoImage {
        data
      }
    }
  }
`;
