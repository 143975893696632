import { EventClassificationState } from 'app/views/alerts/components/event-classification/EventClassification';
import Guid from 'core/types/Guid';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { CLASSIFY_NOISE_EVENT } from './graphql/facility.classifyNoiseEvent';
import { ClassifyNoiseEventMutation, ClassifyNoiseEventMutationVariables } from 'app/__generated__/global';

export const classifyNoiseEvent = async ({
  eventClassificationState: { classifications, comments, disregarded },
  eventId,
  modifiedByUserId,
  utcModifiedTime,
}: {
  eventClassificationState: EventClassificationState;
  eventId: number;
  modifiedByUserId: Guid;
  utcModifiedTime: string;
}) => {
  const { data, errors } = await ApolloProvider.global().mutate<
    ClassifyNoiseEventMutation,
    ClassifyNoiseEventMutationVariables
  >({
    mutation: CLASSIFY_NOISE_EVENT,
    variables: {
      input: {
        id: eventId,
        disregarded,
        comments: comments?.length > 0 ? comments : null,
        classifications: classifications
          .filter(({ isSelected }) => isSelected)
          .map(({ classification: { id } }) => ({
            noiseEventId: eventId,
            classificationId: id,
            modifiedBy: modifiedByUserId,
            utcModifiedTime: utcModifiedTime,
          })),
      },
    },
  });
  if (!data || errors?.length)
    throw new Error(`Something went wrong when trying to classify a noise event, event ID: ${eventId}`);
  return data;
};
