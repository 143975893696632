import { getLoginUrl } from 'app/business-logic/services/security-service/getLoginUrl';
import { Throbber } from 'app/components/throbber';
import { useI18n } from 'core/hooks/useI18n';
import { PropsWithChildren, Suspense, useEffect } from 'react';
import { LoginButton, ProtectedWrapper, UnauthorisedMessage } from './Protected.styles';
import { useAuthenticator } from './use-authenticator';
import { ProtectedErrorBoundary } from './ProtectedErrorBoundary';
import { LogManager } from 'core/logging/LogManager';

const logger = LogManager.getLogger('Protected');

export const Protected = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const { l10n } = useI18n('app');
  return (
    <Suspense fallback={<Throbber>{l10n('activity.loading')}</Throbber>}>
      <ProtectedErrorBoundary fallback={<Unauthorised />}>
        <ProtectedContent>{children}</ProtectedContent>
      </ProtectedErrorBoundary>
    </Suspense>
  );
};

const ProtectedContent = ({ children }: PropsWithChildren<Record<never, never>>) => {
  const authenticator = useAuthenticator();
  const { accessControls, accessibleFacilities, mapConfiguration, userDetails } = authenticator;
  const isUnauthorised =
    accessControls.isError || accessibleFacilities.isError || mapConfiguration.isError || userDetails.isError;
  const logMessage = `
    accessControls: ${accessControls.status}
    accessibleFacilities: ${accessibleFacilities.status}
    mapConfiguration: ${mapConfiguration.status}
    userDetails: ${userDetails.status}
  `;
  useEffect(() => {
    if (!isUnauthorised) return;
    logger.error('Unauthorised', logMessage);
    throw new Error('Unauthorised');
  }, [isUnauthorised, logMessage]);
  return <>{children}</>;
};

const Unauthorised = () => {
  const { l10n } = useI18n('app');
  const loginUrl = getLoginUrl();
  return (
    <ProtectedWrapper>
      <UnauthorisedMessage>{l10n('unauthorised')}</UnauthorisedMessage>
      <LoginButton href={loginUrl}>{l10n('action.logIn')}</LoginButton>
    </ProtectedWrapper>
  );
};
