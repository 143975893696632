import { gql } from '@apollo/client';

export const UPDATE_ALERT_RESPONSE = gql(`
  mutation UpdateAlertResponse($input: UpdateClassifiedAlertEvent!) {
    facility {
      alertResponseClassification {
        updateAlertEventResponse(input: $input) {
          alertEventId
        }
      }
    }
  }
`);
