import { useCallback } from 'react';
import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom';
import { useAllSearchParams } from './useAllSearchParams';

export function useClearSearchParams<Key extends string>(searchParamKeys: Array<Key>) {
  const [, setSearchParams] = useReactRouterSearchParams();
  const allSearchParams = useAllSearchParams();
  return useCallback(
    (specificKeys?: Array<Key>) => {
      const newParams = { ...allSearchParams };
      (specificKeys && specificKeys.length > 0 ? specificKeys : searchParamKeys).forEach(key => {
        delete newParams[key];
      });
      setSearchParams(newParams);
    },
    [allSearchParams, searchParamKeys, setSearchParams]
  );
}
