const modellingRoot = '/modelling';

const models = (root: string) => () => {
  return `${root}${modellingRoot}/models`;
};

const createNew = (root: string) => () => {
  return `${root}${modellingRoot}/models/add`;
};

const edit = (root: string) => () => {
  return `${root}${modellingRoot}/models/:id/edit`;
};

const base = (root: string) => () => {
  return `${root}${modellingRoot}/models/:id/:page`;
};

const aermet = (root: string) => () => {
  return `${root}${modellingRoot}/aermet`;
};

const modelStatus = (root: string) => () => {
  return `${root}${modellingRoot}/model-status`;
};

const emissionData = (root: string) => () => {
  return `${root}${modellingRoot}/emission-data`;
};

export const configurationModellingRoutes = (root: string) => ({
  root: `${root}${modellingRoot}`,
  models: models(root),
  createNew: createNew(root),
  edit: edit(root),
  base: base(root),
  aermet: aermet(root),
  modelStatus: modelStatus(root),
  emissionData: emissionData(root),
});
