// eslint-disable-next-line import/no-named-as-default
import i18n, { TOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import { useI18n } from './core/hooks/useI18n';
import { AppSettings } from 'core/AppSettings';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    backend: {
      loadPath: (lngs: any, namespaces: any) => {
        const randomHash = AppSettings.frontendVersion;
        const cacheBuster = randomHash ? `?v=${randomHash}` : '';
        return `/_i18n/${namespaces}/_i18n/l10n.${lngs}.json${cacheBuster}`;
      },
    },
    // debug: true,
    defaultNS: 'app',
    detection: {
      // order and from where user language should be detected
      order: ['navigator'],
    },
    fallbackLng: 'en',
    load: 'languageOnly',
    ns: ['app'],
    preload: ['en'],
    react: {
      useSuspense: true,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
    },
    interpolation: {
      escapeValue: false,
    },
  });

type UseI18n = ReturnType<typeof useI18n>;

export type Localiser = UseI18n['l10n'];

export { i18n as i18nInstance };

/**
 * Creates an l10n localiser outside of the scope of a functional component. This can be used when localisation is
 * required but you cannot use the `useI18n` hook.
 *
 * The parameters for the method are the same as are used in the `useI18n` hook, e.g:
 * ```
 * const l10n = createl10n('app/components', 'some.namespace');
 * ```
 * @param namespace Specifies the namespace to load the i18n'ed values from
 * @param prefix Optional prefix to add to the beginning of each key
 */
export const createl10n = (namespace: string | string[], prefix?: string) => {
  // noinspection JSIgnoredPromiseFromCall
  i18n.loadNamespaces(namespace);

  return (key: string, options?: TOptions) => {
    const keyParts = [];

    const hasNamespace = /:/.test(key);
    if (!hasNamespace) {
      keyParts.push(Array.isArray(namespace) ? namespace[0] : namespace);
      keyParts.push(':');
    }

    if (prefix) {
      keyParts.push(`${prefix}.`);
    }

    keyParts.push(key);
    const qualifiedKey = keyParts.join('');

    return i18n.t(qualifiedKey, options);
  };
};
