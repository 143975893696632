import { IMapConfiguration, LatLngTuple } from 'app/business-logic/domain-models/Facility';
import { ApolloProvider } from 'core/net/ApolloProvider';

import { FETCH_FACILITY_MAP_CONFIGURATION } from './graphql/facility.fetchFacilityMapConfiguration';
import { FetchFacilityMapConfigurationQuery } from 'app/__generated__/global';

const DEFAULT_MAP_CONFIGURATION = {
  center: [0, 0] as LatLngTuple,
  detailZoomLevels: [],
  mapLogoImage: null,
};

export async function fetchFacilityMapConfiguration({
  companyAlias,
  facilityAlias,
}: {
  companyAlias: string;
  facilityAlias: string;
}): Promise<IMapConfiguration> {
  const { data } = await ApolloProvider.global({
    companyAlias,
    facilityAlias,
  }).query<FetchFacilityMapConfigurationQuery>({
    query: FETCH_FACILITY_MAP_CONFIGURATION,
    errorPolicy: 'all',
  });
  const mapLogoImage = data.facility?.mapLogoImage;

  return { ...DEFAULT_MAP_CONFIGURATION, mapLogoImage: mapLogoImage?.data ?? null };
}
