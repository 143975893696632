import { styled, css } from 'styled-components';
import { srOnly } from 'app/visuals/mixins/srOnly';
import check from './assets/check.svg';
import Color from 'color';

const CHECKBOX_SIZE = 20;
const CHECKBOX_CHECK_SIZE = 10;

type CheckboxSize = 'small' | 'medium' | 'large';

type CheckboxInputProps = {
  $hasError?: boolean;
  $size?: CheckboxSize;
};

const sharedCheckboxStyles = css<CheckboxInputProps>`
  content: '';
  display: inline-block;
  width: ${({ $size }) => ($size === 'small' ? 16 : $size === 'large' ? 24 : CHECKBOX_SIZE)}px;
  height: ${({ $size }) => ($size === 'small' ? 16 : $size === 'large' ? 24 : CHECKBOX_SIZE)}px;
  border: 1px solid transparent;
  border-radius: 3px;
  flex-shrink: 0;
`;

const checkedCheckboxStyles = css`
  border-color: ${({ theme }) => theme.colors.brand.brand02};
  background-color: ${({ theme }) => theme.colors.brand.brand02};
  background-image: ${() => `url("${check}")`};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: ${CHECKBOX_CHECK_SIZE}px;
`;

const uncheckedCheckboxStyles = css`
  border-color: ${({ theme }) => theme.colors.mono.text03};
`;

const focusedCheckboxStyles = css`
  border-color: ${({ theme }) => theme.colors.brand.brand02};
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.brand.brand02};
`;

const errorCheckboxStyles = css`
  border-color: ${({ theme }) => theme.colors.feedback.error};
  background-color: ${({ theme }) => Color(theme.colors.feedback.error).fade(0.65).toString()};
`;

const disabledCheckboxStyles = css`
  background-color: ${({ theme }) => theme.colors.mono.ui04};
  border-color: ${({ theme }) => theme.colors.mono.ui05};
`;

export const CheckboxLabel = styled.label`
  display: block;
  cursor: pointer;
  user-select: none;
  & + & {
    margin-top: ${({ theme }) => theme.spacing()}px;
  }
`;

export const CheckboxLabelText = styled.span`
  display: flex;
  align-items: center;
`;

export const CheckboxLabelTextInner = styled.span<{
  $size?: CheckboxSize;
}>`
  font-size: ${({ $size }) => ($size === 'small' ? '1.3' : $size === 'large' ? '1.8' : '1.6')}rem;
  padding-left: ${({ theme }) => theme.spacing()}px;
  color: ${({ theme }) => theme.colors.mono.text01};
`;

export const CheckboxInput = styled.input.attrs({ type: 'checkbox' })<CheckboxInputProps>`
  ${srOnly}
  & ~ ${CheckboxLabelText} {
    &::before {
      ${sharedCheckboxStyles}
      ${uncheckedCheckboxStyles}
      ${({ $hasError }) => $hasError && errorCheckboxStyles}
    }
  }
  &:checked ~ ${CheckboxLabelText} {
    &::before {
      ${sharedCheckboxStyles}
      ${checkedCheckboxStyles}
      ${({ $hasError }) => $hasError && errorCheckboxStyles}
    }
  }
  &:focus-visible ~ ${CheckboxLabelText} {
    &::before {
      ${sharedCheckboxStyles}
      ${focusedCheckboxStyles}
      ${({ $hasError }) => $hasError && errorCheckboxStyles}
    }
  }
  &:disabled ~ ${CheckboxLabelText} {
    cursor: not-allowed;
    &::before {
      ${sharedCheckboxStyles}
      ${disabledCheckboxStyles}
    }
    ${CheckboxLabelTextInner} {
      opacity: 0.75;
    }
  }
`;
