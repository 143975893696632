import { gql } from '@apollo/client';

export const FETCH_MAP_MONITORS = gql`
  query FetchMapMonitors($filter: ProcessFilter) {
    facility {
      id
      processes(filter: $filter) {
        id
        name
        type
        omnisLocationId
        omnisConfigJson
        ... on Monitor {
          id
          location {
            latitude
            longitude
            elevation
          }
        }
      }
    }
  }
`;
