import { useAlertResponseClassifications } from 'app/views/configuration/pages/alert/alert-responses/alert-responses-list/useAlertResponseClassifications';
import { useAlertById } from '../../hooks';
import { useMemo } from 'react';

export const useDisplayedClassifications = (alertId: string) => {
  const { data: alertResponseClassifications, isFetching: isLoadingClassifications } =
    useAlertResponseClassifications();
  const { data, isLoading: isLoadingAlert } = useAlertById(alertId);
  const { alertTypeId } = data ?? {};
  return useMemo(
    () => ({
      data: alertResponseClassifications
        ?.filter(classification => {
          if (classification.utcDeactivatedAt) return false;
          if (
            !classification.appliedCategories.map(({ categoryId }) => alertTypeId && categoryId).includes(alertTypeId)
          )
            return false;
          return true;
        })
        .sort((a, b) => {
          const { appliedCategories: aCategories } = a;
          const { appliedCategories: bCategories } = b;
          const aCategory = aCategories.find(c => c.categoryId === alertTypeId);
          const bCategory = bCategories.find(c => c.categoryId === alertTypeId);
          if (!aCategory || !bCategory) return 0;
          return aCategory?.sortOrder - bCategory?.sortOrder;
        }),
      isLoading: isLoadingAlert || isLoadingClassifications,
    }),
    [alertResponseClassifications, isLoadingAlert, isLoadingClassifications, alertTypeId]
  );
};
