import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { LinearProgress } from '../linear-progress';
import Logo from 'app/visuals/images/logo-symbol.svg?react';
import { StyledThrobber, ThrobberInner, ThrobberLeft, ThrobberRight } from './Throbber.styles';

type Props = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    linearProgressHeight?: number;
  }>;

export const Throbber = ({ children, linearProgressHeight = 2, ...rest }: Props) => {
  return (
    <StyledThrobber {...rest}>
      <ThrobberInner>
        <ThrobberLeft>
          <Logo />
        </ThrobberLeft>
        {children && <ThrobberRight>{children}</ThrobberRight>}
      </ThrobberInner>
      <LinearProgress height={linearProgressHeight} />
    </StyledThrobber>
  );
};
