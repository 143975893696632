import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGlobalState } from 'app/redux/reducer';
import { AccessibleFacility } from '../user/slice';
import { getPreferredFacility } from 'app/routing/protected/use-authenticator/getPreferredFacility';
import { LogManager } from 'core/logging/LogManager';
import { facilityChanged } from './actions';
import LocalTime from 'core/types/LocalTime';

type Payload = { accessibleFacilities: AccessibleFacility[]; clearCache: () => void };

const logger = LogManager.getLogger('setFacility');

export const setFacility = createAsyncThunk<void, Payload, { state: IGlobalState }>(
  'facility/setFacility',
  async ({ accessibleFacilities, clearCache }: Payload, { getState, dispatch }) => {
    const {
      profile: { facility },
    } = getState();
    const preferredFacility = getPreferredFacility({ accessibleFacilities, clearCache, persistedFacility: facility });
    if (!preferredFacility) {
      return logger.error('Failed to determine preferred facility');
    }
    const { companyAlias, facilityAlias, ianaTimezone } = preferredFacility;
    logger.debug(`Setting preferred facility: ${companyAlias}/${facilityAlias}`);
    dispatch(facilityChanged(preferredFacility));
    logger.debug(`Setting timezone to: ${ianaTimezone}`);
    LocalTime.IanaTimeZone = ianaTimezone;
  }
);
