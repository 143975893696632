import { TemporalDataSeries } from 'app/business-logic/domain-models/Monitoring';
import { LogManager } from 'core/logging/LogManager';
import LocalTime from 'core/types/LocalTime';

const logger = LogManager.getLogger('SensorDataChunk');

export const CHUNK_EXPIRY_TIME_MILLIS = 60000;

export default class SensorDataChunk {
  protected temporalData!: TemporalDataSeries;
  private lastAccessedMillis: number;

  public startMillis!: number;
  public endMillis!: number;

  constructor(data?: TemporalDataSeries) {
    this.lastAccessedMillis = new Date().valueOf();
    if (!data) return;
    this.temporalData = data;
  }

  public get data() {
    this.lastAccessedMillis = new Date().valueOf();
    return this.temporalData;
  }

  public hasExpired(/*nowMillis: number*/) {
    // return !this.lastAccessedMillis || this.lastAccessedMillis + CHUNK_EXPIRY_TIME_MILLIS < nowMillis;
    return false;
  }

  /**
   * Adds a point to the beginning of the chunk and extends the start millis for the chunk to cover this point.
   *
   * @param timeMillis The data point time
   * @param value The data point value
   */
  public unshift(timeMillis: number, value: number) {
    this.temporalData.unshift([timeMillis, value]);
    this.startMillis = timeMillis;
  }

  public canMerge(other: SensorDataChunk) {
    return (
      this.startMillis <= other.endMillis &&
      this.endMillis >= other.startMillis &&
      this.temporalData !== null &&
      other.temporalData !== null
    );
  }

  public merge(other: SensorDataChunk): SensorDataChunk {
    logger.debug(
      'Merging chunks:\n' +
        `  * ${LocalTime.fromMillis(this.startMillis)} - ${LocalTime.fromMillis(this.endMillis)}\n` +
        `  * ${LocalTime.fromMillis(other.startMillis)} - ${LocalTime.fromMillis(other.endMillis)}`
    );

    this.lastAccessedMillis = Math.max(this.lastAccessedMillis, other.lastAccessedMillis);
    this.startMillis = Math.min(this.startMillis, other.startMillis);
    this.endMillis = Math.max(this.endMillis, other.endMillis);

    Array.prototype.push.apply(this.temporalData, other.temporalData);

    this.temporalData = this.temporalData
      // The data is already mostly sorted so this sort should be fairly fast (at least in Chrome, which uses
      // Timsort since v70 - https://v8.dev/blog/array-sort)
      .sort(([leftMillis], [rightMillis]) => leftMillis - rightMillis)

      // Now that the data is sorted, we can just drop any entry that has the same end time as the one before it
      // in order to filter out the duplicates.
      .filter(([timeMillis], index) => {
        const value = this.temporalData[index - 1]?.[0];
        if (typeof value !== 'number') return false;
        return index === 0 || timeMillis > value;
      });

    return this;
  }
}
