import { styled } from 'styled-components';

export const ScenarioCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme: { colors } }) => colors.mono.ui03};
  border: 1px solid ${({ theme }) => theme.colors.mono.ui05};
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme: { spacing } }) => spacing()}px;
`;

export const ScenarioCardTitle = styled.h2`
  margin-top: 0;
  font-size: 1.6rem;
`;

export const Parameter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme: { spacing } }) => spacing()}px;
`;

export const Label = styled.h3`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.mono.text03};
  line-height: 1.2;
  margin: 0;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
`;

export const Value = styled.p`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin: auto 0;
  text-align: right;
`;
