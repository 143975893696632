import Guid from 'core/types/Guid';

import { fetchAlertHistory } from '../../pages/alert-history/use-alert-history/fetchAlertHistory';

export const fetchAlertById = async (alertId: Guid) => {
  const { results: alertLogHistory } = await fetchAlertHistory({
    paging: {
      page: 1,
      pageSize: 1,
    },
    filter: {
      alertEventIds: [alertId],
    },
  });
  if (alertLogHistory.length === 0) throw new Error(`No alert found by ID "${alertId}"`);
  return alertLogHistory[0];
};
