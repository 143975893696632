import { Container, Key, Label, LegendValue } from './Legend.styles';

export type LegendItem = { label?: number; color: string; dashWidth?: number };

interface LegendProps {
  legendItems: LegendItem[];
}

const countDecimals = (value: number) =>
  Math.floor(value) === value ? 0 : value.toString().split('.')?.[1]?.length || 0;

// Need different formatting conditions for numbers in scientific notation and rational numbers
const formatLegendLabel = (value?: number) => {
  if (!value) return;

  if (String(value).indexOf('e+') !== -1) {
    return String(value?.toPrecision(1));
  }
  // round to 3 decimal places if value has more than 3 decimal places
  if (countDecimals(value) > 3) {
    return String(value?.toFixed(3));
  } else {
    return String(value);
  }
};

export const Legend = ({ legendItems }: LegendProps) => {
  return (
    <Container>
      {legendItems.map(({ label, color, dashWidth = 1 }, index) => (
        <LegendValue key={index}>
          <Key $color={color} $dashWidth={dashWidth} />
          <Label>{formatLegendLabel(label)}</Label>
        </LegendValue>
      ))}
    </Container>
  );
};
