import type { GroupBase, StylesConfig as ReactSelectStylesConfig } from 'react-select';
import { useMemo, useCallback } from 'react';
import { useThemeContext } from 'app/theme/useThemeContext';
import { CustomProps, DefaultOption } from './Select';
import { THEME } from 'app/theme/theme';

export type StylesConfig<
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = ReactSelectStylesConfig<Option, IsMulti, Group>;
export type ComponentStyles<
  Option = DefaultOption,
  T extends keyof StylesConfig<Option> = keyof StylesConfig<Option>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = NonNullable<StylesConfig<Option, IsMulti, Group>[T]>;

export const HORIZONTAL_OPTION_PADDING = THEME.spacing(1.5);

const useControl = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  isError,
}: CustomProps) => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'control', IsMulti, Group>>(
    (base, props) => ({
      ...base,
      zIndex: 3,
      backgroundColor: theme.colors.mono.ui03,
      border: `1px solid ${theme.colors.mono.ui04}`,
      fontSize: '1.4rem',
      paddingRight: `${theme.spacing(0.5)}px`,
      ...(isError && {
        border: `1px solid ${theme.colors.feedback.error}`,
      }),
      ...(props.isFocused && {
        border: `1px solid ${theme.colors.mono.ui05}`,
        boxShadow: 'none',
        ...(isError && {
          border: `1px solid ${theme.colors.feedback.error}`,
        }),
      }),
      ...(props.isDisabled && {
        backgroundColor: theme.colors.mono.ui03,
      }),
      '&:hover': {
        border: `1px solid ${theme.colors.mono.ui05}`,
        ...(isError && {
          border: `1px solid ${theme.colors.feedback.error}`,
        }),
      },
    }),
    [isError, theme]
  );
};

const useDropdownIndicator = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'dropdownIndicator', IsMulti, Group>>(
    base => ({
      ...base,
      svg: {
        color: theme.colors.mono.ui06,
      },
    }),
    [theme]
  );
};

const useIndicatorSeparator = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  return useCallback<ComponentStyles<Option, 'indicatorSeparator', IsMulti, Group>>(
    base => ({
      ...base,
      background: 'none',
    }),
    []
  );
};

const useInput = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'input', IsMulti, Group>>(
    base => ({
      ...base,
      height: 'auto',
      color: theme.colors.mono.text01,
    }),
    [theme]
  );
};

const useMenu = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'menu', IsMulti, Group>>(
    base => ({
      ...base,
      fontSize: '1.4rem',
      backgroundColor: theme.colors.mono.ui03,
      color: theme.colors.mono.text01,
      marginTop: -1,
      padding: theme.spacing(0.5),
      border: `1px solid ${theme.colors.mono.ui04}`,
      zIndex: 4,
      width: 'auto',
    }),
    [theme]
  );
};

const useOption = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'option', IsMulti, Group>>(
    (base, props) => ({
      ...base,
      padding: `${theme.spacing()}px ${HORIZONTAL_OPTION_PADDING}px`,
      backgroundColor: theme.colors.mono.ui03,
      fontSize: `1.4rem`,
      borderRadius: '4px',
      cursor: 'pointer',
      ...(props.isFocused && {
        backgroundColor: theme.colors.mono.ui04,
      }),
      '&:hover': {
        backgroundColor: theme.colors.mono.ui04,
      },
      ...(props.isSelected && {
        backgroundColor: theme.colors.brand.brand01,
        fontWeight: theme.font.default.weight.normal,
        color: theme.colors.mono.text01,
        cursor: 'default',
        ...(props.isFocused && {
          '&:hover': {
            backgroundColor: theme.colors.brand.brand01,
          },
        }),
      }),
      ...(props.isDisabled && {
        cursor: 'default',
        span: {
          cursor: 'default',
        },
      }),
    }),
    [theme]
  );
};

const usePlaceholder = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'placeholder', IsMulti, Group>>(
    base => ({
      ...base,
      color: theme.colors.mono.text01,
    }),
    [theme]
  );
};

const useSingleValue = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'singleValue', IsMulti, Group>>(
    (base, props) => ({
      ...base,
      color: theme.colors.mono.text01,
      ...(props.isDisabled && {
        color: theme.colors.mono.ui05,
      }),
    }),
    [theme]
  );
};

const useMultiValueRemove = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>() => {
  const theme = useThemeContext();
  return useCallback<ComponentStyles<Option, 'multiValueRemove', IsMulti, Group>>(
    base => ({
      ...base,
      color: theme.colors.mono.ui05,
    }),
    [theme]
  );
};

export const useSelectStyles = <
  Option = DefaultOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: CustomProps
): StylesConfig<Option, IsMulti, Group> => {
  const control = useControl<Option, IsMulti, Group>(props);
  const dropdownIndicator = useDropdownIndicator<Option, IsMulti, Group>();
  const indicatorSeparator = useIndicatorSeparator<Option, IsMulti, Group>();
  const input = useInput<Option, IsMulti, Group>();
  const option = useOption<Option, IsMulti, Group>();
  const menu = useMenu<Option, IsMulti, Group>();
  const placeholder = usePlaceholder<Option, IsMulti, Group>();
  const singleValue = useSingleValue<Option, IsMulti, Group>();
  const multiValueRemove = useMultiValueRemove<Option, IsMulti, Group>();
  return useMemo(
    () => ({
      control,
      dropdownIndicator,
      indicatorSeparator,
      input,
      option,
      menu,
      placeholder,
      singleValue,
      multiValueRemove,
    }),
    [control, dropdownIndicator, indicatorSeparator, input, option, menu, placeholder, singleValue, multiValueRemove]
  );
};
