import { useNoiseEventClassificationCategories } from 'app/views/configuration/pages/noise/noise-classifications/use-noise-event-classifications';
import Guid from 'core/types/Guid';
import { useMemo } from 'react';

export const useClassificationIconSlugs = ({ categoryIds }: { categoryIds: Guid[] }) => {
  const { categories } = useNoiseEventClassificationCategories();
  return useMemo(
    () =>
      categoryIds.flatMap(id => {
        const category = categories?.find(cat => cat.id === id);
        if (!category) return [];
        return [category.slug];
      }),
    [categories, categoryIds]
  );
};
