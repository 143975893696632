import { IMeasurement, ISensorDataSeries } from 'app/business-logic/domain-models/Monitoring';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';
import LocalTime from 'core/types/LocalTime';
import { DateTime } from 'luxon';

import { FETCH_SENSOR_DATA_FOR_SENSOR_GROUPS } from './graphql/facility.fetchSensorDataForSensorGroups';
import { FETCH_SENSOR_DATA_FOR_SENSORS } from './graphql/facility.fetchSensorDataForSensors';
import { isStale } from './helpers/isStale';
import {
  FetchSensorDataForSensorGroupsQuery,
  FetchSensorDataForSensorGroupsQueryVariables,
  FetchSensorDataForSensorsQuery,
  FetchSensorDataForSensorsQueryVariables,
} from 'app/__generated__/global';

type Aggregation = NonNullable<FetchSensorDataForSensorsQueryVariables['dataFilter']>['aggregate'];

const ALLOW_STALE_DATA = true;

export async function fetchSensorDataForSensorGroup(
  sensorGroupId: Guid,
  from: LocalTime,
  to: LocalTime
): Promise<ISensorDataSeries[]> {
  const startTime = from.toISO();
  const endTime = to.toISO();

  if (!startTime || !endTime) throw new Error(`Invalid date range; From: ${startTime}, To: ${endTime}`);

  const variables: FetchSensorDataForSensorGroupsQueryVariables = {
    sensorGroupFilter: { id_in: [sensorGroupId] },
    dataFilter: {
      endTime_between: {
        startTime,
        endTime,
      },
      allowStaleData: ALLOW_STALE_DATA,
    },
  };

  const { data } = await ApolloProvider.global().query<
    FetchSensorDataForSensorGroupsQuery,
    FetchSensorDataForSensorGroupsQueryVariables
  >({
    query: FETCH_SENSOR_DATA_FOR_SENSOR_GROUPS,
    variables,
    fetchPolicy: 'no-cache',
  });

  const [measurementGroup] = data?.facility?.measurementGroups ?? [];

  if (!measurementGroup) return [];

  return measurementGroup.processVariables.reduce((dataSeries, processVariable) => {
    const { id, variableData, staleDataTimeoutSeconds } = processVariable;
    const data = variableData?.data ?? [];

    dataSeries.push({
      sensorId: id,
      staleDataTimeoutSeconds,
      data,
    });

    return dataSeries;
  }, [] as ISensorDataSeries[]);
}

export async function fetchSpotDataForSensors(sensors: Guid[], spotTime: DateTime): Promise<IMeasurement[]> {
  const data = await fetchSensorData(sensors, spotTime);
  if (!data) return [];

  return (
    data.facility?.processVariables.reduce((measurements, processVariable) => {
      const { id, variableData, staleDataTimeoutSeconds } = processVariable;
      const variableMeasurements =
        variableData?.data.map(([timeMillis, value]) => {
          return {
            sensorId: id,
            timeMillis,
            value,
            isStale: isStale({
              dataTimeMillis: timeMillis,
              staleDataTimeoutSeconds,
              facilityTimeMillis: spotTime.toMillis(),
            }),
          };
        }) ?? [];

      // Turns out using push is way faster than using array concat.
      // https://dev.to/uilicious/javascript-array-push-is-945x-faster-than-array-concat-1oki
      Array.prototype.push.apply(measurements, variableMeasurements);
      return measurements;
    }, [] as IMeasurement[]) ?? []
  );
}

export async function fetchSensorDataSeries(
  sensors: Guid[],
  startTime: DateTime,
  endTime?: DateTime,
  aggregate?: Aggregation
): Promise<ISensorDataSeries[]> {
  const data = await fetchSensorData(sensors, startTime, endTime, aggregate);

  const processVariables = data?.facility?.processVariables;

  if (!processVariables) return [];

  return processVariables.map(({ id, variableData, staleDataTimeoutSeconds }) => {
    return {
      sensorId: id,
      staleDataTimeoutSeconds,
      data: variableData?.data ?? [],
    };
  });
}

async function fetchSensorData(
  sensors: Guid[],
  from: DateTime,
  to: DateTime | null = null,
  aggregate: Aggregation | null = null
) {
  if (!(sensors && sensors.length)) {
    throw new Error('You must query on at least one sensor.');
  }

  const sensorFilter = { id_in: sensors };

  let dataFilter: NonNullable<FetchSensorDataForSensorsQueryVariables['dataFilter']>;

  const startTime = from.toUTC().toISO();
  const endTime = to?.toUTC().toISO();

  if (!startTime) throw new Error(`Invalid start time: ${startTime}`);

  if (endTime) {
    dataFilter = {
      aggregate,
      endTime_between: {
        startTime,
        endTime,
      },
    };
  } else {
    dataFilter = {
      spotTime: from.toUTC().toISO(),
      allowStaleData: ALLOW_STALE_DATA,
    };
  }

  try {
    const { data } = await ApolloProvider.global().query<
      FetchSensorDataForSensorsQuery,
      FetchSensorDataForSensorsQueryVariables
    >({
      query: FETCH_SENSOR_DATA_FOR_SENSORS,
      variables: { sensorFilter, dataFilter },
      fetchPolicy: 'no-cache',
    });
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
