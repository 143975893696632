import { NoResults } from 'app/views/alerts/pages/alert-history/alert-history-no-results/AlertHistoryNoResults.styles';
import { useI18n } from 'core/hooks/useI18n';
import { useEventsListingParams } from './useEventsListingParams';

export const EventsListingNoResults = () => {
  const {
    searchParams: { from, monitor, classification, 'classification-state': classificationState },
  } = useEventsListingParams();
  const parameterCount = [from, monitor, classification, classificationState].filter(
    value => value && value.length > 0
  ).length;
  const { l10n } = useI18n('app/views/alerts', 'eventsListing.results.noResults');
  return (
    <NoResults>
      {parameterCount === 0 && l10n('noFilters')}
      {parameterCount === 1 && l10n('oneFilter')}
      {parameterCount > 1 && l10n('severalFilters')}
    </NoResults>
  );
};
