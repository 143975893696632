import { Spinner, Classes } from '@blueprintjs/core';
import { styled } from 'styled-components';

export const StyledSpinner = styled(Spinner)<{ $color?: string }>`
  && {
    .${Classes.SPINNER_HEAD} {
      stroke: ${({ theme, $color }) => ($color ? $color : theme.colors.brand.brand01)};
    }
    .${Classes.SPINNER_TRACK} {
      stroke: transparent;
    }
  }
`;
