import { useLocalTime } from 'app/hooks/use-local-time';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { closeDrawer, selectAlert } from 'app/redux/ui/actions';
import { useAlertHistory } from 'app/views/alerts/pages/alert-history/use-alert-history';
import { MouseEvent, useCallback, useLayoutEffect, useRef } from 'react';
import {
  AlertCardFooter,
  AlertCardWrapper,
  AlertDescription,
  AlertTime,
  AlertTitle,
  AlertUnit,
  AlertValue,
  AlertValueWrapper,
} from './AlertsCard.styles';

type AlertsCardProps = {
  alert: NonNullable<ReturnType<typeof useAlertHistory>['data']>['results'][number];
  index: number;
};

export const AlertsCard = ({ alert, index }: AlertsCardProps) => {
  const dispatch = useAppDispatch();
  const itemRef = useAlertCardAnimation(index);
  const { id, name, time, displayUnit, value, threshold, monitor } = alert;
  const alertTimeTriggered = useLocalTime(time).toRelative();
  const isMobile = useIsMobile();
  const { selectedAlert } = useAppSelector(state => state.ui);
  const handleOpenEventsDrawer = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      dispatch(selectAlert(id));
      if (isMobile) {
        dispatch(closeDrawer('ALERTS'));
      }
    },
    [id, dispatch, isMobile]
  );

  return (
    <AlertCardWrapper
      data-testid="alert-card"
      onClick={handleOpenEventsDrawer}
      $thresholdColour={threshold.color}
      ref={itemRef}
      $selected={selectedAlert === id}
    >
      <AlertTitle>{name}</AlertTitle>
      <AlertDescription>{monitor.name}</AlertDescription>
      <AlertCardFooter>
        <AlertValueWrapper>
          {typeof value === 'number' && typeof displayUnit.name === 'string' && (
            <>
              <AlertValue $textColor={threshold?.color}>{parseFloat(value.toFixed(2))}</AlertValue>
              <AlertUnit>{displayUnit.name}</AlertUnit>
            </>
          )}
        </AlertValueWrapper>
        <AlertTime>{alertTimeTriggered}</AlertTime>
      </AlertCardFooter>
    </AlertCardWrapper>
  );
};

const useAlertCardAnimation = (index: number) => {
  const previousIndexRef = useRef<number>(-1);
  const itemRef = useRef<HTMLButtonElement>(null);
  useLayoutEffect(() => {
    if (!itemRef.current) return;
    if (previousIndexRef.current === -1) {
      previousIndexRef.current = index;
      return;
    }
    if (previousIndexRef.current === index) return;
    const slideOutAnimation = itemRef.current.animate(
      [
        { transform: `translateY(${itemRef.current.getBoundingClientRect().height}px)` },
        { transform: 'translateY(0)' },
      ],
      {
        duration: 300,
        easing: 'ease-in-out',
      }
    );
    return () => slideOutAnimation.cancel();
  }, [index]);
  return itemRef;
};
