import { useQuery } from '@tanstack/react-query';
import { getAlertConfigurations } from 'app/business-logic/services/alerts-services/getAlertConfigurations';
import { QUERY_KEY } from 'app/business-logic/services/enums';

export const useAlertConfigurations = () => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEY.alertConfigurations],
    queryFn: () => getAlertConfigurations(),
  });
  return {
    data,
    isFetching,
    isLoading,
  };
};
