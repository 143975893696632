import { Component, ErrorInfo } from 'react';
import { UnexpectedError } from 'app/components/error/UnexpectedError';
import { LogManager } from 'core/logging/LogManager';

interface IState {
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

type IProps = React.PropsWithChildren<Record<never, never>>;

const logger = LogManager.getLogger('ErrorBoundary');

const handleError = (error: Error, componentStack: ErrorInfo) => {
  logger.error(error.message, error.name, error.stack, componentStack);
};

export default class ErrorBoundary extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    handleError(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      const { error, errorInfo } = this.state;

      return <UnexpectedError error={error} componentStack={errorInfo?.componentStack ?? ''} />;
    }

    return this.props.children;
  }
}
