import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { fetchBlastScenario } from 'app/business-logic/services/blasting-service';
import { IBlastScenario, BlastScenarioResponse } from 'app/business-logic/domain-models/Blasting';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useMemo } from 'react';

export const useFetchBlastingScenario = (alias?: string) => {
  const { data, isLoading, isSuccess, refetch, isRefetching } = useQuery<BlastScenarioResponse | null>({
    queryKey: [QUERY_KEY.individualBlastingScenario, alias],
    queryFn: () => fetchBlastScenario(alias),
    enabled: !!alias,
    refetchOnMount: false,
  });
  const scenario = useMapBlastingScenario(data);
  return useMemo(() => {
    return {
      data: scenario,
      isLoading,
      isSuccess,
      refetch,
      isRefetching,
    };
  }, [scenario, isLoading, isSuccess, refetch, isRefetching]);
};

function useMapBlastingScenario(scenario?: BlastScenarioResponse | null): IBlastScenario {
  const localTimeCallback = useLocalTimeCallback();

  return useMemo(() => {
    if (!scenario) {
      return {} as IBlastScenario;
    }
    const { utcBlastTimes, utcLastUpdatedDateTime, utcActualBlastTime, ...others } = scenario;

    return {
      ...others,
      blastTimes: utcBlastTimes?.map(blastTime => localTimeCallback(blastTime)) ?? [],
      lastUpdatedTime: localTimeCallback(utcLastUpdatedDateTime),
      actualBlastTime: utcActualBlastTime ? localTimeCallback(utcActualBlastTime) : null,
    };
  }, [localTimeCallback, scenario]);
}
