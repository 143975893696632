import { SVGProps, DetailedHTMLProps, HTMLAttributes } from 'react';
import { AssetType, AssetValue, icons } from './Assets';

type IconLibrary = Record<AssetType, TIcon>;
type ElementProps = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & SVGProps<SVGSVGElement>;

export type TIcon = ReturnType<typeof getStyledIcon>;

export const Icon = Object.entries(icons).reduce<IconLibrary>(
  (accumulator, [iconName, Icon]) => ({
    ...accumulator,
    [iconName]: getStyledIcon(Icon),
  }),
  {} as IconLibrary
);

function getStyledIcon(Icon: AssetValue) {
  const Comp = ({ width, height, style, ...rest }: { width?: string; height?: string } & ElementProps) => (
    <Icon
      {...rest}
      style={{
        display: 'inline-block',
        width: width ?? `1em`,
        height: height ?? `1em`,
        fill: 'currentColor',
        ...style,
      }}
    />
  );
  return Comp;
}
