import { gql } from '@apollo/client';

export const GET_ALERT_HISTORY = gql(`
  query GetAlertHistory($paging: PagingInput!, $filter: GetFilterablePagedAlertEventsRequest!) {
    facility {
      alerts {
        alertListing(paging: $paging, filter: $filter) {
          page
          recordCount
          previous
          next
          results {
            id
            alertScriptConfigurationId
            alertScriptConfigurationName
            alertTypeId
            utcTriggeredTime
            longMessage
            utcResponseTime
            responder {
              id
              email
              firstName
              lastName
					  }
            appliedClassifications {
              id
              name
            }
            triggerSnaphot {
              processVariableId
              values
            }
            inputSnapshot {
              processVariableName
              processVariableId
              isTrace
              processId
              processName
              displayUnitId
              displayUnitName: alertDisplayUnitName
              threshold {
                id
                value
                color
                rank
              }
            }
          }
        }
      }
    }
  }
`);
