import { gql } from '@apollo/client';
import { SENSOR } from './fragment.sensor';

export const FETCH_GRAPH_SENSORS = gql`
  ${SENSOR}
  query FetchGraphSensors($filter: ProcessFilter) {
    facility {
      id
      processes(filter: $filter) {
        id
        ... on Monitor {
          displayConfig {
            graphVariables {
              processVariable {
                ...Sensor
              }
            }
          }
        }
      }
    }
  }
`;
