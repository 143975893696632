import { gql } from '@apollo/client';

export const FETCH_SENSOR_DATA_FOR_SENSOR_GROUPS = gql`
  query FetchSensorDataForSensorGroups($sensorGroupFilter: MeasurementGroupFilter, $dataFilter: VariableDataFilter) {
    facility {
      id
      measurementGroups(filter: $sensorGroupFilter) {
        id
        processVariables {
          id
          staleDataTimeoutSeconds
          variableData(filter: $dataFilter) {
            data
          }
        }
      }
    }
  }
`;
