import { gql } from '@apollo/client';

export const DELETE_CLASSIFICATION = gql(`
  mutation DeleteClassification($id: Guid!) {
    facility {
      classification {
        deleteClassification(id: $id)
      }
    }
  }
`);
