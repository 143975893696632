import { ApolloProvider } from 'core/net/ApolloProvider';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { useMemo } from 'react';
import Guid from 'core/types/Guid';
import { GET_ALERT_INPUT_VARIABLES } from './graphql/facility.getAlertInputVariables';
import { GetAlertInputVariablesQuery, GetAlertInputVariablesQueryVariables } from 'app/__generated__/global';

export const useTraceVariableIds = (sourceId?: Guid) => {
  const { data, ...rest } = useQuery({
    queryKey: [QUERY_KEY.alertInputVariables, sourceId],
    queryFn: () => {
      if (!sourceId) return;
      return getInputVariables(sourceId);
    },
    enabled: !!sourceId,
  });
  return useMemo(() => {
    return {
      data: extractTraceVariableIds(data?.data),
      ...rest,
    };
  }, [data, rest]);
};

function getInputVariables(sourceId: Guid) {
  const variables = {
    alertScriptConfigurationsFilter: {
      id_in: [sourceId],
    },
  };
  return ApolloProvider.global().query<GetAlertInputVariablesQuery, GetAlertInputVariablesQueryVariables>({
    query: GET_ALERT_INPUT_VARIABLES,
    variables,
  });
}

function extractTraceVariableIds(data: GetAlertInputVariablesQuery | undefined): string[] | undefined {
  if (!data) return;
  return data.facility?.alerts?.alertScriptConfigurations.flatMap(config =>
    config.variables.flatMap(variable => {
      if (!variable.isTraceVariable) return [];
      return variable.variable.id;
    })
  );
}
