import { DateTime, Settings } from 'luxon';

const DEFAULT_LOCALE = 'en-AU';

export const getLocalTime = (
  time: number | string | DateTime | undefined,
  ianaTimezone: string | undefined,
  locale: string = DEFAULT_LOCALE
) => {
  // Set default locale so tests run locally and on the build server are consistent
  Settings.defaultLocale = locale;
  if (typeof time === 'undefined') return DateTime.now().setZone(ianaTimezone).setLocale(locale);
  if (typeof time === 'number') return DateTime.fromMillis(time).setZone(ianaTimezone).setLocale(locale);
  if (typeof time === 'string') return DateTime.fromISO(time).setZone(ianaTimezone).setLocale(locale);
  return time.setZone(ianaTimezone).setLocale(locale);
};
