import { getLoginUrl } from 'app/business-logic/services/security-service/getLoginUrl';
import { store } from 'app/redux/store';
import { AppSettings } from 'core/AppSettings';
import { LogManager } from 'core/logging/LogManager';
import AuthParameters from 'core/net/AuthParameters';
import { stringify } from 'query-string';
import urlJoin from 'url-join';

import HttpResponse from './HttpResponse';

const logger = LogManager.getLogger('http');

const NON_JSON_RESPONSE_TYPES = ['image/png', 'audio/opus', 'audio/wav'] as const;

export const httpClient = {
  usePublicApi: {
    asAnonymous: anonymousHttpClient(AppSettings.publicApiUrl),
    asAuthorised: () => {
      const { companyAlias, facilityAlias } = getCurrentAuthContext();
      return authorisedHttpClient(AppSettings.publicApiUrl)(companyAlias, facilityAlias);
    },
    withCredentials: (companyAlias?: string, facilityAlias?: string) => {
      return authorisedHttpClient(AppSettings.publicApiUrl)(companyAlias, facilityAlias);
    },
  },
  useInternalApi: {
    anonymous: anonymousHttpClient(AppSettings.apiUrl),
    asAuthorised: () => {
      const { companyAlias, facilityAlias } = getCurrentAuthContext();
      return authorisedHttpClient(AppSettings.apiUrl)(companyAlias, facilityAlias);
    },
    withCredentials: (companyAlias?: string, facilityAlias?: string) => {
      return authorisedHttpClient(AppSettings.apiUrl)(companyAlias, facilityAlias);
    },
  },
  useArenaInternalApi: {
    anonymous: anonymousHttpClient(AppSettings.arenaApiUrl),
    asAuthorised: () => {
      const { companyAlias, facilityAlias } = getCurrentAuthContext();
      return authorisedHttpClient(AppSettings.arenaApiUrl)(companyAlias, facilityAlias);
    },
    withCredentials: (companyAlias?: string, facilityAlias?: string) => {
      return authorisedHttpClient(AppSettings.arenaApiUrl)(companyAlias, facilityAlias);
    },
  },
};

export default httpClient;

////////////////////

const NO_CONTENT = { data: null, messages: [] };

function anonymousHttpClient(baseUrl: string) {
  return () => ({
    get: (relativeUrl: string, queryStringParams?: Record<string, string>) => {
      const url = buildUrl(baseUrl, relativeUrl, queryStringParams);
      return anonymousHttpGet(url);
    },
    post: (relativeUrl: string, body: string) => {
      const url = buildUrl(baseUrl, relativeUrl);
      return anonymousHttpPost(url, body);
    },
  });

  /////

  function anonymousHttpGet(url: string) {
    const options = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      responseInterceptor: (response: HttpResponse) => response,
    };
    return fetchAsync(url, options);
  }

  function anonymousHttpPost(url: string, body: string) {
    const options = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
      responseInterceptor: (response: HttpResponse) => response,
    };
    return fetchAsync(url, options);
  }
}

function authorisedHttpClient(baseUrl: string) {
  const authorizedHttpPost = authorizedRequestWithBody('POST');
  const authorizedHttpPut = authorizedRequestWithBody('PUT');
  return (companyAlias?: string, facilityAlias?: string) => ({
    get: (relativeUrl: string, queryStringParams?: QueryStringParameters) => {
      const url = buildUrl(baseUrl, relativeUrl, queryStringParams);
      return authorizedHttpGet(url, companyAlias, facilityAlias);
    },
    post: (
      relativeUrl: string,
      body: string | FormData | unknown,
      queryStringParams?: QueryStringParameters,
      signal?: RequestInit['signal']
    ) => {
      const url = buildUrl(baseUrl, relativeUrl, queryStringParams);
      return authorizedHttpPost(url, body, companyAlias, facilityAlias, signal);
    },
    put: (
      relativeUrl: string,
      body: string | FormData | unknown,
      queryStringParams?: QueryStringParameters,
      signal?: RequestInit['signal']
    ) => {
      const url = buildUrl(baseUrl, relativeUrl, queryStringParams);
      return authorizedHttpPut(url, body, companyAlias, facilityAlias, signal);
    },
    delete: (relativeUrl: string, queryStringParams?: QueryStringParameters) => {
      const url = buildUrl(baseUrl, relativeUrl, queryStringParams);
      return authorizedHttpDelete(url, companyAlias, facilityAlias);
    },
  });

  function authorizedHttpGet(url: string, companyAlias?: string, facilityAlias?: string) {
    const options = {
      headers: AuthParameters.createRequestHeaders(companyAlias, facilityAlias),
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      responseInterceptor: authorizedResponseInterceptor,
    };
    return fetchAsync(url, options);
  }

  function authorizedHttpDelete(url: string, companyAlias?: string, facilityAlias?: string) {
    const options = {
      headers: AuthParameters.createRequestHeaders(companyAlias, facilityAlias),
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      responseInterceptor: authorizedResponseInterceptor,
    };
    return fetchAsync(url, options);
  }

  function authorizedRequestWithBody(verb: 'POST' | 'PUT') {
    return (
      url: string,
      body: string | FormData | unknown,
      companyAlias?: string,
      facilityAlias?: string,
      signal?: RequestInit['signal']
    ) => {
      const isFormData = body instanceof FormData;
      const headers = AuthParameters.createRequestHeaders(companyAlias, facilityAlias);
      const jsonHeaders = !isFormData
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          }
        : {};

      const options = {
        method: verb,
        credentials: 'include',
        mode: 'cors',
        headers: {
          ...headers,
          ...jsonHeaders,
        },
        body: isFormData ? body : JSON.stringify(body),
        responseInterceptor: authorizedResponseInterceptor,
        signal,
      };

      return fetchAsync(url, options);
    };
  }

  function authorizedResponseInterceptor(response: HttpResponse) {
    if (response.status === 401) {
      logger.error('Unauthorised request, redirecting to login page');
      window.location.href = getLoginUrl();
    }
    return response;
  }
}

type QueryStringParameters = Parameters<typeof stringify>[0];

const buildUrl = (urlBase: string, relativeUrl: string, queryStringParameters: QueryStringParameters = {}): string => {
  const params = stringify(queryStringParameters);
  return `${urlJoin(urlBase, relativeUrl)}${params ? `?${params}` : ''}`;
};

export async function fetchAsync(
  url: string,
  fetchOptions: { responseInterceptor: (res: HttpResponse) => HttpResponse } & Record<string, any>
): Promise<HttpResponse> {
  try {
    const { responseInterceptor, ...rest } = fetchOptions;
    const fetchResult = await fetch(url, rest);
    const response = new HttpResponse(fetchResult.status, await extractResponseBody(fetchResult));
    if (!fetchResult.ok) {
      logger.warn(`HTTP ${fetchOptions.method} to ${url} is not ok`);
    }
    return responseInterceptor(response);
  } catch (error) {
    console.error(error);
    return new HttpResponse(-1, null, error);
  }

  async function extractResponseBody(fetchResult: Response) {
    try {
      if (fetchResult.ok) {
        const isNotJson = NON_JSON_RESPONSE_TYPES.some(type => fetchResult.headers.get('Content-Type')?.includes(type));
        if (isNotJson) {
          return await fetchResult.blob();
        }

        if (fetchResult.status === 204 || fetchResult.statusText === 'No Content') {
          return NO_CONTENT;
        }

        return await fetchResult.json();
      }

      return await fetchResult.text();
    } catch (err) {
      logger.warn('Could not parse result body after fetch', fetchResult, err);
      return null;
    }
  }
}

function getCurrentAuthContext() {
  const {
    facility: { companyAlias, facilityAlias },
  } = store.getState().profile;

  return {
    companyAlias,
    facilityAlias,
  };
}
