import { AudioVisualisation } from 'app/hooks/use-wavesurfer/types';
import Guid from 'core/types/Guid';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from '../actions';

export const RIGHT_DRAWER_IDS = ['ALERTS', 'SCENARIO_DATA'] as const;
export type RightDrawerId = (typeof RIGHT_DRAWER_IDS)[number];

export const INITIAL_STATE = {
  changeFacilityDialogIsOpen: false,
  openingDrawers: [] as RightDrawerId[],
  selectedAlert: '' as Guid,
  selectedAlertEvent: -1,
  eventAudioVisualisation: 'waveform' as AudioVisualisation,
  newPostCount: 0,
};

export type UiState = typeof INITIAL_STATE;

export const slice = createSlice({
  name: 'ui',
  initialState: INITIAL_STATE,
  reducers: {
    openChangeFacilityDialog: state => {
      state.changeFacilityDialogIsOpen = true;
    },
    closeChangeFacilityDialog: state => {
      state.changeFacilityDialogIsOpen = false;
    },
    openDrawer: (state, { payload }: PayloadAction<RightDrawerId>) => {
      state.openingDrawers.push(payload);
    },
    closeDrawer: (state, { payload }: PayloadAction<RightDrawerId>) => {
      state.openingDrawers = state.openingDrawers.filter(id => id !== payload);
    },
    openUniqueDrawer: (state, { payload }: PayloadAction<RightDrawerId>) => {
      state.openingDrawers = [payload];
    },
    selectAlert: (state, { payload }: PayloadAction<Guid>) => {
      state.selectedAlert = payload;
    },
    deselectAlert: state => {
      state.selectedAlert = '';
      state.selectedAlertEvent = -1;
    },
    selectAlertEvent: (state, { payload }: PayloadAction<number>) => {
      state.selectedAlertEvent = payload;
    },
    deselectAlertEvent: state => {
      state.selectedAlertEvent = -1;
    },
    setEventAudioVisualisation: (state, { payload }: PayloadAction<AudioVisualisation>) => {
      state.eventAudioVisualisation = payload;
    },
    setNewPostCount: (state, { payload }: PayloadAction<number>) => {
      state.newPostCount = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, state => {
      return {
        ...INITIAL_STATE,
        newPostCount: state.newPostCount,
      };
    });
  },
});
