import { useSpectrogram } from 'app/hooks/use-wavesurfer/useSpectrogram';
import { useAppSelector } from 'app/redux/store';
import { useRef } from 'react';
import { useEventNoisePlaybackContext } from './EventNoisePlaybackContext';
import { StyledSpectrogramPlaybackContainer } from './SpectrogramPlaybackContainer.styles';

export const SpectrogramPlaybackContainer = () => {
  const { containerRef, waveSurferRef } = useEventNoisePlaybackContext();
  const { width, height } = containerRef.current?.getBoundingClientRect() ?? {};
  const spectrogramContainerRef = useRef<HTMLDivElement | null>(null);
  const { eventAudioVisualisation } = useAppSelector(state => state.ui);

  useSpectrogram({ spectrogramContainerRef, waveSurferRef });

  return (
    <StyledSpectrogramPlaybackContainer
      ref={spectrogramContainerRef}
      $visualisation={eventAudioVisualisation}
      $width={width}
      $height={height}
    />
  );
};
