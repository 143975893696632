import { styled, css } from 'styled-components';

const activeStyles = css`
  border-color: ${({ theme }) => theme.colors.mono.ui05};
  background-color: ${({ theme }) => theme.colors.mono.ui05};
`;

export const StyledCircularButton = styled.button<{ $isActive?: boolean }>`
  width: 40px;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.mono.ui02};
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &:hover {
    border-color: ${({ theme }) => theme.colors.mono.ui06};
  }
  &:active {
    ${activeStyles}
  }
  ${({ $isActive }) => $isActive && activeStyles}
`;
