import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { closeChangeFacilityDialog, openChangeFacilityDialog } from 'app/redux/ui/actions';
import { useCallback, useMemo } from 'react';

export const useChangeFacilityDialogState = () => {
  const { changeFacilityDialogIsOpen: isOpen } = useAppSelector(state => state.ui);
  const handlers = useHandlers();
  const keyMap = useKeyMap();
  return {
    handlers,
    keyMap,
    isOpen,
  };
};

function useHandlers() {
  const dispatch = useAppDispatch();
  const openSearchBar = useCallback(
    (e?: KeyboardEvent) => {
      if (!e) return;
      e.preventDefault(); // prevent the hotkey showing up
      dispatch(openChangeFacilityDialog());
    },
    [dispatch]
  );
  const closeSearchBar = useCallback(() => {
    dispatch(closeChangeFacilityDialog());
  }, [dispatch]);
  return useMemo(
    () => ({
      OPEN_SEARCH_BAR: openSearchBar,
      CLOSE_SEARCH_BAR: closeSearchBar,
    }),
    [closeSearchBar, openSearchBar]
  );
}

type HandlersKey = keyof ReturnType<typeof useHandlers>;
type KeyMap = Partial<Record<HandlersKey, string>>;

function useKeyMap(): KeyMap {
  return {
    OPEN_SEARCH_BAR: '/',
  };
}
