import { gql } from '@apollo/client';
import { SENSOR } from './fragment.sensor';

export const FETCH_SENSORS = gql`
  ${SENSOR}
  query FetchSensors($sensorFilter: VariableFilter) {
    facility {
      id
      processVariables(filter: $sensorFilter) {
        ...Sensor
      }
    }
  }
`;
