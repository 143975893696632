import { AUDIO_VISUALISATIONS, AudioVisualisation } from 'app/hooks/use-wavesurfer/types';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { setEventAudioVisualisation } from 'app/redux/ui/actions';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';
import { useI18n } from 'core/hooks/useI18n';
import { useCallback } from 'react';
import {
  AudioVisualisationButton,
  AudioVisualisationList,
  AudioVisualisationListItem,
} from './AudioVisualisationSelection.styles';

const AUDIO_VISUALISATIONS_TRACKING_KEYS: Record<AudioVisualisation, string> = {
  waveform: DATA_TRACKING_KEY['event-history-waveform-button'],
  spectrogram: DATA_TRACKING_KEY['event-history-spectrogram-button'],
} as const;

export const AudioVisualisationSelection = () => {
  const { l10n } = useI18n('app/views/alerts', 'playback');
  const { eventAudioVisualisation: selectedVisualisation } = useAppSelector(state => state.ui);
  const dispatch = useAppDispatch();
  const setSelectedVisualisation = useCallback(
    (visualisation: AudioVisualisation) => {
      dispatch(setEventAudioVisualisation(visualisation));
    },
    [dispatch]
  );
  return (
    <AudioVisualisationList>
      {AUDIO_VISUALISATIONS.map(visualisation => (
        <AudioVisualisationListItem key={visualisation}>
          <AudioVisualisationButton
            onClick={() => setSelectedVisualisation(visualisation)}
            key={visualisation}
            $isSelected={visualisation === selectedVisualisation}
            data-tracking={AUDIO_VISUALISATIONS_TRACKING_KEYS[visualisation]}
          >
            {l10n(visualisation)}
          </AudioVisualisationButton>
        </AudioVisualisationListItem>
      ))}
    </AudioVisualisationList>
  );
};
