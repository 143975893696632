import { faDotCircle } from '@fortawesome/pro-duotone-svg-icons/faDotCircle';
import { faTicket } from '@fortawesome/pro-duotone-svg-icons/faTicket';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddCircle from './assets/add-circle.svg?react';
import Antenna from './assets/antenna.svg?react';
import Arrow from './assets/arrow.svg?react';
import Bars from './assets/bars.svg?react';
import Battery50 from './assets/battery50.svg?react';
import Bell from './assets/bell.svg?react';
import Blur from './assets/blur.svg?react';
import BlurCircle from './assets/blur-circle.svg?react';
import Bookmark from './assets/bookmark.svg?react';
import Bubbles from './assets/bubbles.svg?react';
import Build from './assets/build.svg?react';
import Bullets from './assets/bullets.svg?react';
import Chart from './assets/chart.svg?react';
import CloudRain from './assets/cloud-rain.svg?react';
import Cog from './assets/cog.svg?react';
import DotCircle from './assets/dot-circle.svg?react';
import Download from './assets/download.svg?react';
import Drop from './assets/drop.svg?react';
import Eye from './assets/eye.svg?react';
import Filter from './assets/filter.svg?react';
import Fingerprint from './assets/fingerprint.svg?react';
import GpsFixed from './assets/gps-fixed.svg?react';
import History from './assets/history.svg?react';
import Home from './assets/home.svg?react';
import Input from './assets/input.svg?react';
import Layers from './assets/layers.svg?react';
import Menu from './assets/menu.svg?react';
import Navigation from './assets/navigation.svg?react';
import PauseCircle from './assets/pause-circle.svg?react';
import People from './assets/people.svg?react';
import PlayCircle from './assets/play-circle.svg?react';
import Pressure from './assets/pressure.svg?react';
import SatelliteDish from './assets/satellite-dish.svg?react';
import Selected from './assets/selected.svg?react';
import Star from './assets/star.svg?react';
import Sun from './assets/sun.svg?react';
import Swap from './assets/swap.svg?react';
import Thermometer from './assets/thermometer.svg?react';
import Timeline from './assets/timeline.svg?react';
import Times from './assets/times.svg?react';
import Toll from './assets/toll.svg?react';
import Triangle from './assets/triangle.svg?react';
import UserCircle from './assets/user-circle.svg?react';
import VisibilityOff from './assets/visibility-off.svg?react';
import VisibilityOn from './assets/visibility-on.svg?react';
import Warning from './assets/warning.svg?react';
import Wind from './assets/wind.svg?react';
// Nav
import Alerts from './assets/nav/alerts.svg?react';
import Annotations from './assets/nav/annotations.svg?react';
import Dashboard from './assets/nav/dashboard.svg?react';
import Logout from './assets/nav/logout.svg?react';
import Modelling from './assets/nav/modelling.svg?react';
import Monitoring from './assets/nav/monitoring.svg?react';
import Reports from './assets/nav/reports.svg?react';
import SiteConfig from './assets/nav/site-config.svg?react';
import Support from './assets/nav/support.svg?react';
import Trajectories from './assets/nav/trajectories.svg?react';
import Updates from './assets/nav/updates.svg?react';
import UserSettings from './assets/nav/user-settings.svg?react';

import { HTMLAttributes } from 'react';

export type AssetType = keyof Icons;
export type AssetValue = (typeof icons)[keyof Icons];
type Icons = typeof icons;

const Blasting = () => <FontAwesomeIcon icon={faDotCircle} />;
const IncidentIntelligence = () => <FontAwesomeIcon icon={faTicket} />;

const Search = (props: HTMLAttributes<SVGElement>) => <FontAwesomeIcon icon={faSearch} {...props} />;

export const icons = {
  AddCircle,
  Antenna,
  Arrow,
  Bars,
  Battery50,
  Bell,
  Blur,
  BlurCircle,
  Bookmark,
  Bubbles,
  Build,
  Bullets,
  Chart,
  CloudRain,
  Cog,
  DotCircle,
  Download,
  Drop,
  Eye,
  Filter,
  Fingerprint,
  GpsFixed,
  History,
  Home,
  Input,
  Layers,
  Menu,
  Navigation,
  PauseCircle,
  People,
  PlayCircle,
  Pressure,
  SatelliteDish,
  Search,
  Selected,
  Star,
  Sun,
  Swap,
  Thermometer,
  Timeline,
  Times,
  Toll,
  Triangle,
  UserCircle,
  VisibilityOff,
  VisibilityOn,
  Warning,
  Wind,
  // Nav
  Alerts,
  Annotations,
  Blasting,
  Dashboard,
  IncidentIntelligence,
  Logout,
  Modelling,
  Monitoring,
  Reports,
  SiteConfig,
  Support,
  Trajectories,
  Updates,
  UserSettings,
} as const;
