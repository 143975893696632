import { styled } from 'styled-components';
import { NoiseClassificationIcon } from 'app/views/configuration/pages/noise/noise-classifications/noise-classification-icon';

export const EventClassificationIcon = styled(NoiseClassificationIcon).attrs({ tabIndex: -1 })`
  margin-right: ${({ theme }) => theme.spacing()}px;
  width: 24px;
  height: 24px;
  path {
    fill: ${({ theme }) => theme.colors.mono.ui06};
  }
`;
