import { styled } from 'styled-components';

export const StyledDataTable = styled.table`
  font-size: 1.4rem;

  thead {
    color: ${({ theme }) => theme.colors.mono.text03};
  }

  td {
    padding-left: ${({ theme }) => theme.spacing()}px;
  }

  td.color-indicator {
    div {
      height: ${({ theme }) => theme.spacing(1.5)}px;
      margin-right: ${({ theme }) => theme.spacing()}px;
    }
    div.square {
      width: ${({ theme }) => theme.spacing(1.5)}px;
    }
    div.rectangle {
      width: ${({ theme }) => theme.spacing(4)}px;
    }
    .arrow {
      height: ${({ theme }) => theme.spacing(3)}px;
    }
  }
`;
