import { useQuery } from '@tanstack/react-query';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { getAlertResponseClassifications } from 'app/business-logic/services/alerts-services/getAlertResponseClassifications';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { useHasAccess } from 'app/hooks/use-has-access';

export const useAlertResponseClassifications = () => {
  const hasAlertResponseConfigReadAccess = useHasAccess()(KnownAccessControls.app.alerts.response.config.read._);
  return useQuery({
    queryKey: [QUERY_KEY.alertResponseClassifications],
    queryFn: () => getAlertResponseClassifications(),
    select: data => data?.filter(classification => !classification.utcDeletedAt),
    enabled: hasAlertResponseConfigReadAccess,
  });
};
