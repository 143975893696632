const create = (root: string) => () => {
  return `${root}/create`;
};

const edit = (root: string) => () => {
  return `${root}/edit/:id`;
};

export const annotationsRoutes = (root: string) => ({
  root,
  create: create(root),
  edit: edit(root),
});
