import { AccessControl } from 'app/business-logic/security/KnownAccessControls';
import { useAppSelector } from 'app/redux/store';
import { useCallback } from 'react';

export const useHasAccess = () => {
  const { accessControls: userAccessControls } = useAppSelector(state => state.profile.user);
  return useCallback(
    (accessControls: AccessControl | AccessControl[]): boolean => {
      const noAccessControlsSpecified = accessControls.length === 0;
      if (noAccessControlsSpecified) return true;
      if (Array.isArray(accessControls)) return accessControls.every(control => userAccessControls[control]);
      return !!userAccessControls[accessControls];
    },
    [userAccessControls]
  );
};
