import { AppSettings, RequestHeaders } from 'core/AppSettings';

type Headers = Partial<Record<RequestHeaders[keyof RequestHeaders], string> & { Authorization: string }>;

const { guestApiKey, useGuestApiKey, apiKey, isDevelopment } = AppSettings;

const AuthParameters = {
  createRequestHeaders: (companyAlias?: string, facilityAlias?: string): Headers => {
    return {
      ...getApiKey(),
      ...(companyAlias ? { [AppSettings.requestHeaders.companyAlias]: companyAlias } : {}),
      ...(facilityAlias ? { [AppSettings.requestHeaders.facilityAlias]: facilityAlias } : {}),
    };
  },
};

function getApiKey() {
  const useApiKey = isDevelopment && !!apiKey;
  if (useApiKey) return { Authorization: `Token ${apiKey}` };
  if (useGuestApiKey) return { Authorization: `Token ${guestApiKey}` };
  return {};
}

export default AuthParameters;
