import { Classes } from '@blueprintjs/core';
import { getRandomIntegerInclusive } from 'app/helpers/getRandomIntegerInclusive';
import { PageButton, ItemsCount } from './TablePagination.styles';

export const LoadingPages = () => {
  const randomPages = Array(getRandomIntegerInclusive(5, 12))
    .fill(null)
    .map((_, index) => index + 1);
  return (
    <>
      {randomPages.map(number => (
        <PageButton key={number} disabled={true} className={Classes.SKELETON} $isCurrent={number === 1}>
          {number}
        </PageButton>
      ))}
      <ItemsCount className={Classes.SKELETON}>MMMMMMMMMM</ItemsCount>
    </>
  );
};
