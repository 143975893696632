import { getLocalDate } from 'app/helpers/getLocalDate';
import { useLocalTimeCallback } from 'app/hooks/use-local-time';
import { useSearchParams } from 'app/hooks/use-search-params';
import { useAppSelector } from 'app/redux/store';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DateRange } from '@blueprintjs/datetime';

export const URL_DATE_FORMAT = `yyyy-LL-dd'T'HHmm`;
const FROM_KEY = 'from';
const TO_KEY = 'to';

type OnSave = (args: { newState: { from: string[]; to: string[] } }) => void;

type Options = {
  onSave?: OnSave;
  onClear?: () => void;
};

/**
 * Provides values and functions to keep query string parameters in sync with a date range field.
 * @param object.onSave - (optional) - Handle saving the state yourself
 * @returns object.savedDateRange - The date (Date) range that's been saved by the user
 * @returns object.savedDateTimeRange - The date (DateTime) range that's been saved by the user
 * @returns object.isOpen - If the date range selector is open
 * @returns object.selectedDateRange - The date (Date) range that has been selected, before being saved
 * @returns object.onSave - Needs to be called then the user saves a date range
 * @returns object.onChange - Needs to be called then the user selects a date range
 * @returns object.onClear - Needs to be called then the user clears a date range
 * @returns object.setIsOpen - Can be used to open/close the date range selector
 */
export function useUrlDateRangeState(options?: Options) {
  const { fromValue, toValue } = useDateRangeParams();
  const [isOpen, setIsOpen] = useState(false);
  const { searchParamToDate, searchParamToDateTime } = useFormatDate();
  const savedDateRange = useMemo(() => {
    if (!fromValue || !toValue) return [null, null] as DateRange;
    const range: DateRange = [searchParamToDate(fromValue), searchParamToDate(toValue)];
    return range;
  }, [fromValue, searchParamToDate, toValue]);
  const savedDateTimeRange = useMemo(() => {
    if (!fromValue || !toValue) return;
    const range: [DateTime, DateTime] = [searchParamToDateTime(fromValue), searchParamToDateTime(toValue)];
    return range;
  }, [fromValue, searchParamToDateTime, toValue]);
  const { onChange, selectedDateRange } = useSelectedDateRange(savedDateRange);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const onClear = useOnClear({ handleClose, options });
  const onSave = useOnSave({ handleClose, options });

  return {
    // The date range that's been saved by the user
    savedDateRange,
    savedDateTimeRange,
    isOpen,
    // The date range that has been selected, before being saved
    selectedDateRange,
    onSave,
    onChange,
    onClear,
    setIsOpen,
  };
}

function useDateRangeParams() {
  const {
    searchParams: { from: fromValues, to: toValues },
    ...rest
  } = useSearchParams<typeof FROM_KEY | typeof TO_KEY>([FROM_KEY, TO_KEY]);
  const [fromValue] = fromValues ?? [];
  const [toValue] = toValues ?? [];
  return {
    fromValue,
    toValue,
    ...rest,
  };
}

function useOnSave({ handleClose, options }: { handleClose: () => void; options?: Options }) {
  const { setSearchParams } = useDateRangeParams();
  const { dateToSearchParam } = useFormatDate();
  const { onSave } = options ?? {};
  return useCallback(
    (dateRange: DateRange) => {
      const [from, to] = dateRange;
      if (!from || !to) return;
      const newState = {
        from: [dateToSearchParam(from)],
        to: [dateToSearchParam(to)],
      };
      if (onSave) {
        onSave({ newState });
      } else {
        setSearchParams(newState);
      }

      handleClose();
    },
    [dateToSearchParam, handleClose, onSave, setSearchParams]
  );
}

function useOnClear({ handleClose, options }: { handleClose: () => void; options?: Options }) {
  const initialDateRange = useInitialDateRange();
  const { onClear } = options ?? {};
  const { setSearchParams } = useDateRangeParams();
  return useCallback(() => {
    if (onClear) {
      onClear();
    } else {
      setSearchParams(initialDateRange());
    }
    handleClose();
  }, [handleClose, initialDateRange, onClear, setSearchParams]);
}

function useSelectedDateRange(savedDateRange: DateRange) {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>([null, null]);
  const onChange = useCallback((dateRange: DateRange) => setSelectedDateRange(dateRange), []);

  useEffect(() => {
    setSelectedDateRange(savedDateRange);
  }, [savedDateRange]);

  return {
    selectedDateRange,
    onChange,
  };
}

function useFormatDate() {
  const { ianaTimezone } = useAppSelector(state => state.profile.facility);
  const dateToSearchParam = useCallback((date: Date) => {
    return DateTime.fromJSDate(date).toFormat(URL_DATE_FORMAT);
  }, []);
  const searchParamToDateTime = useCallback(
    (date: string) => {
      return DateTime.fromFormat(date, URL_DATE_FORMAT, { zone: ianaTimezone });
    },
    [ianaTimezone]
  );
  const searchParamToDate = useCallback(
    (date: string) => {
      return getLocalDate(searchParamToDateTime(date));
    },
    [searchParamToDateTime]
  );
  return {
    dateToSearchParam,
    searchParamToDate,
    searchParamToDateTime,
  };
}

function useInitialDateRange() {
  const localTime = useLocalTimeCallback();
  return useCallback(() => {
    const now = localTime();
    const threeHoursAgo = now.minus({ hours: 3 });
    return {
      from: [threeHoursAgo.toFormat(URL_DATE_FORMAT)],
      to: [now.toFormat(URL_DATE_FORMAT)],
    };
  }, [localTime]);
}

export function useSetInitialDateRange() {
  const { fromValue, toValue, setSearchParams } = useDateRangeParams();
  const initialDateRange = useInitialDateRange();
  useEffect(() => {
    if (fromValue && toValue) return;
    setSearchParams(initialDateRange());
  }, [fromValue, initialDateRange, setSearchParams, toValue]);
}
