/**
 * Values extracted from the original SCSS variables in _layout.scss
 */

// The z-index of the highest layer on the map. Anything to be displayed above the map has to have a higher
// z-index value than this.
export const Z_INDEX_MAP = 1000;

export const Z_INDEX_MAP_CONTROL_BOX = Z_INDEX_MAP + 1;

// The header bar has to be higher than most other things on the map because it acts as the stacking context
// for the global menu.
export const Z_INDEX_HEADER_BAR = Z_INDEX_MAP + 100;