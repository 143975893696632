import { useWaveSurfer, WaveSurferOptions } from 'app/hooks/use-wavesurfer';
import { createContext, PropsWithChildren, useContext } from 'react';

const EventNoisePlaybackContext = createContext<ReturnType<typeof useWaveSurfer> | null>(null);

interface EventNoisePlaybackProviderProps {
  options?: WaveSurferOptions;
}

export const EventNoisePlaybackProvider = ({
  children,
  options,
}: PropsWithChildren<EventNoisePlaybackProviderProps>) => {
  const waveSurfer = useWaveSurfer(options);
  return <EventNoisePlaybackContext.Provider value={waveSurfer}>{children}</EventNoisePlaybackContext.Provider>;
};

export const useEventNoisePlaybackContext = () => {
  const context = useContext(EventNoisePlaybackContext);
  if (!context) throw new Error('No event noise context found. Is there a wrapping Provider component?');
  return context;
};
