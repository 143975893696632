import { useAppDispatch } from 'app/redux/store';
import { setNewPostCount } from 'app/redux/ui/actions';
import { LogManager } from 'core/logging/LogManager';
import { useCallback } from 'react';

const logger = LogManager.getLogger('useHandleBeamerVisibility');

export const useHandleBeamerVisibility = () => {
  const dispatch = useAppDispatch();
  const handleShowBeamer = useCallback(() => {
    try {
      if (!window.Beamer) {
        return logger.warn(`"Beamer" is not on the window and cannot be shown`);
      }
      window.Beamer.show();
      dispatch(setNewPostCount(0));
    } catch (e) {
      logger.error(e);
    }
  }, [dispatch]);
  return {
    handleShowBeamer,
  };
};
