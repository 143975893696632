import { FetchFacilityQuery, MapProvider } from 'app/__generated__/global';
import IDomainBoundary from 'app/business-logic/domain-models/map/IDomainBoundary';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { FETCH_FACILITY } from './graphql/facility.fetchFacility';

export const fetchFacility = async ({
  companyAlias,
  facilityAlias,
}: {
  companyAlias: string;
  facilityAlias: string;
}) => {
  const { data, error } = await ApolloProvider.global({
    companyAlias,
    facilityAlias,
  }).query<FetchFacilityQuery>({
    query: FETCH_FACILITY,
    errorPolicy: 'all',
  });
  const { company, facility } = data;
  if (!data || error || !company || !facility)
    throw new Error(`Something went wrong when trying to fetch facility for: ${companyAlias}/${facilityAlias}`);
  const { companyId } = company;
  const {
    id,
    name,
    defaultZoomLevel,
    facilityCode,
    ianaTimezone,
    latitude,
    longitude,
    region,
    ticketingSystemTenantId,
    windowsTimezoneId,
    roadMapProvider,
    satelliteMapProvider,
    smaxCustomerCode,
    type,
    useImperialUnits,
    isActive,
  } = facility;
  return [
    {
      companyAlias,
      companyId,
      facilityAlias,
      id,
      name,
      domainBoundaries: [] as IDomainBoundary[],
      defaultZoomLevel: defaultZoomLevel ?? null,
      facilityCode: facilityCode ?? 'NO_FACILITY_CODE',
      ianaTimezone: ianaTimezone ?? 'NO_IANA_TIMEZONE',
      latitude: latitude ?? null,
      longitude: longitude ?? null,
      region: region ?? null,
      ticketingSystemTenantId: ticketingSystemTenantId ?? null,
      windowsTimezoneId: windowsTimezoneId ?? 'NO_WINDOWS_TIMEZONE_ID',
      roadMapProvider: roadMapProvider ?? MapProvider.Mapbox,
      satelliteMapProvider: satelliteMapProvider ?? MapProvider.Azure,
      es1RouteName: '',
      smaxCustomerCode: smaxCustomerCode ?? null,
      type: type ?? null,
      useImperialUnits: useImperialUnits,
      isActive,
    },
  ];
};
