import { css } from 'styled-components';
import { Classes } from '@blueprintjs/core';
import Color from 'color';

export const CLASS_NAME = 'modelling-notifications';

export const StyledModellingNotifications = css`
  .${CLASS_NAME} {
    .${Classes.TOAST_MESSAGE} {
      font-size: 1.4rem;
    }
    .${Classes.TOAST}.${Classes.INTENT_PRIMARY} {
      background-color: ${({ theme }) => theme.colors.brand.brand01};
      .${Classes.BUTTON} {
        background-color: transparent !important;
        &:hover {
          background-color: ${({ theme }) => Color(theme.colors.brand.brand01).darken(0.15).toString()} !important;
        }
      }
    }
  }
`;
