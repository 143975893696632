import { PlaybackControlButton } from 'app/components/playback';
import { AudioVisualisation } from 'app/hooks/use-wavesurfer/types';
import { styled } from 'styled-components';

import { Classes } from '@blueprintjs/core';

import { WAVEFORM_HEIGHT } from './EventNoisePlayback.constants';

export const EventPlaybackWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing()}px;
`;

export const EventPlaybackHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing()}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const EventPlaybackHeaderVisualisationSelection = styled.div``;

export const EventPlaybackHeaderVisualisationUtility = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
`;

export const WavesurferPlayback = styled.div<{ $playbackType: AudioVisualisation }>`
  position: relative;
  && wave {
    display: ${({ $playbackType }) => ($playbackType !== 'waveform' ? 'none' : 'block')} !important;
  }
  && spectrogram {
    display: ${({ $playbackType }) => ($playbackType !== 'spectrogram' ? 'none' : 'block')} !important;
  }
`;

export const WavesurferPlaybackLoading = styled.div.attrs({ className: Classes.SKELETON })`
  width: 100%;
  height: ${WAVEFORM_HEIGHT}px;
  position: absolute;
`;

export const TimeWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing()}px;
`;

export const Timestamp = styled.span`
  color: ${({ theme }) => theme.colors.mono.text01};
  font-size: 1.4rem;
`;

export const AudioDuration = styled.span`
  color: ${({ theme }) => theme.colors.mono.ui05};
  font-size: 1.4rem;
`;

export const Controls = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const PlayPause = styled(PlaybackControlButton)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.colors.mono.text01};
  border-color: transparent;
  color: ${({ theme }) => theme.colors.mono.ui03};
`;

export const NoNoiseEventAudio = styled.p`
  margin: ${({ theme }) => theme.spacing()}px;
`;
