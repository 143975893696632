import { getNoiseEventAudio } from 'app/business-logic/services/noise-service';
import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';

export const useNoiseEventAudio = (noiseEventId: number) => {
  return useQuery({
    queryKey: [QUERY_KEY.noiseEventAudio, noiseEventId],
    queryFn: () => {
      return getNoiseEventAudio(noiseEventId);
    },
    placeholderData: previousData => previousData,
    retry: 1,
  });
};
