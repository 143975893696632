import { reducer as analyseReducer } from 'app/redux/analyse/reducer';
import { reducer as jobsReducer } from 'app/redux/jobs/reducer';
import { persistedReducer as persistedMapReducer } from 'app/redux/map/reducer';
import { persistedReducer as persistedMonitoringReducer } from 'app/redux/monitoring/reducer';
import { reducer as profileReducer } from 'app/redux/profile/reducer';
import { persistedReducer as uiReducer } from 'app/redux/ui/reducer';

import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { store } from './store';

export type IGlobalState = ReturnType<typeof store.getState>;

export const appReducer = combineReducers({
  analyse: analyseReducer,
  jobs: jobsReducer,
  map: persistedMapReducer,
  monitoring: persistedMonitoringReducer,
  profile: profileReducer,
  ui: uiReducer,
});

export const rootReducer: Reducer<ReturnType<typeof appReducer>> = (state, action) => appReducer(state, action);
