import Guid from 'core/types/Guid';
import SensorDataChunk, { CHUNK_EXPIRY_TIME_MILLIS } from './SensorDataChunk';

import { LogManager } from 'core/logging/LogManager';
const logger = LogManager.getLogger('SensorDataCache');

export class CacheEntry {
  lock: Promise<void>;
  chunks: SensorDataChunk[];
  staleDataTimeoutMillis!: number;

  constructor(public sensorId: Guid) {
    this.chunks = [];
    this.lock = Promise.resolve();
  }
}

////////////////////

const cache = new Map<Guid, CacheEntry>();
setInterval(() => expire(), CHUNK_EXPIRY_TIME_MILLIS);

const defaultExport = {
  get: getCacheEntry,
  clear,
};

export default defaultExport;

function getCacheEntry(sensorId: Guid, createIfMissing = true): CacheEntry | null {
  let cacheEntry = cache.get(sensorId);

  if (!cacheEntry && createIfMissing) {
    cache.set(sensorId, (cacheEntry = new CacheEntry(sensorId)));
  }

  return cacheEntry || null;
}

function expire() {
  // const nowMillis = new Date().valueOf();
  let expiredChunkCount = 0;

  for (const [, entry] of cache) {
    const chunkCount = entry.chunks.length;
    entry.chunks = entry.chunks.filter(chunk => !(chunk.hasExpired(/*nowMillis*/)));
    expiredChunkCount += chunkCount - entry.chunks.length;
  }

  if (expiredChunkCount > 0) {
    logger.debug(`Removed ${expiredChunkCount} expired chunk/s from the cache.`);
  }
}

function clear() {
  cache.clear();
}
