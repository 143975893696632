/**
 * This function takes a number and returns it to a specified number of significant figures.
 * 
 * If the number is less than 1e-10, it will be rendered as zero.
 * 
 * Scientific (exponent) notation will be avoided except where the number is below 1e-7 or greater than 1e+21.
 *
 * @param value the number to be converted
 * @param significantFigures the number of significant figures to keep
 * @returns the number with the specified number of significant figures
 */
const epsilon : number = 1e-10; //0.0000000001

export const toSignificantFigures = (value: number | null, significantFigures: number) => {
  if (typeof value !== 'number') {
    return '';
  }
  if (significantFigures < 1) {
    return value.toFixed(0);
  }
  if (Math.abs(value) < epsilon) {
    return "0";
  }

  return String(parseFloat(value.toPrecision(significantFigures)));
  
};
