import { slice } from './slice';

export const {
  facilityChanged,
  switchingFacilities,
  weatherStationsLoaded,
  mapConfigurationChanged,
  preferredWeatherStationChanged,
  domainBoundariesChanged,
} = slice.actions;
