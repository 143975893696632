import { ReturnHome } from 'app/components/error/ReturnHome';

import { useI18n } from 'core/hooks/useI18n';
import { ErrorBlock, ErrorLogo, Inner, MainHeading, SubHeading, Wrapper } from './Error.styles';

interface IProps {
  componentStack: string;
  error: Error;
}

export const UnexpectedError = ({ componentStack, error }: IProps) => {
  const { l10n } = useI18n('app/components', 'errors');

  return (
    <Wrapper>
      <Inner>
        <ErrorLogo />
        <MainHeading>{l10n('unexpected.title')}</MainHeading>
        {error && (
          <>
            <SubHeading>{l10n('unexpected.error', { suffix: ': ' })}</SubHeading>
            <ErrorBlock value={error.toString()} />
          </>
        )}
        {componentStack && (
          <div>
            <SubHeading>{l10n('unexpected.stackTrace', { suffix: ': ' })}</SubHeading>
            <ErrorBlock value={componentStack} />
          </div>
        )}
        <ReturnHome />
      </Inner>
    </Wrapper>
  );
};
