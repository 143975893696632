import { useFetchBlastingScenario } from 'app/views/blasting/pages/view-scenario/useFetchBlastingScenario';
import { useParams } from 'react-router-dom';
import { useI18n } from 'core/hooks/useI18n';
import { Classes } from '@blueprintjs/core';
import { BlastModelType } from 'app/business-logic/domain-models/Blasting';
import { Label, Value, Parameter, ScenarioCardContainer, ScenarioCardTitle } from './ScenarioDataCards.styles';

export const PollutantCard = () => {
  const { alias } = useParams();
  const { l10n } = useI18n('app/views/blasting');

  const { data: scenario, isLoading } = useFetchBlastingScenario(alias);
  const loadingClass = isLoading ? Classes.SKELETON : undefined;
  if (
    !scenario.blastModels?.some(
      model => model.modelType === BlastModelType.FumeManagementZone || model.modelType === BlastModelType.Dispersion
    ) &&
    !isLoading
  ) {
    return null;
  }
  return (
    <ScenarioCardContainer>
      <ScenarioCardTitle className={loadingClass}>
        {l10n('page.createScenario.form.section.pollutant')}
      </ScenarioCardTitle>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.height.labelWithoutUnit')}</Label>
        <Value>
          {scenario.height} {l10n('fields.height.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.length.labelWithoutUnit')}</Label>
        <Value>
          {scenario.length} {l10n('fields.length.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.width.labelWithoutUnit')}</Label>
        <Value>
          {scenario.width} {l10n('fields.width.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.azimuth.label')}</Label>
        <Value>{scenario.azimuth}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.elevation.label')}</Label>
        <Value>
          {scenario.elevation} {l10n('fields.elevation.unit')}
        </Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.windScientificModelId.label')}</Label>
        <Value>{scenario.windScientificModelDescription}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.modelPollutantId.label')}</Label>
        <Value> {scenario.pollutantDescription}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.pollutantScientificModelId.label')}</Label>
        <Value>{scenario.pollutantScientificModelDescription}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.displayUnitId.label')}</Label>
        <Value>{scenario.displayUnitDescription}</Value>
      </Parameter>
      <Parameter className={loadingClass}>
        <Label>{l10n('fields.initialConcentrationGramsPerCubicMetre.labelWithoutUnit')}</Label>
        <Value>
          {scenario.initialConcentrationGramsPerCubicMetre} {l10n('fields.initialConcentrationGramsPerCubicMetre.unit')}
        </Value>
      </Parameter>
    </ScenarioCardContainer>
  );
};
