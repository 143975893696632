import { styled } from 'styled-components';
import { srOnly } from 'app/visuals/mixins/srOnly';

export const DateRangeWrapper = styled.span`
  position: relative; // Important to keep hidden input contained
`;

export const DateRangeHiddenInput = styled.input`
  ${srOnly}
`;
