import { Classes } from '@blueprintjs/core';
import { KnownAccessControls } from 'app/business-logic/security/KnownAccessControls';
import { FieldValidationError } from 'app/components/form/field-validation-error';
import { useHasAccess } from 'app/hooks/use-has-access';
import {
  ClassificationCommentsField,
  HiddenLabel,
} from 'app/views/alerts/components/event-classification/EventClassification.styles';
import { useI18n } from 'core/hooks/useI18n';
import { useFormikContext } from 'formik';
import { AlertResponseFormType } from '../AlertResponse';

type AlertResponseCommentsFieldProps = {
  isLoading: boolean;
};

const LABEL_ID = 'alert-response-comments';
const INPUT_ID = `${LABEL_ID}-input`;

export const AlertResponseCommentsField = ({ isLoading }: AlertResponseCommentsFieldProps) => {
  const { l10n } = useI18n('app/views/alerts', 'alertResponse');
  const {
    setFieldValue,
    values: { comments },
    errors,
    touched,
  } = useFormikContext<AlertResponseFormType>();
  const isError = !!errors.comments && !!touched.comments;
  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const key: keyof AlertResponseFormType = 'comments';
    const value: AlertResponseFormType[typeof key] = e.target.value;
    setFieldValue(key, value);
  };
  const hasAlertResponseWriteAccess = useHasAccess()(KnownAccessControls.app.alerts.response.data.write._);
  const loadingClass = isLoading ? Classes.SKELETON : undefined;
  return (
    <>
      <HiddenLabel id={LABEL_ID} htmlFor={INPUT_ID}>
        {l10n('labels.comments')}
      </HiddenLabel>
      <ClassificationCommentsField
        $invalid={false}
        id={INPUT_ID}
        value={comments}
        onChange={handleOnChange}
        disabled={isLoading || !hasAlertResponseWriteAccess}
        placeholder={l10n('commentsPlaceholder')}
        className={loadingClass}
      />
      {isError && <FieldValidationError aria-describedby={LABEL_ID}>{errors.comments}</FieldValidationError>}
    </>
  );
};
