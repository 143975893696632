import { httpClient } from 'core/net/http';
import HttpResponse from 'core/net/HttpResponse';

export async function getNoiseEventAudio(noiseEventId: number) {
  const { data, isSuccess, messages }: HttpResponse<Blob> = await httpClient.useArenaInternalApi
    .asAuthorised()
    .get(`noise-file/${noiseEventId}`);
  if (!isSuccess) throw new Error(messages?.join(', '));
  return data ?? undefined;
}
