import { DateTime } from 'luxon';

/**
 * Returns a native JavaScript Date object at the same date/time as the passed Luxon DateTime argument.
 * The Date object disregards the DateTime's timezone and is based off the user's system.
 */
export function getLocalDate(dateTime: DateTime) {
  const { year, month, day, hour, minute, second, millisecond } = dateTime;
  return DateTime.local(year, month, day, hour, minute, second, millisecond).toJSDate();
}
