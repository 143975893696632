import { useHasAccess } from 'app/hooks/use-has-access';
import { useAppDispatch } from 'app/redux/store';
import { closeDrawer } from 'app/redux/ui/actions';
import {
  LastUpdated,
  NoAlerts,
  SidebarCloseButton,
  SidebarContainer,
  SidebarHeader,
  SidebarTitle,
} from 'app/views/components/right-drawer/RightDrawer.styles';
import CloseIcon from 'app/visuals/images/icons/cross.svg?react';
import { useI18n } from 'core/hooks/useI18n';
import { DateTime } from 'luxon';
import { AlertsCard } from '../alerts-card/AlertsCard';
import { useAlertsDrawerData } from 'app/views/alerts/components/alerts-drawer-content/useAlertsDrawerData';

interface AlertsDrawerContentProps {
  alerts: ReturnType<typeof useAlertsDrawerData>['data'];
  lastUpdated: DateTime | null;
}

export const AlertsDrawerContent = ({ alerts, lastUpdated }: AlertsDrawerContentProps) => {
  const { l10n } = useI18n('app/views/alerts', 'sidebar');
  const dispatch = useAppDispatch();
  const handleCloseAlertsSidebar = () => {
    dispatch(closeDrawer('ALERTS'));
  };
  const hasNoAlerts = alerts.length === 0;
  const hasAlertsAccess = useHasAccess()(['app.alerts']);

  if (!hasAlertsAccess) return null;

  return (
    <>
      <SidebarHeader>
        <SidebarCloseButton title={l10n('close')} data-testid="alerts-drawer-close" onClick={handleCloseAlertsSidebar}>
          <CloseIcon />
        </SidebarCloseButton>
        <SidebarTitle>{l10n('header')}</SidebarTitle>
      </SidebarHeader>
      <SidebarContainer $hasNoAlerts={hasNoAlerts}>
        {alerts.map((alert, index) => (
          <AlertsCard alert={alert} key={alert.id} index={index} />
        ))}
        {lastUpdated && hasNoAlerts && (
          <>
            <NoAlerts>{l10n('noAlerts')}</NoAlerts>
            {
              <LastUpdated>
                {l10n('lastUpdated')} {lastUpdated.toLocaleString(DateTime.DATETIME_MED)}
              </LastUpdated>
            }
          </>
        )}
      </SidebarContainer>
    </>
  );
};
