import { useI18n } from 'core/hooks/useI18n';
import { Comments, NoComments } from './EventClassificationComments.styles';

type EventClassificationCommentsProps = {
  comments: string | null;
};

export const EventClassificationComments = ({ comments }: EventClassificationCommentsProps) => {
  const { l10n } = useI18n('app/views/alerts', 'eventsListing');
  const hasComments = !!comments;
  const tooltip = hasComments
    ? comments.length > 120
      ? `${comments.slice(0, 120)}...`
      : comments
    : l10n('results.noComments');
  return (
    <>
      {hasComments && <Comments title={tooltip} data-testid="has-comments" />}
      {!hasComments && <NoComments title={tooltip} data-testid="has-no-comments" />}
    </>
  );
};
