import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { getNoiseEventClassificationCategories } from 'app/business-logic/services/noise-service';
import { useMemo } from 'react';
import { createLeveledCategories } from './createLeveledCategories';
import { createNestedCategories } from './createNestedCategories';

export const useNoiseEventClassificationCategories = () => {
  const { data, isFetching } = useQuery({
    queryFn: getNoiseEventClassificationCategories,
    queryKey: [QUERY_KEY.noiseEventClassificationCategories],
    staleTime: 60000,
  });
  const sortedCategories = useMemo(
    () => data && [...data].sort((cat1, cat2) => cat1.name.localeCompare(cat2.name)),
    [data]
  );
  const nestedCategories = useMemo(
    () => sortedCategories && createNestedCategories(sortedCategories, -1),
    [sortedCategories]
  );
  const leveledCategories = useMemo(
    () => nestedCategories && createLeveledCategories(nestedCategories),
    [nestedCategories]
  );
  return useMemo(
    () => ({
      categories: sortedCategories,
      nestedCategories,
      leveledCategories,
      isFetching,
    }),
    [isFetching, leveledCategories, nestedCategories, sortedCategories]
  );
};
