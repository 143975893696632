import { styled } from 'styled-components';

export const AlertResponseWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  border: 1px solid ${({ theme }) => theme.colors.mono.ui04};
  border-radius: 5px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  &:empty {
    display: none;
  }
`;

export const AlertResponseHeading = styled.h3`
  font-size: 1.2rem;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.mono.text03};
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  margin: ${({ theme }) => theme.spacing(3)}px 0;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mono.ui05};
  letter-spacing: 0.05em;
  &:first-of-type {
    margin-top: 0;
  }
`;

export const NoAlertResponseClassifications = styled.p`
  margin: 5px 0;
  font-size: 1.6rem;
`;
