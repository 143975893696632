import { useRef } from 'react';
import { useClearSearchParams } from './useClearSearchParams';
import { useSearchParamValues } from './useSearchParamValues';
import { useSetSearchParams } from './useSetSearchParams';

/**
 * A wrapper around the useSearchParams hook from react-router-dom that makes working with a specific set of search parameters safer.
 * @param searchParamKeys An array of search parameter keys that you want to work with
 * @returns The search parameters for the provided keys, a function to clear those parameters and a function to set those parameters
 */
export function useSearchParams<Key extends string>(searchParamKeys: Array<Key>) {
  const searchParamKeysRef = useRef(searchParamKeys);
  const searchParams = useSearchParamValues(searchParamKeysRef.current);
  const clearSearchParams = useClearSearchParams(searchParamKeysRef.current);
  const setSearchParams = useSetSearchParams<Key>();
  return { searchParams, clearSearchParams, setSearchParams };
}
