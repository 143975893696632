import { gql } from '@apollo/client';

export const GET_ALERT_CONFIGURATIONS = gql`
  query GetAlertConfigurations {
    facility {
      alerts {
        alertScriptConfigurations {
          id
          name
          alertTypeId
          linkedOutputs {
            alertOutputs {
              __typename
            }
          }
        }
      }
    }
  }
`;
