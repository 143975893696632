import { PlaybackControlButton } from 'app/components/playback';
import { AudioVisualisation } from 'app/hooks/use-wavesurfer/types';
import { styled } from 'styled-components';

import { Classes } from '@blueprintjs/core';

import { WAVESURFER_PLAYBACK_HEIGHT } from './WaveSurferConstants';

export const WaveSurferPlaybackContainer = styled.div<{ $visualisation: AudioVisualisation }>`
  position: relative;
  height: ${WAVESURFER_PLAYBACK_HEIGHT}px;
`;

export const WaveSurferPlaybackLoading = styled.div.attrs({ className: Classes.SKELETON })`
  width: 100%;
  height: ${WAVESURFER_PLAYBACK_HEIGHT}px;
  position: absolute;
`;

export const PlaybackCanvas = styled.canvas<{ $width: number }>`
  height: ${WAVESURFER_PLAYBACK_HEIGHT}px;
  width: ${({ $width }) => $width}px;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  top: 0;
  left: 0;
`;

export const TimeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Timestamp = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.mono.text01};
  font-size: 1.4rem;
  margin-right: 0.2em;
`;

export const AudioDuration = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.mono.text01};
  font-size: 1.4rem;
  margin-left: 0.2em;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const PlayPause = styled(PlaybackControlButton)`
  background-color: ${({ theme }) => theme.colors.mono.text01};
  border-color: transparent;
  color: ${({ theme }) => theme.colors.mono.ui03};
  margin-right: ${({ theme }) => theme.spacing(2)}px;
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.brand.brand02};
  }
`;
