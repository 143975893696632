import { Icon } from 'app/components/icon';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';
import { useI18n } from 'core/hooks/useI18n';
import { ButtonText, DownloadAudioButton, DownloadAudioButtonInner } from './DownloadAudio.styles';
import { useHandleAudioDownload } from './useHandleAudioDownload';

export type DownloadAudioProps = {
  data: Blob;
  noiseEventId: number;
  startTime: string;
};

export const DownloadAudio = ({ data, noiseEventId, startTime }: DownloadAudioProps) => {
  const { l10n } = useI18n('app/views/alerts/components/event-noise-playback', 'audio');
  const handleAudioDownload = useHandleAudioDownload({ data, noiseEventId, startTime });
  return (
    <DownloadAudioButton
      $variant="text"
      onClick={handleAudioDownload()}
      data-tracking={DATA_TRACKING_KEY['event-history-download-button']}
    >
      <DownloadAudioButtonInner>
        <Icon.Download width="16px" height="16px" /> <ButtonText>{l10n('download')}</ButtonText>
      </DownloadAudioButtonInner>
    </DownloadAudioButton>
  );
};
