import { RecursiveValues } from 'app/types';

const app = {
  alerts: {
    _: 'app.alerts',
    dismiss: { _: 'app.alerts.dismiss' },
    acknowledge: { _: 'app.alerts.acknowledge' },
    response: {
      config: {
        read: {
          _: 'app.alert.response.config.read',
        },
        write: {
          _: 'app.alert.response.config.write',
        },
      },
      data: {
        read: {
          _: 'app.alert.response.data.read',
        },
        write: {
          _: 'app.alert.response.data.write',
        },
      },
    },
  },
  blasting: {
    _: 'app.blasting',
    flyrock: { _: 'app.blasting.flyrock' },
    groundVibration: { _: 'app.blasting.ground-vibration' },
    modify: { _: 'app.blasting.modify' },
    overpressure: { _: 'app.blasting.overpressure' },
  },
  config: {
    _: 'app.config',

    annotations: {
      monitorPollutants: { _: 'app.config.annotations.monitor-pollutants' },
    },

    credentials: { _: 'app.config.credentials' },

    modelling: {
      _: 'app.config.modelling',
      emissionData: { _: 'app.config.modelling.emission-data' },
      runModels: { _: 'app.config.modelling.run-models' },
    },

    monitoring: { _: 'app.config.monitoring' },
  },
  dashboard: {
    _: 'app.dashboard',
  },
  incidentIntelligence: {
    _: 'app.incident-intelligence',
    externalObservations: { _: 'app.incident-intelligence.external-observations' },
  },
  modelling: {
    _: 'app.modelling',
    runAermodModelling: { _: 'app.modelling.run-aermod-modelling' },
    trajectory: { _: 'app.modelling.trajectory' },
  },
  monitoring: {
    _: 'app.monitoring',

    sourceIdentification: {
      _: 'app.monitoring.source-identification',
      debug: { _: 'app.monitoring.source-identification.debug' },
    },

    temporalMapLayers: { _: 'app.monitoring.temporal-map-layers' },
  },
  noise: {
    classification: {
      config: {
        read: {
          _: 'app.noise.classification.config.read',
        },
        write: {
          _: 'app.noise.classification.config.write',
        },
      },
      data: {
        read: {
          _: 'app.noise.classification.data.read',
        },
        write: {
          _: 'app.noise.classification.data.write',
        },
      },
    },
  },
  omnis: {
    _: 'app.omnis',
  },
  report: {
    historical: {
      read: {
        _: 'app.report.historical.read',
      },
    },
    sentinel: {
      read: {
        _: 'app.report.sentinel.read',
      },
    },
  },
  reports: { _: 'app.reports' },
  vibration: {
    classification: {
      data: {
        read: {
          _: 'app.vibration.classification.data.read',
        },
      },
    },
  },
} as const;

const appLegacy = {};

const api = {
  company: {
    alerts: {
      modify: { _: 'api.company.alerts.modify' },
    },
    jsonBlobs: {
      delete: { _: 'api.company.json-blobs.delete' },
    },
    monitoring: {
      displayConfig: {
        read: { _: 'api.company.monitoring.display-config.read' },
      },
      displayVariables: {
        linkToMonitor: { _: 'api.company.monitoring.display-variables.link-to-monitor' },
      },
    },
    processes: {
      read: { _: 'api.company.monitors.read' },
      modify: { _: 'app.config.monitoring' },
      delete: { _: 'api.company.monitor.delete' },
    },
    processVariables: {
      read: { _: 'api.company.monitor-variables.read' },
      readData: { _: 'api.company.monitor-variable-data.read' },
    },
    reportDefinitions: {
      read: { _: 'api.company.report-definitions.read' },
      create: { _: 'api.company.report-definitions.create' },
      update: { _: 'api.company.report-definitions.update' },
      delete: { _: 'api.company.report-definitions.delete' },
    },
    sequences: {
      read: { _: 'api.company.sequences.read' },
      create: { _: 'api.company.sequences.create' },
      modify: { _: 'api.company.sequences.modify' },
      delete: { _: 'api.company.sequences.delete' },
    },
  },
  monitoring: {
    data: {
      read: { _: 'app.monitoring.data.read' },
      write: { _: 'app.monitoring.data.write' },
    },
  },
  system: {
    accessControls: {
      read: { _: 'api.system.access-controls.read' },
      linkToAccessGroup: { _: 'api.system.access-controls.link-to-access-group' },
      linkToAuthDomain: { _: 'api.system.access-controls.link-to-auth-domain' },
      linkToCredential: { _: 'api.system.access-controls.link-to-credential' },
    },
    accessGroups: {
      read: { _: 'api.system.access-groups.read' },
      create: { _: 'api.system.access-groups.create' },
      delete: { _: 'api.system.access-groups.delete' },
      update: { _: 'api.system.access-groups.update' },
    },
    authDomains: {
      read: { _: 'api.system.auth-domains.read' },
      create: { _: 'api.system.auth-domains.create' },
      delete: { _: 'api.system.auth-domains.delete' },
      update: { _: 'api.system.auth-domains.update' },
    },
    companies: {
      read: { _: 'api.system.companies.read' },
      create: { _: 'api.system.companies.create' },
      delete: { _: 'api.system.companies.delete' },
    },
    credentials: {
      read: { _: 'api.system.credentials.read' },
    },
    diagnosis: { _: 'api.system.diagnostics' },
    facilities: {
      create: { _: 'api.system.facilities.create' },
      delete: { _: 'api.system.facilities.delete' },
    },
    jobs: {
      enqueue: { _: 'api.system.jobs.enqueue' },
    },
    reportTypes: {
      read: { _: 'api.system.reportTypes.read' },
    },
    units: {
      read: { _: 'api.system.units.read' },
    },
    users: {
      read: { _: 'api.system.users.read' },
      modify: { _: 'api.system.users.modify' },
      delete: { _: 'api.system.users.delete' },
    },
    contacts: {
      read: { _: 'api.system.contacts.read' },
      create: { _: 'api.system.contacts.create' },
      delete: { _: 'api.system.contacts.delete' },
      update: { _: 'api.system.contacts.update' },
    },
  },
} as const;

const service = {};

export const KnownAccessControls = {
  app,
  appLegacy,
  api,
  service,
};

export type KnownAccessControl = { _: AccessControl };

export type AccessControl = RecursiveValues<typeof KnownAccessControls>;
