import { useEffect } from 'react';

import { WaveSurferRef } from '../types';
import { useSetIsPlaying } from './useSetIsPlaying';

export const useIsPlaying = (waveSurferRef: WaveSurferRef) => {
  const { isPlaying, onPause, onPlay } = useSetIsPlaying();
  const waveSurfer = waveSurferRef.current;
  useEffect(() => {
    if (!waveSurfer) return;

    waveSurfer.on('play', onPlay);
    waveSurfer.on('pause', onPause);
    return () => {
      waveSurfer.un('play', onPlay);
      waveSurfer.un('pause', onPause);
    };
  }, [onPause, onPlay, waveSurfer]);
  return isPlaying;
};
