import { styled, css } from 'styled-components';

export const SIDE_BAR_WIDTH_OPEN = 346;
export const SIDE_BAR_TRANSITION_SPEED_MILLISECONDS = 200;

export const Sidebar = styled.div<{ $isOpen?: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  width: ${({ $isOpen }) => ($isOpen ? SIDE_BAR_WIDTH_OPEN : 0)}px;
  height: 100vh; // Fallback for safety, in case --doc-height goes missing
  height: var(--doc-height);
  z-index: ${({ theme }) => theme.zIndex.headerBar};
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  transition: width ${SIDE_BAR_TRANSITION_SPEED_MILLISECONDS}ms ease-out;
  box-shadow:
    0 0 25px rgba(0, 0, 0, 0.4) inset,
    1px 0 5px rgba(0, 0, 0, 0.15) inset;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      `
    )}
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing()}px;
`;

export const SidebarTitle = styled.h1`
  font-size: 1.8rem;
  margin: 0;
  margin-left: ${({ theme }) => theme.spacing()}px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
`;

export const SidebarCloseButton = styled.button`
  width: 40px;
  height: 40px;
  svg {
    width: 14px;
    height: 14px;
    fill: ${({ theme }) => theme.colors.mono.text02};
  }
  &:hover svg {
    fill: ${({ theme }) => theme.colors.mono.text01};
  }
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.mono.text01};
  }
`;

const noAlertsStyles = css`
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const SidebarContainer = styled.div<{ $hasNoAlerts?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(2)}px;
  overflow-y: auto;
  height: 100%;
  ${({ $hasNoAlerts }) => $hasNoAlerts && noAlertsStyles}
`;

export const NoAlerts = styled.h2`
  font-size: 1.6rem;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
  padding: 0 ${({ theme }) => theme.spacing(0.5)}px;
`;

export const LastUpdated = styled.p`
  font-size: 1.4rem;
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacing(0.5)}px;
`;
