import { gql } from '@apollo/client';

export const UPDATE_ACKNOWLEDGE_ALERT_EVENT = gql(`
  mutation UpdateAcknowledgeAlertEvent($input: AcknowledgeAlertEventInput!) {
    facility {
      alertEvent {
        acknowledgeAlertEvent(input: $input)
      }
    }
  }
`);
