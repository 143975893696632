import { useCallback, useState } from 'react';
import { WaveSurferRef } from '../types';

export const useSetDurationMilliseconds = (waveSurferRef: WaveSurferRef) => {
  const [durationMilliseconds, setDurationMilliseconds] = useState<number>();
  const handleSetDurationMilliseconds = useCallback(() => {
    const seconds = waveSurferRef.current?.getDuration();
    const milliseconds = typeof seconds === 'number' ? Math.round(seconds * 1000) : undefined;
    setDurationMilliseconds(milliseconds);
  }, [waveSurferRef]);
  return {
    durationMilliseconds,
    setDurationMilliseconds: handleSetDurationMilliseconds,
  };
};
