import { useCallback } from 'react';
import { getLoginUrl } from '../getLoginUrl';
import { logout } from './logout';
import { useClearCache } from './useClearCache';

export const useLogout = () => {
  const clearCache = useClearCache();
  return useCallback(async () => {
    try {
      const { isSuccess } = await logout();
      if (!isSuccess) throw new Error('Failed to log out');
      clearCache();
      const loginUrl = getLoginUrl();
      window.location.assign(loginUrl);
    } catch (error) {
      console.error(error);
    }
  }, [clearCache]);
};
