import { LinearProgressInner1, LinearProgressInner2, StyledLinearProgress } from './LinearProgress.styles';

interface IProps {
  height?: number;
}

export const LinearProgress = ({ height = 2, ...rest }: IProps & Parameters<typeof StyledLinearProgress>[0]) => {
  const { style = {} } = rest;
  return (
    <StyledLinearProgress style={{ height: `${height}px`, ...style }} {...rest}>
      <LinearProgressInner1 />
      <LinearProgressInner2 />
    </StyledLinearProgress>
  );
};
