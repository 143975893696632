import { ApolloProvider } from 'core/net/ApolloProvider';
import { GET_NOISE_EVENT_CLASSIFICATIONS } from './graphql/facility.getNoiseEventClassifications';
import {
  GetNoiseEventClassificationsQuery,
  GetNoiseEventClassificationsQueryVariables,
} from 'app/__generated__/global';

export type NoiseEventClassification = Awaited<ReturnType<typeof getNoiseEventClassifications>>[number];

export const getNoiseEventClassifications = async (classificationIds?: string[]) => {
  const { data } = await ApolloProvider.global().query<
    GetNoiseEventClassificationsQuery,
    GetNoiseEventClassificationsQueryVariables
  >({
    query: GET_NOISE_EVENT_CLASSIFICATIONS,
  });
  if (!data) throw new Error('Something went wrong when trying to get noise event classifications');
  const allClassifications = data.facility?.classifications?.types ?? [];
  if (!classificationIds) return allClassifications;
  return allClassifications.filter(({ id }) => classificationIds.includes(id));
};
