import { NavigationBar } from 'app/views/components/navigation-bar';
import { RightDrawer } from 'app/views/components/right-drawer';
import { Outlet } from 'react-router-dom';
import { AppWrapper, ContentWrapper } from './Layout.styles';
import { ChangeFacilityDialog } from './components/change-facility-dialog';
import { useRememberRoute } from './useRememberRoute';
import { MobileNavigationBar } from './components/mobile-navigation-bar';
import { useMobileNavRef } from './useMobileNavPortal';
import { MobileSubnavigationBar } from './components/mobile-subnavigation-bar';
import { EventsDrawer } from 'app/views/alerts/components/events-drawer';

export const LayoutWrapper = () => {
  const { mobileNav, mobileSubnav } = useMobileNavRef();

  useRememberRoute();

  return (
    <AppWrapper>
      <ChangeFacilityDialog />
      <MobileNavigationBar ref={mobileNav} />
      <MobileSubnavigationBar ref={mobileSubnav} />
      <NavigationBar />
      <ContentWrapper>
        <Outlet context={{ mobileNav: mobileNav.current, mobileSubnav: mobileSubnav.current }} />
        <EventsDrawer />
      </ContentWrapper>
      <RightDrawer />
    </AppWrapper>
  );
};
