import { useCallback, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

export const useSetLoadingProgressPercentage = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const throttleSetLoadingProgress = useThrottledCallback(setLoadingProgress, 50);
  const handleSetLoadingProgress = useCallback(
    (event: number) => {
      throttleSetLoadingProgress(event);
    },
    [throttleSetLoadingProgress]
  );
  return {
    loadingProgress,
    setLoadingProgress: handleSetLoadingProgress,
  };
};
