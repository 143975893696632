export const LANGUAGE_OPTIONS = [
  {
    key: 'english',
    label: 'English',
    value: 'en',
  },
  {
    key: 'chinese',
    label: '中文',
    value: 'zh',
  },
  {
    key: 'french',
    label: 'Français',
    value: 'fr',
  },
  {
    key: 'lithuanian',
    label: 'lietuvis',
    value: 'lt',
  },
  {
    key: 'portuguese',
    label: 'Português',
    value: 'pt',
  },
  {
    key: 'spanish',
    label: 'Español',
    value: 'es',
  },
  {
    key: 'vietnamese',
    label: 'Tieng Viet',
    value: 'vi',
  },
] as const;
