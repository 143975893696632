import { gql } from '@apollo/client';

export const FETCH_SENSOR_GROUPS = gql`
  query FetchSensorGroups {
    facility {
      id
      measurementGroups {
        id
        description
        defaultOrder
      }
    }
  }
`;
