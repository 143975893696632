import { Classes } from '@blueprintjs/core';
import { StyledFormContent } from 'app/components/form/Form.styles';
import { Link } from 'react-router-dom';
import { styled, css } from 'styled-components';
import { srOnly } from 'app/visuals/mixins/srOnly';
import { MOBILE_NAVIGATION_BAR_HEIGHT } from 'app/views/components/mobile-navigation-bar/MobileNavigationBar.styles';

export const BlastingScenarioBackLink = styled(Link)`
  display: block;
  text-align: center;
  color: ${({ theme }) => theme.colors.mono.text01} !important;
  font-size: 1.4rem;
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;

  &:hover {
    color: ${({ theme }) => theme.colors.mono.text01};
    text-decoration: none;
  }

  svg {
    vertical-align: middle;
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const CreateScenarioFormWrapper = styled.div`
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => `0 ${spacing(2)}px`};
  overflow-y: auto;

  h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.mono.text03};

    &::after {
      content: '';
      display: block;
      border-bottom: 2px solid ${({ theme }) => theme.colors.mono.ui04};
      padding-top: ${({ theme }) => theme.spacing(0.5)}px;
    }
  }

  ${StyledFormContent} {
    input {
      width: 100%;
    }
  }

  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        min-height: calc(25vh - ${MOBILE_NAVIGATION_BAR_HEIGHT}px);
      `
    )}
`;

export const FormSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;

  & > :first-child {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px !important;
  }
`;

export const StyledModelSelection = styled(FormSection)`
  /* .${Classes.FORM_GROUP} {
    margin-bottom: 0;
  } */
`;

export const RemoveSimulationTimeButton = styled.button`
  margin: auto;
`;

export const SimulationTimeFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const HiddenBlastTimeLabel = styled.label`
  ${() => srOnly}
`;

export const BlastingButton = styled.button`
  color: ${({ theme }) => theme.colors.mono.text03};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  &:disabled {
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    color: ${({ theme }) => theme.colors.mono.text01};
  }
  & svg {
    color: ${({ theme }) => theme.colors.mono.text01};
  }
`;

export const BlastingButtonText = styled.span`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing()}px;
`;
