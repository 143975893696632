import { useAppSelector } from 'app/redux/store';
import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';

import * as routes from './routes';
import * as roots from './Routing.constants';

const {
  ABOUT_ROUTES_ROOT,
  ALERTS_ROUTES_ROOT,
  ANNOTATIONS_ROUTES_ROOT,
  BLASTING_ROUTES_ROOT,
  CONFIGURATION_ROUTES_ROOT,
  GRID_ROUTES_ROOT,
  INCIDENT_INTELLIGENCE_ROUTES_ROOT,
  MODELLING_ROUTES_ROOT,
  MONITORING_ROUTES_ROOT,
  PROFILE_ROUTES_ROOT,
  REPORTS_ROUTES_ROOT,
  TRAJECTORIES_ROUTES_ROOT,
} = roots;

const {
  aboutRoutes,
  alertsRoutes,
  annotationsRoutes,
  blastingRoutes,
  configurationRoutes,
  gridRoutes,
  incidentIntelligenceRoutes,
  modellingRoutes,
  monitoringRoutes,
  profileRoutes,
  reportsRoutes,
  trajectoriesRoutes,
} = routes;

/**
 * Used to get paths meant for links,
 * e.g. `/demo/acqua-roma/trajectories/view/5c5e4450-6604-40c7-ad73-6420d3b0b292/path`,
 * as it replaces `companyAlias` and `facilityAlias` with values in the Redux store.
 * Often used alongside [generatePath](https://reactrouter.com/docs/en/v6/utils/generate-path)
 * to replace parameter placeholders with values.
 */
export const useRouting = () => {
  const { companyAlias = null, facilityAlias = null } = useAppSelector(state => state.profile.facility);
  return useMemo(
    () => ({
      generatePath,
      routes: {
        about: aboutRoutes(generatePath(ABOUT_ROUTES_ROOT, { companyAlias, facilityAlias })),
        alerts: alertsRoutes(generatePath(ALERTS_ROUTES_ROOT, { companyAlias, facilityAlias })),
        annotations: annotationsRoutes(generatePath(ANNOTATIONS_ROUTES_ROOT, { companyAlias, facilityAlias })),
        blasting: blastingRoutes(generatePath(BLASTING_ROUTES_ROOT, { companyAlias, facilityAlias })),
        configuration: configurationRoutes(generatePath(CONFIGURATION_ROUTES_ROOT, { companyAlias, facilityAlias })),
        grid: gridRoutes(generatePath(GRID_ROUTES_ROOT, { companyAlias, facilityAlias })),
        incidentIntelligence: incidentIntelligenceRoutes(
          generatePath(INCIDENT_INTELLIGENCE_ROUTES_ROOT, { companyAlias, facilityAlias })
        ),
        modelling: modellingRoutes(generatePath(MODELLING_ROUTES_ROOT, { companyAlias, facilityAlias })),
        monitoring: monitoringRoutes(generatePath(MONITORING_ROUTES_ROOT, { companyAlias, facilityAlias })),
        profile: profileRoutes(generatePath(PROFILE_ROUTES_ROOT, { companyAlias, facilityAlias })),
        reports: reportsRoutes(generatePath(REPORTS_ROUTES_ROOT, { companyAlias, facilityAlias })),
        trajectories: trajectoriesRoutes(generatePath(TRAJECTORIES_ROUTES_ROOT, { companyAlias, facilityAlias })),
      },
    }),
    [companyAlias, facilityAlias]
  );
};
