import { useEffect } from 'react';

import { WaveSurferRef } from '../types';
import { useSetIsLoading } from './useSetIsLoading';

export const useIsLoading = (waveSurferRef: WaveSurferRef) => {
  const { isLoading, setIsLoading } = useSetIsLoading();

  useEffect(() => {
    if (!waveSurferRef.current) return;
    const waveSurfer = waveSurferRef.current;

    waveSurfer.on('ready', setIsLoading);
    return () => {
      waveSurfer.un('ready', setIsLoading);
    };
  }, [setIsLoading, waveSurferRef]);
  return isLoading;
};
