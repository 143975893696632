import { gql } from '@apollo/client';

export const GET_ALERT_RESPONSE_CLASSIFICATIONS = gql(`
  query GetAlertResponseClassification {
    facility {
      alertResponseClassifications {
        types {
          id
          name
          utcDeactivatedAt
          utcDeletedAt
          hexColor
          appliedCategories {
					  categoryId
					  sortOrder
				  }
        }
      }
    }
  }
`);
