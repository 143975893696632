import { gql } from '@apollo/client';

export const FETCH_WIND_DATA_FOR_MONITOR = gql`
  query FetchWindDataForMonitor($monitorFilter: ProcessFilter, $weatherDataFilter: MonitorWeatherDataFilter) {
    facility {
      id
      processes(filter: $monitorFilter) {
        id
        ... on Monitor {
          weatherData(filter: $weatherDataFilter) {
            process {
              id
              name
            }
            windSpeedVariableData {
              processVariableId
              data
            }
            windDirectionVariableData {
              processVariableId
              data
            }
          }
        }
      }
    }
  }
`;
