import { NoiseEventClassificationCategory } from 'app/business-logic/services/noise-service/noiseService.types';
import { LeveledCategory } from './createLeveledCategories';

export type NestedCategory = {
  children: NestedCategory[];
  level: number;
} & LeveledCategory;

export const createNestedCategories = (
  categories: NoiseEventClassificationCategory[],
  level: number
): NestedCategory[] => {
  const rootCategories = categories.filter(isRootCategory);
  const hasRootCategories = !!rootCategories.length;
  return (hasRootCategories ? rootCategories : categories).sort(sortByName).map(category => {
    const childCategories = categories.filter(isChildCategory(category)).sort(sortByName);
    return { ...category, level: level + 1, children: createNestedCategories(childCategories, level + 1) };
  });
};

function isRootCategory({ parentId }: NoiseEventClassificationCategory) {
  return parentId === null;
}

function isChildCategory(parentCategory: NoiseEventClassificationCategory) {
  return (category: NoiseEventClassificationCategory) => category.parentId === parentCategory.id;
}

function sortByName(a: NoiseEventClassificationCategory, b: NoiseEventClassificationCategory) {
  return a.name.localeCompare(b.name);
}
