import { NoiseEventClassification } from 'app/business-logic/services/noise-service/noiseService.types';
import { SelectableButton } from 'app/components/selectable-button';
import { useClassificationIconSlugs } from 'app/views/alerts/hooks/use-classification-icon-slugs';
import { DATA_TRACKING_KEY } from 'app/views/components/heap-analytics';
import { noiseClassificationSlugHasIcon } from 'app/views/configuration/pages/noise/noise-classifications/noise-classification-icon/noiseClassificationSlugHasIcon';
import Guid from 'core/types/Guid';
import { ChangeEvent } from 'react';

type ClassificationButtonProps = {
  classification: NoiseEventClassification;
  isSelected: boolean;
  isLoading: boolean;
  selectClassification: (params: { id: Guid; isSelected: boolean }) => void;
};

export const ClassificationButton = ({
  classification,
  isSelected,
  isLoading,
  selectClassification,
}: ClassificationButtonProps) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    selectClassification({ id: classification.id, isSelected: e.target.checked });
  };
  const [slug] = useClassificationIconSlugs({ categoryIds: [classification.categoryId] });
  const icon = noiseClassificationSlugHasIcon(slug) ? slug : 'omnis';
  return (
    <SelectableButton
      name={classification.name}
      isSelected={isSelected}
      isLoading={isLoading}
      onChange={handleOnChange}
      icon={icon}
      buttonInnerProps={{ 'data-tracking': DATA_TRACKING_KEY['event-history-classification-buttons'] }}
    />
  );
};
