import { gql } from '@apollo/client';

export const FETCH_INFLUENCE_ARC_DATA_FOR_MONITOR = gql`
  query FetchInfluenceArcDataForMonitor($monitorFilter: ProcessFilter, $weatherDataFilter: MonitorWeatherDataFilter) {
    facility {
      id
      processes(filter: $monitorFilter) {
        ... on Monitor {
          id
          weatherData(filter: $weatherDataFilter) {
            process {
              id
              name
            }
            windSpeedVariableData {
              processVariableId
              data
            }
            windDirectionVariableData {
              processVariableId
              data
            }
            sigmaThetaVariableData {
              processVariableId
              data
            }
          }
        }
      }
    }
  }
`;
