import { Button } from 'app/components/buttons';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { useAppDispatch } from 'app/redux/store';
import { deselectAlert, openDrawer } from 'app/redux/ui/actions';
import { useI18n } from 'core/hooks/useI18n';
import Guid from 'core/types/Guid';
import { useUpdateAcknowledgeAlertEvent } from '../useUpdateAcknowledgeAlertEvent';
import { AlertAcknowledgeFooterWrapper } from './AlertAcknowledgeFooter.styles';

type AlertResponseFooterProps = {
  isLoading: boolean;
  alertId: Guid;
};

export const AlertAcknowledgeFooter = ({ isLoading, alertId }: AlertResponseFooterProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const { l10n } = useI18n('app/views/alerts', 'alertResponse');
  const { updateAcknowledgeAlertEvent, isPending: isUpdatingAcknowledgeAlertEvent } = useUpdateAcknowledgeAlertEvent();
  const handleOnConfirm = () => {
    updateAcknowledgeAlertEvent(alertId);
    dispatch(deselectAlert());
    if (isMobile) {
      dispatch(openDrawer('ALERTS'));
    }
  };
  const handleOnCancel = () => {
    dispatch(deselectAlert());
    if (isMobile) {
      dispatch(openDrawer('ALERTS'));
    }
  };
  return (
    <AlertAcknowledgeFooterWrapper>
      <Button $variant="outlined" disabled={isLoading || isUpdatingAcknowledgeAlertEvent} onClick={handleOnConfirm}>
        {l10n('actions.confirm')}
      </Button>
      <Button $variant="text" type="button" onClick={handleOnCancel}>
        {l10n('actions.discard')}
      </Button>
    </AlertAcknowledgeFooterWrapper>
  );
};
