import { AudioVisualisation } from 'app/hooks/use-wavesurfer/types';
import { styled } from 'styled-components';

export const StyledSpectrogramPlaybackContainer = styled.div<{
  $visualisation: AudioVisualisation;
  $width?: number;
  $height?: number;
}>`
  display: ${({ $visualisation }) => ($visualisation === 'spectrogram' ? 'block' : 'none')};
  width: ${({ $width }) => $width ?? 0}px;
  height: ${({ $height }) => $height ?? 0}px;
`;
