import { gql } from '@apollo/client';
import { SENSOR } from './fragment.sensor';

export const FETCH_SENSORS_FOR_SENSOR_GROUP_QUERY = gql`
  ${SENSOR}
  query FetchSensorsForSensorGroup($measurementGroupFilter: MeasurementGroupFilter) {
    facility {
      id
      measurementGroups(filter: $measurementGroupFilter) {
        id
        processVariables {
          ...Sensor
        }
      }
    }
  }
`;
