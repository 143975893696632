import { gql } from '@apollo/client';

export const GET_NOISE_EVENT_CLASSIFICATIONS = gql(`
  query GetNoiseEventClassifications {
    facility {
      classifications {
        types {
          id
          name
          categoryId
          utcDeactivatedAt
          utcDeletedAt
          disregardEvents
        }
      }
    }
  }
`);
