import {
  DateObjectUnits,
  DateTime,
  DateTimeFormatOptions,
  DateTimeUnit,
  DurationLike,
  LocaleOptions,
} from 'luxon';

const PARSE_FORMATS = ['yyyy-MM-ddTHH:mm:ss.SSS', 'yyyy-MM-ddTHH:mm:ss', 'yyyy-MM-ddTHH:mm', 'yyyy-MM-dd'];

const BROWSER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * @deprecated Use useLocalTime or useLocalTimeCallback instead
 */
export default class LocalTime {
  private static IANA_TIMEZONE: string;

  public static timeZoneOffset(): number {
    return LocalTime.now().timeZoneOffset();
  }

  public static set IanaTimeZone(ianaTimeZone: string) {
    LocalTime.IANA_TIMEZONE = ianaTimeZone;
  }

  // Construction operations

  private readonly localTime: DateTime = DateTime.now();

  constructor(localTime: DateTime) {
    this.localTime = localTime;
  }

  public static now(): LocalTime {
    const now = DateTime.fromJSDate(new Date(), { zone: LocalTime.IANA_TIMEZONE });
    return new LocalTime(now);
  }

  public static fromServer(utcDateTime: string): LocalTime {
    if (!utcDateTime) return new LocalTime(DateTime.now());

    const date = new Date(utcDateTime);
    const localTime = DateTime.fromJSDate(date, { zone: LocalTime.IANA_TIMEZONE });
    return new LocalTime(localTime);
  }

  public static fromMillis(millisSinceEpoch: number): LocalTime {
    const localTime = LocalTime.convertToLocalTime(millisSinceEpoch);
    return localTime && new LocalTime(localTime);
  }

  public static fromBrowser(dateTime: string | number | Date): LocalTime {
    const localTime = LocalTime.convertToLocalTime(dateTime);
    return localTime && new LocalTime(localTime);
  }

  public static fromISOString(isoString: string): LocalTime {
    const localTime = LocalTime.convertToLocalTime(isoString);
    return localTime && new LocalTime(localTime);
  }

  public clone(): LocalTime {
    const clone = DateTime.fromMillis(this.localTime.toMillis(), { zone: LocalTime.IANA_TIMEZONE });
    return new LocalTime(clone);
  }

  // Manipulation operations
  public set = (values: DateObjectUnits): LocalTime => new LocalTime(this.localTime.set(values));
  public startOf = (unit: DateTimeUnit): LocalTime => new LocalTime(this.localTime.startOf(unit));

  public plus = (duration: DurationLike): LocalTime =>
    new LocalTime(this.localTime.plus(duration));
  public minus = (duration: DurationLike): LocalTime =>
    new LocalTime(this.localTime.minus(duration));

  // Parts
  public get(unit: keyof DateTime) {
    return this.localTime.get(unit);
  }

  // Comparison operations
  public isSame(dateTime: LocalTime): boolean {
    return this.localTime.equals(dateTime.localTime);
  }

  public diff = (dateTime: LocalTime) => this.localTime.diff(dateTime.localTime);

  public isBefore = (dateTime: LocalTime) => this.localTime.valueOf() < dateTime.valueOf();
  public isSameOrBefore = (dateTime: LocalTime) => this.localTime.valueOf() <= dateTime.valueOf();
  public isAfter = (dateTime: LocalTime) => this.localTime.valueOf() > dateTime.valueOf();
  public isSameOrAfter = (dateTime: LocalTime) => this.localTime.valueOf() >= dateTime.valueOf();

  // public isBetween(firstDateTime: LocalDateTime, secondDateTime: LocalDateTime, granularity?: UnitOfTime.StartOf, inclusivity?: '()' | '[)' | '(]' | '[]'): boolean {
  //     return this.localDateTime.isBetween(firstDateTime.localDateTime, secondDateTime.localDateTime, granularity, inclusivity);
  // }

  public static max(...dateTimes: LocalTime[]): LocalTime {
    if (!(dateTimes && dateTimes.length)) {
      return LocalTime.now();
    }

    return dateTimes.reduce((max, facilityDateTime) => {
      if (!max) return facilityDateTime;
      if (!facilityDateTime) return max;

      return !max || facilityDateTime.isAfter(max) ? facilityDateTime : max;
    });
  }

  public static min(...dateTimes: LocalTime[]): LocalTime {
    if (!(dateTimes && dateTimes.length)) {
      return LocalTime.now();
    }

    return dateTimes.reduce((min, facilityDateTime) => {
      if (!min) return facilityDateTime;
      if (!facilityDateTime) return min;

      return facilityDateTime.isBefore(min) ? facilityDateTime : min;
    });
  }

  // Output operations
  public valueOf = () => this.localTime.valueOf();
  public toISO = () => this.localTime.toISO();
  public toUTC = () => this.localTime.toUTC();
  public toLocaleString = (options?: LocaleOptions & DateTimeFormatOptions) => this.localTime.toLocaleString(options);

  /** Returns the time as a JS date. */
  public toDate = () => this.localTime.toJSDate();

  /** Returns the time as a JS date, preserving the time of day in the source time zone. */
  public toBrowser = () => {
    return this.localTime.setZone(BROWSER_TIMEZONE, { keepLocalTime: true }).toJSDate();
  };

  public toString = () => this.localTime.toLocaleString(DateTime.DATETIME_MED);

  public timeZoneOffset = () => this.localTime.offset;
  public timeZoneOffsetNameLong = () => this.localTime.offsetNameLong;
  public timeZoneOffsetNameShort = () => this.localTime.offsetNameShort;

  /**
   * Gets the date and time string from the given time object, ignoring any timezone information.
   *
   * @param {number | string | Date | DateTime} time The time object to convert
   * @returns {string} Time string mostly compliant with ISO8601, but without any timezone information.
   */
  private static convertToLocalTime(time: number | string | Date | DateTime): DateTime {
    if (!time) return DateTime.now();

    if (DateTime.isDateTime(time)) {
      return time.setZone(LocalTime.IANA_TIMEZONE);
    }

    if (typeof time === 'number') {
      return DateTime.fromMillis(time, { zone: LocalTime.IANA_TIMEZONE });
    }

    if (time instanceof Date) {
      const browserTime = DateTime.fromJSDate(time);
      return browserTime.setZone(LocalTime.IANA_TIMEZONE, { keepLocalTime: true });
    }

    if (typeof time === 'string') {
      const utcTime = DateTime.fromISO(time, { zone: LocalTime.IANA_TIMEZONE });
      if (utcTime.isValid) return utcTime;

      for (const formatString in PARSE_FORMATS) {
        const localTime = DateTime.fromFormat(time, formatString, { zone: LocalTime.IANA_TIMEZONE });
        if (localTime.isValid) return localTime;
      }

      throw new Error(`Could not parse time string: ${time}`);
    }

    throw new Error(`Unrecognized time: ${time}`);
  }
}

export interface ITimeRangeMillis {
  startMillis: number;
  endMillis: number;
}
