import { useI18n } from 'core/hooks/useI18n';
import { useMemo, useState } from 'react';

import { MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Omnibar, OmnibarProps } from '@blueprintjs/select';

export interface IItem {
  key: any;
  description: string;
  label: string;
  searchString: string;
}

type SearchBarProps = {
  items: IItem[];
  onItemSelect: (item: IItem) => void;
  selectedItem?: IItem;
  isOpen: boolean;
} & Omit<OmnibarProps<IItem>, 'itemRenderer'>;

const OmnibarSearchBar = Omnibar.ofType<IItem>();

export const SearchBar = ({ items, onItemSelect, selectedItem, isOpen, ...rest }: SearchBarProps) => {
  const { l10n } = useI18n('app');
  const [query] = useState('');
  const [input] = useState();
  const omnibarProps = useMemo(
    () => ({
      ...rest,
      items,
      itemPredicate: filter,
      itemRenderer: renderer,
      onItemSelect,
      resetOnSelect: true,
      itemsEqual: (item1: IItem, item2: IItem) => item1.key === item2.key,
      inputProps: { placeholder: selectedItem?.description || l10n('action.select'), inputRef: input },
      query,
    }),
    [input, items, l10n, onItemSelect, query, rest, selectedItem?.description]
  );

  return (
    <OmnibarSearchBar
      isOpen={isOpen}
      // Work-around for a bug in the Omnibar
      // See: https://github.com/palantir/blueprint/pull/4368
      initialContent={undefined}
      {...omnibarProps}
    />
  );
};

const renderer: ItemRenderer<IItem> = (item, { handleClick, modifiers }) => {
  if (!modifiers.matchesPredicate) return null;
  return (
    <MenuItem
      selected={modifiers.active}
      aria-selected={modifiers.active}
      disabled={modifiers.disabled}
      label={item.label}
      key={item.key}
      onPointerUp={handleClick}
      text={item.description}
    />
  );
};

const filter: ItemPredicate<IItem> = (query, item, _index, exactMatch) => {
  if (!query) return true;

  const normalizedSearchString = (item.searchString && item.searchString.toLowerCase()) || '';
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedSearchString === normalizedQuery;
  } else {
    return normalizedSearchString.indexOf(normalizedQuery) >= 0;
  }
};
