import { PersistConfig, PersistState, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { INITIAL_STATE, MonitoringState, slice } from './slice';

// ****************************************************************************************

const currentVersion = 15; // increment this number every time the state contract changes

// ****************************************************************************************

const persistenceConfig: PersistConfig<MonitoringState> = {
  key: 'monitoring',
  storage,
  version: currentVersion,
  whitelist: ['map', 'preferredMonitoringView'],
  migrate: (state, version) => {
    if (!state || (state._persist && state._persist.version < version)) {
      return Promise.resolve(INITIAL_STATE as MonitoringState & { _persist: PersistState });
    }
    return Promise.resolve(state);
  },
};

export const persistedReducer = persistReducer<MonitoringState>(persistenceConfig, slice.reducer);
