import { useAppSelector } from 'app/redux/store';
import { useEffect } from 'react';

export const useHeapDetermineInternalUsers = () => {
  const { details } = useAppSelector(state => state.profile.user);
  const { email } = details ?? {};
  useEffect(() => {
    if (!window.heap) return;
    if (!email) return;
    const isInternal = email.includes('@envirosuite.');
    window.heap.addUserProperties({
      Is_Internal: isInternal,
    });
  }, [email]);
};
