import { UpdateAlertResponseMutation, UpdateAlertResponseMutationVariables } from 'app/__generated__/global';
import { AlertResponseFormType } from 'app/views/alerts/pages/alert-response';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { UPDATE_ALERT_RESPONSE } from './graphql/facility.updateAlertResponse';

export const updateAlertResponse = async ({
  alertEventId,
  classifications,
  comments,
}: AlertResponseFormType & { alertEventId: string }) => {
  const selectedClassifications = classifications.flatMap(({ isSelected, classification }) => {
    if (!isSelected) return [];
    return {
      alertEventId,
      alertResponseClassificationId: classification.id,
    };
  });

  const { data, errors } = await ApolloProvider.global().mutate<
    UpdateAlertResponseMutation,
    UpdateAlertResponseMutationVariables
  >({
    mutation: UPDATE_ALERT_RESPONSE,
    variables: {
      input: { alertEventId, classifications: selectedClassifications, comments },
    },
  });
  if (!data || errors) throw new Error(`Something went wrong when trying update alert response: ${alertEventId}`);
  const updatedAlertEventId = data.facility?.alertResponseClassification.updateAlertEventResponse?.alertEventId;
  return {
    alertEventId: updatedAlertEventId,
  };
};
