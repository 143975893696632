import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from 'app/business-logic/services/enums';
import { getNoiseEventClassifications } from 'app/business-logic/services/noise-service';
import { useMemo } from 'react';

export const useNoiseEventClassifications = () => {
  const { data: noiseEventClassifications, isFetching } = useQuery({
    queryFn: () => getNoiseEventClassifications(),
    queryKey: [QUERY_KEY.noiseEventClassifications],
    select: data => [...data].sort((a, b) => a.name.localeCompare(b.name)),
    staleTime: 60000,
  });
  const data = useMemo(
    () => noiseEventClassifications?.filter(({ utcDeletedAt }) => !utcDeletedAt),
    [noiseEventClassifications]
  );
  return { data, isFetching };
};
