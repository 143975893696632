import { gql } from '@apollo/client';

export const GET_ALERT_RESPONSE_CATEGORIES = gql`
  query GetAlertResponseCategories {
    lookup {
      alertResponseCategories {
        id
        name
        slug
      }
    }
  }
`;
