import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetStore } from '../actions';
import { MonitorsMapControlOption } from './constants';

export type MonitoringState = {
  map: {
    controls: {
      monitors: MonitorsMapControlOption;
    };
  };
  isAddingMonitor: boolean;
  preferredMonitoringView: 'MAP' | 'GRID';
};

export const INITIAL_STATE: MonitoringState = {
  map: {
    controls: {
      monitors: 'all',
    },
  },
  isAddingMonitor: false,
  preferredMonitoringView: 'MAP',
};

export const slice = createSlice({
  name: 'monitoring',
  initialState: INITIAL_STATE,
  reducers: {
    setMonitorsMapControl: (state, { payload }: PayloadAction<MonitorsMapControlOption>) => {
      state.map.controls.monitors = payload;
    },

    setIsAddingMonitor: (state, { payload }: PayloadAction<boolean>) => {
      state.isAddingMonitor = payload;
    },

    setPreferredMonitoringView: (state, { payload }: PayloadAction<'MAP' | 'GRID'>) => {
      state.preferredMonitoringView = payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(resetStore.type, () => {
      return INITIAL_STATE;
    });
  },
});
