import { useCallback } from 'react';
import { SearchParams } from './useSearchParams.types';
import { useSearchParams as useReactRouterSearchParams } from 'react-router-dom';
import { useAllSearchParams } from './useAllSearchParams';

export function useSetSearchParams<Key extends string>() {
  const [, setSearchParams] = useReactRouterSearchParams();
  const allSearchParams = useAllSearchParams();
  return useCallback(
    <K extends Key = Key>(searchParams: Partial<SearchParams<K>>) => {
      setSearchParams(() => {
        return {
          ...allSearchParams,
          ...searchParams,
        };
      });
    },
    [allSearchParams, setSearchParams]
  );
}
