import { HTMLAttributes, PropsWithChildren } from 'react';
import { styled } from 'styled-components';

export type FieldValidationErrorProps = PropsWithChildren<Record<never, never>> & HTMLAttributes<HTMLParagraphElement>;

const StyledFieldValidationError = styled.p`
  margin: 0;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.threshold.high};
  margin-top: ${({ theme }) => theme.spacing()}px;
`;

export const FieldValidationError = ({ children, ...rest }: FieldValidationErrorProps) => {
  return (
    <StyledFieldValidationError role="alert" {...rest}>
      {children}
    </StyledFieldValidationError>
  );
};
