import Color from 'color';
import { css } from 'styled-components';
import { THEME } from 'app/theme/theme';

export const HANDLE_SIZE = 24;
export const TRACK_HEIGHT = 4;
export const DOT_WIDTH = 20;

export const rcSliderStyles = (
  colors: {
    track?: string;
    rail?: string;
    handle?: { default?: string; hover?: string; disabled?: string };
    dot?: { default?: string; hover?: string; active?: string };
  } = {}
) => {
  const {
    track = THEME.colors.brand.brand02,
    rail = THEME.colors.mono.ui04,
    handle = {
      default: THEME.colors.brand.brand02,
      hover: THEME.colors.brand.brand02,
      disabled: THEME.colors.brand.brand02,
    },
    dot = {
      default: THEME.colors.mono.text01,
      hover: THEME.colors.brand.brand02,
      active: THEME.colors.brand.brand02,
    },
  } = colors;

  return css`
    .rc-slider-track {
      background-color: ${track};
    }

    .rc-slider-rail {
      background-color: ${rail};
    }

    .rc-slider-step {
      height: ${TRACK_HEIGHT}px;
      pointer-events: auto;
    }

    .rc-slider-handle {
      background-color: ${handle.default};
      border: 0 !important;
      transform: translate(-50%, -50%) !important;
      margin-top: 2px !important;
      width: ${HANDLE_SIZE}px;
      height: ${HANDLE_SIZE}px;
      box-shadow: none;
      opacity: 1;
    }

    .rc-slider-handle:hover,
    .rc-slider-handle-click-focused:focus {
      background-color: ${handle.hover};
    }

    .rc-slider-handle-dragging {
      box-shadow: 0 0 0 5px ${Color(handle.default).fade(0.5).toString()} !important;
    }

    .rc-slider-handle:focus-visible {
      box-shadow: 0 0 0 3px ${Color(handle.default).fade(0.5).toString()} !important;
    }

    .rc-slider-dot {
      border: 0;
      width: ${DOT_WIDTH}px;
      height: ${DOT_WIDTH}px;
      bottom: -${DOT_WIDTH / 2 - TRACK_HEIGHT / 2}px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        background-color: ${dot.default};
        content: '';
        width: ${TRACK_HEIGHT * 2}px;
        height: ${TRACK_HEIGHT * 2}px;
        display: block;
        border-radius: 50%;
      }

      &:hover {
        &::before {
          background-color: ${dot.hover};
          box-shadow: 0 0 0 ${TRACK_HEIGHT}px ${dot.default};
        }
      }
    }

    .rc-slider-dot-active {
      &::before {
        box-shadow: 0 0 0 ${TRACK_HEIGHT}px ${dot.active};
      }
      &:hover {
        &::before {
          background-color: ${dot.hover};
          box-shadow: 0 0 0 ${TRACK_HEIGHT}px ${dot.default};
        }
      }
    }

    .rc-slider-disabled {
      background-color: transparent;
      opacity: 0.6;
      .rc-slider-handle {
        background-color: ${handle.disabled};
        border-color: transparent;
      }
    }

    .rc-slider-mark-text {
      user-select: none;
    }
  `;
};
