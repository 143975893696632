import { useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { WaveSurferRef } from './types';

export const usePlay = (waveSurferRef: WaveSurferRef): WaveSurfer['play'] => {
  return useCallback(
    async (...args) => {
      waveSurferRef.current?.play(...args);
    },
    [waveSurferRef]
  );
};
