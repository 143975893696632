import { styled, css } from 'styled-components';
import { Button, Classes } from '@blueprintjs/core';
import ChevronIcon from 'app/visuals/images/icons/chevron-right.svg?react';

const isCurrentStyles = css`
  background-color: ${({ theme }) => theme.colors.mono.text01};
  color: ${({ theme }) => theme.colors.mono.ui01};
  &:hover {
    background-color: ${({ theme }) => theme.colors.mono.text01};
    color: ${({ theme }) => theme.colors.mono.ui01};
  }
`;

const sharedNextPrevButtonStyles = css`
  background-color: transparent;
  background-image: none;
  color: ${({ theme }) => theme.colors.mono.text01};
  box-shadow: none;
  padding: ${({ theme }) => theme.spacing(0.5)}px;
  aspect-ratio: 1/1;
  &:hover {
    background-color: ${({ theme }) => theme.colors.mono.ui04};
  }

  &[disabled] {
    opacity: 0.5;
    &:hover {
      background-color: transparent;
    }
  }
  .${Classes.BUTTON_TEXT} {
    display: flex;
    align-items: center;
  }
  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand.brand02};
  }
`;

export const Wrapper = styled.div`
  margin-top: ${({ theme: { spacing } }) => spacing(3)}px;
  margin-left: 2px;
  flex-shrink: 0;
  position: relative;
  top: -2px;
  display: flex;
  font-variant-numeric: tabular-nums;
`;

export const Items = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const PageButton = styled(Button)<{ $isCurrent: boolean }>`
  &&&& {
    background-color: transparent;
    background-image: none;
    color: ${({ theme }) => theme.colors.mono.text01};
    font-weight: ${({ theme }) => theme.font.default.weight.bold};
    box-shadow: none;
    padding: ${({ theme }) => theme.spacing(0.5)}px;
    aspect-ratio: 1/1;

    &:hover {
      background-color: ${({ theme }) => theme.colors.mono.ui04};
    }

    ${({ $isCurrent }) => ($isCurrent ? isCurrentStyles : '')}

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.brand.brand02};
    }
  }
`;

export const PrevButton = styled(Button)`
  &&&& {
    ${() => sharedNextPrevButtonStyles};
  }
`;

export const NextButton = styled(Button)`
  &&&& {
    ${() => sharedNextPrevButtonStyles};
  }
`;

const Icon = styled(ChevronIcon)`
  width: 12px;
  height: 12px;
`;

export const PrevPageIcon = styled(Icon)`
  transform: rotate(180deg);
`;

export const NextPageIcon = styled(Icon)``;

export const Dots = styled.span`
  display: inline-block;
  padding: ${({ theme: { spacing } }) => spacing()}px;
  color: ${({ theme }) => theme.colors.mono.text01};
  font-weight: ${({ theme }) => theme.font.default.weight.bold};
  text-align: center;
`;

export const ItemsCount = styled.span`
  color: ${({ theme }) => theme.colors.mono.text02};
  margin: auto;
  margin-right: ${({ theme: { spacing } }) => spacing(3)}px;
`;
