import Guid from 'core/types/Guid';
import { EventsChartLoading, StyledEventsChart } from './EventsChart.styles';
import { useEventsChartOptions } from './use-events-chart-options';

interface EventsChartProps {
  selectedAlertId: Guid;
  selectAlertEvent: (alertEventId: number) => void;
}

export const EventsChart = ({ selectedAlertId, selectAlertEvent }: EventsChartProps) => {
  const { options, isLoading } = useEventsChartOptions(selectedAlertId, selectAlertEvent);
  if (isLoading) return <EventsChartLoading />;
  if (options.series?.length === 0) return null;
  return <StyledEventsChart options={options} />;
};
