import { THEME } from 'app/theme/theme';
import { styled } from 'styled-components';
import Color from 'color';

const UNREAD_INDICATOR_SIZE = THEME.spacing();
const UNREAD_INDICATOR_SPACING = THEME.spacing(2);

const TITLE_PADDING = UNREAD_INDICATOR_SIZE + UNREAD_INDICATOR_SPACING;

export const UnreadIndicator = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${({ theme }) => theme.colors.brand.brand02};
  width: ${UNREAD_INDICATOR_SIZE}px;
  height: ${UNREAD_INDICATOR_SIZE}px;
  margin: ${UNREAD_INDICATOR_SPACING}px;
  border-radius: 50%;
`;

export const AlertCardWrapper = styled.button<{ $thresholdColour?: string; $selected: boolean }>`
  position: relative;
  border: 1px solid ${({ theme, $thresholdColour }) => $thresholdColour ?? theme.colors.mono.ui05};
  background-color: ${({ $thresholdColour, theme, $selected }) =>
    $selected
      ? Color($thresholdColour ?? theme.colors.mono.ui05)
          .fade(0.8)
          .toString()
      : Color($thresholdColour ?? theme.colors.mono.ui05)
          .fade(0.95)
          .toString()};
  border-bottom-width: 10px;
  padding: ${({ theme }) => theme.spacing(2)}px ${({ theme }) => theme.spacing(2)}px;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;

  &:hover,
  &:focus-visible {
    background-color: ${({ $thresholdColour, theme }) =>
      Color($thresholdColour ?? theme.colors.mono.ui05)
        .fade(0.85)
        .toString()};
  }

  &:active {
    background-color: ${({ $thresholdColour, theme }) =>
      Color($thresholdColour ?? theme.colors.mono.ui05)
        .fade(0.8)
        .toString()};
  }

  & + & {
    margin-top: ${({ theme }) => theme.spacing()}px;
  }

  && {
    color: ${({ theme }) => theme.colors.mono.text01};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.colors.mono.text01};
    }
  }
`;

export const AlertTitle = styled.h2`
  font-size: 1.6rem;
  line-height: 1.1;
  margin: 0;
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: ${TITLE_PADDING}px;
  font-weight: ${({ theme }) => theme.font.default.weight.bold};
  letter-spacing: 0.02em;
`;

export const AlertDescription = styled.p`
  font-size: 1.4rem;
  line-height: 1.2;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: ${({ theme }) => theme.spacing(0.5)}px;
  font-weight: ${({ theme }) => theme.font.default.weight.light};
`;

export const AlertCardFooter = styled.div`
  font-size: 1.6rem;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  line-height: 1;
`;

export const AlertValueWrapper = styled.div``;

export const AlertValue = styled.span<{ $textColor?: string }>`
  color: ${({ $textColor, theme }) => ($textColor ? $textColor : theme.colors.mono.ui06)};
  font-weight: ${({ theme }) => theme.font.default.weight.bold};
`;

export const AlertUnit = styled.span`
  color: ${({ theme }) => theme.colors.mono.text02};
  font-weight: ${({ theme }) => theme.font.default.weight.normal};
  margin-right: 0.2em;
  margin-left: 0.2em;
`;

export const AlertTime = styled.span`
  color: ${({ theme }) => theme.colors.mono.ui06};
  margin-right: 0.2em;
  font-size: 1.6rem;
`;
