import { useEffect } from 'react';
import { connect } from 'react-redux';
import { IGlobalState } from 'app/redux/reducer';
import { i18nInstance } from 'i18n';

type StateProps = ReturnType<typeof mapStateToProps>;

const LanguageLocalizer = ({ locale }: StateProps) => {
  const { language } = i18nInstance;

  useEffect(() => {
    if (locale && language !== locale) {
      i18nInstance.changeLanguage(locale);
    }
  }, [locale, language]);

  return null;
};

const mapStateToProps = (state: IGlobalState) => {
  const { locale } = state.profile.user?.details ?? { locale: null };
  return { locale };
};

export default connect(mapStateToProps)(LanguageLocalizer);
