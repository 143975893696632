import { DateRange, DateRangePicker, DateRangePickerProps } from '@blueprintjs/datetime';
import { StyledDateRangeSelector, Title } from './DateRangeSelector2.styles';
import { ReactNode, useCallback, useEffect, useState, ComponentProps } from 'react';
import { useShortcuts } from './useShortcuts';
import { Classes, Position, PopoverProps, Popover } from '@blueprintjs/core';
import { usePreventPopoverDismiss } from './usePreventPopoverDismiss';
import { styled } from 'styled-components';
import ClearIcon from 'app/visuals/images/icons/cross-in-circle.svg?react';
import { useI18n } from 'core/hooks/useI18n';
import { i18nInstance } from 'i18n';
import { localeUtils, DEFAULT_LOCALE } from '../date-selectors/date-time-selector/localeUtils';
import { useDateRangePopoverOffset } from './useDateRangePopoverOffset';
import { useIsMobile } from 'app/hooks/useIsMobile';
import { Button } from 'app/components/buttons';
import { DataAttributeProps } from 'app/types';

export type IProps = DateRangePickerProps & {
  title?: ReactNode;
  popoverProps?: PopoverProps;
  children?: ReactNode;
  onSave: (selectedDates: DateRange) => void;
  onClear: () => void;
  saveButtonProps?: ComponentProps<typeof Button> & DataAttributeProps;
  clearButtonProps?: ComponentProps<typeof Button> & DataAttributeProps;
};

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()}px;
`;

const Apply = styled(Button)`
  && {
    font-weight: normal;
    border: 2px solid ${({ theme }) => theme.colors.mono.text02};
    transition:
      border-color 0.2s ease,
      color 0.2s ease;
    &:hover {
      border-color: ${({ theme }) => theme.colors.mono.text01};
    }
    &:disabled {
      border-color: ${({ theme }) => theme.colors.mono.text02};
    }
  }
`;

const Clear = styled(Button)`
  && {
    padding: ${({ theme }) => theme.spacing()}px;
    font-weight: normal;
    border: 2px solid transparent;
    & > * {
      display: inline-flex;
      align-items: center;
      gap: ${({ theme }) => theme.spacing()}px;
    }
  }
`;

const Selector = (props: IProps) => {
  const { l10n } = useI18n('app/components', 'dates');
  const defaultShortcuts = useShortcuts();
  const {
    title,
    shortcuts = defaultShortcuts,
    allowSingleDayRange = true,
    value,
    onSave,
    onClear,
    dayPickerProps,
    saveButtonProps,
    clearButtonProps,
    ...rest
  } = props;
  const handleSave = useCallback(() => {
    if (value) onSave(value);
  }, [value, onSave]);
  const isMobile = useIsMobile();
  const dayPickerContainerProps: Record<string, string> = { 'data-testid': 'day-picker' };

  return (
    <StyledDateRangeSelector data-testid="date-range-selector">
      {title && <Title>{title}</Title>}
      <DateRangePicker
        shortcuts={shortcuts}
        allowSingleDayRange={allowSingleDayRange}
        value={value}
        locale={i18nInstance.language ?? DEFAULT_LOCALE}
        localeUtils={localeUtils}
        singleMonthOnly={isMobile ? true : undefined}
        dayPickerProps={{
          containerProps: dayPickerContainerProps,
          ...dayPickerProps,
        }}
        {...rest}
      />
      <ButtonsWrapper>
        <Apply
          $variant="text"
          {...saveButtonProps}
          onClick={e => {
            saveButtonProps?.onClick?.(e);
            handleSave();
          }}
          className={Classes.POPOVER_DISMISS}
        >
          {l10n('apply')}
        </Apply>
        <Clear
          $variant="text"
          {...clearButtonProps}
          onClick={e => {
            clearButtonProps?.onClick?.(e);
            onClear();
          }}
          className={Classes.POPOVER_DISMISS}
        >
          <ClearIcon /> {l10n('clear')}
        </Clear>
      </ButtonsWrapper>
    </StyledDateRangeSelector>
  );
};

export const DateRangeSelector2 = (props: IProps) => {
  const { l10n } = useI18n('app/components', 'date');
  const { popoverProps, children, onChange, value, onClear, ...rest } = props;
  const [startValue, endValue] = value ?? [];
  const { temporaryValue, setTemporaryValue } = useTemporaryValue(props.value);
  const { popoverNode, setPopoverNode, preventPopoverDismiss } = usePreventPopoverDismiss();
  const onOpened = popoverProps?.onOpened;
  const onOpening = popoverProps?.onOpening;
  const handleOnOpened = useCallback(
    (node: HTMLElement) => {
      preventPopoverDismiss(node);
      setPopoverNode(node);
      if (onOpened) onOpened(node);
    },
    [onOpened, preventPopoverDismiss, setPopoverNode]
  );
  const handleOnOpening = useCallback(
    (node: HTMLElement) => {
      if (!startValue || !endValue) setTemporaryValue([null, null]);
      if (onOpening) onOpening(node);
    },
    [startValue, endValue, setTemporaryValue, onOpening]
  );
  const handleOnChange = useCallback(
    (selectedDates: DateRange) => {
      setTemporaryValue(selectedDates);
      if (onChange) onChange(selectedDates);
      if (popoverNode) preventPopoverDismiss(popoverNode);
    },
    [setTemporaryValue, onChange, popoverNode, preventPopoverDismiss]
  );
  const handleClear = useCallback(() => {
    setTemporaryValue([null, null]);
    onClear();
  }, [onClear, setTemporaryValue]);
  const selectorProps: IProps = {
    title: l10n('selectDateRange'),
    ...rest,
    value: temporaryValue,
    onChange: handleOnChange,
    onClear: handleClear,
  };
  const offset = useDateRangePopoverOffset();

  if (!popoverProps) return <Selector {...selectorProps} />;

  const {
    minimal = true,
    modifiers = {
      offset: {
        enabled: true,
        options: {
          offset,
        },
      },
    },
    position = Position.BOTTOM_LEFT,
    ...restPopoverProps
  } = popoverProps;

  return (
    <Popover
      minimal={minimal}
      modifiers={modifiers}
      position={position}
      {...restPopoverProps}
      onOpened={handleOnOpened}
      onOpening={handleOnOpening}
      content={<Selector {...selectorProps} />}
    >
      {children}
    </Popover>
  );
};

function useTemporaryValue(value: DateRange | undefined) {
  const [temporaryValue, setTemporaryValue] = useState(value);

  // Keep actual value and temporary value in sync
  useEffect(() => {
    const [from, to] = value ?? [];
    if (!from || !to) return;
    setTemporaryValue(value);
  }, [value]);

  return {
    temporaryValue,
    setTemporaryValue,
  };
}
