import { styled, keyframes } from 'styled-components';
import Color from 'color';

export const indeterminate1 = keyframes`
  0% {
      left: -35%;
      right: 100%;
  }
  60% {
      left: 100%;
      right: -90%;
  }
  100% {
      left: 100%;
      right: -90%;
  }
`;

export const indeterminate2 = keyframes`
  0% {
      left: -200%;
      right: 100%;
  }
  60% {
      left: 107%;
      right: -8%;
  }
  100% {
      left: 107%;
      right: -8%;
  }
`;

export const StyledLinearProgress = styled.span`
  position: relative;
  overflow: hidden;
  display: block;
  background-color: ${Color('#59cbe8')
    .fade(0.5)
    .toString()}; // Not using theme so component can be used outside of theme context, e.g. Suspense
`;

export const LinearProgressInner = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: ${'#59cbe8'}; // Not using theme so component can be used outside of theme context, e.g. Suspense
`;

export const LinearProgressInner1 = styled(LinearProgressInner)`
  animation: ${indeterminate1} 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
`;

export const LinearProgressInner2 = styled(LinearProgressInner)`
  animation: ${indeterminate2} 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
`;
