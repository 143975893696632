import { CircularButton } from 'app/components/buttons/circular-button';
import { useAppDispatch, useAppSelector } from 'app/redux/store';
import { openChangeFacilityDialog } from 'app/redux/ui/actions';
import { useI18n } from 'core/hooks/useI18n';
import { useCallback } from 'react';

import { useCanChangeFacility } from '../change-facility-dialog/useCanChangeFacility';
import { LocationSelectorIcon } from './ChangeFacilityButton.styles';
import { DATA_TRACKING_KEY } from '../heap-analytics';

export const ChangeFacilityButton = () => {
  const { l10n } = useI18n('app/views/components', 'subnav.changeFacility');
  const dispatch = useAppDispatch();
  const { changeFacilityDialogIsOpen: isOpen } = useAppSelector(state => state.ui);
  const openDialog = useCallback(() => dispatch(openChangeFacilityDialog()), [dispatch]);
  const canChangeFacility = useCanChangeFacility();

  if (!canChangeFacility) return null;

  return (
    <CircularButton
      $isActive={isOpen}
      onClick={openDialog}
      title={l10n('label')}
      data-tracking={DATA_TRACKING_KEY['monitoring-change-facility-button']}
    >
      <LocationSelectorIcon />
    </CircularButton>
  );
};
