import { Classes } from '@blueprintjs/core';
import { useCallback, useState } from 'react';

// When a "shortcut" date is selected, e.g. "Today", if the date range selector
// is in a popover, the popover is closed. We need to prevent this because the
// user needs to hit the "Apply" button within the popover to actually set the date.
// The popover is closed due to "dismiss" class names that are applied to each
// shortcut item. The workaround is to remove these class names.
// https://github.com/palantir/blueprint/issues/3338
export const usePreventPopoverDismiss = () => {
  const [popoverNode, setPopoverNode] = useState<HTMLElement>();
  const preventPopoverDismiss = useCallback((node: HTMLElement) => {
    node.querySelectorAll(`.${Classes.MENU_ITEM}`).forEach(el => {
      el.classList.remove(Classes.POPOVER_DISMISS);
    });
  }, []);
  return {
    popoverNode,
    setPopoverNode,
    preventPopoverDismiss,
  };
};
