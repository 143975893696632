import { styled, css } from 'styled-components';

export const ChartWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        flex-direction: column;
      `
    )}
`;

export const StyledChart = styled.div`
  width: 100%;
  overflow: hidden; // Prevent chart overflow

  & .highcharts-reset-zoom {
    & .highcharts-button-box {
      fill: ${({ theme }) => theme.colors.mono.ui04};
      stroke-width: 0;
    }
    &:hover {
      & .highcharts-button-box {
        fill: ${({ theme }) => theme.colors.mono.ui05};
        border-radius: 3px;
      }
    }
    & text {
      color: ${({ theme }) => theme.colors.mono.text01} !important;
      fill: ${({ theme }) => theme.colors.mono.text01} !important;
      line-height: 1;
      font-size: 1.4rem;
      transform: translateY(2px);
    }
  }
`;
