import { css } from 'styled-components';

export const reset = css`
  html {
    &.-no-scroll,
    &.-no-scroll body {
      overflow: hidden;
      position: relative;
      height: 100%;
    }
  }

  body {
    position: fixed;
    overflow: hidden;
    width: 100vw;
    height: 100vh; // Fallback before JS loads
    height: var(--doc-height);
    margin: 0;
    padding: 0;
    -webkit-text-size-adjust: 100%; // Fix for iOS
    background: white;

    &.touch-enabled {
      cursor: pointer;

      * {
        cursor: pointer !important;
      }
    }
  }

  * {
    -webkit-overflow-scrolling: touch;
  }

  *,
  *:before,
  *:after {
    font-smoothing: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  a,
  button {
    text-decoration: none;
    color: inherit;
    transition: color 0.15s;
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    line-height: normal;

    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &.boxed {
      margin: 0 auto;
      display: block;
      font-size: 1em;
      text-transform: uppercase;
      border: 1px solid currentColor;
      line-height: 1em;
      padding: 0.6em 2em;
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  form,
  input,
  textarea,
  select,
  button {
    font-size: 1em;
  }

  ::-webkit-input-placeholder {
    opacity: 0.5;
  }

  ::-moz-placeholder {
    opacity: 0.5;
  }

  :-ms-input-placeholder {
    opacity: 0.5;
  }

  :-moz-placeholder {
    opacity: 0.5;
  }
`;
