import { gql } from '@apollo/client';

export const FETCH_SENSOR_DATA_FOR_SENSORS = gql`
  query FetchSensorDataForSensors($sensorFilter: VariableFilter, $dataFilter: VariableDataFilter) {
    facility {
      id
      processVariables(filter: $sensorFilter) {
        id
        staleDataTimeoutSeconds
        variableData(filter: $dataFilter) {
          data
        }
      }
    }
  }
`;
