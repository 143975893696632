import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultState } from './Context';
import { FilterName, SelectedSimulation } from './types';

const slice = createSlice({
  name: 'blasting',
  initialState: defaultState,
  reducers: {
    setStatusFilter: (stateDraft, { payload }: PayloadAction<string>) => {
      stateDraft.filters.status = payload;
    },
    unsetFilter: (stateDraft, { payload }: PayloadAction<FilterName>) => {
      delete stateDraft.filters[payload];
    },
    unsetFilters: stateDraft => {
      stateDraft.filters = {};
    },
    selectBlastSimulation: (stateDraft, { payload }: PayloadAction<SelectedSimulation>) => {
      stateDraft.selectedBlastSimulations.push(payload);
    },
    deselectBlastSimulation: (stateDraft, { payload }: PayloadAction<string>) => {
      stateDraft.selectedBlastSimulations.splice(
        stateDraft.selectedBlastSimulations.findIndex(simulation => simulation.simulationKey === payload),
        1
      );
    },
    deselectAllSimulations: stateDraft => {
      stateDraft.selectedBlastSimulations = [];
    },
  },
});
export const { reducer, actions } = slice;
export const {
  setStatusFilter,
  unsetFilter,
  unsetFilters,
  selectBlastSimulation,
  deselectBlastSimulation,
  deselectAllSimulations,
} = actions;
