import { Button } from 'app/components/buttons';
import { srOnly } from 'app/visuals/mixins/srOnly';
import { styled, css } from 'styled-components';

export const DownloadAudioButton = styled(Button)`
  && {
    font-weight: normal;
  }
`;

export const DownloadAudioButtonInner = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()}px;
`;

export const ButtonText = styled.span`
  font-size: 1.4rem;
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        ${srOnly}
      `
    )}
`;
