import { monitorHasSecondlyNoiseConfig } from 'app/business-logic/helpers/monitorHasSecondlyNoiseConfig';
import { useMemo } from 'react';
import { LogManager } from 'core/logging/LogManager';
import { IMonitorWithSecondlyNoiseConfig } from 'app/business-logic/domain-models/Monitoring';
import { useMonitors } from 'app/hooks/useMonitors';

const logger = LogManager.getLogger('useNoiseMonitors');

export function useNoiseMonitors() {
  const { data: monitors } = useMonitors();
  return useMemo(
    () =>
      monitors
        ?.flatMap(monitor => {
          if (!monitorHasSecondlyNoiseConfig(monitor)) return [];
          return monitor;
        })
        .filter(deviceIdIsUnique) ?? [],
    [monitors]
  );
}

type Filter = Parameters<Array<IMonitorWithSecondlyNoiseConfig>['filter']>[0];

const deviceIdIsUnique: Filter = (monitor, index, arr) => {
  const firstInstanceIndex = arr.findIndex(
    m => m.omnisConfigJson.config.deviceId === monitor.omnisConfigJson.config.deviceId
  );
  const firstInstance = arr[firstInstanceIndex]!;
  const isUnique = firstInstanceIndex === index;
  if (isUnique) return true;
  logger.warn(
    `Process "${monitor.name}" (${monitor.id}) has been configured with the same Omnis device ID as "${firstInstance.name}" (${firstInstance.id})`
  );
  return false;
};
