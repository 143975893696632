export enum PlaybackControlButtonIcon {
  play = 'play',
  pause = 'pause',
  rewind = 'rewind',
  skip = 'skip',
}

export enum PlaybackControlButtonStatus {
  idle = 'idle',
  active = 'active',
  buffering = 'buffering',
}
