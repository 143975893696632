import {
  GetNoiseEventClassificationCategoriesQuery,
  GetNoiseEventClassificationCategoriesQueryVariables,
} from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { GET_NOISE_EVENT_CLASSIFICATION_CATEGORIES } from './graphql/lookup.getNoiseEventClassificationCategories';
import { NoiseEventClassificationCategory } from './noiseService.types';
import { LogManager } from 'core/logging/LogManager';

const logger = LogManager.getLogger('getNoiseEventClassificationCategories');

type CategoryWithOptionalFields = GetNoiseEventClassificationCategoriesQuery['lookup']['categories'][number];

export const getNoiseEventClassificationCategories = async () => {
  const { data } = await ApolloProvider.global().query<
    GetNoiseEventClassificationCategoriesQuery,
    GetNoiseEventClassificationCategoriesQueryVariables
  >({
    query: GET_NOISE_EVENT_CLASSIFICATION_CATEGORIES,
  });
  if (!data) throw new Error('Something went wrong when trying to getch noise event classification categories');
  return data.lookup.categories.flatMap(requiredFields);
};

function requiredFields({ id, name, parentId, slug }: CategoryWithOptionalFields): NoiseEventClassificationCategory[] {
  if (!id || !name || !slug) {
    logger.warn(`Invalid category: ${JSON.stringify({ id, name, slug })}`);
    return [];
  }
  return [
    {
      id,
      name,
      parentId: parentId ?? null,
      slug,
    },
  ];
}
