import { useMemo } from 'react';

import { Visibility } from './DrawerWithHandle';

export const useOffset = ({
  handleHeight,
  drawerVisibility,
}: {
  handleHeight: number;
  drawerVisibility: Visibility;
}) => {
  return useMemo(() => {
    if (drawerVisibility === 'hidden') return '0px';
    if (drawerVisibility === 'visible') return '-100%';
    return `-${Math.round(handleHeight)}px`;
  }, [drawerVisibility, handleHeight]);
};
