import { GetAlertResponseClassificationQuery } from 'app/__generated__/global';
import { ApolloProvider } from 'core/net/ApolloProvider';
import { GET_ALERT_RESPONSE_CLASSIFICATIONS } from './graphql/facility.getAlertResponseClassifications';

export const getAlertResponseClassifications = async () => {
  const { data, error } = await ApolloProvider.global().query<GetAlertResponseClassificationQuery>({
    query: GET_ALERT_RESPONSE_CLASSIFICATIONS,
  });

  if (!data || error) throw new Error('Something went wrong when trying to get alert response classifications');

  return data.facility?.alertResponseClassifications?.types.map(type => {
    return {
      id: type.id,
      name: type.name,
      utcDeactivatedAt: type.utcDeactivatedAt,
      utcDeletedAt: type.utcDeletedAt,
      hexColor: type.hexColor,
      appliedCategories: type.appliedCategories.map(appliedCategory => ({
        categoryId: appliedCategory.categoryId,
        sortOrder: appliedCategory.sortOrder,
      })),
    };
  });
};
