import { httpClient } from 'core/net/http';
import { IUser } from 'app/business-logic/domain-models/User';
import HttpResponse from 'core/net/HttpResponse';

export async function fetchUserDetails(companyAlias: string, facilityAlias: string) {
  const { data }: HttpResponse<IUser> = await httpClient.useInternalApi
    .withCredentials(companyAlias, facilityAlias)
    .get('user');
  if (!data) throw new Error('Something went wrong when fetching user details');

  const { id, username, email, firstName, lastName, locale } = data;

  return {
    id,
    username,
    email,
    firstName,
    lastName,
    locale,
  };
}
