import { Switch as BlueprintSwitch } from '@blueprintjs/core';
import { Theme } from 'app/theme/theme';
import Color from 'color';
import { styled } from 'styled-components';

const controlIndicator = (theme: Theme) => `.${theme.blueprint.namespace}-control-indicator`;

export const StyledSwitch = styled(BlueprintSwitch)`
  &&&&& {
    font-size: 1.4rem;

    ${({ theme }) => controlIndicator(theme)} {
      background-color: ${({ theme }) => theme.colors.mono.ui05};
      &:before {
        background-color: ${({ theme }) => theme.colors.mono.ui06};
        box-shadow: none;
      }
    }
    &:hover {
      ${({ theme }) => controlIndicator(theme)} {
        background-color: ${({ theme }) => Color(theme.colors.mono.ui05).darken(0.25).hex()};
        &:before {
          background-color: ${({ theme }) => theme.colors.mono.ui06};
          box-shadow: none;
        }
      }
      input:checked ~ {
        ${({ theme }) => controlIndicator(theme)} {
          background-color: ${({ theme }) => Color(theme.colors.brand.brand01).darken(0.25).hex()};
        }
      }
    }
    input:checked ~ {
      ${({ theme }) => controlIndicator(theme)} {
        background-color: ${({ theme }) => theme.colors.brand.brand01};
      }
    }
    input:disabled ~ {
      ${({ theme }) => controlIndicator(theme)} {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
`;
