import {
  FetchMapMonitorsQuery,
  FetchMapMonitorsQueryVariables,
  ProcessClass,
  ProcessType,
} from 'app/__generated__/global';
import { IMonitor, IMonitorWithLocation } from 'app/business-logic/domain-models/Monitoring';
import { ApolloProvider } from 'core/net/ApolloProvider';
import Guid from 'core/types/Guid';

import { FETCH_MAP_MONITORS } from './graphql/facility.fetchMapMonitors';

type MonitorFilter = NonNullable<FetchMapMonitorsQueryVariables['filter']>;

export type FetchMapMonitorsParams = {
  monitorSensorIds?: Guid[];
  includeHidden?: boolean;
  type_in?: ProcessType[];
};

export function fetchMapMonitors({
  monitorSensorIds = [],
  includeHidden = false,
  type_in,
}: FetchMapMonitorsParams = {}): Promise<IMonitor[]> {
  const filter: MonitorFilter = {
    processVariable_in: monitorSensorIds,
    processClass_is: ProcessClass.Monitor,
    type_in,
  };

  if (includeHidden) {
    filter['include_hidden'] = includeHidden;
  }

  return fetchMonitors(filter);
}

async function fetchMonitors(filter: MonitorFilter) {
  const { data, errors } = await ApolloProvider.global().query<FetchMapMonitorsQuery, FetchMapMonitorsQueryVariables>({
    query: FETCH_MAP_MONITORS,
    variables: { filter },
    errorPolicy: 'ignore',
  });

  if (errors) {
    throw new Error(errors.map(({ message }) => message).join(', '));
  }

  const monitors: IMonitorWithLocation[] =
    data.facility?.processes.flatMap(monitor => {
      if (!('location' in monitor)) return [];
      const { location, omnisConfigJson, ...rest } = monitor;
      if (!location) return [];
      if (!('latitude' in location) || !('longitude' in location) || !('elevation' in location)) return [];
      const { latitude, longitude, elevation } = location;
      return [
        {
          ...rest,
          ...(omnisConfigJson ? { omnisConfigJson: JSON.parse(omnisConfigJson) } : {}),
          location: {
            latitude,
            longitude,
            elevationMeters: elevation ?? undefined,
          },
        },
      ];
    }) ?? [];

  return monitors.sort((left: IMonitor, right: IMonitor) => left.name.localeCompare(right.name));
}
