import { AccessControls } from 'app/redux/profile/user/slice';
import { LogManager } from 'core/logging/LogManager';
import HttpResponse from 'core/net/HttpResponse';
import { httpClient } from 'core/net/http';
import Guid from 'core/types/Guid';
const logger = LogManager.getLogger('fetchAccessControls');

export async function fetchAccessControls(companyId: Guid, facilityId: Guid) {
  try {
    const response: HttpResponse<AccessControls> = await httpClient.useInternalApi
      .withCredentials()
      .get('security/access-controls', { companyId, facilityId });

    if (!response.isSuccess) {
      logger.error('[FetchAccessControls] error', response.error);
      return null;
    }
    return response.data;
  } catch (error) {
    logger.error('[FetchAccessControls] error', error);
    return null;
  }
}
