import { OPENING_DELAY_MS } from 'app/views/components/navigation-bar/useDesktopNavigationVisibility';
import LogoSvg from 'app/visuals/images/logo.svg?react';
import { Link } from 'react-router-dom';
import { styled, css } from 'styled-components';

export const NAV_BAR_WIDTH = 72;
export const NAV_BAR_TRANSITION_SPEED = 0.2;

const NAV_BAR_WIDTH_OPEN = 320;
const SIDE_BAR_LINK_HEIGHT = 65;
const SIDE_BAR_LINK_FONT_SIZE = '1.6rem';

const LOGO_WIDTH = 89;
const LOGO_HEIGHT = 30;

const textVisibleStyles = css`
  transition-property: opacity;
  transition-timing-function: linear;
  transition-duration: ${NAV_BAR_TRANSITION_SPEED}s;
  transition-delay: ${NAV_BAR_TRANSITION_SPEED * 0.5}s;
  opacity: 1;
`;

const textHiddenStyles = css`
  transition-duration: ${NAV_BAR_TRANSITION_SPEED * 0.5}s;
  transition-delay: 0s;
  opacity: 0;
`;

export const StyledSideBar = styled.nav<{ $isOpen?: boolean }>`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.mono.ui02};
  width: ${NAV_BAR_WIDTH}px;
  height: 100vh; // Fallback for safety, in case --doc-height goes missing
  height: var(--doc-height);
  z-index: ${({ theme }) => theme.zIndex.headerBar + 1};
  display: flex;
  flex-direction: column;
  transition:
    width ${NAV_BAR_TRANSITION_SPEED}s ease-out,
    box-shadow ${NAV_BAR_TRANSITION_SPEED}s ease-out;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      width: ${NAV_BAR_WIDTH_OPEN}px;
      box-shadow:
        0 0 25px rgba(0, 0, 0, 0.4),
        1px 0 5px rgba(0, 0, 0, 0.15);
    `}
`;

export const SideBarTitleContainer = styled.div`
  overflow: hidden;
  width: ${LOGO_HEIGHT}px;
  height: ${LOGO_HEIGHT}px;
  margin: ${(NAV_BAR_WIDTH - LOGO_HEIGHT) / 2}px;
  position: relative;
  ${StyledSideBar}:hover & {
    width: 100%;
  }
`;

export const LogoLink = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
`;

export const Logo = styled(LogoSvg)`
  fill: white;
  width: ${LOGO_WIDTH}px;
  height: ${LOGO_HEIGHT}px;
  .omnis-logo-word {
    ${textHiddenStyles};
  }
  ${StyledSideBar}:hover & {
    .omnis-logo-word {
      ${textVisibleStyles}
      transition-delay: ${OPENING_DELAY_MS}ms;
    }
  }
`;

export const NavItemIcon = styled.span<{ $isExternal?: boolean }>`
  height: ${SIDE_BAR_LINK_HEIGHT}px;
  min-width: ${NAV_BAR_WIDTH}px;
  max-width: ${NAV_BAR_WIDTH}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  ${({ $isExternal }) => $isExternal && textHiddenStyles}
  ${StyledSideBar}:hover & {
    ${textVisibleStyles}
  }
  ${({ theme }) =>
    theme.breakpoints.down(
      'sm',
      css`
        opacity: 1;
      `
    )}
`;

export const SideBarLinkList = styled.div`
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  a {
    text-decoration: none;
  }
`;

export const NavItemLinkTitle = styled.span`
  flex: 1 1 1px;
  white-space: nowrap;
  overflow: hidden;
  font-size: ${SIDE_BAR_LINK_FONT_SIZE};
  text-align: left;
  ${({ theme }) =>
    theme.breakpoints.up(
      'sm',
      css`
        ${textHiddenStyles}
        ${StyledSideBar}:hover & {
          ${textVisibleStyles}
        }
      `
    )}
`;
