import { SPECTROGRAM_MAX_FREQUENCY } from 'app/views/alerts/components/event-noise-playback/wave-surfer-playback/WaveSurferConstants';
import { MutableRefObject, RefObject, useEffect } from 'react';
import WaveSurfer from 'wavesurfer.js';
import SpectrogramPlugin from 'wavesurfer.js/dist/plugins/spectrogram';
import { useColorMap } from './useColorMap';

export const useSpectrogram = ({
  spectrogramContainerRef,
  waveSurferRef,
}: {
  spectrogramContainerRef: RefObject<HTMLDivElement>;
  waveSurferRef: MutableRefObject<WaveSurfer | undefined>;
}) => {
  const waveSurfer = waveSurferRef.current;
  const spectrogramContainer = spectrogramContainerRef.current;
  const spectrogramColorMap = useColorMap();
  useEffect(() => {
    if (!spectrogramContainer) return;
    if (!waveSurfer) return;
    const plugins = waveSurfer.getActivePlugins();
    if (plugins.some(plugin => 'container' in plugin && Object.is(plugin.container, spectrogramContainer))) return;
    waveSurfer.registerPlugin(
      SpectrogramPlugin.create({
        container: spectrogramContainer,
        colorMap: spectrogramColorMap,
        labels: true,
        frequencyMax: SPECTROGRAM_MAX_FREQUENCY,
      })
    );
  }, [spectrogramContainer, spectrogramColorMap, waveSurfer]);
};
